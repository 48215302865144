import { PatientsBirthdateChangeState, PatientsBirthdateChangeAction } from "./patients.birthdate.change.types"

export const initialPatientsBirthdateState: PatientsBirthdateChangeState = {
  fetching: false,
  message: '',
}

const PatientsBirthdateChangeReducer = (state = initialPatientsBirthdateState, action: PatientsBirthdateChangeAction): PatientsBirthdateChangeState => {
  switch (action.type) {
    case 'PATIENT_BIRTHDATE_CHANGE_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENT_BIRTHDATE_CHANGE_SUCCESS':
    case 'PATIENT_BIRTHDATE_CHANGE_FAILURE':
      return {
        ...state,
        message: action.params,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
    case 'PATIENTS_CLEAR':
      return {
        ...initialPatientsBirthdateState,
      }
    default:
      return state
  }
}

export default PatientsBirthdateChangeReducer