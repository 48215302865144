import { PricesByDoctorAction, PricesByDoctorState } from "./pricelist.prices.bydoctor.types"

export const initialPricesByDoctorState: PricesByDoctorState = {
  fetching: false,
  prices: [],
}

const PricesByDoctorReducer = (state = initialPricesByDoctorState, action: PricesByDoctorAction): PricesByDoctorState => {
  switch (action.type) {
    case 'PRICES_BY_DOCTOR_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'PRICES_BY_DOCTOR_SUCCESS':
      return {
        ...state,
        fetching: false,
        prices: action.params,
      }
    case 'PRICES_BY_DOCTOR_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricesByDoctorState,
      }
    default:
      return state
  }
}

export default PricesByDoctorReducer