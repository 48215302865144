import React from 'react'
import { Appointment as IAppointment } from '../../api/appointment.types'
import JournalItem, { ButtonsProps } from '../JournalItem'
import './style.less'

export type FutureAppointmentProps = {
  appointment: IAppointment
  onPay?: (appointment: IAppointment) => void
  onDelete?: (appointment: IAppointment) => void
  onInfo?: (appointment: IAppointment) => void
}

class FutureAppointment extends React.Component<FutureAppointmentProps> {
  private getButtons = (): ButtonsProps['buttons'] => {
    if (this.isPaymentRequire()) {
      return [this.payButton(), this.deleteButton()]
    }
    return [this.deleteButton()]
  }

  private isPaymentRequire = () => {
    const { appointment } = this.props
    const { is_tm, paid, dms, paysum: price = appointment.appointment_cost } = appointment
    return !paid && !dms && Boolean(price) && !is_tm
  }

  private payButton = () => {
    return {
      type: 'primary' as const,
      text: 'Оплатить',
      onClick: this.onPay,
    }
  }

  private deleteButton = () => {
    return {
      type: 'default' as const,
      text: 'Отменить запись',
      onClick: this.onDelete,
    }
  }

  private onPay = () => {
    const { appointment, onPay } = this.props
    onPay && onPay(appointment)
  }

  private onDelete = () => {
    const { appointment, onDelete } = this.props
    onDelete && onDelete(appointment)
  }

  private onInfo = () => {
    const { appointment, onInfo } = this.props
    onInfo && onInfo(appointment)
  }

  render() {
    const { appointment } = this.props
    const {
      appointment_date_time: datetime,
      paysum: price = appointment.appointment_cost,
      appointment_spec: specialization,
      appointment_doctor_name: doctorName,
      division: address,
      appointment_office_number: office,
      is_tm,
    } = appointment
    return <JournalItem
      datetime={datetime}
      datetimeActive
      price={price}

      specialization={specialization}
      onInfo={this.onInfo}
      is_tm={is_tm}

      doctorName={doctorName}
      address={address}
      office={office}

      buttons={this.getButtons()}

      showPayInfo={this.isPaymentRequire()}
    />
  }
}

export default FutureAppointment