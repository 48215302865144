import { DoctorsAllState, DoctorsAllAction } from './doctors.all.types'
export const initialDoctorsAllState: DoctorsAllState = {
  fetching: false,
  doctors: [],
}
const DoctorsAllReducer = (state = initialDoctorsAllState, action: DoctorsAllAction): DoctorsAllState => {
  switch (action.type) {
    case 'DOCTORS_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DOCTORS_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        doctors: action.params,
      }
    case 'DOCTORS_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorsAllState
      }
    default:
      return state
  }
}
export default DoctorsAllReducer