import { MyMedicAppAction, MyMedicAppState } from "./mymedic.app.types"

export const initialMyMedicAppState: MyMedicAppState = {
  fetching: false,
  app: {},
}

const MyMedicAppReducer = (state = initialMyMedicAppState, action: MyMedicAppAction): MyMedicAppState => {
  switch (action.type) {
    case 'MYMEDIC_APP_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'MYMEDIC_APP_SUCCESS':
      return {
        ...state,
        fetching: false,
        app: action.params,
      }
    case 'MYMEDIC_APP_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMyMedicAppState,
      }
    default:
      return state
  }
}

export default MyMedicAppReducer