import { IPatient, ISortablePatient } from '../types'

export interface IPatientsState {
  data: IPatient[]
  fetching: boolean
}

export const PATIENTS_BY_PHONE_SORT = 'PATIENTS_BY_PHONE_SORT'
export const PATIENTS_BY_PHONE_GET = 'PATIENTS_BY_PHONE_GET'
export const PATIENTS_BY_PHONE_SET = 'PATIENTS_BY_PHONE_SET'

export const patientsByPhoneGetAction = () => ({ type: PATIENTS_BY_PHONE_GET })
export const patientsByPhoneSetAction = (patients: IPatient[]) => ({
  type: PATIENTS_BY_PHONE_SET,
  patients,
})
export const patientsByPhoneSortAction = (sort: ISortablePatient[]) => ({
  type: PATIENTS_BY_PHONE_SORT,
  params: { sort },
})

const initialState: IPatientsState = {
  data: [],
  fetching: false,
}

const PatientsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PATIENTS_BY_PHONE_SORT:
      return { ...state, fetching: true }
    case PATIENTS_BY_PHONE_GET:
      return { ...initialState, fetching: true }
    // case PATIENTS_BY_PHONE_SET:
    //   return {
    //     ...state,
    //     fetching: false,
    //     data: action.patients.sort((a: IPatient, b: IPatient) => a.weight - b.weight),
    //   }
    default:
      return state
  }
}

export default PatientsReducer
