import { Button } from 'antd'
import React from 'react'
import DoctorCardTemplate, { DoctorCardTemplateProps } from '../DoctorCardTemplate'

export type DoctorCardMainProps = DoctorCardTemplateProps & {
  onClick?: () => void
  buttonText?: string
}

class DoctorCardMain extends React.Component<DoctorCardMainProps> {
  render() {
    const { avatar, name, spec, onClick, buttonText, onDoctor, onInfo } = this.props
    return <DoctorCardTemplate
      avatar={avatar}
      name={name}
      spec={spec}
      onDoctor={onDoctor}
      onInfo={onInfo}
    >
      {<Button type="default" className="doctor" onClick={onClick}>{buttonText || 'Выбрать'}</Button>}
    </DoctorCardTemplate>
  }
}

export default DoctorCardMain