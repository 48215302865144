import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../../store'
import { authByLoginRequest } from '../../../store/reducks/auth/auth.actions'
import MedcardAuth from '../Auth'
import './style.less'

export type ResearchAuthProps = ReduxConnectedProps & {
  onRecovery?: () => void
}

class ResearchAuth extends React.Component<ResearchAuthProps> {
  private onAuth = (login: string, password: string) => {
    this.props.authByLoginRequest({ login, password })
  }
  private onRecovery = () => {
    const { onRecovery } = this.props
    onRecovery && onRecovery()
  }

  render() {
    return <div className="researches-auth">
      <div className="title">
        <span>Для просмотра анализов требуется войти в медкарту</span>
      </div>
      <MedcardAuth
        onAuth={this.onAuth}
        onRecovery={this.onRecovery}
      />
    </div>
  }
}

const connector = connect(
  (state: AppState) => ({
  }), {
    authByLoginRequest,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(ResearchAuth)