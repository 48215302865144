import React from 'react'
import Template from '../../components/Template'
import MedcardHeader from '../../components/Medcard/Header'
import { Patient } from '../../api/patient.types'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { medcardOnlineVisitsAllRequestAction } from '../../store/reducks/medcard/medcard.online_visits.all.actions'
import { medcardOnlineVisitsByPatientRequestAction } from '../../store/reducks/medcard/medcard.online_visits.bypatient.actions'
import { medcardVisitsAllRequestAction } from '../../store/reducks/medcard/medcard.visits.all.actions'
import { medcardVisitsByPatientRequestAction } from '../../store/reducks/medcard/medcard.visits.bypatient.actions'
import { medcardViewPatientSetAction } from '../../store/reducks/medcard/medcard.view.actions'
import { patientsByphoneRequest } from '../../store/reducks/patients/patients.byphone.actions'
import MedcardVisit from '../../components/Medcard/Visit'
import MedcardAuthHeader from '../../components/Medcard/AuthHeader'
import { VisitItem } from '../../api/medcard.types'
import './style.less'
import { medcardAuthModalSetAction } from '../../store/reducks/modals/modals.medcardAuth.actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { CatalogPreview, Price } from '../../api/pricelist.types'
import { recordClearAction, recordStateSetAction } from '../../store/reducks/record/record.actions'
import config from '../../config'
import querystring from 'querystring'
import moment from 'moment'
import EmptyJournal from '../../components/EmptyJournal'
import { recoveryViewSetAction } from '../../store/reducks/recovery/recovery.view.actions'

export type MedcardViewProps = ReduxConnectedProps & RouteComponentProps & {}

export type MedcardViewState = {
  serviceName?: string
  startDate?: string
  endDate?: string
  visits: VisitItem[]
}

class MedcardView extends React.Component<MedcardViewProps, MedcardViewState> {
  constructor(props: MedcardViewProps) {
    super(props)
    this.state = {
      visits: []
    }
  }

  componentDidMount() {
    this.props.patientsByphoneRequest()
  }

  componentDidUpdate(prevProps: MedcardViewProps, prevState: MedcardViewState) {
    const { patient, user } = this.props
    if (
      (patient.medic_user_id !== prevProps.patient.medic_user_id)
      || (!this.patientsFetching(this.props) && this.patientsFetching(prevProps))
      || (user.authByLogin && !prevProps.user.authByLogin)
    ) {
      this.fetchVisits()
    } else if (!this.visitsFetching(this.props) && this.visitsFetching(prevProps)) {
      const visits = this.getVisits()
      this.setState({ visits, serviceName: '', startDate: undefined, endDate: undefined })
    }
  }

  private fetchVisits = () => {
    const { patient, user } = this.props
    const { medic_user_id } = patient
    if (!medic_user_id && patient.name) {
      this.props.medcardOnlineVisitsAllRequestAction()
      if (user.authByLogin) {
        this.props.medcardVisitsAllRequestAction()
      }
    }
    if (medic_user_id && patient.name) {
      this.props.medcardOnlineVisitsByPatientRequestAction({ medic_user_id })
      if (user.authByLogin) {
        this.props.medcardVisitsByPatientRequestAction({ medic_user_id })
      }
    }
  }

  private patientsFetching = (props: MedcardViewProps) => {
    const { patients_byphone } = props
    return patients_byphone.main.fetching || patients_byphone.added.fetching
  }

  private visitsFetching = (props: MedcardViewProps) => {
    const { patient, online_visits, visits } = props
    if (patient.medic_user_id && patient.name) {
      return online_visits.bypatient.fetching || visits.bypatient.fetching
    }
    if(!patient.medic_user_id && patient.name) {
      return online_visits.all.fetching || visits.all.fetching
    }
    return false
  }

  private getVisits = () =>  {
    const { online_visits, visits: offline_visits, patient, user } = this.props
    const visits: VisitItem[] = []
    if (!patient.medic_user_id && patient.name) {
      visits.push(...online_visits.all.visits)
      if (user.authByLogin) {
        visits.push(...offline_visits.all.visits)
      }
    }
    if (patient.medic_user_id && patient.name) {
      visits.push(...online_visits.bypatient.visits)
      if (user.authByLogin) {
        visits.push(...offline_visits.bypatient.visits)
      }
    }
    return visits.filter((visit, index, array) => {
      return array.findIndex(v => v.visit_id == visit.visit_id) === index
    })
  }

  private renderHeader = () => {
    const { serviceName } = this.state
    const { patients_byphone, patient, user } = this.props
    return <>
    <MedcardHeader
      searchValue={serviceName}
      onSearch={this.onSearch}
      onDateRange={this.onDateRange}
      onDateCancel={this.onDateCancel}
      mainPatients={patients_byphone.main.users}
      addedPatients={patients_byphone.added.users}
      onChangePatient={this.onPatient}
      selectedPatient={patient}
    />
    {user.authByLogin ? null : <MedcardAuthHeader onAuth={this.openAuthModal}/>}
    </>
  }
  private onSearch = (serviceName: string) => {
    this.setState({ serviceName })
  }
  private onDateRange = (ranges: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = ranges
    // @ts-ignore
    this.setState({ startDate, endDate })
  }
  private onDateCancel = () => {
    this.setState({ startDate: undefined, endDate: undefined })
  }
  private onPatient = (patient: Patient) => {
    this.props.medcardViewPatientSetAction(patient)
  }
  private openAuthModal = () => {
    this.props.medcardAuthModalSetAction({
      visible: true,
      onRecovery: () => {
        this.props.recoveryViewSetAction({ path: '/medcard' })
        this.props.history.push('/recovery')
      }
    })
  }

  private filterVisits = () => {
    const { visits, serviceName, startDate, endDate } = this.state
    const searchByService = serviceName && serviceName.trim()
    const searchByDateRange = startDate && endDate
    if (!searchByService && !searchByDateRange) {
      return visits
    }
    if (!searchByDateRange && searchByService) {
      return visits.filter(({ pls_name }) => {
        // @ts-ignore
        return pls_name.toLowerCase().includes(serviceName.trim().toLowerCase())
      })
    }
    if (!searchByService && searchByDateRange) {
      return visits.filter((visit) => {
        const visitDate = moment(visit.visit_date)
        return visitDate.isSameOrAfter(startDate) && visitDate.isSameOrBefore(endDate)
      })
    }
    return visits.filter((visit) => {
      const visitDate = moment(visit.visit_date)
      // @ts-ignore
      return visit.pls_name.toLowerCase().includes(serviceName.trim().toLowerCase())
        && visitDate.isSameOrAfter(startDate) && visitDate.isSameOrBefore(endDate)
    })
  }

  private renderVisit = (visit: VisitItem, index: number) => {
    return <MedcardVisit
      key={index}
      visit={visit}
      onProtocol={this.onProtocol}
      onRepeat={this.onRepeat}
    />
  }

  private onProtocol = (visit: VisitItem) => {
    const { user } = this.props
    const { visit_id } = visit
    const query = {
      visit_id,
      login: user.phone,
      token: user.token,
    }
    const url = `${config.api.telemedURL}/api/appointment/protocol.pdf?${querystring.stringify(query)}`
    window.open(url)
  }

  private onRepeat = (visit: VisitItem) => {
    const { history } = this.props
    const { service_id, pls_id, is_tm, medic_user_id } = visit
    const patient = this.findPatient(medic_user_id)
    this.props.recordClearAction()
    this.props.recordStateSetAction({
      is_repeat: true,
      stage: 5,
      format: is_tm ? 'online' : 'offline',
      patient: patient as Patient,
      catalog: {} as CatalogPreview,
      service: { service_id, pls_id } as Price,
    })
    history.replace('/recording')
  }

  private findPatient = (medic_user_id: number) => {
    const { patient } = this.props
    if (patient.medic_user_id === medic_user_id) {
      return patient
    }
    const { patients_byphone } = this.props
    const patient_main = patients_byphone.main.users.find(u => u.medic_user_id === medic_user_id)
    if (patient_main) {
      return patient_main
    }
    const patient_added = patients_byphone.added.users.find(u => u.medic_user_id === medic_user_id)
    return patient_added || { medic_user_id }
  }

  private renderEmptyList = () => {
    return <EmptyJournal
      text="Здесь будут отображаться ваши визиты на прием"
    />
  }

  render() {
    const visits = this.filterVisits()
    return <Template
      title="Медкарта"
      className="medcard"
      sidebar={<div className="sidebar-default"/>}
      headerFooter={this.renderHeader()}
      currentItem="medcard"
    >
      {visits.length ? visits.map(this.renderVisit) : this.renderEmptyList()}
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    user: state.user,

    patient: state.medcard.view.patient,
    online_visits: state.medcard.online_visits,
    visits: state.medcard.visits,

    patients_byphone: state.patients.byphone,
  }), {
  patientsByphoneRequest,

  medcardViewPatientSetAction,

  medcardOnlineVisitsAllRequestAction,
  medcardOnlineVisitsByPatientRequestAction,

  medcardVisitsAllRequestAction,
  medcardVisitsByPatientRequestAction,

  medcardAuthModalSetAction,

  recordClearAction,
  recordStateSetAction,

  recoveryViewSetAction,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(MedcardView))