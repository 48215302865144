import config from "../config";
import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { AutoPayment } from "./autoPayment.types";

class AutoPaymentAPI {
  private static GET_AUTO_PAYMENTS = `${config.api.telemedURL}/api/ecom/auto_payments/appointments` as const
  private static GET_AUTO_DEBTS = `${config.api.telemedURL}/api/ecom/auto_payments/debts` as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(AutoPaymentAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    return res
  }

  getAutoPayments = async () => {
    const res = await this.request<{ auto_payments: AutoPayment[] }, { message: string }>({
      method: 'get',
      url: AutoPaymentAPI.GET_AUTO_PAYMENTS,
    })
    return res
  }
  getAutoPaymentsDebts = async () => {
    const res = await this.request<{ has_debt: boolean; debts: AutoPayment[] }, { message: string }>({
      method: 'get',
      url: AutoPaymentAPI.GET_AUTO_DEBTS,
    })
    return res
  }
}

export default AutoPaymentAPI