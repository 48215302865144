import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { ArticlePreview } from '../../../api/newsfeed.types'
import { CatalogPreview } from '../../../api/pricelist.types'
import { AppState } from '../../../store'
import { articlesAllRequestAction } from '../../../store/reducks/articles/articles.all.actions'
import ArticleList from '../../ArticleList'
import TemplateContent from '../../Template/content'

export type ArticlesProps = ReduxConnectedProps & {
  catalog: CatalogPreview
  onBack?: () => void
  onSelect?: (article: ArticlePreview) => void
}

export type ArticlesState = {
  articlesLoaded?: boolean
}

class Articles extends React.Component<ArticlesProps, ArticlesState> {
  constructor(props: ArticlesProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { catalog } = this.props
    const { service_id } = catalog
    this.props.articlesAllRequestAction({ service_id })
  }

  componentDidUpdate(prevProps: ArticlesProps) {
    const { articles } = this.props
    if (!articles.fetching && !articles.error && prevProps.articles.fetching) {
      this.setState({ articlesLoaded: true })
    }
  }

  render() {
    const { catalog, articles } = this.props
    const { articlesLoaded } = this.state
    return <TemplateContent
      title={catalog.service_name}
      subTitle="Статьи"
      // onTitleBack={this.props.onBack}
      onSubTitleBack={this.props.onBack}
    >
      {articlesLoaded && <ArticleList
        articles={articles.articles}
        onSelect={this.props.onSelect}
      />}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    articles: state.articles.all,
  }), {
  articlesAllRequestAction,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>


export default connector(Articles)