import moment from 'moment'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { Patient } from '../../api/patient.types'
import { ResearchItem } from '../../api/research.types'
import EmptyJournal from '../../components/EmptyJournal'
import MedcardHeader from '../../components/Medcard/Header'
import Research from '../../components/Medcard/Research'
import ResearchAuth from '../../components/Medcard/ResearchAuth'
import Template from '../../components/Template'
import config from '../../config'
import { AppState } from '../../store'
import { patientsByphoneRequest, patientsRequest } from '../../store/reducks/patients/patients.actions'
import { researchesAllRequestAction } from '../../store/reducks/researches/researches.all.actions'
import { researchesByPatientRequestAction } from '../../store/reducks/researches/researches.bypatient.actions'
import { researchesFileRequestAction } from '../../store/reducks/researches/researches.file.actions'
import { researchesViewPatientSetAction } from '../../store/reducks/researches/researches.view.actions'
import researchesNotFound from '../../images/researchesNotFound.png'
import saveBase64File from '../../tools/saveBase64File'
import saveLinkFile from '../../tools/saveLinkFile'
import './style.less'
import { recoveryViewSetAction } from '../../store/reducks/recovery/recovery.view.actions'

export type MedcardResearchesViewProps = RouteComponentProps & ReduxConnectedProps & {}

export type MedcardResearchesViewState = {
  serviceName?: string
  startDate?: string
  endDate?: string
  researches: ResearchItem[]
}
class MedcardResearchesView extends React.Component<MedcardResearchesViewProps, MedcardResearchesViewState> {
  constructor(props: MedcardResearchesViewProps) {
    super(props)
    this.state = {
      researches: [],
    }
  }

  componentDidMount() {
    this.fetchPatients()
  }

  componentDidUpdate(prevProps: MedcardResearchesViewProps) {
    const { patient, user } = this.props
    if (!user.authByLogin) {
      return
    }
    if (
      (patient.medic_user_id !== prevProps.patient.medic_user_id)
      || (!this.patientsFetching(this.props) && this.patientsFetching(prevProps))
      || (user.authByLogin && !prevProps.user.authByLogin)
    ) {
      this.fetchResearches()
    } else if (!this.researchesFetching(this.props) && this.researchesFetching(prevProps)) {
      const researches = this.getResearches()
      this.setState({ researches, serviceName: '', startDate: undefined, endDate: undefined })
    }

    const { file } = this.props
    if (!file.fetching && prevProps.file.fetching && !file.error) {
      const { research_file: base64, research_filename: filename, research_mimetype: mime, url } = file.file
      if (base64 && mime) {
        saveBase64File(`data:${mime};base64,${base64}`, filename)
      } else if (url) {
        saveLinkFile(`${config.api.baseURL}${url}`, filename)
      }
    }
  }

  private fetchPatients = () => {
    const { user } = this.props
    if (user.authByLogin) {
      this.props.patientsRequest()
    } else {
      this.props.patientsByphoneRequest()
    }
  }
  private patientsFetching(props: MedcardResearchesViewProps) {
    const { patients_byphone, patients_bymistoken } = props
    return patients_byphone.main.fetching || patients_byphone.added.fetching
      || patients_bymistoken.main.fetching || patients_bymistoken.added.fetching
  }

  private fetchResearches = () => {
    const { patient } = this.props
    const { medic_user_id } = patient
    if (typeof medic_user_id != 'number') {
      // patient не выбран
      return
    }
    if (medic_user_id === 0) {
      this.props.researchesAllRequestAction()
    } else {
      this.props.researchesByPatientRequestAction({ medic_user_id })
    }
  }

  private researchesFetching(props: MedcardResearchesViewProps) {
    const { researches_bypatient, researhces_all } = props
    return researhces_all.fetching || researches_bypatient.fetching
  }

  private getResearches = () => {
    const { patient, researches_bypatient, researhces_all } = this.props
    if (patient.medic_user_id) {
      return researches_bypatient.researches
    }
    return researhces_all.researches
  }

  private renderHeader = () => {
    const { serviceName } = this.state
    const { patients_byphone, patient } = this.props
    return <MedcardHeader
      searchValue={serviceName}
      onSearch={this.onSearch}
      onDateRange={this.onDateRange}
      onDateCancel={this.onDateCancel}
      mainPatients={patients_byphone.main.users}
      addedPatients={patients_byphone.added.users}
      onChangePatient={this.onPatient}
      selectedPatient={patient}
    />
  }

  private onSearch = (serviceName: string) => {
    this.setState({ serviceName })
  }
  private onDateRange = (ranges: { startDate: string; endDate: string }) => {
    const { startDate, endDate } = ranges
    // @ts-ignore
    this.setState({ startDate, endDate })
  }
  private onDateCancel = () => {
    this.setState({ startDate: undefined, endDate: undefined })
  }
  private onPatient = (patient: Patient) => {
    this.props.researchesViewPatientSetAction(patient)
  }

  private renderItem = (research: ResearchItem, index: number) => {
    const { patient } = this.props
    return <Research
      key={index}
      research={research}
      patient={patient as Patient}
      onClick={this.onDownload}
    />
  }

  private onDownload = (research: ResearchItem) => {
    const { research_id } = research
    this.props.researchesFileRequestAction({ research_id })
  }

  private filterResearches = () => {
    const { researches, serviceName, startDate, endDate } = this.state
    const searchByService = serviceName && serviceName.trim()
    const searchByDateRange = startDate && endDate
    if (!searchByService && !searchByDateRange) {
      return researches
    }
    if (!searchByDateRange && searchByService) {
      return researches.filter((research) => {
        // @ts-ignore
        return research.research_name.toLowerCase().includes(serviceName.trim().toLowerCase())
      })
    }
    if (!searchByService && searchByDateRange) {
      return researches.filter((research) => {
        const researchDate = moment(research.research_date)
        return researchDate.isSameOrAfter(startDate) && researchDate.isSameOrBefore(endDate)
      })
    }
    return researches.filter((research) => {
      const researchDate = moment(research.research_date)
      // @ts-ignore
      return research.research_name.toLowerCase().includes(serviceName.trim().toLowerCase())
        && researchDate.isSameOrAfter(startDate) && researchDate.isSameOrBefore(endDate)
    })
  }

  private renderEmptyList = () => {
    return <EmptyJournal
      icon={<img src={researchesNotFound} />}
      text="Здесь будут отображаться результаты анализов"
    />
  }

  private onRecovery = () => {
    this.props.recoveryViewSetAction({ path: '/researches' })
    this.props.history.push('/recovery')
  }

  render() {
    const { user } = this.props
    const researches = this.filterResearches()
    return <Template
      className="researches"
      title="Анализы"
      sidebar={<div className="sidebar-default" />}
      currentItem="researches"
      headerFooter={user.authByLogin ? this.renderHeader() : undefined}
    >
      {user.authByLogin && researches.length > 0 && researches.map(this.renderItem)}
      {user.authByLogin && researches.length == 0 && this.renderEmptyList()}
      {!user.authByLogin && <ResearchAuth onRecovery={this.onRecovery}/>}
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    user: state.user,
    patients_byphone: state.patients.byphone,
    patients_bymistoken: state.patients.bymistoken,
    researhces_all: state.researches.all,
    researches_bypatient: state.researches.bypatient,
    patient: state.researches.view.patient,
    file: state.researches.file,
  }), {
    researchesAllRequestAction,
    researchesByPatientRequestAction,
    researchesViewPatientSetAction,
    patientsRequest,
    patientsByphoneRequest,
    researchesFileRequestAction,

    recoveryViewSetAction,
  }
)



type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(MedcardResearchesView))