import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { AppState } from '../../../store'
import { programsItemRequestAction } from '../../../store/reducks/programs/programs.item.actions'
import TemplateContent from '../../Template/content'
import ProgramInfo from '../../ProgramInfo'

export type ProgramProps = ReduxConnectedProps & RouteComponentProps<{ id: string }> & {}

class Program extends React.Component<ProgramProps> {
  componentDidMount() {
    this.fetch()
  }

  private fetch = () => {
    const { match } = this.props
    const { id: program_id }: { id: string } = match.params
    this.props.programsItemRequestAction({ program_id: +program_id })
  }

  private goBack = () => {
    this.props.history.goBack()
  }

  render() {
    const { program } = this.props
    return <TemplateContent
      title="Программа"
      onTitleBack={this.goBack}
    >
      {program.program && <ProgramInfo program={program.program} />}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    program: state.programs.item,
  }), {
  programsItemRequestAction,
}
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Program))