import { AppState } from "../.."
import lazyStore from "../../lazyStore"

export const getUserToken = () => {
  return selectUserToken(lazyStore.store.getState())
}
export const selectUserToken = (state: AppState) => state.user.token

export const getUserPhone = () => {
  return selectUserPhone(lazyStore.store.getState())
}
export const selectUserPhone = (state: AppState) => state.user.phone

export const getUser = () => {
  return selectUser(lazyStore.store.getState())
}
export const selectUser = (state: AppState) => state.user