export const ALLOWED_FILE_EXTENSIONS = [
  'doc',
  'docx',
  'bmp',
  'dcm',
  'jpg',
  'jpeg',
  'pdf',
  'png',
  'rtf',
  'txt',
  'xls',
  'xlsx',
  'heif',
  'hevc',
]
