import { RequestTransform } from "apisauce"
import { getUserToken } from "../../store/reducks/user/user.utils"

const requestTransform: RequestTransform = (request) => {
  if (request.data) {
    request.data.country_code = '7'
    request.data.token = request.data.token || getUserToken()
    request.data.app = 'lk'
  }
  if (request.method === 'get' || request.method === 'head' || request.method == 'delete') {
    request.params = {
      ...request.params,
      app: 'lk',
      token: request.params?.token || getUserToken(),
    }
  }
  if (!request.url?.includes('_bonus_')) {
    if (request.params) {
      request.params.from_lk = true
    }
    if (request.data) {
      request.data.from_lk = true
    }
  }
  if (request.url?.includes('auto_payment')) {
    request.params = {
      ...request.params,
      auth_token: request.params?.token || getUserToken(),
    }
  }
}

export default requestTransform
