import React from 'react'
import config from '../../config'
import { ProgramPreview } from '../../api/newsfeed.types'
import './style.less'

export type ProgramListProps = {
  programs: ProgramPreview[]
  className?: string
  onPress?: (program: ProgramPreview) => void
}

export type ProgramListState = {
  search?: string
}

class ProgramList extends React.Component<ProgramListProps, ProgramListState> {
  constructor(props: ProgramListProps) {
    super(props)
    this.state = {}
  }

  private renderItem = (program: ProgramPreview, index: number) => {
    const _price: number = program.price || 0
    const _discount: number = program.discount || 0
    const _discountPrice = Math.round(_price - _price * _discount / 100)

    return (
      <div key={index} className='program-card' onClick={() => this.onPress(program)}>
        <img src={`${config.staticURL}/programs/${program.image}`} />
        <div className='right'>
          <span className='title'>{program.name}</span>
          {_price > 0 &&
            <div className='cost'>
              <span className='cost__title'>Стоимость</span>
              <span className={`cost__price ${_price != _discountPrice && 'cost__price_cancel'}`}>{_price}₽</span>
              {_price != _discountPrice && <span className='cost__discount'>{_discountPrice}₽</span>}
            </div>
          }
        </div>
      </div>
    )
  }

  private onPress = (program: ProgramPreview) => {
    const { onPress } = this.props
    onPress && onPress(program)
  }

  render() {
    const { className, programs } = this.props
    return <div className={`program-list ${className || ''} ${programs.length ? '' : 'empty'}`}>
      {programs.length > 0 && programs.map((program, index) =>
        <>
          {this.renderItem(program, index)}
          {index < programs.length - 1 && <div key={`divider-${index}`} className='divider' />}
        </>
      )}
    </div>
  }
}

export default ProgramList