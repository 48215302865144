import { combineReducers } from 'redux'
import RecoveryCheckCodeReducer from './recovery.check_code.reducer'
import RecoverySendPasswordReducer from './recovery.send_password.reducer'
import RecoverySendSmsReducer from './recovery.send_sms.reducer'
import RecoveryViewReducer from './recovery.view.reducer'

const RecoveryReducer = combineReducers({
  sendSms: RecoverySendSmsReducer,
  checkCode: RecoveryCheckCodeReducer,
  sendPassword: RecoverySendPasswordReducer,
  view: RecoveryViewReducer,
})

export default RecoveryReducer