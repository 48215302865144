import React from 'react'
import { Button } from 'antd'
import { DoctorPreview } from '../../api/doctor.types'
import DoctorCardTemplate from '../DoctorCardTemplate'
import SearchInput from '../SearchInput'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { recordClearAction, recordStateSetAction } from '../../store/reducks/record/record.actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { doctorInfoModalSetAction } from '../../store/reducks/modals/modals.doctorInfo.actions'
import { doctorByEmployerIdRequestAction } from '../../store/reducks/doctors/doctors.item.actions'

export type DoctorListProps = ReduxConnectedProps & RouteComponentProps & {
  doctors: DoctorPreview[]
  className?: string
  onInfo?: (doctor: DoctorPreview) => void
  onDoctor?: (doctor: DoctorPreview) => void
  onRecord?: (doctor: DoctorPreview) => void
  hideSearch?: boolean
}

export type DoctorListState = {
  search?: string
}

class DoctorList extends React.Component<DoctorListProps, DoctorListState> {
  constructor(props: DoctorListProps) {
    super(props)
    this.state = {}
  }

  private renderSearchBar = () => {
    return <div className="search-bar">
      <SearchInput
        value={this.state.search}
        label="Фамилия, имя врача, специальность"
        onChangeText={(search) => {
          this.setState({ search })
        }}
      />
    </div>
  }

  private filterDoctors = () => {
    const { doctors, hideSearch } = this.props
    const { search } = this.state
    if (!search || hideSearch) {
      return doctors
    }
    const searchText = search.toLowerCase()
    return doctors.filter(doctor => {
      const { doctor_name: name, service_name: service } = doctor
      return name.toLowerCase().includes(searchText)
        || (service && service.toLowerCase().includes(searchText))
    })
  }

  private renderItem = (doctor: DoctorPreview, index: number) => {
    return <div
      className="doctor-item"
      onClick={() => this.onDoctor(doctor)}
      key={index}
    >
      <DoctorCardTemplate
        name={doctor.doctor_name}
        spec={doctor.service_name}
        avatar={`doctors/${doctor.doctor_preview_img}`}
        onInfo={() => this.onInfo(doctor)}
      />
      <Button
        className="record-button"
        type="default"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          this.onRecord(doctor)
        }}
      >
        {'Записаться'}
      </Button>
    </div>
  }

  private onInfo = (doctor: DoctorPreview) => {
    const { onInfo } = this.props
    onInfo && onInfo(doctor)
    this.props.doctorByEmployerIdRequestAction({ employer_id: doctor.doctor_employer_id })
    this.props.doctorInfoModalSetAction({ visible: true })
  }
  private onDoctor = (doctor: DoctorPreview) => {
    const { onDoctor } = this.props
    onDoctor && onDoctor(doctor)
  }
  private onRecord = (doctor: DoctorPreview) => {
    this.props.recordClearAction()
    this.props.recordStateSetAction({
      type: 'bydoctor',
      stage: 2,
      doctor: {
        ...doctor,
        doctor_id: doctor.doctor_employer_id,
      },
      to_doctor: true,
      // @ts-ignore
      catalog: {},
      format: 'offline',
    })
    this.props.history.push('/recording')
    const { onRecord } = this.props
    onRecord && onRecord(doctor)
  }

  public flushSearch = () => {
    this.setState({ search: undefined })
  }

  render() {
    const { className, hideSearch } = this.props
    const doctors = this.filterDoctors()
    return <div className={`doctor-list ${className || ''} ${doctors.length ? '' : 'empty'}`}>
      {!hideSearch && this.renderSearchBar()}
      {doctors.length > 0 && doctors.map(this.renderItem)}
    </div>
  }
}

const connector = connect(
  (state: AppState) => ({

  }), {
  recordClearAction,
  recordStateSetAction,
  doctorInfoModalSetAction,
  doctorByEmployerIdRequestAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(DoctorList))