import { DeleteAppointmentMainAction, DeleteAppointmentMainState } from "./appointments.delete.main.types"

export const initialDeleteAppointmentMainState: DeleteAppointmentMainState = {
  fetching: false,
}

const DeleteAppointmentMainReducer = (state = initialDeleteAppointmentMainState, action: DeleteAppointmentMainAction): DeleteAppointmentMainState => {
  switch (action.type) {
    case 'DELETE_APPOINTMENT_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DELETE_APPOINTMENT_MAIN_SUCCESS':
      return {
        ...state,
        fetching: false,
      }
    case 'DELETE_APPOINTMENT_MAIN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDeleteAppointmentMainState,
      }
    default:
      return state
  }
}

export default DeleteAppointmentMainReducer