import { ScheduleByDateDivisionsDoctorsAction, ScheduleByDateDivisionsDoctorsState } from "./schedule.bydate.divisions_doctors.types"

export const initialScheduleByDateDivisiontsDatetimesState: ScheduleByDateDivisionsDoctorsState = {
  fetching: false,
  divisions: [],
}

const ScheduleByDateDivisionsDoctorsReducer = (state = initialScheduleByDateDivisiontsDatetimesState, action: ScheduleByDateDivisionsDoctorsAction): ScheduleByDateDivisionsDoctorsState => {
  switch (action.type) {
    case 'SCHEDULE_BY_DATE_DIVISIONS_DOCTORS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'SCHEDULE_BY_DATE_DIVISIONS_DOCTORS_SUCCESS':
      return {
        ...state,
        fetching: false,
        divisions: action.params,
      }
    case 'SCHEDULE_BY_DATE_DIVISIONS_DOCTORS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialScheduleByDateDivisiontsDatetimesState,
      }
    default:
      return state
  }
}

export default ScheduleByDateDivisionsDoctorsReducer