import { ErrorAction, ErrorState } from "./error.types"

export const initialErrorState: ErrorState = {
  message: null
}

const ErrorReducer = (state = initialErrorState, action: ErrorAction): ErrorState => {
  switch (action.type) {
    case 'ERROR_CLEAR':
      return {
        ...state,
        message: null,
      }
    case 'ERROR_SET':
      return {
        ...state,
        message: action.message as string,
      }
    default:
      return state
  }
}

export default ErrorReducer