import { takeLatest, all, put, fork } from 'redux-saga/effects'
import { authSendCodeSaga, authCheckCodeSaga } from './auth'
import { patientsByPhoneGetSaga, patientsByPhoneSortSaga } from './patients'
import { pricelistCatalogsGetSaga, pricelistCatalogServicesGetSaga } from './pricelist'
import { AUTH_SEND_CODE, AUTH_CHECK_CODE, authLogoutAction } from '../reducers/auth'
import { PATIENTS_BY_PHONE_SORT, PATIENTS_BY_PHONE_GET } from '../reducers/patients'
import { PRICELIST_CATALOGS_GET, PRICELIST_CATALOG_SERVICES_GET } from '../reducers/pricelist'
import { store } from '..'
import { watchAuthSaga } from '../reducks/auth/auth.saga'
import watchPatients from '../reducks/patients/patients.saga'
// import watchClinic from '../reducks/clinic/clinic.saga'
import watchPricelist from '../reducks/pricelist/pricelist.saga'
import watchSchedule from '../reducks/schedule/schedule.saga'
import watchAppointments from '../reducks/appointments/appointments.saga'
import watchBonus from '../reducks/bonus/bonusSettingsRequestSaga'
import watchChangePassword from '../reducks/changePassword/changePassword.saga'
import watchRegister from '../reducks/register/register.saga'
import watchAutoPayments from '../reducks/autoPayments/autoPayments.saga'
import watchPayment from '../reducks/payment/payment.saga'
import watchMedcard from '../reducks/medcard/medcard.saga'
import watchResearches from '../reducks/researches/researches.saga'
import watchRecovery from '../reducks/recovery/recovery.saga'
import watchMyMedic from '../reducks/mymedic/mymedic.saga'
import watchClinic from '../reducks/clinic/clinic.saga'
import watchPrices from '../reducks/prices/prices.saga'
import watchNews from '../reducks/news/news.saga'
import watchPrograms from '../reducks/programs/programs.saga'
import watchActions from '../reducks/actions/actions.saga'
import watchDoctors from '../reducks/doctors/doctors.saga'
import watchArticles from '../reducks/articles/articles.saga'
import watchFeedBack from '../reducks/feedback/feedback.saga'
import watchChat from '../reducks/chat/chat.saga'

function* watchIncrementAsync() {
  yield takeLatest('persist/REHYDRATE', reset)
  yield fork(watchAuthSaga)
  yield fork(watchPatients)
  // yield fork(watchClinic)
  yield fork(watchPricelist)
  yield fork(watchSchedule)
  yield fork(watchAppointments)
  yield fork(watchBonus)
  yield fork(watchChangePassword)
  yield fork(watchRegister)
  yield fork(watchAutoPayments)
  yield fork(watchPayment)
  yield fork(watchMedcard)
  yield fork(watchResearches)
  yield fork(watchRecovery)
  yield fork(watchMyMedic)
  yield fork(watchClinic)

  // yield fork(watchPrices)
  yield fork(watchNews)
  yield fork(watchPrograms)
  yield fork(watchActions)
  yield fork(watchDoctors)
  yield fork(watchArticles)

  yield fork(watchFeedBack)
  yield fork(watchChat)

  // yield takeLatest(AUTH_SEND_CODE, authSendCodeSaga)
  // yield takeLatest(AUTH_CHECK_CODE, authCheckCodeSaga)
  yield takeLatest(PATIENTS_BY_PHONE_GET, patientsByPhoneGetSaga)
  yield takeLatest(PATIENTS_BY_PHONE_SORT, patientsByPhoneSortSaga)
  yield takeLatest(PRICELIST_CATALOGS_GET, pricelistCatalogsGetSaga)
  yield takeLatest(PRICELIST_CATALOG_SERVICES_GET, pricelistCatalogServicesGetSaga)
}

function* reset() {
  const state = store.getState()
  if (state.auth.fetching) {
    yield put(authLogoutAction())
  }
}

export default function* init() {
  yield all([watchIncrementAsync()])
}
