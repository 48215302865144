import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Button } from 'antd'

import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { bonusPointsRequestAction } from '../../store/reducks/bonus/bonus.points.actions'
import { bonusSettingsRequestAction } from '../../store/reducks/bonus/bonus.settings.actions'

export type BonusPaymentProps = ReduxConnectedProps & {
  price: number
  onPay: (params: { bonusCode: string; bonusPoints: number; }) => void
  onSkip: () => void
}

const BonusProperty: React.FC<{ name: string; value: string | number; className?: string }> = (props) => {
  const { name, value, className } = props
  return <div className={`bonus-property ${className || ''}`}>
    <span className="name">{name}</span>
    <span className="value">{value}</span>
  </div>
}

export type BonusPaymentState = {
  bonusCode?: string
  bonusPoints?: number
  bonusPointsLoaded?: boolean
}

class BonusPayment extends React.Component<BonusPaymentProps, BonusPaymentState> {
  constructor(props: BonusPaymentProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.bonusSettingsRequestAction()
  }

  componentDidUpdate(prevProps: BonusPaymentProps) {
    const { bonus_points } = this.props
    if (prevProps.bonus_points.fetching && !bonus_points.fetching && !bonus_points.error) {
      this.setState({ bonusPointsLoaded: true, bonusPoints: this.getAvailablePoints() })
    }
  }

  private onBonusCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value && value.split('').some(c => (c < '0' || c > '9'))) {
      e.preventDefault()
    } else if (value.length <= 6 ){
      this.setState({ bonusCode: value, bonusPointsLoaded: false })
    }
  }

  private onBonusPoints = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (value && value.split('').some(c => (c < '0' || c > '9'))) {
      return e.preventDefault()
    }
    const points = parseInt(value) || 0
    if (this.getAvailablePoints() < points) {
      e.preventDefault()
    } else {
      this.setState({ bonusPoints: points })
    }
  }

  private getTotalPoints = () => {
    const { bonus_points } = this.props
    return Math.floor(bonus_points.points.amount || 0)
  }

  private renderTotalPrice = () => {
    const { price } = this.props
    return `${price} ₽`
  }

  private getMaxPercent = () => {
    const { bonus_settings } = this.props
    const { max_percent } = bonus_settings.settings
    return max_percent || 0
  }

  private getAvailablePoints = () => {
    const { is_partial, price } = this.props
    if (is_partial) {
      return 0
    }
    const totalPoints = this.getTotalPoints()
    const maxPercentage = this.getMaxPercent()
    const maxPoints = price * maxPercentage / 100;
    const availablePoints = Math.min(totalPoints, maxPoints)
    return Math.max(0, Math.floor(availablePoints))
  }

  private requestBonusPoints = () => {
    const { bonusCode } = this.state
    if (bonusCode) {
      this.props.bonusPointsRequestAction({ bonus_code: bonusCode })
    }
  }

  private renderFinalPrice = () => {
    const { price } = this.props
    const { bonusPoints } = this.state
    const finalPrice = bonusPoints ? Math.max(0, price - bonusPoints) : price
    return `${finalPrice} ₽`
  }

  private onFlush = () => {
    this.setState({ bonusPoints: 0 })
  }

  private onPay = () => {
    const { onPay, onSkip } = this.props
    const { bonusCode, bonusPoints } = this.state
    if (bonusCode) {
      // pay WITH bonuses
      onPay && onPay({ bonusCode, bonusPoints: bonusPoints || 0 })
    } else {
      // pay WITHOUT bonuses
      onSkip && onSkip()
    }
  }

  private onSkip = () => {
    // pay WITHOUT bonuses
    const { onSkip } = this.props
    onSkip && onSkip()
  }

  render() {
    const { bonus_points, bonus_settings } = this.props
    const { bonusCode, bonusPoints, bonusPointsLoaded } = this.state
    return <div className="bonus-payment">
      <div className="bonus-code">
        <span className="code-title">
          {'В мобильном приложении зайдите в раздел «Бонусная программа», нажмите «Показать QR-код» и введите 6 цифр.'}
        </span>
        <div className="code-content">
          <TextField
            type="text"
            autoFocus
            className="text-input"
            value={bonusCode || ''}
            onChange={this.onBonusCode}
            label={'Введите код'}
            variant="outlined"
            size="small"
          />
          <Button
            type="primary"
            onClick={this.requestBonusPoints}
            disabled={!bonusCode || bonusCode.length < 6 || bonusPointsLoaded}
            className="code-button"
          >
            {'Применить'}
          </Button>
        </div>
        {!bonusPointsLoaded && (<Button
          type="default"
          className="skip-button"
          onClick={this.onSkip}
        >
          {'Пропустить'}
        </Button>)}
      </div>
      {bonusPointsLoaded && (<div
        className={`bonus-points ${bonus_settings.fetching || bonus_points.fetching ? 'hidden' : ''}`}
      >
        <div className="bonus-data">
          <BonusProperty name="Всего баллов" value={this.getTotalPoints()} />
          <BonusProperty name="Сумма к оплате" value={this.renderTotalPrice()} />
          <BonusProperty name="Доступно к списанию" value={this.getAvailablePoints()} />
        </div>
        <TextField
          type="text"
          className="text-input"
          value={bonusPoints || ''}
          onChange={this.onBonusPoints}
          label="Использовать баллы"
          variant="outlined"
          size="small"
        />
        <Button
          className="flush-button"
          type="default"
          onClick={this.onFlush}
        >
          {'Не использовать баллы'}
        </Button>
        <BonusProperty className="final-price" name="Сумма к оплате" value={this.renderFinalPrice()}/>
        <Button
          className="pay-button"
          type="primary"
          onClick={this.onPay}
        >
          {'Оплатить'}
        </Button>
      </div>)}
    </div>
  }
}

const connector = connect((state: AppState) => ({
  bonus_settings: state.bonus.settings,
  bonus_points: state.bonus.points,
  is_partial: state.payment.combinedAppointments.is_partial || state.payment.appointmentType.type.is_partial,
}), {
  bonusPointsRequestAction,
  bonusSettingsRequestAction,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(BonusPayment)