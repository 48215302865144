import { put, call } from 'redux-saga/effects'
import { MedicApi } from '../../_api'
import { patientsByPhoneSetAction } from '../reducers/patients'

export function* patientsByPhoneGetSaga() {
  const patients: any[] = yield call(MedicApi.patientsByPhoneGetRequest)
  yield put(patientsByPhoneSetAction(patients))
}

export function* patientsByPhoneSortSaga(action: any) {
  const patients: any[] = yield call(MedicApi.patientsByPhoneSortRequest, action.params)
  yield put(patientsByPhoneSetAction(patients))
}
