import React from 'react'
import { smartBytes } from '../../tools/print-bytes'
import Button from '../Button';
import './style.less'
import { CloseIcon } from '../../icons';

export const InputFile = (props: { file?: { name: string; size: number }, onCancel?: () => any }) => {
  const { file, onCancel } = props;
  if (!file) {
    return null;
  }
  return <div className="input-file">
    <span>
      <span>{file.name}</span>
      <span> ({smartBytes(file.size)})</span>
    </span>
    <Button className={'cancel'} onClick={onCancel}>
      <CloseIcon width={20} height={20} fill={'#B12326'} />
    </Button>
  </div>
}