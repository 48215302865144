import { ScheduleByDateDivisionsDatetimesAction, ScheduleByDateDivisionsDatetimesState } from "./schedule.bydate.divisions_datetimes.types"

export const initialScheduleByDateDivisiontsDatetimesState: ScheduleByDateDivisionsDatetimesState = {
  fetching: false,
  divisions: [],
}

const ScheduleByDateDivisionsDatetimesReducer = (state = initialScheduleByDateDivisiontsDatetimesState, action: ScheduleByDateDivisionsDatetimesAction): ScheduleByDateDivisionsDatetimesState => {
  switch (action.type) {
    case 'SCHEDULE_BY_DATE_DIVISIONS_DATETIMES_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'SCHEDULE_BY_DATE_DIVISIONS_DATETIMES_SUCCESS':
      return {
        ...state,
        fetching: false,
        divisions: action.params,
      }
    case 'SCHEDULE_BY_DATE_DIVISIONS_DATETIMES_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialScheduleByDateDivisiontsDatetimesState,
      }
    default:
      return state
  }
}

export default ScheduleByDateDivisionsDatetimesReducer