import { BonusSettingsAction, BonusSettingsState } from "./bonus.settings.types"

export const initialBonusSettingsState: BonusSettingsState = {
  fetching: false,
  settings: {},
}

const BonusSettingsReducer = (state = initialBonusSettingsState, action: BonusSettingsAction): BonusSettingsState => {
  switch (action.type) {
    case 'BONUS_SETTINGS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        settings: {},
      }
    case 'BONUS_SETTINGS_SUCCESS':
      return {
        ...state,
        fetching: false,
        settings: action.params,
      }
    case 'BONUS_SETTINGS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialBonusSettingsState,
      }
    default:
      return state
  }
}

export default BonusSettingsReducer