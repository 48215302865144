import React from 'react'
import config from '../../config'
import { NoticeIcon } from '../../icons'
import './style.less'

export type DoctorCardTemplateProps = {
  avatar?: string
  name: string
  spec?: string
  onDoctor?: () => void
  onInfo?: () => void
}

class DoctorCardTemplate extends React.Component<DoctorCardTemplateProps> {
  private onDoctor = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onDoctor } = this.props
    if (onDoctor) {
      e.preventDefault()
      e.stopPropagation()
      onDoctor()
    }
  }

  private onInfo = (e: React.MouseEvent<HTMLDivElement>) => {
    const { onInfo } = this.props
    if (onInfo) {
      e.preventDefault()
      e.stopPropagation()
      onInfo()
    }
  }

  private getAvatar = () => {
    const { avatar } = this.props
    if (avatar && avatar.length > 1000) {
      return `data:image/png;base64,${avatar}`
    }
    return `${config.staticURL}/${avatar?.includes('doctors') ? avatar : `doctors/${avatar}`}`
  }

  render() {
    const { name, spec, children } = this.props
    return <div className="doctor-card">
      <div className="avatar" onClick={this.onDoctor}>
        <img src={this.getAvatar()} />
      </div>
      <div className="right">
        <div className="top" onClick={this.onDoctor}>
          <div className="doctor">
            <span className="name">
              {name}
            </span>
            <span className="spec">
              {spec}
            </span>
          </div>
          <div className="info" onClick={this.onInfo}>
            <NoticeIcon />
          </div>
        </div>
        <div className="bottom">
          {children}
        </div>
      </div>
    </div>
  }
}

export default DoctorCardTemplate