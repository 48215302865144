import React, { Component, ReactNode, Children } from 'react'
import { Route, Redirect } from 'react-router-dom'

interface Props {
  children: any
  authorized: boolean
  exact?: boolean
  path: string
}

class PrivateRoute extends Component<Props, {}> {
  render() {
    const { children, authorized, exact = false, path } = this.props
    return (
      <Route
        path={path}
        exact={exact}
        render={({ location }) =>
          authorized ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }
      />
    )
  }
}

export default PrivateRoute
