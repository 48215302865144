import { PatientsByphoneAddedAction, PatientsByphoneAddedState } from "./patients.byphone.added.types"

export const initialPatientsByphoneAddedState: PatientsByphoneAddedState = {
  fetching: false,
  users: [],
}

const PatientsByphoneAddedReducer = (state = initialPatientsByphoneAddedState, action: PatientsByphoneAddedAction): PatientsByphoneAddedState => {
  switch (action.type) {
    case 'PATIENTS_BYPHONE_ADDED_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENTS_BYPHONE_ADDED_SUCCESS':
    case 'PATIENTS_BYPHONE_ADDED_FAILURE':
      return {
        ...state,
        ...action.params,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
    case 'PATIENTS_CLEAR':
      return {
        ...initialPatientsByphoneAddedState,
      }
    default:
      return state
  }
}

export default PatientsByphoneAddedReducer