import React, { Component, ReactNode } from 'react'

interface Props {
  title: string
  description: string
  align: 'left' | 'right'
  image: ReactNode
  onClick: () => void
}

interface State {}

class RecordFormat extends Component<Props, State> {
  render() {
    const { image, align, title, description, onClick } = this.props
    return (
      <div className={`format ${align}`} onClick={onClick}>
        <div className="image">{image}</div>
        <div className="ellipse-wrapper">
          <div className="ellipse"></div>
        </div>
        <div className="info">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    )
  }
}

export default RecordFormat
