import { ProgramsItemState, ProgramsItemAction } from './programs.item.types'

export const initialProgramsItemState: ProgramsItemState = {
  fetching: false,
}

const ProgramsItemReducer = (state = initialProgramsItemState, action: ProgramsItemAction): ProgramsItemState => {
  switch (action.type) {
    case 'PROGRAMS_ITEM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        program: undefined,
      }
    case 'PROGRAMS_ITEM_SUCCESS':
      return {
        ...state,
        fetching: false,
        program: action.params,
      }
    case 'PROGRAMS_ITEM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialProgramsItemState
      }
    default:
      return state
  }
}
export default ProgramsItemReducer