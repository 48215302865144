import { DoctorsViewState, DoctorsViewAction } from './doctors.view.types'

export const initialDoctorsViewState: DoctorsViewState = {
  department_id: 1,
}
const DoctorsViewReducer = (state = initialDoctorsViewState, action: DoctorsViewAction): DoctorsViewState => {
  switch (action.type) {
    case 'DOCTORS_VIEW_PARAMS_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorsViewState
      }
    default:
      return state
  }
}
export default DoctorsViewReducer