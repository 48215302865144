import { FutureAppointmentsMainAction, FutureAppointmentsMainState } from "./appointments.future.main.types"

export const initialFutureAppointmentsMainState: FutureAppointmentsMainState = {
  fetching: false,
  appointments: [],
}

const FutureAppointmentsMainReducer = (state = initialFutureAppointmentsMainState, action: FutureAppointmentsMainAction): FutureAppointmentsMainState => {
  switch (action.type) {
    case 'FUTURE_APPOINTMENTS_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'FUTURE_APPOINTMENTS_MAIN_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: action.params,
      }
    case 'FUTURE_APPOINTMENTS_MAIN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialFutureAppointmentsMainState,
      }
    default:
      return state
  }
}

export default FutureAppointmentsMainReducer