import React, { Component } from 'react'
import telemedicine from '../../images/telemed.png'
import simple from '../../images/medic-build.png'
import RecordFormat from './format'
import Modal from '../../components/Modal'
import './style.less'

interface Props {
  show: boolean
  onCancel: () => void
  onSelect: (selectedFormat: 'offline' | 'online' | null) => void
}

interface State {}

class RecordFormatModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const { onSelect, onCancel } = this.props
    return (
      <Modal
        width={520}
        visible={this.props.show}
        title="1. Выберите формат записи"
        className="record-format-modal"
        onCancel={onCancel}
      >
        <RecordFormat
          image={<img src={simple} alt="" />}
          align="right"
          title={'Прием в клинике'}
          description={'Очный прием врача'}
          onClick={() => {
            onSelect('offline')
          }}
        />
        <RecordFormat
          image={<img src={telemedicine} alt="" />}
          align="left"
          title={'Онлайн - консультация'}
          description={'Как в скайпе, только с врачом'}
          onClick={() => {
            onSelect('online')
          }}
        />
      </Modal>
    )
  }
}

export default RecordFormatModal
