import React, { Component } from 'react'
import { Modal } from '../../components/index'
import './style.less'

interface Props {
  show: boolean
  onCancel: () => void
}

interface State {}

class AgreementModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  static defaultProps = {
    show: false,
  }

  render() {
    const { show, onCancel } = this.props
    return (
      <Modal
        onCancel={onCancel}
        title="Пользовательское соглашение электронных сервисов ООО «Медик»"
        visible={show}
        width={900}
        className="agreement-modal"
      >
        <p>
          <strong>1. Общие положения.</strong>
        </p>
        <p>
          1.1. ООО «Медик» (далее по тексту - Клиника) предлагает своим клиентам - пользователям сети Интернет (далее по
          тексту - Пользователь) использовать электронные сервисы ООО «Медик» на условиях, изложенных в настоящем
          Пользовательском соглашении (далее по тексту - Соглашение).
        </p>
        <p>
          1.2. Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке,
          предусмотренном настоящим Соглашением.
        </p>
        <p>
          1.3. Клиника предлагает Пользователям доступ к широкому спектру сервисов, включая средства ведения и хранения
          медицинской документации, получения удаленных консультаций медицинского персонала, размещения и хранения
          разного рода информации и материалов (контента), персонализации контента, иные сервисы. Все существующие на
          момент выражения Пользователем согласия с условиями Соглашения сервисы, а также любое развитие их и/или
          добавление новых является предметом настоящего Соглашения.
        </p>
        <p>1.4. Право пользования электронными сервисами Клиники предоставляется Пользователю бесплатно.</p>
        <p>
          1.5. Использование электронных сервисов Клиники регулируется настоящим Соглашением, Политикой в отношении
          обработки персональных данных, а также условиями использования отдельных сервисов. Соглашение может быть
          изменено администратором электронных сервисов ООО «Медик» без какого-либо специального уведомления, новая
          редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце
          адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция Пользовательского
          соглашения и Политики в отношении обработки персональных данных всегда находится на официальном сайте ООО
          «Медик» по адресу:{' '}
          <a target="blank" rel="noopener noreferrer" href="https://www.mcmedic.ru">
            https://www.mcmedic.ru
          </a>{' '}
          .
        </p>
        <p>
          1.6. Начиная использовать какой-либо электронный сервис / его отдельные функции и пройдя процедуру
          регистрации, Пользователь считается принявшим условия настоящего Соглашения в полном объеме, без всяких
          оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений настоящего Соглашения,
          Пользователь не в праве использовать электронные сервисы ООО «Медик». В случае если в электронные сервисы
          Клиники и в настоящее Соглашение были внесены какие-либо изменения в порядке, предусмотренном пунктом 1.5.
          настоящего Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование электронных
          сервисов ООО «Медик».
        </p>
        <p>1.7. К электронным сервисам ООО «Медик» относятся:</p>
        <p>
          - официальный сайт ООО «Медик» в сети Интернет -{' '}
          <a target="blank" rel="noopener noreferrer" href="https://www.mcmedic.ru">
            https://www.mcmedic.ru
          </a>{' '}
          ;
        </p>
        <p>
          - сервис обратной связи с Клиникой через официальный сайт ООО «Медик» -{' '}
          <a target="blank" rel="noopener noreferrer" href="https://www.mcmedic.ru/page/ostavit-otzyv">
            https://www.mcmedic.ru/page/ostavit-otzyv
          </a>{' '}
          ;
        </p>
        <p>
          - сервис «Письмо руководителю» через официальный сайт ООО «Медик» -{' '}
          <a target="blank" rel="noopener noreferrer" href="https://www.mcmedic.ru/page/pismo-rukovoditelyu">
            https://www.mcmedic.ru/page/pismo-rukovoditelyu
          </a>{' '}
          ;
        </p>
        <p>
          - сервис «Запись на прием» через официальный сайт ООО «Медик» - сайт Клиники{' '}
          <a target="blank" rel="noopener noreferrer" href="https://www.mcmedic.ru">
            https://www.mcmedic.ru
          </a>{' '}
          , вкладка на главной странице «Запись на прием»;
        </p>
        <p>
          - программа электронной передачи Клиникой медицинских сведений клиентам (пациентам) – Мобильное приложение
          «Частная клиника «МЕДИК» и «Личный Кабинет»
        </p>
        <p>
          <strong>2. Регистрация Пользователя в программе </strong>Мобильное приложение «Частная клиника «МЕДИК» и
          «Личный Кабинет»
        </p>
        <p>
          <strong>Учетная запись Пользователя.</strong>
        </p>
        <p>
          2.1. Для того, чтобы воспользоваться сервисами Мобильного приложения «Частная клиника «МЕДИК» и «Личный
          Кабинет»<strong> </strong> или функциями сервисов, Пользователю необходимо пройти процедуру регистрации в
          программе Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет», в результате которой для
          Пользователя будет создана уникальная учетная запись.
        </p>
        <p>
          2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам,
          предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь
          предоставляет неверную информацию или у Клиники есть основания полагать, что предоставленная Пользователем
          информация неполна или недостоверна, Клиника имеет право по своему усмотрению заблокировать либо удалить
          учетную запись Пользователя и отказать Пользователю в использовании своих сервисов (либо их отдельных
          функций). При изменении персональных данных (в том числе контактных данных: адреса проживания и/или
          регистрации, номера телефона, адреса электронной почты) Пользователь обязан в течение 3 (Трех) календарных
          дней письменно уведомить о произошедших изменениях Клинику.
        </p>
        <p>
          2.3. Клиника оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных,
          указанных при регистрации, и запросить в связи с этим подтверждающие документы, непредоставление которых, по
          усмотрению Клиники, может быть приравнено к предоставлению недостоверной информации и повлечь последствия,
          предусмотренные п. 2.2. настоящего Соглашения.
        </p>
        <p>
          2.4. Персональная информация Пользователя, содержащаяся в учетной записи Пользователя, хранится и
          обрабатывается Клиникой в соответствии с условиями Политики в отношении обработки персональных данных,
          размещенной в открытом доступе на официальном сайте ООО «Медик» по адресу{' '}
          <a target="blank" rel="noopener noreferrer" href="https://www.mcmedic.ru">
            https://www.mcmedic.ru
          </a>{' '}
          .
        </p>
        <p>2.5. Логин и пароль для доступа к учетной записи Пользователя:</p>
        <p>
          2.5.1. Заявка на регистрацию в программе Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет»
          подается в Клинику (через администраторов Клиники) в письменном виде по форме Приложения №1 к настоящему
          Соглашению;
        </p>
        <p>
          2.5.2. При получении от Пользователя заявки на регистрацию в программе Мобильное приложение «Частная клиника
          «МЕДИК» и «Личный Кабинет» (п.2.5.1) Клиника в течение 3 (Трех) календарных дней осуществляет создание учетной
          записи соответствующего Пользователя в программе Мобильное приложение «Частная клиника «МЕДИК» и «Личный
          Кабинет», предоставляя Пользователю временный пароль для первичного доступа к его учетной записи.
        </p>
        <p>
          Указанный временный пароль для первичного доступа к учетной записи Пользователя известен некоторым сотрудникам
          Клиники, осуществляющим обработку заявок на регистрацию в программе Мобильное приложение «Частная клиника
          «МЕДИК» или «Личный Кабинет» и создание учетных записей Пользователей. В связи с этим Пользователь обязан в
          кратчайшие сроки при первом входе в свою учетную запись в программе Мобильное приложение «Частная клиника
          «МЕДИК» или «Личный Кабинет» изменить пароль во избежание несанкционированного доступа третьих лиц к
          персональным данным Пользователя.
        </p>
        <p>
          2.5.3. Клиника вправе устанавливать требования к логину и паролю (длина, допустимые символы и т.д.)
          пользователя в программе Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет».
        </p>
        <p>
          2.5.4. Пользователь самостоятельно несет ответственность за безопасность (устойчивость к угадыванию)
          выбранного им пароля, а также самостоятельно обеспечивает конфиденциальность своего пароля. Пользователь
          самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием
          электронных сервисов ООО «Медик» под учетной записью Пользователя, включая случаи добровольной передачи
          Пользователем данных для доступа к учетной записи Пользователя в программе Мобильное приложение «Частная
          клиника «МЕДИК» или «Личный Кабинет» третьим лицам на любых условиях (в том числе по договорам или
          соглашениям). При этом все действия в рамках или с использованием программы Мобильное приложение «Частная
          клиника «МЕДИК» и «Личный Кабинет» под учетной записью Пользователя считаются произведенными самим
          Пользователем, за исключением случаев, когда Пользователь, в порядке, предусмотренном настоящим Соглашением
          (п.2.5.5), уведомил Клинику о несанкционированном доступе к сервисам программы Мобильное приложение «Частная
          клиника «МЕДИК» и «Личный Кабинет» с использованием учетной записи Пользователя и/или о любом нарушении
          (подозрениях о нарушении) конфиденциальности своего пароля.
        </p>
        <p>
          2.5.5. Пользователь обязан немедленно уведомить Клинику о любом случае несанкционированного (не разрешенного
          Пользователем) доступа к сервисам программы Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет» с
          использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении)
          конфиденциальности своего пароля.
        </p>
        <p>
          2.5.6. В целях безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение работы под
          своей учетной записью (выход из личной учетной записи программы) по окончании каждой сессии работы с сервисами
          программы Мобильное приложение «Частная клиника «МЕДИК» или «Личный Кабинет». Клиника не отвечает за возможную
          потерю или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения
          Пользователем положений этой части Соглашения.
        </p>
        <p>
          2.5.7. Каждый вход в учетную запись Пользователя осуществляется на основании логина, пароля и разового кода
          доступа, пересылаемого Клиникой Пользователю в СМС-сообщении на номер мобильного телефона Пользователя,
          указанный им при регистрации (создании учетной записи) в программе Мобильное приложение «Частная клиника
          «МЕДИК» и «Личный Кабинет» (раздел 2.4). Указанный код является разовым и не подлежит использованию при
          последующем входе в учетную запись пользователя в Мобильное приложение «Частная клиника «МЕДИК» и «Личный
          Кабинет».
        </p>
        <p>2.6. Использование Пользователем своей учетной записи.</p>
        <p>
          2.6.1. Пользователь не в праве воспроизводить, повторять и копировать, продавать и перепродавать, а также
          использовать для каких-либо коммерческих целей какие-либо части сервисов программы Мобильное приложение
          «Частная клиника «МЕДИК» и «Личный Кабинет» (включая контент, доступный Пользователю посредством сервисов),
          или доступ к ним, кроме тех случаев, когда Пользователь получил такое письменное разрешение от Клиники, либо
          когда это прямо предусмотрено Пользовательским соглашением какого-либо электронного сервиса ООО «Медик».
        </p>
        <p>
          2.6.2. Пользователь не имеет права заходить в программу Мобильное приложение «Частная клиника «МЕДИК» и
          «Личный Кабинет» с использованием чужой учетной записи. В случае получения данных, позволяющих осуществить
          вход в программу Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет» под чужой учетной записью
          (завладение логином и паролем других Пользователей программы Мобильное приложение «Частная клиника «МЕДИК» и
          «Личный Кабинет» с их согласия или без такового), Пользователь несет персональную ответственность перед
          другими Пользователями (под учетной записью которых осуществлен вход в программу) за нарушение
          законодательства Российской Федерации о защите персональных данных и врачебной тайны (в том числе, но не
          исключительно: за разглашение любым способом персональных сведений (в том числе медицинских) о других
          Пользователях программы; за использование полученных таким незаконным способом персональных (в том числе
          медицинских) сведений о Пользователях в корыстных целях и т.д.).
        </p>
        <p>
          2.6.3. По завершении сеанса в программе Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет»
          Пользователь обязуется осуществлять каждый раз выход из своей учетной записи во избежание несанкционированного
          доступа третьих лиц к своим персональным данным.
        </p>
        <p>
          2.6.4. Пользователь несет ответственность за получение третьими лицами доступа к его персональным данным,
          содержащимся в его учетной записи в программе Мобильное приложение «Частная клиника «МЕДИК» и «Личный
          Кабинет», в случае добровольной передачи третьим лицам сведений о своем логине и пароле, а также в случае
          невыхода из своей учетной записи по завершении сеанса в программе Мобильное приложение «Частная клиника
          «МЕДИК» и «Личный Кабинет».
        </p>
        <p>
          2.6.5. Персональные сведения (в том числе медицинские, составляющие врачебную тайну) в программе Мобильное
          приложение «Частная клиника «МЕДИК» и «Личный Кабинет» предоставляются Клиникой Пользователю через публичную
          сеть Интернет, в связи с чем Клиника не гарантирует безопасность указанного канала связи с точки зрения
          несанкционированного доступа третьих лиц. Передача Клиникой результатов медицинских исследований через
          публичную сеть «Интернет» потенциально допускает разглашение медицинской тайны (в результате взлома третьими
          лицами учетной записи Пользователя, хакерских атак на сайт Клиники и т.д.).
        </p>
        <p>
          2.6.6. Клиника не несет ответственность за утечку личной информации о Пользователе, возникшую в результате
          умышленных действий третьих лиц по несанкционированному Пользователем доступу к ней.
        </p>
        <p>
          2.6.7. Клиника не несет ответственность за неполучение Пользователем доступа в программу Мобильное приложение
          «Частная клиника «МЕДИК» и «Личный Кабинет» и иным электронным сервисам Клиники вследствие технических
          неполадок в сети «Интернет», в оборудовании Пользователя, через которое им осуществляется доступ к программе.
        </p>
        <p>2.7. Прекращение регистрации.</p>
        <p>
          2.7.1. Администратор веб-сервиса Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет» вправе
          заблокировать или удалить учетную запись Пользователя, а также запретить доступ с использованием какой-либо
          учетной записи к определенным сервисам Мобильное приложение «Частная клиника «МЕДИК» и «Личный Кабинет», и
          удалить любой контент без объяснения причин, в том числе в случае нарушения Пользователем условий Соглашения
          или условий иных документов, предусмотренных п. 1.5. настоящего Соглашения.
        </p>
        <p>2.8. Удаление учетной записи Пользователя.</p>
        <p>
          2.8.1. Пользователь вправе в любой момент удалить свою учетную запись в программе Мобильное приложение
          «Частная клиника «МЕДИК» и «Личный Кабинет», направив письменное заявление в Клинику на бумажном носителе по
          адресу: Чувашская Республика, город Чебоксары, улица Тополиная, дом 11 «А».
        </p>
        <p>
          2.8.2. Удаление учетной записи администратором веб-сервиса Мобильное приложение «Частная клиника «МЕДИК» и
          «Личный Кабинет» осуществляется в следующем порядке:
        </p>
        <p>
          - Учетная запись блокируется на срок один месяц, в течение которого размещенные с ее использованием контент и
          иные Пользовательские данные не удаляются, однако доступ к ним становится невозможен.
        </p>
        <p>
          - Если в течение указанного выше срока учетная запись Пользователя будет восстановлена, доступ к указанным
          данным возобновляется в объеме, существовавшем на момент блокирования (за исключением контента, нарушающего
          условия Соглашения или иных документов, регулирующих соответствующий сервис).
        </p>
        <p>
          - Если в течение указанного выше срока учетная запись Пользователя не будет восстановлена, весь контент,
          размещенный с ее использованием, будет удален. С этого момента восстановление учетной записи, какой-либо
          информации, относящейся к ней, а равно доступов к сервисам Мобильное приложение «Частная клиника «МЕДИК» и
          «Личный Кабинет» с использованием этой учетной записи невозможны.
        </p>
        <p>
          <strong>3. Общие положения об использовании и хранении данных Пользователей.</strong>
        </p>
        <p>
          3.1. Клиника вправе устанавливать ограничения в использовании сервисов для всех Пользователей, либо для
          отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка, на котором
          предоставляется сервис и т.д.), в том числе: наличие / отсутствие отдельных функций сервиса, любого другого
          контента, максимальное количество сообщений, которые могут быть отправлены или получены одним
          зарегистрированным Пользователем, максимальный размер дискового пространства, максимальное количество
          обращений к сервису за указанный период времени, максимальный срок хранения контента, специальные параметры
          загружаемого контента и т.д. Клиника может запретить автоматическое обращение к своим сервисам, а также
          прекратить прием любой информации, сгенерированной автоматически (например, почтового спама).
        </p>
        <p>3.2. Клиника вправе посылать своим Пользователям информационные сообщения.</p>
        <p>
          <strong>4. Контент Пользователя.</strong>
        </p>
        <p>
          4.1. Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем
          контента требованиям действующего законодательства, включая ответственность перед третьим лицами в случаях,
          когда размещение Пользователем того или иного контента или содержание контента нарушает права и законные
          интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих
          лиц, и/или посягает на принадлежащие им нематериальные блага.
        </p>
        <p>
          4.2. Пользователь признает и соглашается с тем, что Клиника не обязана просматривать контент любого вида,
          размещаемый и/или распространяемый Пользователем посредством электронных сервисов Клиники, а также то, что
          Клиника имеет право (но не обязанность) по своему усмотрению отказать Пользователю в размещении и/или
          распространении им контента или удалить любой контент, который доступен посредством электронных сервисов
          Клиники. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с
          использованием контента, включая оценку надежности, полноты или полезности этого контента.
        </p>
        <p>
          4.3. Пользователь осознает и соглашается с тем, что технология работы сервисов может потребовать копирование
          (воспроизведение) контента Пользователя электронных сервисов Клиники, а также переработки его Клиникой для
          соответствия техническим требованиям того или иного сервиса.
        </p>
        <p>
          <strong>5. Условия использования электронных сервисов Клиники.</strong>
        </p>
        <p>
          5.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с
          использованием электронных сервисов Клиники, в том числе, если такие действия приведут к нарушению прав и
          законных интересов третьих лиц, а также за соблюдение законодательства при использовании электронных сервисов
          Клиники.
        </p>
        <p>5.2. При использовании электронных сервисов Клиники Пользователь не вправе:</p>
        <p>
          - Загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, который
          является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует (или является
          пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности, пропагандирует ненависть
          и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит
          оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии,
          детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том
          числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических
          веществ или их аналогов, взрывчатых веществ или иного оружия.
        </p>
        <p>- Нарушать права третьих лиц, в том числе несовершеннолетних, и/или причинять им вред в любой форме.</p>
        <p>
          - Выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав,
          в том числе за сотрудников Клиники, за модераторов форумов, за владельца сайта, а также применять любые другие
          формы и способы незаконного представительства других лиц в сети, а также вводить пользователей или Клинику в
          заблуждение относительно свойств и характеристик каких-либо субъектов или объектов.
        </p>
        <p>
          - Загружать, посылать, передавать или любым другим способом размещать и/или распространять контент, при
          отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям.
        </p>
        <p>
          - Загружать, посылать, передавать или любым другим способом размещать и/или распространять не разрешенную
          специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих адресов электронной
          почты, схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка и e-mail-
          бизнесов, «письма счастья», а также использовать сервисы «OnDoc» для участия в этих мероприятиях, или
          использовать электронные сервисы Клиники, исключительно для перенаправления пользователей на страницы других
          доменов.
        </p>
        <p>
          - Загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо
          материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для нарушения,
          уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования или
          программ, для осуществления несанкционированного доступа, а также серийные номера к коммерческим программным
          продуктам и программы для их генерации, логины, пароли и прочие средства для получения несанкционированного
          доступа к платным ресурсам в Интернете, а также размещения ссылок на вышеуказанную информацию.
        </p>
        <p>- Несанкционированно собирать и хранить персональные данные других лиц.</p>
        <p>- Нарушать нормальную работу веб-сайта и электронных сервисов Клиники.</p>
        <p>- Размещать ссылки на ресурсы сети, содержание которых противоречит действующему законодательству РФ.</p>
        <p>
          - Способствовать действиям, направленным на нарушение ограничений и запретов, налагаемых настоящим
          Соглашением.
        </p>
        <p>- Другим образом нарушать нормы законодательства РФ, а также нормы международного права.</p>
        <p>
          <strong>6. Исключительные права на содержание электронных сервисов Клиники и контент.</strong>
        </p>
        <p>
          6.1. Все объекты, доступ к которым возможен при помощи электронных сервисов Клиники, включая элементы дизайна,
          текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие
          объекты (далее — «содержание сервисов»), а также любой контент, размещенный на электронных сервисах Клиники,
          являются объектами исключительных прав ООО «Медик», Пользователей и других официальных правообладателей.
        </p>
        <p>
          6.2. Использование контента, а также каких-либо иных элементов сервисов возможно только в рамках функционала,
          предлагаемого тем или иным сервисом. Никакие элементы содержания электронных сервисов Клиники, а также любой
          контент, размещенный на электронных сервисах Клиники, не могут быть использованы иным образом без
          предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе: воспроизведение,
          копирование, переработка, распространение на любой основе, отображение во фрейме и т.д. Исключение составляют
          случаи, прямо предусмотренные законодательством РФ или условиями использования того или иного электронного
          сервиса Клиники.
        </p>
        <p>
          6.3. Использование Пользователем элементов содержания сервисов, а также любого контента для личного
          некоммерческого использования, допускается при условии сохранения всех знаков охраны авторского права, смежных
          прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования
          правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение
          составляют случаи, прямо предусмотренные законодательством РФ или Пользовательскими соглашениями того или
          иного электронного сервиса Клиники.
        </p>
        <p>
          <strong>7. Сайты и контент третьих лиц.</strong>
        </p>
        <p>
          7.1. Электронные сервисы Клиники могут содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц).
          Указанные третьи лица и их контент не проверяются Клиникой на соответствие тем или иным требованиям
          (достоверности, полноты, законности и т.п.). Клиника не несет ответственность за любую информацию, материалы,
          размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием электронных
          сервисов Клиники, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц, рекламу и
          т.п., а также за доступность таких сайтов или контента и последствия их использования Пользователем.
        </p>
        <p>
          7.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого
          характера, размещенная на сайте Клиники, не является одобрением или рекомендацией данных продуктов (услуг,
          деятельности) со стороны Клиники, за исключением случаев, когда на это прямо указывается на ресурсах Клиники.
        </p>
        <p>
          <strong>8. Реклама на сервисах Клиники.</strong>
        </p>
        <p>
          8.1. Клиника несет ответственность за рекламу, размещенную на электронных сервисах Клиники, в пределах,
          установленных законодательством РФ.
        </p>
        <p>
          <strong>9. Отсутствие гарантий, ограничение ответственности.</strong>
        </p>
        <p>
          9.1. Пользователь использует электронные сервисы Клиники на свой собственный риск. Клиника не принимает на
          себя никакой ответственности (в том числе за соответствие сервисов целям Пользователя), за исключением
          случаев, прямо предусмотренных настоящим Соглашением.
        </p>
        <p>
          9.2. Клиника не гарантирует, что: электронные сервисы соответствуют / будут соответствовать требованиям
          Пользователя; сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые
          могут быть получены с использованием сервисов, будут точными и надежными и могут использоваться для каких-либо
          целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество
          какого-либо продукта, услуги, информации и пр., полученных с использованием сервисов, будет соответствовать
          ожиданиям Пользователя;
        </p>
        <p>
          9.3. Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и руководства
          к действию и т.д.), доступ к которым Пользователь получает с использованием электронных сервисов Клиники,
          Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за
          возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который это
          может причинить компьютеру (иному техническому устройству, в том числе телефону, планшету и т.д.) Пользователя
          или третьим лицам, за потерю данных или любой другой вред.
        </p>
        <p>
          9.4. Клиника не несет ответственности за любые виды убытков, произошедшие вследствие использования
          Пользователем электронных сервисов Клиники или отдельных частей/функций сервисов.
        </p>
        <p>
          9.5. При любых обстоятельствах ответственность Клиники в соответствии со статьей 15 Гражданского кодекса РФ
          ограничена 10 000,00 (Десятью тысячами) рублей и возлагается на веб-сервис при наличии в его действиях вины.
        </p>
        <p>
          <strong>10. Консультации специалистов в электронных сервисах Клиники.</strong>
        </p>
        <p>
          10.1. Врачи, осуществляющие консультации Пользователя посредством электронных сервисов Клиники, имеют все
          разрешительные документы и лицензии для работы врачом на территории РФ.
        </p>
        <p>
          10.2. Рекомендации врачей, полученные посредством общения через электронные сервисы Клиники, не являются
          обязательными к выполнению Пользователем.
        </p>
        <p>
          10.3. Услуги электронных сервисов Клиники по получению Пользователем консультаций, рекомендаций врачей,
          хранению личной медицинской информации, иные услуги не являются медицинскими услугами, а относятся к категории
          информационно-технологических услуг.
        </p>
        <p>
          <strong>11. Иные положения.</strong>
        </p>
        <p>
          11.1. Настоящее Соглашение представляет собой договор между Пользователем и Клиникой относительно порядка
          использования электронных сервисов и заменяет собой все предыдущие соглашения между Пользователем и Клиникой
          по указанным вопросам.
        </p>
        <p>
          11.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
          Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
          Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
          разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского
          права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство»
          понимается законодательство Российской Федерации.
        </p>
        <p>
          11.3. Ввиду безвозмездности услуг, оказываемых в рамках настоящего Соглашения, нормы о защите прав
          потребителей, предусмотренные законодательством Российской Федерации, не могут быть к применимыми к отношениям
          между Пользователем и Клиникой в части использования электронных сервисов Клиники.
        </p>
        <p>
          11.4. Ничто в Соглашении не может пониматься как установление между Пользователем и Клиникой агентских
          отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо
          каких-то иных отношений, прямо не предусмотренных Соглашением.
        </p>
        <p>
          11.5. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
          недействительными или не имеющими юридической силы, это не окажет влияния на действительность или применимость
          остальных положений Соглашения.
        </p>
        <p>
          11.6. Бездействие со стороны Клиники в случае нарушения Пользователем либо иными Пользователями положений
          настоящего Соглашения не лишает Клинику права предпринять соответствующие действия в защиту своих интересов
          позднее, а также не означает отказа Клиники от своих прав в случае совершения в последующем подобных либо
          сходных нарушений.
        </p>
        <p>
          11.7. Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть предоставлено
          Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии
          Соглашения на ином языке, применяются положения русскоязычной версии настоящего Соглашения.
        </p>
        <p>
          11.8. Принимая условия настоящего Соглашения, Пользователь тем самым дает свое согласие на обработку его
          персональных данных, согласно Федеральному закону от 27.07.2006 № 152-ФЗ «О персональных данных», для целей
          обеспечения удаленного доступа к личному кабинету Пользователя, предоставления всех электронных сервисов
          Клиники и для оказания услуг Пользователю. К персональным данным, на обработку которых дает согласие
          Пользователь, относятся: Ф.И.О., дата рождения, пол, город проживания, телефон и адрес электронной почты,
          номера полисов ОМС, ДМС, медицинские и иные сведения, составляющие врачебную тайну, согласно ст. 13
          Федерального закона от 21.11.2011 № 323- ФЗ «Об основах охраны здоровья граждан в Российской Федерации»,
          размещаемые в личном кабинете медицинским учреждением, с которым Пользователь заключил партнерский договор,
          либо размещаемые Пользователем самостоятельно. Настоящее согласие действует со дня его подписания до дня
          отзыва в письменной форме.
        </p>
        <p>
          11.9. Использование электронных сервисов Клиники означает безоговорочное согласие Пользователя с настоящим
          Соглашением и Политикой в отношении обработки персональных данных ООО «Медик» и указанными в них условиями
          обработки персональной информации Пользователя; в случае несогласия с этими условиями Пользователь должен
          воздержаться от использования электронных сервисов Клиники.
        </p>
        <p>
          11.10. Персональная информация пользователей, которую получает и обрабатывает Клиника посредством электронных
          сервисов:
        </p>
        <p>
          - Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании
          учётной записи) или в процессе использования Сервисов, включая персональные данные Пользователя, а также
          информация, являющаяся врачебной тайной согласно ст. 13 Федерального закона от 21.11.2011 № 323-ФЗ «Об основах
          охраны здоровья граждан в Российской Федерации».
        </p>
        <p>
          - Данные, которые автоматически передаются электронными сервисами Клиники в процессе их использования с
          помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP- адрес, информация
          из cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к
          Сервисам), время доступа, адрес запрашиваемой страницы.
        </p>
        <p>
          - Иная информация о Пользователе, сбор и/или предоставление которой определено в Политике ООО «Медик» в
          отношении обработки персональных данных.
        </p>
        <p>
          11.11. Настоящее Соглашение и Политика в отношении обработки ООО «Медик» персональных данных (размещена в
          свободном доступе на сайте ООО «Медик») применима только к электронным сервисам Клиники. Клиника не
          контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по
          ссылкам, доступным на электронных сервисах Клиники, в том числе в результатах поиска. На таких сайтах у
          Пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные
          действия.
        </p>
        <p>
          11.12. Клиника в общем случае не проверяет достоверность персональной информации, предоставляемой
          Пользователями, и не осуществляет контроль за их дееспособностью. Однако Клиника исходит из того, что
          Пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в форме
          регистрации, и поддерживает эту информацию в актуальном состоянии.
        </p>
      </Modal>
    )
  }
}

export default AgreementModal
