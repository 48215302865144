import { ActionsItemState, ActionsItemAction } from './actions.item.types'

export const initialActionsItemState: ActionsItemState = {
  fetching: false,
}

const ActionsItemReducer = (state = initialActionsItemState, action: ActionsItemAction): ActionsItemState => {
  switch (action.type) {
    case 'ACTIONS_ITEM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'ACTIONS_ITEM_SUCCESS':
      return {
        ...state,
        fetching: false,
        action: action.params,
      }
    case 'ACTIONS_ITEM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialActionsItemState
      }
    default:
      return state
  }
}
export default ActionsItemReducer