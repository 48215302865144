import { MedcardVisitsAllAction, MedcardVisitsAllState } from "./medcard.visits.all.types"

export const initialMedcardVisitsAllState: MedcardVisitsAllState = {
  fetching: false,
  visits: [],
}

const MedcardVisitsAllReducer = (state = initialMedcardVisitsAllState, action: MedcardVisitsAllAction): MedcardVisitsAllState => {
  switch (action.type) {
    case 'MEDCARD_VISITS_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'MEDCARD_VISITS_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        visits: action.params,
      }
    case 'MEDCARD_VISITS_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMedcardVisitsAllState,
      }
    default:
      return state
  }
}

export default MedcardVisitsAllReducer