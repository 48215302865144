import React from 'react'
import PatientSelect, { PatientSelectProps } from '../../PatientSelect'
import MedcardSearchBar, { MedcardSearchBarProps } from '../SearchBar'
import './style.less'

export type MedcardHeaderProps = MedcardSearchBarProps & Omit<PatientSelectProps, 'patientsByphoneOrderRequest'> & {}

class MedcardHeader extends React.Component<MedcardHeaderProps> {

  render() {
    const {
      searchLabel, searchValue, onSearch, onDateRange, onDateCancel,
      mainPatients, addedPatients, onChangePatient, selectedPatient,
    } = this.props
    return <div className="medcard-header">
      <MedcardSearchBar
        searchLabel={searchLabel || "Поиск специальности"}
        searchValue={searchValue}
        onSearch={onSearch}
        onDateRange={onDateRange}
        onDateCancel={onDateCancel}
      />
      <PatientSelect
        patientAllEnabled
        mainPatients={mainPatients}
        addedPatients={addedPatients}
        onChangePatient={onChangePatient}
        selectedPatient={selectedPatient}
      />
    </div>
  }
}

export default MedcardHeader