import React from 'react'
import { CatalogPreview } from '../../../api/pricelist.types'
import { PricelistCatalogsState } from '../../../store/reducks/pricelist/pricelist.catalogs.types'
import CatalogList from '../../CatalogList'

interface Props {
  catalogs: PricelistCatalogsState['catalogsByDepartments']
  activeKey: string
  onSelect: (selectedCatalog: CatalogPreview) => void
}

const RecordingStage3: React.FC<Props> = (props) => {
  return <CatalogList {...props} className="recording-stage-third"/>
}

export default RecordingStage3
