import { PatientsBymistokenAddedAction, PatientsBymistokenAddedState } from "./patients.bymistoken.added.types"

export const initialPatientsBymistokenAddedState: PatientsBymistokenAddedState = {
  fetching: false,
  users: [],
}

const PatientsBymistokenAddedReducer = (state = initialPatientsBymistokenAddedState, action: PatientsBymistokenAddedAction): PatientsBymistokenAddedState => {
  switch (action.type) {
    case 'PATIENTS_BYMISTOKEN_ADDED_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENTS_BYMISTOKEN_ADDED_SUCCESS':
    case 'PATIENTS_BYMISTOKEN_ADDED_FAILURE':
      return {
        ...state,
        ...action.params,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
    case 'PATIENTS_CLEAR':
      return {
        ...initialPatientsBymistokenAddedState,
      }
    default:
      return state
  }
}

export default PatientsBymistokenAddedReducer