import React, { Component } from 'react'
import TextInputMask from '../TextInput/mask'
import TextInput from '../TextInput'
import BirthdateModal from '../../modals/BirthdateModal'
import RadioButtons from '../RadioButtons'
import { Button } from 'antd'
import { inputValidation, phoneValidation, birthdateValidation } from '../../tools/validation'
import { NewPatient } from '../../store/reducks/record/record.types'
import moment from 'moment'
import 'moment/locale/ru'
import './style.less'

interface Props {
  onCreate: (patient: NewPatient) => void
  newPatientInit?: NewPatient | null
}

interface State {
  birthdate: string
  surname: string
  name: string
  midname: string
  phone: string
  confirmPhone: string
  sex: 0 | 1
  birthdateError: string
  surnameError: string
  nameError: string
  midnameError: string
  phoneError: string
  confirmPhoneError: string
  showCalendar: boolean
}

export default class NewPatientForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { newPatientInit } = props
    this.state = {
      birthdate: !!newPatientInit ? moment(newPatientInit.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
      surname: newPatientInit?.lastname ?? '',
      name: newPatientInit?.firstname ?? '',
      midname: newPatientInit?.midname ?? '',
      phone: newPatientInit?.phone ?? '',
      confirmPhone: '',
      sex: !!newPatientInit ? newPatientInit.sex : 1,
      birthdateError: '',
      surnameError: '',
      nameError: '',
      midnameError: '',
      phoneError: '',
      confirmPhoneError: '',
      showCalendar: false,
    }
  }

  private openCalendar = () => this.setState({ showCalendar: true })
  private closeCalendar = () => this.setState({ showCalendar: false })

  validation(): boolean {
    const { birthdate, surname, name, midname, phone, confirmPhone, } = this.state
    const birthdateError = birthdateValidation(birthdate)
    const surnameError = inputValidation(surname, 'Фамилия')
    const nameError = inputValidation(name, 'Имя')
    const midnameError = inputValidation(midname, 'Отчество')
    const phoneFormatted = phone.replace(/[-_\s]/g, '')
    const confirmPhoneFormatted = confirmPhone.replace(/[-_\s]/g, '')
    const phoneError = phoneValidation(phoneFormatted)
    const confirmPhoneError = confirmPhoneFormatted !== phoneFormatted ? 'Номера должны совпадать' : ''

    this.setState({
      birthdateError,
      surnameError,
      nameError,
      midnameError,
      phoneError,
      confirmPhoneError,
    })

    return !birthdateError && !surnameError && !nameError && !midnameError && !phoneError && !confirmPhoneError
  }

  sendForm() {
    const { onCreate } = this.props
    const { birthdate, surname, name, midname, phone, sex } = this.state
    const phoneFormatted = phone.replace(/[-_\s]/g, '')

    const isValid = this.validation()

    if (isValid) {
      const newPatient: NewPatient = {
        firstname: name,
        lastname: surname,
        midname: midname,
        sex: sex,
        birthdate: moment(birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        phone: phoneFormatted,
      }

      onCreate(newPatient)
    }
  }

  render() {
    const {
      birthdate,
      surname,
      name,
      midname,
      phone,
      confirmPhone,
      birthdateError,
      surnameError,
      nameError,
      midnameError,
      phoneError,
      confirmPhoneError,
      sex,
      showCalendar
    }: State = this.state

    return (
      <div className='new-patient-form'>
        <div className='new-patient__title'>Для записи на прием заполните свои персональные данные.
          <div><span className='new-patient__title_accent'>Внимание!</span> Указывайте только достоверные данные</div>
        </div>

        <TextInput
          className={`new-patient__input_${!!surnameError ? 'error' : 'default'}`}
          value={surname}
          label="Фамилия"
          error={!!surnameError}
          helperText={surnameError}
          onChangeText={(value) => this.setState({ surname: value, surnameError: '' })}
        />
        <TextInput
          className={`new-patient__input_${!!nameError ? 'error' : 'default'}`}
          value={name}
          label="Имя"
          error={!!nameError}
          helperText={nameError}
          onChangeText={(value) => this.setState({ name: value, nameError: '' })}
        />
        <TextInput
          className={`new-patient__input_${!!midnameError ? 'error' : 'default'}`}
          value={midname}
          label="Отчество"
          error={!!midnameError}
          helperText={midnameError}
          onChangeText={(value) => this.setState({ midname: value, midnameError: '' })}
        />
        <RadioButtons
          className='new-patient__radio'
          title='Пол'
          value={sex === 1 ? 'male' : 'female'}
          onChange={(value) => {
            this.setState({ sex: value })
          }}
        />
        <TextInputMask
          orderId={1}
          className={`new-patient__input_${!!birthdateError ? 'error' : 'default'}`}
          value={birthdate}
          errorString={birthdateError}
          mask='date'
          autoComplete='off'
          postfix='calendar'
          label='Дата рождения'
          onChange={(e) => {
            const { value } = e.target
            const isNotEmpty = /\d+/.test(value)
            this.setState({
              birthdate: isNotEmpty ? value : '',
              birthdateError: '',
            })
          }}
          onPostfix={this.openCalendar}
        />
        <TextInputMask
          orderId={2}
          className={`new-patient__input_${!!phoneError ? 'error' : 'default'}`}
          value={phone}
          errorString={phoneError}
          autoComplete='off'
          mask='phone'
          prefix='phone'
          label='Номер телефона'
          onChange={(e) => {
            const { value } = e.target
            const isNotEmpty = /\d+/.test(value)
            this.setState({
              phone: isNotEmpty ? e.target.value : '',
              phoneError: ''
            })
          }}
        />
        <TextInputMask
          orderId={3}
          className={`new-patient__input_${!!confirmPhoneError ? 'error' : 'default'}`}
          value={confirmPhone}
          errorString={confirmPhoneError}
          autoComplete='off'
          mask='phone'
          prefix='phone'
          label='Подтвердите номер телефона'
          onChange={(e) => {
            const { value } = e.target
            const isNotEmpty = /\d+/.test(value)
            this.setState({
              confirmPhone: isNotEmpty ? e.target.value : '',
              confirmPhoneError: ''
            })
          }}
        />
        <Button
          type='primary'
          onClick={() => this.sendForm()}
          className='new-patient__button'
        >
          Отправить
        </Button>

        <BirthdateModal
          visible={showCalendar}
          date={birthdate}
          onCancel={this.closeCalendar}
          onAccept={(date) => {
            this.setState({
              birthdate: date,
              birthdateError: '',
            })
            this.closeCalendar()
          }}
        />
      </div>
    )
  }
}