import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { CombinedAppointment } from "./payment.types";

class PaymentAPI {
  private static GET_APPOINTMENT_TYPE = '/is_combined_appointment' as const
  private static GET_COMBINED_APPOINTMENTS = '/get_combined_appointment' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(PaymentAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    return res
  }

  getAppointmentType = async (params: { appointment_id: number; medic_user_id: number; }) => {
    const res = await this.request<{ is_combined: boolean; is_partial: boolean; }, { message: string }>({
      method: 'get',
      url: PaymentAPI.GET_APPOINTMENT_TYPE,
      params,
    })
    return res
  }

  getCombinedAppointments = async (params: { appointment_id: number; medic_user_id: number; }) => {
    const res = await this.request<{ is_partial: boolean; appointments: CombinedAppointment[] }, { message: string }>({
      method: 'get',
      url: PaymentAPI.GET_COMBINED_APPOINTMENTS,
      params,
    })
    return res
  }
}

export default PaymentAPI