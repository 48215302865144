import { RecordState } from "./record.types";

class Record {
  private record: Partial<RecordState>
  constructor(record: Partial<RecordState>) {
    this.record = record
  }

  static getPrice(record: Partial<RecordState>) {
    const { service, register_info } = record
    if (register_info) {
      return register_info.price - register_info.discount
    }
    if (service && typeof service.price != 'undefined') {
      return service.discount_price || service.price || 0
    }
    return 0
  }

  get price() {
    return Record.getPrice(this.record)
  }
}

export default Record