import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CatalogPreview } from '../../../api/pricelist.types'
import { AppState } from '../../../store'
import { pricelistCatalogInfoRequestAction } from '../../../store/reducks/pricelist/pricelist.catalog_info.actions'
import TemplateContent from '../../Template/content'
import './style.less'
import SimpleList, { SimpleListItem } from '../../SimpleList'
import fixHTMLString from '../../../tools/fixHTMLString'

export type CatalogProps = ReduxConnectedProps & {
  catalog: CatalogPreview
  onBack?: () => void
  onDoctor?: () => void
  onServices?: () => void
  onArticles?: () => void
}

export type CatalogState = {
  catalogLoaded?: boolean
}

class Catalog extends React.Component<CatalogProps, CatalogState> {
  private items: SimpleListItem[]

  constructor(props: CatalogProps) {
    super(props)
    this.state = {}
    this.items = [{
      name: 'Врачи',
      onClick: this.onDoctor,
    }, {
      name: 'Услуги и цены',
      onClick: this.onServices,
    }, {
      name: 'Полезные статьи',
      onClick: this.onArticles,
    }]
  }
  componentDidMount() {
    this.fetchInfo()
  }
  componentDidUpdate(prevProps: CatalogProps) {
    const { catalogInfo } = this.props
    if (!catalogInfo.fetching && !catalogInfo.error && prevProps.catalogInfo.fetching) {
      this.setState({ catalogLoaded: true })
    }
  }

  private onDoctor = () => {
    const { onDoctor } = this.props
    onDoctor && onDoctor()
  }

  private onServices = () => {
    const { onServices } = this.props
    onServices && onServices()
  }

  private onArticles = () => {
    const { onArticles } = this.props
    onArticles && onArticles()
  }

  private fetchInfo = () => {
    const { catalog } = this.props
    const { service_id } = catalog
    this.props.pricelistCatalogInfoRequestAction({ service_id })
  }

  private onBack = () => {
    const { onBack } = this.props
    onBack && onBack()
  }

  private renderInfo = () => {
    const { catalogInfo } = this.props
    const { info } = catalogInfo
    return <div className="catalog-info">
      <SimpleList
        className="catalog-buttons"
        items={this.items}
      />
      <div className="catalog-description">
        <h3>Описание</h3>
        <div dangerouslySetInnerHTML={{ __html: fixHTMLString(info.service_descr || '') }}></div>
      </div>
    </div>
  }

  render() {
    const { catalogLoaded } = this.state
    const { catalogInfo, catalog } = this.props
    return <TemplateContent
      title={catalog.service_name}
      onTitleBack={this.onBack}
    >
      {catalogLoaded && this.renderInfo()}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    catalogInfo: state.pricelist.catalogInfo,
  }), {
  pricelistCatalogInfoRequestAction,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(Catalog)