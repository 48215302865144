import { ScheduleByDoctorDoctorsAction, ScheduleByDoctorDoctorsState } from "./schedule.bydoctor.doctors.types"

export const initialScheduleByDoctorDoctorsState: ScheduleByDoctorDoctorsState = {
  fetching: false,
  doctors: [],
}

const ScheduleByDoctorDoctorsReducer = (state = initialScheduleByDoctorDoctorsState, action: ScheduleByDoctorDoctorsAction): ScheduleByDoctorDoctorsState => {
  switch (action.type) {
    case 'SCHEDULE_BY_DOCTOR_DOCTORS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'SCHEDULE_BY_DOCTOR_DOCTORS_SUCCESS':
      return {
        ...state,
        fetching: false,
        doctors: action.params,
      }
    case 'SCHEDULE_BY_DOCTOR_DOCTORS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialScheduleByDoctorDoctorsState,
      }
    default:
      return state
  }
}

export default ScheduleByDoctorDoctorsReducer