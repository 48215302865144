import { AutoPaymentsDebtsAction, AutoPaymentsDebtsState } from "./autoPayments.debts.types"

export const initialAutoPaymentsDebtsState: AutoPaymentsDebtsState = {
  fetching: false,
  debts: {
    has_debt: false,
    debts: [],
  }
}

const AutoPaymentsDebtsReducer = (state = initialAutoPaymentsDebtsState, action: AutoPaymentsDebtsAction): AutoPaymentsDebtsState => {
  switch (action.type) {
    case 'AUTO_PAYMENT_DEBTS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'AUTO_PAYMENT_DEBTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        debts: action.params,
      }
    case 'AUTO_PAYMENT_DEBTS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAutoPaymentsDebtsState,
      }
    default:
      return state
  }
}

export default AutoPaymentsDebtsReducer