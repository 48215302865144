import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { callMeFailureAction, callMeSuccessAction } from "./call.me.actions";
import { doctorRatingFailureAction, doctorRatingSuccessAction } from "./doctor.rating.actions";
import { doctorRatingTmFailureAction, doctorRatingTmSuccessAction } from "./doctor.rating.tm.actions";
import { FeedbackAction } from "./feedback.types";
import { reportErrorFailureAction, reportErrorSuccessAction } from "./report.error.actions";
import { sendFeedbackFailureAction, sendFeedbackSuccessAction } from "./send.feedback.actions";

export function* callMeSaga(action: FeedbackAction) {
  const res: ThenReturnType<typeof api.feedback.callMe> = yield call(api.feedback.callMe, action.params)
  if (res.error) {
    yield put(callMeFailureAction())
  } else {
    yield put(callMeSuccessAction())
  }
}

export function* doctorRatingSaga(action: FeedbackAction) {
  const res: ThenReturnType<typeof api.feedback.sendDoctorRating> = yield call(api.feedback.sendDoctorRating, action.params)
  if (res.error) {
    yield put(doctorRatingFailureAction())
  } else {
    yield put(doctorRatingSuccessAction())
  }
}

export function* doctorRatingTmSaga(action: FeedbackAction) {
  const res: ThenReturnType<typeof api.feedback.sendDoctorRatingTM> = yield call(api.feedback.sendDoctorRatingTM, action.params)
  if (res.error) {
    yield put(doctorRatingTmFailureAction())
  } else {
    yield put(doctorRatingTmSuccessAction())
  }
}

export function* reportErrorSaga(action: FeedbackAction) {
  const res: ThenReturnType<typeof api.feedback.reportError> = yield call(api.feedback.reportError, action.params)
  if (res.error) {
    yield put(reportErrorFailureAction())
  } else {
    yield put(reportErrorSuccessAction())
  }
}

export function* sendFeedbackSaga(action: FeedbackAction) {
  const res: ThenReturnType<typeof api.feedback.sendFeedBack> = yield call(api.feedback.sendFeedBack, action.params)
  if (res.error) {
    yield put(sendFeedbackFailureAction())
  } else {
    yield put(sendFeedbackSuccessAction())
  }
}

export default function* watchFeedBack() {
  yield takeLeadingPattern<FeedbackAction['type']>('CALL_ME_REQUEST', callMeSaga, callMeFailureAction)
  yield takeLeadingPattern<FeedbackAction['type']>('DOCTOR_RATING_REQUEST', doctorRatingSaga, doctorRatingFailureAction)
  yield takeLeadingPattern<FeedbackAction['type']>('DOCTOR_RATING_TM_REQUEST', doctorRatingTmSaga, doctorRatingTmFailureAction)
  yield takeLeadingPattern<FeedbackAction['type']>('REPORT_ERROR_REQUEST', reportErrorSaga, reportErrorFailureAction)
  yield takeLeadingPattern<FeedbackAction['type']>('SEND_FEEDBACK_REQUEST', sendFeedbackSaga, sendFeedbackFailureAction)
}
