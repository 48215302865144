import { PricelistDoctorServicesState, PricelistDoctorServicesAction } from './pricelist.doctor_services.types'

export const initialPricelistDoctorServicesState: PricelistDoctorServicesState = {
  fetching: false,
  services: [],
}
const PricelistDoctorServicesReducer = (state = initialPricelistDoctorServicesState, action: PricelistDoctorServicesAction): PricelistDoctorServicesState => {
  switch (action.type) {
    case 'PRICELIST_DOCTOR_SERVICES_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'PRICELIST_DOCTOR_SERVICES_SUCCESS':
      return {
        ...state,
        fetching: false,
        services: action.params,
      }
    case 'PRICELIST_DOCTOR_SERVICES_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricelistDoctorServicesState
      }
    default:
      return state
  }
}
export default PricelistDoctorServicesReducer