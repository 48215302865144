import { ChatSocketAction, ChatSocketState } from "./chat.socket.types"

export const initialChatSocketState: ChatSocketState = {
  connecting: false,
}

const ChatSocketReducer = (state = initialChatSocketState, action: ChatSocketAction): ChatSocketState => {
  switch (action.type) {
    case 'CHAT_SOCKET_CONNECT_REQUEST':
      return {
        ...state,
        connecting: true,
        error: false,
      }
    case 'CHAT_SOCKET_CONNECT_SUCCESS':
      return {
        ...state,
        connecting: false,
        socket: {
          id: action.params.id,
          connected: true,
        }
      }
    case 'CHAT_SOCKET_CONNECT_FAILURE':
      return {
        ...state,
        connecting: false,
        error: true,
      }
    case 'CHAT_SOCKET_DISCONNECT_SUCCESS':
      if (!state.socket || (state.socket.id && state.socket.id !== action.params?.id)) {
        return state
      }
      return {
        ...state,
        socket: {
          ...state.socket,
          connected: false,
        }
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialChatSocketState,
      }
    default:
      return state
  }
}

export default ChatSocketReducer