import { RecoveryViewAction, RecoveryViewState } from "./recovery.view.types"

export const initialRecoveryViewState: RecoveryViewState = {
}

const RecoveryViewReducer = (state = initialRecoveryViewState, action: RecoveryViewAction): RecoveryViewState => {
  switch (action.type) {
    case 'RECOVERY_VIEW_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'RECOVERY_VIEW_CLEAR':
    case 'STORAGE_CLEAR':
      return {
        ...initialRecoveryViewState,
      }
    default:
      return state
  }
}

export default RecoveryViewReducer