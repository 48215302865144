import { ResearchesViewAction, ResearchesViewState } from "./researches.view.types";

export const initialResearchesViewState: ResearchesViewState = {
  patient: { medic_user_id: 0, name: 'Все пациенты' }
}

const ResearchesViewReducer = (state = initialResearchesViewState, action: ResearchesViewAction): ResearchesViewState => {
  switch (action.type) {
    case 'RESEARCHES_VIEW_PATIENT_SET':
      return {
        ...state,
        patient: action.params,
      }
    case 'RESEARCHES_VIEW_PATIENT_CLEAR':
      return {
        ...state,
        patient: {
          ...initialResearchesViewState.patient
        },
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialResearchesViewState,
      }
    default:
      return state
  }
}

export default ResearchesViewReducer