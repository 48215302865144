import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../../store'
import { newsAllRequestAction } from '../../../store/reducks/news/news.all.actions'
import TemplateContent from '../../Template/content'
import NewsItem from '../../NewsItem'
import './style.less'
import config from '../../../config'
import { RouteChildrenProps, withRouter } from 'react-router-dom'

export type NewsProps = ReduxConnectedProps & RouteChildrenProps & {}

class News extends React.Component<NewsProps> {

  componentDidMount() {
    this.props.newsAllRequestAction()
  }

  private renderNews = () => {
    const { news } = this.props
    return <>
      {news.news.map((item, index) => (<NewsItem
        key={index}
        date={item.news_date}
        title={item.news_name}
        content={item.news_preview_desc}
        image={`${config.staticURL}/news/${item.news_preview_img}`}
        onImage={() => {
          this.props.history.push('/clinic/news/' + item.news_id)
        }}
      />))}
    </>
  }


  render() {
    return <TemplateContent
      title="Новости"
    >
      {this.renderNews()}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    news: state.news.all,
  }),
  {
    newsAllRequestAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(News))