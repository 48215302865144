import API, { ApiConfig } from "../api"
import AppointmentAPI from "../appointment.api"
import AutoPaymentAPI from "../autoPayment.api"
import BonusAPI from "../bonus.api"
import ChatAPI from "../chat.api"
import ClinicAPI from "../clinic.api"
import DoctorAPI from "../doctor.api"
import FeedBackAPI from "../feedback.api"
import LoginAPI from "../login.api"
import MedcardAPI from "../medcard.api"
import MyMedicAPI from "../mymedic.api"
import NewsFeedAPI from "../newsfeed.api"
import PatientAPI from "../patient.api"
import PaymentAPI from "../payment.api"
import PricelistAPI from "../pricelist.api"
import RegisterAPI from "../register.api"
import ResearchAPI from "../research.api"
import ScheduleAPI from "../schedule.api"

const create = (config: ApiConfig) => {
  const api = new API(config)
  return {
    instance: api,
    login: new LoginAPI(api),
    patient: new PatientAPI(api),
    clinic: new ClinicAPI(api),
    doctor: new DoctorAPI(api),
    newsfeed: new NewsFeedAPI(api),
    pricelist: new PricelistAPI(api),
    schedule: new ScheduleAPI(api),
    appointment: new AppointmentAPI(api),
    register: new RegisterAPI(api),
    bonus: new BonusAPI(api),
    autopayment: new AutoPaymentAPI(api),
    payment: new PaymentAPI(api),
    medcard: new MedcardAPI(api),
    research: new ResearchAPI(api),
    mymedic: new MyMedicAPI(api),
    feedback: new FeedBackAPI(api),
    chat: new ChatAPI(api),
  }
}

export default create