export function errorMessage(message: string) {
  switch (message) {
    case 'query error':
    case 'send sms error':
    case 'connection error':
    case 'internal error':
    case 'forbidden':
    case 'read file error':
    case 'image size error':
    case 'write file error':
    case 'convert to buffer error':
      return 'Произошла внутренняя ошибка сервера, попробуйте позже';
    case 'Too many requests, please try again later.':
      return 'Превышен лимит авторизации. Повторите через 60 минут';
    case 'code not found':
      return 'Неверный код для проверки';
    case 'phone number not found':
      return 'Данный номер телефона не найден';
    case 'websocket error':
      return 'Ошибка соединения с клиникой';
    case 'incorrect phone':
    case 'incorrect username or password':
      return 'Неверные логин или пароль';
    case 'incorrect old login':
      return 'Старый логин введен неверно';
    case 'incorrect new login':
      return 'Новый логин уже занят, попробуйте другой';
    case 'using demo number to change password':
    case 'using demo number to delete appointment':
    case 'using demo number to register':
    case 'using demo number to recovery password':
      return 'Недоступно в демо режиме';
    case 'incorrect old password':
      return 'Неверный старый пароль';
    case 'medic_user_id not found':
    case 'incorrect medic_user_id':
      return 'Данный пациент не найден';
    case 'datestimes not found':
      return 'К сожалению, у выбранного специалиста полная запись';
    case 'doctors not found':
      return 'Врачи не найдены';
    case 'doctor not found':
      return 'Врач не найден';
    case 'register_info not found':
      return 'Ошибка получения данных о записи';
    case 'services not found':
      return 'Услуги не найдены';
    case 'service not found':
      return 'Услуга не найдена';
    case 'prepare info not found':
      return 'Информация о подготовке не найдена';
    case 'prices not found':
      return 'Цены не найдены';
    case 'article not found':
      return 'Статья не найдена';
    case 'departments not found':
      return 'Отделения не найдены';
    case 'contacts not found':
      return 'Контакты не найдены';
    case 'user not found!':
    case 'users not found!':
    case 'appointments not found':
    case 'records not found!':
    case 'researches not found':
    case 'visits not found':
    case 'articles not found':
    case 'news not found':
    case 'actions not found':
    case 'Извините, при заданных условиях запись невозможна. ' +
      'Пожалуйта, свяжитесь с оператором по тел. <a href=\"tel:+78352237723\">+7 (8352) 23-77-23</a>':
      return
    case 'visit not found':
      return 'Визит не найден';
    case 'file not found':
      return 'Исследование не найдено';
    case 'incorrect demo username or password error':
      return 'Некорректные логин и пароль демо режима';
    case 'pushs not found':
      return 'Уведомления не найдены';
    case 'incorrect recovery data':
      return 'Некорректные данные для восстановления пароля';
    case 'token not found':
    case 'mis_token not found':
    case 'phone not found':
      return 'Кто-то зашел под вашей учетной записью';
    case 'unauthorize':
    case 'unauthorized':
      return 'Ошибка авторизации'
    case 'internal app error':
      return 'Ошибка приложения 115. Пожалуйста, перезапустите приложение и сообщите код ошибки в службу тех. поддержки';
    case 'account is blocked':
      return 'Ограничен доступ к Медкарте, свяжитесь с администратором';
    case 'account not activated':
      return 'Аккаунт не активирован';
    case 'invalid bonus token':
    case 'invalid bonus code':
    case 'invalid bonus token or code':
      return 'Ошибка бонусной системы. Пожалуйста, сообщите об этом администратору'
    case 'can not read property \'message\' of null':
      return 'Произошла ошибка соединения, проверьте подключение к интернету';
    default:
      // return 'Произошла ошибка соединения, проверьте подключение к интернету';
      return message
  }
}