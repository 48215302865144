import { CallMeState, CallMeAction } from './call.me.types'
export const initialCallMeState: CallMeState = {
  fetching: false,
}
const CallMeReducer = (state = initialCallMeState, action: CallMeAction): CallMeState => {
  switch (action.type) {
    case 'CALL_ME_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'CALL_ME_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: action.params,
      }
    case 'CALL_ME_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialCallMeState
      }
    default:
      return state
  }
}
export default CallMeReducer