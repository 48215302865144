import { MedcardOnlineVisitsAllAction, MedcardOnlineVisitsAllState } from "./medcard.online_visits.all.types"

export const initialMedcardOnlineVisitsAllState: MedcardOnlineVisitsAllState = {
  fetching: false,
  visits: [],
}

const MedcardOnlineVisitsAllReducer = (state = initialMedcardOnlineVisitsAllState, action: MedcardOnlineVisitsAllAction): MedcardOnlineVisitsAllState => {
  switch (action.type) {
    case 'MEDCARD_ONLINE_VISITS_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'MEDCARD_ONLINE_VISITS_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        visits: action.params,
      }
    case 'MEDCARD_ONLINE_VISITS_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMedcardOnlineVisitsAllState,
      }
    default:
      return state
  }
}

export default MedcardOnlineVisitsAllReducer