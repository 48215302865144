import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { doctorsAllFailureAction, doctorsAllSuccessAction } from "./doctors.all.actions";
import { doctorsBydepartmentFailureAction, doctorsBydepartmentSuccessAction } from "./doctors.bydepartment.actions";
import { doctorsByserviceFailureAction, doctorsByserviceSuccessAction } from "./doctors.byservice.actions";
import { doctorByEmployerIdFailureAction, doctorByEmployerIdSuccessAction, doctorByIdFailureAction, doctorByIdSuccessAction } from "./doctors.item.actions";
import { DoctorsAction } from "./doctors.types";

export function* doctorsAllRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.doctor.getDoctors> = yield call(api.doctor.getDoctors)
  if (res.error) {
    yield put(doctorsAllFailureAction())
  } else {
    yield put(doctorsAllSuccessAction(res.data.doctors))
  }
}

export function* doctorsByServiceRequestSaga(action: DoctorsAction) {
  const res: ThenReturnType<typeof api.doctor.getDoctorsByService> = yield call(api.doctor.getDoctorsByService, action.params)
  if (res.error) {
    yield put(doctorsByserviceFailureAction())
  } else {
    yield put(doctorsByserviceSuccessAction(res.data.doctors))
  }
}

export function* doctorsByDepartmentRequestSaga(action: DoctorsAction) {
  const res: ThenReturnType<typeof api.doctor.getDoctorsByDepartment> = yield call(api.doctor.getDoctorsByDepartment, action.params)
  if (res.error) {
    yield put(doctorsBydepartmentFailureAction())
  } else {
    yield put(doctorsBydepartmentSuccessAction(res.data.doctors))
  }
}

export function* doctorByIdRequestSaga(action: DoctorsAction) {
  const res: ThenReturnType<typeof api.doctor.getDoctor> = yield call(api.doctor.getDoctor, action.params)
  if (res.error) {
    yield put(doctorByIdFailureAction())
  } else {
    yield put(doctorByIdSuccessAction(res.data.doctor))
  }
}

export function* doctorByEmployerIdRequestSaga(action: DoctorsAction) {
  const res: ThenReturnType<typeof api.doctor.getDoctorByEmployer> = yield call(api.doctor.getDoctorByEmployer, action.params)
  if (res.error) {
    yield put(doctorByEmployerIdFailureAction())
  } else {
    yield put(doctorByEmployerIdSuccessAction(res.data.doctor))
  }
}

export default function* watchDoctors() {
  yield takeLeadingPattern<DoctorsAction['type']>('DOCTORS_ALL_REQUEST', doctorsAllRequestSaga, doctorsAllFailureAction)
  yield takeLeadingPattern<DoctorsAction['type']>('DOCTORS_BYDEPARTMENT_REQUEST', doctorsByDepartmentRequestSaga, doctorsBydepartmentFailureAction)
  yield takeLeadingPattern<DoctorsAction['type']>('DOCTORS_BYSERVICE_REQUEST', doctorsByServiceRequestSaga, doctorsByserviceFailureAction)
  yield takeLeadingPattern<DoctorsAction['type']>('DOCTOR_BY_ID_REQUEST', doctorByIdRequestSaga, doctorByIdFailureAction)
  yield takeLeadingPattern<DoctorsAction['type']>('DOCTOR_BY_EMPLOYERID_REQUEST', doctorByEmployerIdRequestSaga, doctorByEmployerIdFailureAction)
}