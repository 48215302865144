import React from 'react'
import { Card, Button } from 'antd'
import moment from 'moment'
import { CameraIcon, DoctorIcon, FilledInfoIcon as InfoIcon, MapMarkerIcon, NoticeIcon, RubleIcon } from '../../icons'
// import Button from 'antd/es/button'
import './style.less'

export type DateTimeProps = {
  datetime: string
  datetimeFormat?: string
  datetimeActive?: boolean
}

export type PriceProps = {
  price?: number
}

export type SpecializationProps = {
  specialization: string
  hideIcon?: boolean
  is_tm?: boolean
  onInfo?: () => void
}

export type DoctorProps = {
  doctorName: string
  service?: string
}

export type AddressProps = {
  address: string
  className?: string
  office?: number
}

export type ButtonProps = {
  text: string;
  className?: string;
  onClick?: () => void;
  type: 'primary' | 'default';
}
export type ButtonsProps = {
  buttons: ButtonProps[]
  longButton?: boolean
}

export type JournalItemProps = DateTimeProps & PriceProps & SpecializationProps & DoctorProps & Partial<AddressProps> & ButtonsProps & {
  showPayInfo?: boolean
}

class JournalItem extends React.Component<JournalItemProps> {
  static Header: React.FC<DateTimeProps & PriceProps & SpecializationProps> = (props) => {
    const { datetime, datetimeActive, datetimeFormat, price, specialization, is_tm, onInfo, hideIcon } = props
    return <div className="appointment-header">
      <div className="appointment-datetime-price">
        <JournalItem.DateTime
          datetime={datetime}
          datetimeActive={datetimeActive}
          datetimeFormat={datetimeFormat}
        />
        <JournalItem.Price price={price} />
      </div>
      <JournalItem.Specialization specialization={specialization} hideIcon={hideIcon} onInfo={onInfo} is_tm={is_tm} />
    </div>
  }

  static DateTime: React.FC<DateTimeProps> = (props) => {
    const { datetime, datetimeFormat: format = 'dd, DD MMMM, HH:mm', datetimeActive: active } = props
    return <div className={`appointment-date-time ${active ? 'active' : ''}`}>
      <span>{moment(datetime).format(format)}</span>
    </div>
  }

  static Price: React.FC<PriceProps> = (props) => {
    const { price } = props
    return <div className="appointment-price">
      {typeof price == 'undefined' ? null : <>
        <RubleIcon />
        <span>{`${price} ₽`}</span>
      </>}
    </div>
  }

  static Specialization: React.FC<SpecializationProps> = (props) => {
    const { specialization, hideIcon, onInfo, is_tm } = props
    return <div className="appointment-specialization">
      {!hideIcon && <div className={`icon ${is_tm ? 'tm' : ''}`} onClick={is_tm ? undefined : onInfo}>
        {is_tm ? <CameraIcon /> : <NoticeIcon />}
      </div>}
      <span>{specialization}</span>
    </div>
  }

  static Content: React.FC<DoctorProps & Partial<AddressProps> & ButtonsProps & { showPayInfo?: boolean; is_tm?: boolean }> = (props) => {
    const { doctorName, service, address, office, buttons, longButton, showPayInfo, is_tm } = props
    return <div className="appointment-body">
      <div className="appointment-doctor-address">
        <JournalItem.Doctor doctorName={doctorName} service={service} />
        {address && !is_tm && <JournalItem.Address address={address} office={office} />}
      </div>
      <JournalItem.Buttons buttons={buttons} longButton={longButton} />
      {showPayInfo && <JournalItem.PayInAdvance />}
    </div>
  }

  static Doctor: React.FC<DoctorProps> = (props) => {
    const { doctorName, service } = props
    const names = [doctorName, service].filter(Boolean)
    if (names.length == 0) {
      return null
    }
    return <div className="appointment-doctor">
      <DoctorIcon />
      <span>{names.join(' – ')}</span>
    </div>
  }

  static Address: React.FC<AddressProps> = (props) => {
    const { address, office, className } = props
    return <div className={`appointment-address ${className || ''}`}>
      <MapMarkerIcon />
      <span>
        {address}
        {office ? ', ' : ''}
        <b>{office ? `каб. ${office}` : ''}</b>
      </span>
    </div>
  }

  static Buttons: React.FC<ButtonsProps> = (props) => {
    const { buttons, longButton } = props
    if (buttons.length == 0) {
      return null
    }
    return <div className={`appointment-buttons ${longButton ? 'long' : ''}`}>
      {buttons.map((button, index) => <JournalItem.Button key={index} {...button} />)}
    </div>
  }

  static Button: React.FC<ButtonProps> = (props) => {
    const { type, text, onClick, className } = props
    return <Button
      type={type}
      onClick={onClick}
      className={className}
    >
      {text}
    </Button>
  }

  static PayInAdvance: React.FC = () => {
    return <div className="appointment-pay-in-advance">
      <InfoIcon />
      <span>{'Вы можете оплатить прием заранее'}</span>
    </div>
  }

  private headerProps = () => {
    const { datetime, datetimeActive, datetimeFormat, price, specialization, onInfo, hideIcon, is_tm } = this.props
    return { datetime, datetimeActive, datetimeFormat, price, specialization, onInfo, hideIcon, is_tm }
  }

  private contentProps = () => {
    const { doctorName, service, address, office, buttons, longButton, showPayInfo, is_tm } = this.props
    return { doctorName, service, address, office, buttons, longButton, showPayInfo, is_tm }
  }

  render() {
    return <div className="appointment-container">
      <JournalItem.Header {...this.headerProps()}/>
      <JournalItem.Content {...this.contentProps()} />
    </div>
  }
}

export default JournalItem