import React from 'react'
import copyTextToClipboard from '../../tools/copyToClipboard'

export type CopyableTextProps = {
  successMessage?: string
  failureMessage?: string
  className?: string
  value: string | React.ReactNode
  disabled?: boolean
}

const CopyableText: React.FC<CopyableTextProps> = (props) => {
  const { value, successMessage = 'скопировано', failureMessage, className, disabled } = props
  const onCopy = () => {
    copyTextToClipboard(value?.toString()).then(() => {
      console.log(successMessage)
    }).catch(e => {
      console.log(failureMessage || '', e)
    })
  }
  return <span
    className={`copyable-text ${className || ''}`}
    onClick={disabled ? undefined : onCopy}
  >
    {value}
  </span>
}

export default CopyableText