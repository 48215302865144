import { ActionsAllState, ActionsAllAction } from './actions.all.types'

export const initialActionsAllState: ActionsAllState = {
  fetching: false,
  actions: [],
}
const ActionsAllReducer = (state = initialActionsAllState, action: ActionsAllAction): ActionsAllState => {
  switch (action.type) {
    case 'ACTIONS_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'ACTIONS_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        actions: action.params,
      }
    case 'ACTIONS_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialActionsAllState
      }
    default:
      return state
  }
}
export default ActionsAllReducer