import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { newsAllFailureAction, newsAllSuccessAction } from "./news.all.actions";
import { newsItemFailureAction, newsItemSuccessAction } from "./news.item.actions";
import { NewsAction } from "./news.types";

export function* newsAllRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.newsfeed.getNewsAll> = yield call(api.newsfeed.getNewsAll)
  if (res.error) {
    yield put(newsAllFailureAction())
  } else {
    yield put(newsAllSuccessAction(res.data.news))
  }
}

export function* newsItemRequestSaga(action: NewsAction) {
  const res: ThenReturnType<typeof api.newsfeed.getNewsItem> = yield call(api.newsfeed.getNewsItem, action.params)
  if (res.error) {
    yield put(newsItemFailureAction())
  } else {
    yield put(newsItemSuccessAction(res.data))
  }
}

export default function* watchNews() {
  yield takeLeadingPattern<NewsAction['type']>('NEWS_ALL_REQUEST', newsAllRequestSaga, newsAllFailureAction)
  yield takeLeadingPattern<NewsAction['type']>('NEWS_ITEM_REQUEST', newsItemRequestSaga, newsItemFailureAction)
}