import { DoctorsBydepartmentState, DoctorsBydepartmentAction } from './doctors.bydepartment.types'
export const initialDoctorsBydepartmentState: DoctorsBydepartmentState = {
  fetching: false,
  doctors: [],
}
const DoctorsBydepartmentReducer = (state = initialDoctorsBydepartmentState, action: DoctorsBydepartmentAction): DoctorsBydepartmentState => {
  switch (action.type) {
    case 'DOCTORS_BYDEPARTMENT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DOCTORS_BYDEPARTMENT_SUCCESS':
      return {
        ...state,
        fetching: false,
        doctors: action.params,
      }
    case 'DOCTORS_BYDEPARTMENT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorsBydepartmentState
      }
    default:
      return state
  }
}
export default DoctorsBydepartmentReducer