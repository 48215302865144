import { DoctorsByserviceState, DoctorsByserviceAction } from './doctors.byservice.types'
export const initialDoctorsByserviceState: DoctorsByserviceState = {
  fetching: false,
  doctors: [],
}
const DoctorsByserviceReducer = (state = initialDoctorsByserviceState, action: DoctorsByserviceAction): DoctorsByserviceState => {
  switch (action.type) {
    case 'DOCTORS_BYSERVICE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DOCTORS_BYSERVICE_SUCCESS':
      return {
        ...state,
        fetching: false,
        doctors: action.params,
      }
    case 'DOCTORS_BYSERVICE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorsByserviceState
      }
    default:
      return state
  }
}
export default DoctorsByserviceReducer