import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { pricelistCatalogsFailure, pricelistCatalogsSuccess } from "./pricelist.catalogs.actions";
import { pricelistCatalogInfoFailureAction, pricelistCatalogInfoSuccessAction } from "./pricelist.catalog_info.actions";
import { pricelistCatalogServicesFailure, pricelistCatalogServicesSuccess } from "./pricelist.catalog_services.actions";
import { PricelistCatalogServicesAction } from "./pricelist.catalog_services.types";
import { pricelistDoctorServicesFailureAction, pricelistDoctorServicesRequestAction, pricelistDoctorServicesSuccessAction } from "./pricelist.doctor_services.actions";
import { pricelistPrepareInfoFailureAction, pricelistPrepareInfoSuccessAction } from "./pricelist.prepare_info.actions";
import { pricesByDoctorFailureAction, pricesByDoctorSuccessAction } from "./pricelist.prices.bydoctor.actions";
import { pricesByServiceFailureAction, pricesByServiceSuccessAction } from "./pricelist.prices.byservice.actions";
import { pricelistServiceFailure, pricelistServiceSuccess } from "./pricelist.service.actions";
import { PricelistServiceAction } from "./pricelist.service.types";
import { PricelistAction } from "./pricelist.types";

export function* pricelistCatalogsRequestSaga(action: PricelistAction) {
  const res: ThenReturnType<typeof api.pricelist.getCatalogs> = yield call(api.pricelist.getCatalogs)
  if (res.error) {
    yield put(pricelistCatalogsFailure())
  } else {
    yield put(pricelistCatalogsSuccess(res.data.catalogs))
  }
}

type GetCatalogServicesParameters = Parameters<typeof api.pricelist.getCatalogServicesByPatient>[0]

export function* pricelistCatalogServicesRequestSaga(action: PricelistCatalogServicesAction<GetCatalogServicesParameters>) {
  const res: ThenReturnType<typeof api.pricelist.getCatalogServicesByPatient> = yield call(api.pricelist.getCatalogServicesByPatient, action.params as GetCatalogServicesParameters)
  if (res.error) {
    yield put(pricelistCatalogServicesFailure())
  } else {
    yield put(pricelistCatalogServicesSuccess(res.data.services))
  }
}

export function* pricelistServiceRequestSaga(action: PricelistServiceAction) {
  const { pls_id } = action.params
  const res: ThenReturnType<typeof api.pricelist.getService> = yield call(api.pricelist.getService, { pls_id })
  if (res.error) {
    yield put(pricelistServiceFailure())
  } else {
    yield put(pricelistServiceSuccess(res.data.service))
  }
}

export function* pricelistPrepareInfoRequestSaga(action: PricelistAction) {
  const { pls_id } = action.params
  const res: ThenReturnType<typeof api.pricelist.getPrepareInfo> = yield call(api.pricelist.getPrepareInfo, { pls_id })
  if (res.error) {
    yield put(pricelistPrepareInfoFailureAction())
  } else {
    yield put(pricelistPrepareInfoSuccessAction(res.data))
  }
}

export function* pricelistCatalogInfoRequestSaga(action: PricelistAction) {
  const res: ThenReturnType<typeof api.pricelist.getCatalogDescription> = yield call(api.pricelist.getCatalogDescription, action.params)
  if (res.error) {
    yield put(pricelistCatalogInfoFailureAction())
  } else {
    yield put(pricelistCatalogInfoSuccessAction(res.data))
  }
}

export function* pricesByServiceRequestSaga(action: PricelistAction) {
  const res: ThenReturnType<typeof api.pricelist.getPricesByService> = yield call(api.pricelist.getPricesByService, action.params)
  if (res.error) {
    yield put(pricesByServiceFailureAction())
  } else {
    yield put(pricesByServiceSuccessAction(res.data.prices))
  }
}

export function* pricesByDoctorRequestSaga(action: PricelistAction) {
  const res: ThenReturnType<typeof api.pricelist.getPricesByDoctor> = yield call(api.pricelist.getPricesByDoctor, action.params)
  if (res.error) {
    yield put(pricesByDoctorFailureAction())
  } else {
    yield put(pricesByDoctorSuccessAction(res.data.prices))
  }
}

export function* pricelistDoctorServicesRequestSaga(action: PricelistAction) {
  const res: ThenReturnType<typeof api.pricelist.getDoctorServicesByPatient> = yield call(api.pricelist.getDoctorServicesByPatient, action.params)
  if (res.error) {
    yield put(pricelistDoctorServicesFailureAction())
  } else {
    yield put(pricelistDoctorServicesSuccessAction(res.data.services))
  }
}

export default function* watchPricelist() {
  yield takeLeadingPattern('PRICELIST_CATALOGS_REQUEST', pricelistCatalogsRequestSaga, pricelistCatalogsFailure)
  yield takeLeadingPattern('PRICELIST_CATALOG_SERVICES_REQUEST', pricelistCatalogServicesRequestSaga, pricelistCatalogServicesFailure)
  yield takeLeadingPattern('PRICELIST_SERVICE_REQUEST', pricelistServiceRequestSaga, pricelistServiceFailure)
  yield takeLeadingPattern<PricelistAction['type']>('PRICELIST_PREPARE_INFO_REQUEST', pricelistPrepareInfoRequestSaga, pricelistPrepareInfoFailureAction)
  yield takeLeadingPattern<PricelistAction['type']>('PRICELIST_CATALOG_INFO_REQUEST', pricelistCatalogInfoRequestSaga, pricelistCatalogInfoFailureAction)
  yield takeLeadingPattern<PricelistAction['type']>('PRICES_BY_SERVICE_REQUEST', pricesByServiceRequestSaga, pricesByServiceFailureAction)
  yield takeLeadingPattern<PricelistAction['type']>('PRICES_BY_DOCTOR_REQUEST', pricesByDoctorRequestSaga, pricesByDoctorFailureAction)
  yield takeLeadingPattern<PricelistAction['type']>('PRICELIST_DOCTOR_SERVICES_REQUEST', pricelistDoctorServicesRequestSaga, pricelistDoctorServicesFailureAction)
}
