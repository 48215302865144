import React, { useState } from 'react'
import MaskedInput from 'antd-mask-input'
import { Button } from 'antd'
import TextInput from '../TextInput'
import { Patient } from '../../api/patient.types'
import { Man, Woman, Boy, Girl } from '../../images'
import PatientHelper from '../../store/reducks/patients/PatientHelper'
import { BackIcon } from '../../icons'
import './style.less'

export type PhoneInputProps = {
  phone: string
  buttonType: 'default' | 'primary'
  buttonText: string
  onPhoneSubmit?: () => void
  buttonDisabled?: boolean
}

export type CodeInputProps = {
  onCodeSubmit?: (code: string) => void
  codeError?: string
}

export type PatientItemProps = {
  patient: Patient
  onSelect?: (patient: Patient) => void
}

export type HeaderProps = {
  title: string
  onBack?: () => void
  className?: string
}

export type Step1Props = {
  phone: string;
  onSubmit?: () => void
  disabled?: boolean
}

export type Step2Props = CodeInputProps & Pick<PhoneInputProps, 'phone' | 'onPhoneSubmit'> & {
  phoneDisabled?: boolean
}

export type Step3Props = {
  patients: Patient[]
  onPatientSelect?: (patient: Patient) => void
}

class Recovery {
  static Header: React.FC<HeaderProps> = (props) => {
    const { title, onBack, className } = props
    return <div className={`recovery-header ${className || ''}`} onClick={onBack}>
      <div className="back-icon"><BackIcon /></div>
      <div className="title">{title}</div>
    </div>
  }

  static Step1: React.FC<Step1Props> = (props) => {
    return <div className="recovery-step1">
      <Recovery.Title />
      <div className="content">
        <Recovery.PhoneInput
          phone={props.phone}
          buttonText="Отправить"
          buttonType="primary"
          onPhoneSubmit={props.onSubmit}
          buttonDisabled={props.disabled}
        />
      </div>
    </div>
  }

  static Step2: React.FC<Step2Props> = (props) => {
    return <div className="recovery-step2">
      <Recovery.Title />
      <div className="content">
        <Recovery.PhoneInput
          phone={props.phone}
          buttonText="Отправить снова"
          buttonType="default"
          buttonDisabled={props.phoneDisabled}
          onPhoneSubmit={props.onPhoneSubmit}
        />
        <Recovery.CodeInput
          codeError={props.codeError}
          onCodeSubmit={props.onCodeSubmit}
        />
      </div>
    </div>
  }

  static Step3: React.FC<Step3Props> = (props) => {
    const { patients, onPatientSelect } = props
    return <div className="recovery-step3 patients">
      {patients.map((patient, index) => (<Recovery.PatientItem
        key={index}
        patient={patient}
        onSelect={onPatientSelect}
      />))}
    </div>
  }

  static PhoneInput: React.FC<PhoneInputProps> = (props) => {
    const { phone, buttonText, buttonType, onPhoneSubmit: onSubmit, buttonDisabled } = props
    return <div className="phone">
      <MaskedInput
        className="phone-input"
        disabled
        value={phone}
        prefix="+7"
        mask="(111) 111-11-11"
        placeholder="Номер телефона"
        size="large"
      />
      <Button
        className="phone-submit"
        type={buttonType}
        onClick={onSubmit}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button>
    </div>
  }

  static CodeInput: React.FC<CodeInputProps> = (props) => {
    const [code, setCode] = useState<string>('')
    const { onCodeSubmit: onSubmit, codeError: error } = props
    return <div className="code">
      <TextInput
        className="code-input"
        value={code}
        onChangeText={setCode}
        label="Код из СМС"
        error={Boolean(error)}
        helperText={error ? error : undefined}
      />
      <Button
        type="primary"
        className="code-submit"
        onClick={() => {
          onSubmit && onSubmit(code)
        }}
      >
        {'Продолжить'}
      </Button>
    </div>
  }

  static PatientItem: React.FC<PatientItemProps> = (props) => {
    const { patient, onSelect } = props
    return <div
      className="patient"
      onClick={() => {
        onSelect && onSelect(patient)
      }}
    >
      <div className="avatar">
        <img src={Man} />
      </div>
      <div className="name">
        <span>{PatientHelper.getName(patient)}</span>
      </div>
    </div>
  }

  static Title: React.FC = () => {
    return <div className="title">
      <span>
        {'Для восстановления пароля вам будет отправлено СМС на следующий номер'}
      </span>
    </div>
  }
}

export default Recovery