import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ActionPreview } from '../../../api/newsfeed.types'
import config from '../../../config'
import { AppState } from '../../../store'
import { actionsAllRequestAction } from '../../../store/reducks/actions/actions.all.actions'
import NewsItem from '../../NewsItem'
import TemplateContent from '../../Template/content'

export type ActionsProps = ReduxConnectedProps & RouteComponentProps & {}

class Actions extends React.Component<ActionsProps> {
  componentDidMount() {
    this.props.actionsAllRequestAction()
  }

  private renderItem = (action: ActionPreview, index: number) => {
    return <NewsItem
      key={index}
      date={action.action_date}
      title={action.action_name}
      content={action.action_preview_text}
      image={`${config.staticURL}/actions/${action.action_preview_img}`}
      onImage={() => {
        this.props.history.push(`/clinic/actions/${action.action_id}`)
      }}
    />
  }

  render() {
    const { actions } = this.props
    return <TemplateContent
      title="Акции"
    >
      {actions.actions.map(this.renderItem)}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    actions: state.actions.all,
  }), {
    actionsAllRequestAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Actions))