import React from 'react'
import Template from '../../components/Template'
import TelemedicineDisabled from '../../components/TelemedicineDisabled'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import querystring from 'querystring'
import config from '../../config'
import { myMedicTMRequestAction } from '../../store/reducks/mymedic/mymedic.tm.actions'

export type TelemedicineProps = ReduxConnectedProps & RouteComponentProps & {}
export type TelemedicineState = {
  mymedicLoaded?: boolean
}

class Telemedicine extends React.Component<TelemedicineProps, TelemedicineState> {
  constructor(props: TelemedicineProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.myMedicTMRequestAction()
  }

  componentDidUpdate(prevProps: TelemedicineProps) {
    const { tm } = this.props
    if (!tm.fetching && !tm.error && prevProps.tm.fetching) {
      this.setState({ mymedicLoaded: true })
      if (!tm.app.disabled) {
        this.gotoTelemedicine()
      }
    }
  }

  private gotoTelemedicine = () => {
    const { user } = this.props
    window.location.href = `${config.api.telemedURL}/Auth?${querystring.stringify({ login: user.phone, token: user.token })}`
  }

  render() {
    const { tm } = this.props
    const { mymedicLoaded } = this.state
    return <Template
      className="telemedicine"
      title="Телемедицина"
      sidebar={<div className="sidebar-default" />}
      currentItem="telemedicine"
    >
      {mymedicLoaded && tm.app.disabled && <TelemedicineDisabled />}
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    user: state.user,
    tm: state.mymedic.tm,
  }), {
  myMedicTMRequestAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Telemedicine))