import { RecoverySendPasswordAction, RecoverySendPasswordState } from "./recovery.send_password.types"

export const initialRecoverySendPasswordState: RecoverySendPasswordState = {
  fetching: false,
}

const RecoverySendPasswordReducer = (state = initialRecoverySendPasswordState, action: RecoverySendPasswordAction): RecoverySendPasswordState => {
  switch (action.type) {
    case 'RECOVERY_SEND_PASSWORD_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'RECOVERY_SEND_PASSWORD_SUCCESS':
      return {
        ...state,
        fetching: false,
      }
    case 'RECOVERY_SEND_PASSWORD_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialRecoverySendPasswordState,
      }
    default:
      return state
  }
}

export default RecoverySendPasswordReducer