import { CatalogPreview } from "../../../api/pricelist.types"
import { PricelistCatalogsAction, PricelistCatalogsState } from "./pricelist.catalogs.types"

export const initialPricelistCatalogsReducer: PricelistCatalogsState = {
  fetching: false,
  error: false,
  catalogs: [],
  catalogsByDepartments: [],
}

export const combineCatalogsByDepartments = (services: CatalogPreview[]) => {
  const deparments: PricelistCatalogsState['catalogsByDepartments'] = services.reduce((acc, catalog) => {
    const index = acc.findIndex(d => d.name == catalog.department_name)
    if (index > -1) {
      acc[index].catalogs.push(catalog)
    } else {
      acc.push({ name: catalog.department_name, catalogs: [catalog] })
    }
    return acc
  }, [] as PricelistCatalogsState['catalogsByDepartments'])
  return deparments
}


const PricelistCatalogsReducer = (state = initialPricelistCatalogsReducer, action: PricelistCatalogsAction): PricelistCatalogsState => {
  switch (action.type) {
    case 'PRICELIST_CATALOGS_REQUEST': 
      return {
        ...state,
        fetching: true,
      }
    case 'PRICELIST_CATALOGS_SUCCESS':
      return {
        ...state,
        fetching: false,
        catalogs: action.params as CatalogPreview[],
        catalogsByDepartments: combineCatalogsByDepartments(action.params as CatalogPreview[]),
      }
    case 'PRICELIST_CATALOGS_FAILURE':
      return {
        ...state,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricelistCatalogsReducer,
      }
    default:
      return state
  }
}

export default PricelistCatalogsReducer