import { PatientsBymistokenOrderAction, PatientsBymistokenOrderState } from "./patients.bymistoken.order.types"

export const initialPatientsBymistokenOrderState: PatientsBymistokenOrderState = {
  fetching: false
}

const PatientsBymistokenOrderReducer = (state = initialPatientsBymistokenOrderState, action: PatientsBymistokenOrderAction): PatientsBymistokenOrderState => {
  switch (action.type) {
    case 'PATIENTS_BYMISTOKEN_ORDER_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENTS_BYMISTOKEN_ORDER_SUCCESS':
    case 'PATIENTS_BYMISTOKEN_ORDER_FAILURE':
      return {
        ...state,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPatientsBymistokenOrderState,
      }
    default:
      return state
  }
}

export default PatientsBymistokenOrderReducer