import { RecoverySendSmsAction, RecoverySendSmsState } from "./recovery.send_sms.types"

export const initialRecoverySendSmsState: RecoverySendSmsState = {
  fetching: false,
}

const RecoverySendSmsReducer = (state = initialRecoverySendSmsState, action: RecoverySendSmsAction): RecoverySendSmsState => {
  switch (action.type) {
    case 'RECOVERY_SEND_SMS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        phone: action.params?.phone,
      }
    case 'RECOVERY_SEND_SMS_SUCCESS':
      return {
        ...state,
        fetching: false,
      }
    case 'RECOVERY_SEND_SMS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialRecoverySendSmsState,
      }
    default:
      return state
  }
}

export default RecoverySendSmsReducer