import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { AppState } from '../../../store'
import { doctorByEmployerIdRequestAction, doctorByIdRequestAction } from '../../../store/reducks/doctors/doctors.item.actions'
import TemplateContent from '../../Template/content'
import DoctorInfo from '../../DoctorInfo'

export type DoctorProps = ReduxConnectedProps & RouteComponentProps<{ id: string }> & {}

class Doctor extends React.Component<DoctorProps> {
  componentDidMount() {
    this.fetchDoctor()
  }

  private fetchDoctor = () => {
    const { match } = this.props
    const { id: doctor_id } = match.params
    this.props.doctorByEmployerIdRequestAction({ employer_id: +doctor_id })
  }

  private goBack = () => {
    const { history } = this.props
    if (history.action == 'POP') {
      history.push('/clinic/doctors')
    } else {
      history.goBack()
    }
  }

  render() {
    const { doctor } = this.props
    return <TemplateContent
      title="Врачи"
      onTitleBack={this.goBack}
    >
      {doctor.doctor && <DoctorInfo doctor={doctor.doctor} />}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    doctor: state.doctors.item,
  }), {
    doctorByIdRequestAction,
    doctorByEmployerIdRequestAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Doctor))