import { PricelistServiceAction, PricelistServiceState } from "./pricelist.service.types"

export const initialPricelistServiceState: PricelistServiceState = {
  fetching: false,
  error: false,
  service: null,
}

const PricelistServiceReducer = (state = initialPricelistServiceState, action: PricelistServiceAction): PricelistServiceState => {
  switch (action.type) {
    case 'PRICELIST_SERVICE_REQUEST':
      return {
        ...state,
        error: false,
        fetching: true,
      }
    case 'PRICELIST_SERVICE_FAILURE':
      return {
        ...state,
        error: true,
        fetching: false,
      }
    case 'PRICELIST_SERVICE_SUCCESS':
      return {
        ...state,
        fetching: false,
        service: action.params,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricelistServiceState,
      }
    default:
      return state
  }
}

export default PricelistServiceReducer