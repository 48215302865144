import React, { Component, Key } from 'react'
import { Menu } from 'antd'
import {
	SettingsIcon,
	PencilIcon,
	MedcardIcon,
	JournalIcon,
	TelemedicineIcon,
	ClinicIcon,
	InfoIcon,
	ChatIcon,
	CallbackIcon,
	ResearchIcon,
	LogoutIcon,
} from '../../icons'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import logo from './../../images/MedicLogo.svg'
import Stores from '../Stores'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { logoutRequest } from '../../store/reducks/auth/auth.actions'
import { menuCloseAction, menuCurrentItemSetAction, menuOpenAction, menuOpenedKeysSetAction } from '../../store/reducks/menu/menu.actions'

const { Item, Divider, SubMenu } = Menu

const clinic_items = [
	{ key: 'about', label: 'О клинике', type: 'link' },
	{ key: 'services', label: 'Услуги', type: 'link' },
	{ key: 'news', label: 'Новости', type: 'link' },
	{ key: 'actions', label: 'Акции', type: 'link' },
	{ key: 'doctors', label: 'Врачи', type: 'link' },
	{ key: 'programs', label: 'Программы', type: 'link' },
]
const menu = [
	{ key: 'recording', label: 'Записаться на прием', icon: <PencilIcon />, type: 'link' },
	{ key: 'medcard', label: 'Медкарта', icon: <MedcardIcon />, type: 'link' },
	{ key: 'researches', label: 'Анализы', icon: <ResearchIcon />, type: 'link' },
	{ key: 'journal', label: 'Журнал записей', icon: <JournalIcon />, type: 'link' },
	{ key: 'clinic', label: 'Клиника', icon: <ClinicIcon />, type: 'submenu', items: clinic_items },
	{ key: 'telemedicine', label: 'Телемедицина', icon: <TelemedicineIcon />, type: 'link' },
	// { key: 'info', label: 'Информация', icon: <InfoIcon />, type: 'link' },
	{ key: 'chat', label: 'Онлайн Чат', icon: <ChatIcon />, type: 'link' },
	{ key: 'callback', label: 'Обратная связь', icon: <CallbackIcon />, type: 'link' },
	{ key: 'settings', label: 'Настройки', icon: <SettingsIcon />, type: 'link' },
	// { key: 'icons', label: 'Иконки', icon: <SettingsIcon />, type: 'link' },
	{ key: 'logout', label: 'Выйти', icon: <LogoutIcon />, type: 'link' },
]

interface OwnProps {
	onMenuItemClick?: (key: Key) => void
	currentItem?: string
}

type Props = ReduxConnectedProps & RouteComponentProps & OwnProps

interface State {
	selected: string[]
}

class TemplateMenu extends Component<Props, State> {
	
	constructor(props: Props) {
		super(props)
		this.state = {
			selected: this.getSelected()
		}
	}
	
	private getSelected = () => {
		const { history } = this.props
		const { pathname } = history.location
		return pathname.substring(1).split('/');
	}
	
	
	render() {
		const { selected } = this.state
		const { menu: menuState, user, history, onMenuItemClick } = this.props
		return (
			<div className={`template__menu ${menuState.open ? 'open' : ''}`}>
				<div
					className="header"
					onClick={() => {
						this.props.history.push('/')
					}}
				>
					<img src={logo} alt="" />
				</div>
				<div className="menu">
					<Menu
						onOpenChange={(e) => {
							this.props.menuOpenedKeysSetAction(e as string[])
						}}
						selectedKeys={selected}
						onClick={(item) => {
							const { key } = item
							if (key != 'divider') {
								this.props.menuCloseAction()
								const path = item.keyPath.reverse().join('/')
								if (typeof onMenuItemClick === 'function') {
									onMenuItemClick(key)
									this.props.menuCurrentItemSetAction(path)
								} else if (key == 'logout') {
									this.props.logoutRequest()
									this.props.menuCurrentItemSetAction()
								} else {
									history.push('/' + path)
									this.props.menuCurrentItemSetAction(path)
								}
							}
						}}
						mode={"inline"}
					>
						{menu.filter(item => {
							if (user.is_demo) {
								return item.key != 'chat'
							}
							return item.key != 'logout'
						}).map(({ key, type, label, icon, items }) => {
							if (type == 'divider') {
								return <Divider key={key} />
							} else if (type == 'submenu' && items && Array.isArray(items)) {
								return <SubMenu
									key={key}
									title={<span className="title">
                    <span>{label}</span>
                    <ArrowForwardIos />
                  </span>}
									icon={icon}
								>
									{items.map((item, index) => (<Item
										key={item.key}
									>
										<div className="title">{item.label}</div>
									</Item>))}
								</SubMenu>
							} else {
								return (
									<Item key={key}>
										<div className="icon">{icon}</div>
										<div className="title">{label}</div>
									</Item>
								)
							}
						})}
					</Menu>
				</div>
				<div className="footer">
					<Stores />
				</div>
			</div>
		)
	}
}

const connector = connect(
	(state: AppState) => ({
		user: state.user,
		menu: state.menu,
	}), {
		logoutRequest,
		menuOpenAction,
		menuCloseAction,
		menuOpenedKeysSetAction,
		menuCurrentItemSetAction,
	})



type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(TemplateMenu))
