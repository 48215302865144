import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { scheduleByDateDivisionsDatetimesFailureAction, scheduleByDateDivisionsDatetimesSuccessAction } from "./schedule.bydate.divisions_datetimes.actions";
import { scheduleByDateDivisionsDoctorsFailureAction, scheduleByDateDivisionsDoctorsSuccessAction } from "./schedule.bydate.divisions_doctors.actions";
import { scheduleByDoctorDivisionsDatetimesFailureAction, scheduleByDoctorDivisionsDatetimesSuccessAction } from "./schedule.bydoctor.divisions_datetimes.actions";
import { scheduleByDoctorDoctorsFailureAction, scheduleByDoctorDoctorsSuccessAction } from "./schedule.bydoctor.doctors.actins";
import { ScheduleAction } from "./schedule.types";

export function* scheduleByDateDivisionsDatetimesRequestSaga(action: ScheduleAction) {
  const res: ThenReturnType<typeof api.schedule.getDivisionsDatetimes> = yield call(api.schedule.getDivisionsDatetimes, action.params)
  if (res.error) {
    yield put(scheduleByDateDivisionsDatetimesFailureAction())
  } else {
    yield put(scheduleByDateDivisionsDatetimesSuccessAction(res.data.divisions))
  }
}

export function* scheduleByDateDivisionsDoctorsRequestSaga(action: ScheduleAction) {
  const res: ThenReturnType<typeof api.schedule.getDivisionsDoctorsByDate> = yield call(api.schedule.getDivisionsDoctorsByDate, action.params)
  if (res.error) {
    yield put(scheduleByDateDivisionsDoctorsFailureAction())
  } else {
    yield put(scheduleByDateDivisionsDoctorsSuccessAction(res.data.divisions))
  }
}

export function* scheduleByDoctorDoctorsRequestSaga(action: ScheduleAction) {
  const res: ThenReturnType<typeof api.schedule.getDoctorsByPricelist> = yield call(api.schedule.getDoctorsByPricelist, action.params)
  if (res.error) {
    yield put(scheduleByDoctorDoctorsFailureAction())
  } else {
    yield put(scheduleByDoctorDoctorsSuccessAction(res.data.doctors))
  }
}

export function* scheduleByDoctorDivisionsDatetimesRequestSaga(action: ScheduleAction) {
  const res: ThenReturnType<typeof api.schedule.getDivisionsDatetimesByDoctor> = yield call(api.schedule.getDivisionsDatetimesByDoctor, action.params)
  if (res.error) {
    yield put(scheduleByDoctorDivisionsDatetimesFailureAction())
  } else {
    yield put(scheduleByDoctorDivisionsDatetimesSuccessAction(res.data.divisions))
  }
}

export default function* watchSchedule() {
  yield takeLeadingPattern<ScheduleAction['type']>('SCHEDULE_BY_DATE_DIVISIONS_DATETIMES_REQUEST', scheduleByDateDivisionsDatetimesRequestSaga, scheduleByDateDivisionsDatetimesFailureAction)
  yield takeLeadingPattern<ScheduleAction['type']>('SCHEDULE_BY_DATE_DIVISIONS_DOCTORS_REQUEST', scheduleByDateDivisionsDoctorsRequestSaga, scheduleByDateDivisionsDoctorsFailureAction)
  yield takeLeadingPattern<ScheduleAction['type']>('SCHEDULE_BY_DOCTOR_DOCTORS_REQUEST', scheduleByDoctorDoctorsRequestSaga, scheduleByDoctorDoctorsFailureAction)
  yield takeLeadingPattern<ScheduleAction['type']>('SCHEDULE_BY_DOCTOR_DIVISIONS_DATETIMES_REQUEST', scheduleByDoctorDivisionsDatetimesRequestSaga, scheduleByDoctorDivisionsDatetimesFailureAction)
}