import { GlobalModalAction, GlobalModalState } from "./modals.global.types"

export const initialGlobalModalState: GlobalModalState = {
  visible: false,
  title: '',
  content: '',
  buttons: [],
}

const noop = () => {}

const GlobalModalReducer = (state = initialGlobalModalState, action: GlobalModalAction): GlobalModalState => {
  switch (action.type) {
    case 'GLOBAL_MODAL_CLOSE':
      return {
        ...state,
        visible: false,
        buttons: [],
        onClose: noop,
      }
    case 'GLOBAL_MODAL_OPEN':
      return {
        ...state,
        visible: true,
      }
    case 'GLOBAL_MODAL_SET':
      return {
        ...state,
        ...action.params,
      }
    default:
      return state
  }
}

export default GlobalModalReducer