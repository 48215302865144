import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ActionPreview } from '../../../api/newsfeed.types'
import config from '../../../config'
import { AppState } from '../../../store'
import { actionsAllRequestAction } from '../../../store/reducks/actions/actions.all.actions'
import { actionsItemRequestAction } from '../../../store/reducks/actions/actions.item.actions'
import NewsItem from '../../NewsItem'
import TemplateContent from '../../Template/content'

export type ActionProps = ReduxConnectedProps & RouteComponentProps<{ id: string }> & {}

class Action extends React.Component<ActionProps> {
  componentDidMount() {
    const { match } = this.props
    const { id: action_id } = match.params
    this.props.actionsItemRequestAction({ action_id: +action_id })
  }

  private renderAction = () => {
    const { action } = this.props
    if (action.fetching || action.error || !action.action) {
      return null
    }
    const { action: item } = action
    return <NewsItem
      title={item.action_name}
      content={item.action_descr}
      image={`${config.staticURL}/actions/${item.action_img}`}
    />
  }

  private goBack = () => {
    const { history } = this.props
    if (history.action == 'POP') {
      history.push('/clinic/actions')
    } else {
      history.goBack()
    }
  }

  render() {
    const { action, history } = this.props
    return <TemplateContent
      title="Акции"
      onTitleBack={this.goBack}
    >
      {this.renderAction()}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    action: state.actions.item,
  }), {
    actionsItemRequestAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Action))