import React, { Component } from 'react'
import Template from '../../components/Template'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../store'
import PatientList from '../../components/PatientList'
import { patientBirthdateRequest } from '../../store/reducks/patients/patients.birthdate.date.action'
import { patientBirthdateChangeRequest } from '../../store/reducks/patients/patients.birthdate.change.action'
import { patientsByphoneRequest } from '../../store/reducks/patients/patients.byphone.actions'
import { Button } from 'antd'
import { birthdateValidation } from '../../tools/validation'
import TextInputMask from '../../components/TextInput/mask'
import BirthdateModal from '../../modals/BirthdateModal'
import moment from 'moment'
import 'moment/locale/ru'
import './style.less'

type Props = RouteComponentProps & ReduxConnectedProps & {}
interface State {
  showPatientListModal: boolean
  stage: 'select' | 'edit'
  showCalendar: boolean
  title: string
  patientBirthdate: string
  patientBirthdateError: string
  patientBirthdatePersist: string
  medicUserId?: number
}

class ProfileEditView extends Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      showPatientListModal: false,
      stage: 'select',
      showCalendar: false,
      title: 'Выберите пациента',
      patientBirthdate: '',
      patientBirthdateError: '',
      patientBirthdatePersist: '',
    }
  }

  componentDidMount() {
    this.props.patientsByPhoneGetAction()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.patients_fetching && !this.props.patients_fetching) {
      if (!this.props.error) {
        this.setState({ showPatientListModal: true })
      }
    }

    if ((prevProps.birthdate_fetching && !this.props.birthdate_fetching)) {
      if (!this.props.error) {
        const birthdate = this.props.birthdate
        const formatted = moment(birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY')
        this.setState({
          patientBirthdate: formatted,
          patientBirthdatePersist: formatted,
        })
      }
    }
  }

  private onBack = () => {
    const { stage } = this.state

    if (stage == 'edit') {
      this.setState({
        stage: 'select',
        title: 'Выберите пациента',
        showPatientListModal: true,
        patientBirthdate: '',
        patientBirthdateError: '',
      })
    }
    else this.props.history.goBack()
  }

  private openCalendar = () => this.setState({ showCalendar: true })
  private closeCalendar = () => this.setState({ showCalendar: false })

  validation(): boolean {
    const { patientBirthdate } = this.state
    const patientBirthdateError = birthdateValidation(patientBirthdate)

    this.setState({
      patientBirthdateError,
    })

    if (!patientBirthdateError) return true
    return false
  }

  render() {
    const { patients, patientBirthdateRequest, patientBirthdateChangeRequest } = this.props
    const {
      stage,
      showPatientListModal,
      showCalendar,
      title,
      patientBirthdate,
      patientBirthdateError,
      patientBirthdatePersist,
      medicUserId
    } = this.state

    return (
      <Template
        className="edit-profile"
        title="Профиль"
        onTitleBack={this.onBack}
        currentItem="settings"
        sidebar={<div className="sidebar-default" />}
      >
        <div className='profile-title'>{title}</div>

        {stage == 'select' && showPatientListModal && (
          <PatientList
            mainPatients={patients.byphone.main.users}
            addedPatients={patients.byphone.added.users}
            onSelect={(patient) => {
              this.setState({
                showPatientListModal: false,
                stage: 'edit',
                title: !!patient ? patient.name : '',
                medicUserId: patient?.medic_user_id
              })

              if (!!patient) {
                patientBirthdateRequest({ medic_user_id: patient.medic_user_id })
              }
            }}
          />
        )}
        {stage == 'edit' && (
          <div className={`edit-form`}>
            <TextInputMask
              value={patientBirthdate}
              errorString={patientBirthdateError}
              mask='date'
              autoComplete='off'
              postfix='calendar'
              label='Дата рождения'
              onChange={(e) => {
                const { value } = e.target
                const isNotEmpty = /\d+/.test(value)
                this.setState({
                  patientBirthdate: isNotEmpty ? value : '',
                  patientBirthdateError: '',
                })
              }}
              onPostfix={this.openCalendar}
            />

            <Button
              type='primary'
              onClick={() => {
                const isValid = this.validation()
                if (!!medicUserId && isValid) {
                  const dateFormatted = moment(patientBirthdate, 'DD.MM.YYYY').format('YYYY-MM-DD')
                  patientBirthdateChangeRequest({
                    birthdate: dateFormatted,
                    medic_user_id: medicUserId,
                  })
                }
              }}
              className='edit-button'
              disabled={patientBirthdate === patientBirthdatePersist}
            >
              Сохранить
            </Button>
          </div>
        )}

        <BirthdateModal
          visible={showCalendar}
          date={patientBirthdate}
          onCancel={this.closeCalendar}
          onAccept={(date) => {
            this.setState({
              patientBirthdate: date,
              patientBirthdateError: '',
            })
            this.closeCalendar()
          }}
        />
      </Template >
    )
  }
}

const connector = connect(
  (state: AppState) => ({
    error: state.error.message,
    user: state.user,
    patients: state.patients,
    patients_fetching: state.patients.byphone.main.fetching || state.patients.byphone.added.fetching,
    birthdate: state.patients.birthdate.date.date,
    birthdate_fetching: state.patients.birthdate.date.fetching,
    birthdate_change_fetching: state.patients.birthdate.change.fetching,
  }), {
  patientsByPhoneGetAction: patientsByphoneRequest,
  patientBirthdateRequest: patientBirthdateRequest,
  patientBirthdateChangeRequest: patientBirthdateChangeRequest,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(ProfileEditView))
