import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import DoctorInfo from '../../components/DoctorInfo'
import Modal from '../../components/Modal'
import { AppState } from '../../store'
import { doctorInfoModalClearAction, doctorInfoModalSetAction } from '../../store/reducks/modals/modals.doctorInfo.actions'
import './style.less'

export type DoctorInfoModalProps = ReduxConnectedProps & {}

class DoctorInfoModal extends React.Component<DoctorInfoModalProps> {
  private closeModal = () => {
    this.props.doctorInfoModalClearAction()
  }

  render() {
    const { modal, doctor } = this.props
    if (!doctor.doctor) {
      return null
    }
    return <Modal
      title="Врач"
      visible={modal.visible}
      onCancel={this.closeModal}
    >
      <DoctorInfo
        doctor={doctor.doctor}
        column
        hideButton
      />
    </Modal>
  }
}

const connector = connect(
  (state: AppState) => ({
    modal: state.modals.doctorInfo,
    doctor: state.doctors.item,
  }), {
    doctorInfoModalClearAction,
    doctorInfoModalSetAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(DoctorInfoModal)