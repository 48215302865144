import { PricelistCatalogInfoAction, PricelistCatalogInfoState } from "./pricelist.catalog_info.types"

export const initialPricelistCatalogInfoState: PricelistCatalogInfoState = {
  fetching: false,
  info: {},
}

const PricelistCatalogInfoReducer = (state = initialPricelistCatalogInfoState, action: PricelistCatalogInfoAction): PricelistCatalogInfoState => {
  switch (action.type) {
    case 'PRICELIST_CATALOG_INFO_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'PRICELIST_CATALOG_INFO_SUCCESS':
      return {
        ...state,
        fetching: false,
        info: action.params,
      }
    case 'PRICELIST_CATALOG_INFO_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricelistCatalogInfoState,
      }
    default:
      return state
  }
}

export default PricelistCatalogInfoReducer