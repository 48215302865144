import React, { FormEvent } from 'react'
import { ChatMessage } from '../../api/chat.types'
import { bufferToBase64 } from '../../tools/buffer-to-base64'
import { fileToBase64 } from '../../tools/file-to-base64'
import MessageList from '../MessageList'
import SendInput from '../SendInput'

import './style.less'

export type ChatProps = {
  messages: ChatMessage[]
  onSend: (text: string, files?: Array<{ name: string; base64: string }>) => void
  className?: string
}

export type ChatState = {
  message?: string
  file?: File;
}

class Chat extends React.Component<ChatProps, ChatState> {
  constructor(props: ChatProps) {
    super(props)
    this.state = {}
  }

  private onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    const { onSend } = this.props
    const { message, file } = this.state
    e?.preventDefault()
    const text = message?.trim();
    if (!text && !file) {
      return
    }
    if (file) {
      const base64 = await fileToBase64(file)
      onSend(text || file.name, [{ name: file.name, base64: base64.split(';base64,').pop() as string }])
    } else if (text) {
      onSend(text)
    }
    this.setState({ message: '', file: undefined })
  }

  render() {
    const { messages, className } = this.props
	const helloMessage:ChatMessage = {
		created: "",
		operator_name: "Медик",
		body: 'Здравствуйте! Есть вопросы? Мы на месте.'
	}
	const _messages:ChatMessage[] = [helloMessage, ...messages];

    return <div className={`chat-container ${className || ''}`}>
      <MessageList
        messages={_messages}
      />
      <form className="message-form" onSubmit={this.onSubmit}>
        <SendInput
          value={this.state.message}
          label="Введите сообщение"
          onChangeText={(message) => this.setState({ message })}
          onFile={(file) => this.setState({ file })}
          file={this.state.file}
          onClick={() => this.onSubmit()}
        />
      </form>
    </div>
  }
}

export default Chat