import { SendFeedbackState, SendFeedbackAction } from './send.feedback.types'
export const initialSendFeedbackState: SendFeedbackState = {
  fetching: false,
}
const SendFeedbackReducer = (state = initialSendFeedbackState, action: SendFeedbackAction): SendFeedbackState => {
  switch (action.type) {
    case 'SEND_FEEDBACK_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'SEND_FEEDBACK_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: action.params,
      }
    case 'SEND_FEEDBACK_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialSendFeedbackState
      }
    default:
      return state
  }
}
export default SendFeedbackReducer