import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { articlesAllFailureAction, articlesAllSuccessAction } from "./articles.all.actions";
import { articlesItemFailureAction, articlesItemSuccessAction } from "./articles.item.actions";
import { ArticlesAction } from "./articles.types";

export function* articlesAllRequestSaga(action: ArticlesAction) {
  const res: ThenReturnType<typeof api.newsfeed.getArticles> = yield call(api.newsfeed.getArticles, action.params)
  if (res.error) {
    yield put(articlesAllFailureAction())
  } else {
    yield put(articlesAllSuccessAction(res.data.articles))
  }
}

export function* articlesItemRequestSaga(action: ArticlesAction) {
  const res: ThenReturnType<typeof api.newsfeed.getArticle> = yield call(api.newsfeed.getArticle, action.params)
  if (res.error) {
    yield put(articlesItemFailureAction())
  } else {
    yield put(articlesItemSuccessAction(res.data.article))
  }
}

export default function* watchArticles() {
  yield takeLeadingPattern<ArticlesAction['type']>('ARTICLES_ALL_REQUEST', articlesAllRequestSaga, articlesAllFailureAction)
  yield takeLeadingPattern<ArticlesAction['type']>('ARTICLES_ITEM_REQUEST', articlesItemRequestSaga, articlesItemFailureAction)
}