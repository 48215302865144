import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CatalogPreview } from '../../../api/pricelist.types'
import { AppState } from '../../../store'
import { pricelistCatalogsRequest } from '../../../store/reducks/pricelist/pricelist.catalogs.actions'
import CatalogList from '../../CatalogList'
import TemplateContent from '../../Template/content'
import './style.less'

export type CatalogsProps = ReduxConnectedProps & {
  title?: string
  onSelect?: (catalog: CatalogPreview) => void
}

export type CatalogsState = {
  catalogsLoaded?: boolean
  activeKey: string
}

class Catalogs extends React.Component<CatalogsProps, CatalogsState> {
  constructor(props: CatalogsProps) {
    super(props)
    this.state = {
      activeKey: '0',
    }
  }

  componentDidMount() {
    this.props.pricelistCatalogsRequest()
  }

  componentDidUpdate(prevProps: CatalogsProps) {
    const { catalogs } = this.props
    if (!catalogs.fetching && !catalogs.error && prevProps.catalogs.fetching) {
      this.setState({ catalogsLoaded: true })
    }
  }

  private getTabs = () => {
    const { catalogsLoaded } = this.state
    const { catalogsByDepartments } = this.props.catalogs
    return catalogsLoaded ? catalogsByDepartments.map(d => d.name) : undefined
  }

  private onTabChange = (key: string) => {
    this.setState({ activeKey: key })
  }

  private onSelect = (catalog: CatalogPreview) => {
    const { onSelect } = this.props
    onSelect && onSelect(catalog)
  }

  render() {
    const { title = 'Услуги', catalogs } = this.props
    const { catalogsLoaded, activeKey } = this.state
    return <TemplateContent
      title={title}
      onTabChange={this.onTabChange}
      tabs={this.getTabs()}
    >
      {catalogsLoaded && <CatalogList
        catalogs={catalogs.catalogsByDepartments}
        activeKey={activeKey}
        onSelect={this.onSelect}
      />}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    catalogs: state.pricelist.catalogs,
  }), {
    pricelistCatalogsRequest,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(Catalogs)