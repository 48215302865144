import { PriceGroup } from "../../../api/pricelist.types"
import { ClinicPricesByserviceAction, ClinicPricesByserviceState } from "./clinic.prices.byservice.types"

export const initialClinicPricesByserviceState: ClinicPricesByserviceState = {
  fetching: false,
  prices: [],
}

const ClinicPricesByserviceReducer = (state = initialClinicPricesByserviceState, action: ClinicPricesByserviceAction): ClinicPricesByserviceState => {
  switch (action.type) {
    case 'CLINIC_PRICES_BYSERVICE_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'CLINIC_PRICES_BYSERVICE_SUCCESS':
      return {
        ...state,
        fetching: false,
        prices: action.params as PriceGroup[],
      }
    case 'CLINIC_PRICES_BYSERVICE_FAILURE':
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}

export default ClinicPricesByserviceReducer