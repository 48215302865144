import { combineReducers } from 'redux'
import ClinicContactsReducer from './clinic.contacts.reducer'
import ClinicDeparmentsReducer from './clinic.departments.reducer'
import ClinicPricesReducer from './clinic.prices.reducer'

const ClinicReducer = combineReducers({
  deparments: ClinicDeparmentsReducer,
  prices: ClinicPricesReducer,
  contacts: ClinicContactsReducer,
})

export default ClinicReducer