import moment from 'moment'
import React from 'react'
import './style.less'

export type RecordingDateTimesProps = {
  datetimes: string[]
  onDateTime?: (date: string) => void
  selectedDateTime?: string
}

class RecordingDateTimes extends React.Component<RecordingDateTimesProps> {
  private onDateTime = (datetime: string) => {
    const { onDateTime } = this.props
    onDateTime && onDateTime(datetime)
  }

  renderItem = (datetime: string, index: number) => {
    const { selectedDateTime } = this.props
    return <div
      key={index}
      className={`datetime ${selectedDateTime && selectedDateTime == datetime ? 'selected' : ''}`}
      onClick={() => this.onDateTime(datetime)}
    >
      {moment(datetime).format('HH:mm')}
    </div>
  }

  render() {
    const { datetimes } = this.props
    return <div className="recording__datetimes">
      {datetimes.map(this.renderItem)}
    </div>
  }
}

export default RecordingDateTimes