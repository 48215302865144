import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

interface Props {
  title?: string
  className?: string
  onChange?: (value: 0 | 1) => void
  defaultValue?: 'male' | 'female'
  value?: 'male' | 'female'
}

export default (props: Props) => {
  const { title, className, defaultValue = 'male', value } = props
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { onChange } = props
    onChange?.(value === 'male' ? 1 : 0)
  }

  return (
    <FormControl className={className} component="fieldset">
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup row aria-label="position" name="position" defaultValue={defaultValue} value={value} onChange={onChange}>
        <FormControlLabel value="male" control={<Radio color="primary" />} label="Мужской" />
        <FormControlLabel value="female" control={<Radio color="primary" />} label="Женский" />
      </RadioGroup>
    </FormControl>
  );
}