
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import TemplateContent from '../../components/Template/content'
import Container from '../../components/Template/container'
import { Button } from 'antd'
import TextInput from '../../components/TextInput'
import {inputPasswordValidation} from "../../tools/validation";
import './style.less'
import {changePasswordRequestAction} from "../../store/reducks/changePassword/changePassword.actions";
import {ChangePasswordState} from "../../store/reducks/changePassword/changePassword.types";
import showToast from "../../tools/showToast";

export type ChangePasswordViewProps = ReduxConnectedProps & RouteComponentProps & {}
export type ChangePasswordViewState = {
	oldPassword: string
	newPassword: string
	confirmPassword: string
	oldPasswordError: string
	newPasswordError: string
	confirmPasswordError: string
}

class ChangePasswordView extends React.Component<ChangePasswordViewProps, ChangePasswordViewState> {
	constructor(props: ChangePasswordViewProps) {
		super(props)
		this.state = {
			oldPassword:'',
			newPassword:'',
			confirmPassword:'',
			oldPasswordError: '',
			newPasswordError: '',
			confirmPasswordError: ''
		};
	}
	
	componentDidUpdate(prevProps: ChangePasswordViewProps, prevState: ChangePasswordViewState) {
		if (
			prevProps.isFetching
			&& this.props.isFetching
			&& !this.props.error
			&& this.props.success == true
		) {
			showToast('Пароль успешно изменен');
		}
	}
	
	changePassword() {
		const isValid = this.validation()
		if (isValid) {
			this.props.changePasswordRequestAction({
				old_password: this.state.oldPassword,
				new_password: this.state.newPassword
			})
		}
	}
	
	validation(): boolean {
		const { oldPassword, newPassword, confirmPassword } = this.state
		const oldPasswordError = inputPasswordValidation(oldPassword, 'Старый пароль')
		const newPasswordError = inputPasswordValidation(newPassword, 'Новый пароль')
		const confirmPasswordError = inputPasswordValidation(confirmPassword, 'Подтверждение пароля')
		
		this.setState({
			oldPasswordError:oldPasswordError,
			newPasswordError:newPasswordError,
			confirmPasswordError:confirmPasswordError,
		})
		
		return !oldPasswordError && !newPasswordError && !confirmPasswordError
	}
	
	private onBack = () => {
		this.props.history.goBack()
	}
	
	render() {
		const {oldPassword,
			newPassword,
			confirmPassword,
			oldPasswordError,
			newPasswordError,
			confirmPasswordError} = this.state
		
		return <Container
			className="callback"
			currentItem="callback"
			sidebar={<div className="sidebar-default" />}
		>
			<TemplateContent
				title="Смена пароля"
				onTitleBack={this.onBack}
			>
				<div className='change-password'>
					<div className="change-password__field">
						<TextInput
							type={'password'}
							className={`change-password__input`}
							value={oldPassword}
							label="Старый пароль"
							error={!!oldPasswordError}
							helperText={oldPasswordError}
							onChangeText={(value) => this.setState({oldPassword: value,})}
						/>
					</div>
					<div className="change-password__field">
						<TextInput
							type={'password'}
							className={`change-password__input`}
							value={newPassword}
							label="Новый пароль"
							error={!!newPasswordError}
							helperText={newPasswordError}
							onChangeText={(value) => this.setState({newPassword: value,})}
						/>
					</div>
					<div className="change-password__field">
						<TextInput
							type={'password'}
							className={`change-password__input`}
							value={confirmPassword}
							label="Подтверждение нового пароля"
							error={!!confirmPasswordError}
							helperText={confirmPasswordError}
							onChangeText={(value) => this.setState({confirmPassword: value,})}
						/>
					</div>
					<div className="change-password__button">
						<Button
							type='primary'
							onClick={() => this.changePassword()}
						>
							Сменить пароль
						</Button>
					</div>
				</div>
			</TemplateContent>
		</Container>
	}
}

const connector = connect((state: ChangePasswordState) => ({
	error: state.error,
	success: state.success,
	isFetching: state.fetching
}), {
	changePasswordRequestAction
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(ChangePasswordView))
