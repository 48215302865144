import { NewsAllAction, NewsAllState } from "./news.all.types"

export const initialNewsAllState: NewsAllState = {
  fetching: false,
  news: [],
}

const NewsAllReducer = (state = initialNewsAllState, action: NewsAllAction): NewsAllState => {
  switch (action.type) {
    case 'NEWS_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'NEWS_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        news: action.params,
      }
    case 'NEWS_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialNewsAllState,
      }
    default:
      return state
  }
}

export default NewsAllReducer