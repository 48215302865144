import { RecoveryCheckCodeAction, RecoveryCheckCodeState } from "./recovery.check_code.types"

export const initialRecoveryCheckCodeState: RecoveryCheckCodeState = {
  fetching: false,
  patients: [],
}

const RecoveryCheckCodeReducer = (state = initialRecoveryCheckCodeState, action: RecoveryCheckCodeAction): RecoveryCheckCodeState => {
  switch (action.type) {
    case 'RECOVERY_CHECK_CODE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'RECOVERY_CHECK_CODE_SUCCESS':
      return {
        ...state,
        fetching: false,
        patients: action.params,
      }
    case 'RECOVERY_CHECK_CODE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialRecoveryCheckCodeState,
      }
    default:
      return state
  }
}

export default RecoveryCheckCodeReducer