import { call, put, select, takeEvery } from "redux-saga/effects";
import { AppState } from "../..";
import api from "../../../api";
import { ChatMessage } from "../../../api/chat.types";
import lazyStore from "../../lazyStore";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { chatMessageAppendAction, chatMessageFailureAction, chatMessageSuccessAction, chatMessageUnreadDropAction, chatMessageUnreadIncreaseAction } from "./chat.message.actions";
import ChatSocket from "./chat.socket";
import { chatSocketConnectFailureAction } from "./chat.socket.actions";
import { ChatAction } from "./chat.types";

export function* chatMessageRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.chat.getMessages> = yield call(api.chat.getMessages)
  if (res.error) {
    yield put(chatMessageFailureAction())
  } else {
    yield put(chatMessageSuccessAction(res.data.messages))
  }
}

export type ChatSocketMessage = {
  text: string;
  type: string;
  date: string;
  operator_id?: string;
  operator_name?: string;
}

export function* chatSocketConnectRequestSaga(action: any) {
  const socket = ChatSocket.connect()
  socket.on('message', (message: ChatSocketMessage) => {
    lazyStore.store.dispatch(chatMessageAppendAction({
      body: message.text,
      created: message.date,
      operator_id: message.operator_id,
      operator_name: message.operator_name,
    }))
  })
}

export const selectChat = (state: AppState) => state.chat

export function* chatMessageAppendSaga(action: ChatAction) {
  const message = action.params as ChatMessage
  if (!message.operator_id && !message.operator_name) {
    return
  }
  const socket = ChatSocket.getConnectedSocket()
  const chat: ReturnType<typeof selectChat> = yield select(selectChat)
  if (chat.messaging.active) {
    socket?.emit('read_message')
    yield put(chatMessageUnreadDropAction())
  } else {
    yield put(chatMessageUnreadIncreaseAction())
  }
}

export function* chatMessageSendSaga(action: ChatAction) {
  const socket = ChatSocket.getConnectedSocket()
  if (!socket) {
    // TODO
    return
  }
  socket.emit('message', {
    text: action.params.text,
    files: action.params.files,
    type: 'default',
  })
}

export default function* watchChat() {
  yield takeLeadingPattern<ChatAction['type']>('CHAT_MESSAGE_REQUEST', chatMessageRequestSaga, chatMessageFailureAction)

  yield takeLeadingPattern<ChatAction['type']>('CHAT_SOCKET_CONNECT_REQUEST', chatSocketConnectRequestSaga, chatSocketConnectFailureAction)
  yield takeEvery('CHAT_MESSAGE_SEND', chatMessageSendSaga)
  yield takeEvery('CHAT_MESSAGE_APPEND', chatMessageAppendSaga)
}