import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { actionsAllFailureAction, actionsAllSuccessAction } from "./actions.all.actions";
import { actionsItemFailureAction, actionsItemSuccessAction } from "./actions.item.actions";
import { ActionsAction } from "./actions.types";

export function* actionsAllRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.newsfeed.getActions> = yield call(api.newsfeed.getActions)
  if (res.error) {
    yield put(actionsAllFailureAction())
  } else {
    yield put(actionsAllSuccessAction(res.data.actions))
  }
}

export function* actionsItemRequestSaga(action: ActionsAction) {
  const res: ThenReturnType<typeof api.newsfeed.getAction> = yield call(api.newsfeed.getAction, action.params)
  if (res.error) {
    yield put(actionsItemFailureAction())
  } else {
    yield put(actionsItemSuccessAction(res.data.action))
  }
}

export default function* watchActions() {
  yield takeLeadingPattern<ActionsAction['type']>('ACTIONS_ALL_REQUEST', actionsAllRequestSaga, actionsAllFailureAction)
  yield takeLeadingPattern<ActionsAction['type']>('ACTIONS_ITEM_REQUEST', actionsItemRequestSaga, actionsItemFailureAction)
}