import { ResearchesByPatientAction, ResearchesByPatientState } from "./researches.bypatient.types"

export const initialResearchesByPatientState: ResearchesByPatientState = {
  fetching: false,
  researches: [],
}

const ResearchesByPatientReducer = (state = initialResearchesByPatientState, action: ResearchesByPatientAction): ResearchesByPatientState => {
  switch (action.type) {
    case 'RESEARCHES_BY_PATIENT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'RESEARCHES_BY_PATIENT_SUCCESS':
      return {
        ...state,
        fetching: false,
        researches: action.params,
      }
    case 'RESEARCHES_BY_PATIENT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialResearchesByPatientState,
      }
    default:
      return state
  }
}

export default ResearchesByPatientReducer