import { ProgramsAllState, ProgramsAllAction } from './programs.all.types'

export const initialProgramsAllState: ProgramsAllState = {
  fetching: false,
  programs: [],
}

const ProgramsAllReducer = (state = initialProgramsAllState, action: ProgramsAllAction): ProgramsAllState => {
  switch (action.type) {
    case 'PROGRAMS_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'PROGRAMS_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        programs: action.params,
      }
    case 'PROGRAMS_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialProgramsAllState
      }
    default:
      return state
  }
}
export default ProgramsAllReducer