import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import Container from '../../components/Template/container'
import { CatalogPreview } from '../../api/pricelist.types'
import Catalogs from '../../components/Clinic/Services/Catalogs'
import Catalog from '../../components/Clinic/Services/Catalog'
import Doctors from '../../components/Clinic/Services/Doctors'
import Prices from '../../components/Clinic/Services/Prices'
import { DoctorPreview } from '../../api/doctor.types'
import Doctor from '../../components/Clinic/Services/Doctor'
import Articles from '../../components/Clinic/Services/Articles'

export type ServicesVeiwProps = ReduxConnectedProps & {}

export type ServicesViewState = {
  catalog?: CatalogPreview
  page: 'MAIN'
    | 'CATALOG'
    | 'DOCTORS' | 'DOCTOR'
    | 'SERVICES' | 'ARTICLES'
  doctor?: DoctorPreview
}

class ServicesVeiw extends React.Component<ServicesVeiwProps, ServicesViewState> {
  constructor(props: ServicesVeiwProps) {
    super(props)
    this.state = {
      page: 'MAIN',
    }
  }

  private onSelectCatalog = (catalog: CatalogPreview) => {
    this.setState({ catalog, page: 'CATALOG' })
    console.log('catalog', catalog)
  }

  private onBack = () => {
    const { page } = this.state
    switch (page) {
      case 'CATALOG':
        this.setState({ page: 'MAIN', catalog: undefined })
        break
      case 'DOCTORS':
      case 'ARTICLES':
      case 'SERVICES':
        this.setState({ page: 'CATALOG' })
        break
      case 'DOCTOR':
        this.setState({ page: 'DOCTORS', doctor: undefined })
        break
      default:
        break
    }
  }

  private openDoctors = () => {
    this.setState({ page: 'DOCTORS' })
  }

  private openDoctor = (doctor: DoctorPreview) => {
    this.setState({ doctor, page: 'DOCTOR' })
  }

  private openServices = () => {
    this.setState({ page: 'SERVICES' })
  }

  private openArticles = () => {
    this.setState({ page: 'ARTICLES' })
  }

  render() {
    const { page, catalog, doctor } = this.state
    return <Container
      className="services"
      currentItem="clinic/services"
      sidebar={<div className="sidebar-default" />}
    >
      {page == 'MAIN' && <Catalogs onSelect={this.onSelectCatalog} />}
      {page == 'CATALOG' && catalog && <Catalog
        catalog={catalog}
        onDoctor={this.openDoctors}
        onServices={this.openServices}
        onArticles={this.openArticles}
        onBack={this.onBack}
      />}
      {page == 'DOCTORS' && catalog && <Doctors
        catalog={catalog }
        onBack={this.onBack}
        onDoctor={this.openDoctor}
      />}
      {page == 'DOCTOR' && doctor && <Doctor
        preview={doctor}
        onBack={this.onBack}
        onRecord={(doctor) => {
          // TODO
        }}
      />}
      {page == 'SERVICES' && catalog && <Prices
        catalog={catalog}
        onBack={this.onBack}
      />}
      {page == 'ARTICLES' && catalog && <Articles
        catalog={catalog}
        onBack={this.onBack}
      />}
    </Container>
  }
}

const connector = connect(
  (state: AppState) => ({
  }),
  {
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(ServicesVeiw)