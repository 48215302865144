import { AppointmentsByPhoneAction, AppointmentsByPhoneState } from "./appointments.byphone.types"

export const initialAppointmentsByPhoneState: AppointmentsByPhoneState = {
  fetching: false,
  appointments: [],
}

const AppointmentsByPhoneReducer = (state = initialAppointmentsByPhoneState, action: AppointmentsByPhoneAction): AppointmentsByPhoneState => {
  switch (action.type) {
    case 'APPOINTMENTS_BY_PHONE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'APPOINTMENTS_BY_PHONE_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: action.params,
      }
    case 'APPOINTMENTS_BY_PHONE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAppointmentsByPhoneState,
      }
    default:
      return state
  }
}

export default AppointmentsByPhoneReducer