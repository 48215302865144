import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { myMedicAppFailureAction, myMedicAppSuccessAction } from "./mymedic.app.actions";
import { myMedicTMFailureAciton, myMedicTMSuccessAction } from "./mymedic.tm.actions";
import { MyMedicAction } from "./mymedic.types";

export function* myMedicAppRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.mymedic.getApp> = yield call(api.mymedic.getApp)
  if (res.error) {
    yield put(myMedicAppFailureAction())
  } else {
    yield put(myMedicAppSuccessAction(res.data))
  }
}

export function* myMedicTMRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.mymedic.isTMDisabled> = yield call(api.mymedic.isTMDisabled)
  if (res.error) {
    yield put(myMedicTMFailureAciton())
  } else {
    yield put(myMedicTMSuccessAction(res.data))
  }
}

export default function* watchMyMedic() {
  yield takeLeadingPattern<MyMedicAction['type']>('MYMEDIC_APP_REQUEST', myMedicAppRequestSaga, myMedicAppFailureAction)
  yield takeLeadingPattern<MyMedicAction['type']>('MYMEDIC_TM_REQUEST', myMedicTMRequestSaga, myMedicTMFailureAciton)
}