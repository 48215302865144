import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Template } from '../../components'
import ClinicInfo from '../../components/Clinic/Info'
import { AppState } from '../../store'
import { clinicContactsRequestAction } from '../../store/reducks/clinic/clinic.contacts.actions'

export type ClinicInfoViewProps = ReduxConnectedProps & {}

class ClinicInfoView extends React.Component<ClinicInfoViewProps> {
  componentDidMount() {
    this.props.clinicContactsRequestAction()
  }

  render() {
    const { contacts } = this.props
    return <Template
      className="clinic_info"
      title="Информация"
      sidebar={<div className="sidebar-default" />}
      currentItem="clinic/about"
    >
      {!contacts.fetching ? <ClinicInfo
        contacts={contacts.contacts}
      /> : null}
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    contacts: state.clinic.contacts,
  }), {
  clinicContactsRequestAction,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(ClinicInfoView)