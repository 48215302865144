import moment from 'moment'
import React from 'react'
import './style.less'

export type NewsItemProps = {
  date?: string
  title: string
  content: string
  image: string
  className?: string
  onImage?: () => void
  dateformat?: string
}

const NewsItem: React.FC<NewsItemProps> = (props) => {
  const { className = '', dateformat = 'DD MMM YYYY' } = props
  return <div className={`news-item ${className}`}>
    <div className="news-photo" onClick={props.onImage}>
      <img src={props.image} />
      {!!props.date && <div className="date">{moment(props.date).format(dateformat)}</div>}
    </div>
    <div className="news-content">
      <div className="title">{props.title}</div>
      <div className="content" dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  </div>
}

export default NewsItem