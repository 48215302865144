import React from 'react'
import Modal from '../../components/Modal'
import BonusPayment from '../../components/BonusPayment'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { bonusPaymentModalCloseAction } from '../../store/reducks/modals/modals.bonusPayment.actions'

export type BonusPaymentModalProps = ReduxConnectedProps & {}

class BonusPaymentModal extends React.Component<BonusPaymentModalProps> {

  private onSkip = () => {
    const { modal } = this.props
    modal.onSkip && modal.onSkip()
    this.props.bonusPaymentModalCloseAction()
  }

  private onPay = (params: { bonusCode: string; bonusPoints: number }) => {
    const { modal } = this.props
    modal.onPay && modal.onPay(params)
    this.props.bonusPaymentModalCloseAction()
  }

  render() {
    const { modal } = this.props
    return <Modal
      title="Бонусная программа"
      visible={modal.visible}
      onCancel={this.props.bonusPaymentModalCloseAction}
    >
      <BonusPayment
        price={modal.price}
        onPay={this.onPay}
        onSkip={this.onSkip}
      />
    </Modal>
  }
}

const connector = connect(
  (state: AppState) => ({
    modal: state.modals.bonusPayment,
  }), {
    bonusPaymentModalCloseAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(BonusPaymentModal)