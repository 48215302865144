import { combineReducers } from 'redux'
import DoctorsAllReducer from './doctors.all.reducer'
import DoctorsBydepartmentReducer from './doctors.bydepartment.reducer'
import DoctorsByserviceReducer from './doctors.byservice.reducer'
import DoctorsItemReducer from './doctors.item.reducer'
import DoctorsViewReducer from './doctors.view.reducer'

const DoctorsReducer = combineReducers({
  all: DoctorsAllReducer,
  byservice: DoctorsByserviceReducer,
  bydepartment: DoctorsBydepartmentReducer,
  item: DoctorsItemReducer,
  view: DoctorsViewReducer,
})

export default DoctorsReducer