import React, { Component } from 'react'
import { Modal } from '../../components/index'
import { IPatient, ISortablePatient } from '../../store/types'
import './style.less'
import PatientList from '../../components/PatientList'
import { Patient } from '../../api/patient.types'

interface Props {
  show?: boolean
  onCancel: () => void
  onSelect: (selectedPatient: IPatient | null) => void
  onSort: (sort: ISortablePatient[]) => void
  onBack: () => void
  mainPatients: Patient[]
  addedPatients: Patient[]
  withBack?: boolean
  title?: string
  patientAllEnabled?: boolean
}

class PatientListModal extends Component<Props> {

  render() {
    const { onSelect, onCancel, onBack, mainPatients, addedPatients, withBack, title, patientAllEnabled } = this.props
    return (
      <Modal
        width={520}
        visible={this.props.show}
        withBack={withBack}
        onBack={onBack}
        className="patient-list-modal"
        onCancel={onCancel}
        title={title}
      >
        <PatientList
          mainPatients={mainPatients}
          addedPatients={addedPatients}
          onSelect={onSelect}
          patientAllEnabled={patientAllEnabled}
        />
      </Modal>
    )
  }
}

export default PatientListModal
