import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { bonusPointsFailureAction, bonusPointsSuccessAction } from "./bonus.points.actions";
import { bonusSettingsFailureAction, bonusSettingsSuccessAction } from "./bonus.settings.actions";
import { BonusAction } from "./bonus.types";

export function* bonusSettingsRequestSaga(action: BonusAction) {
  const res: ThenReturnType<typeof api.bonus.getSettings> = yield call(api.bonus.getSettings)
  if (res.error) {
    yield put(bonusSettingsFailureAction())
  } else {
    yield put(bonusSettingsSuccessAction(res.data.settings))
  }
}

export function* bonusPointsRequestSaga(action: BonusAction) {
  const res: ThenReturnType<typeof api.bonus.getPoints> = yield call(api.bonus.getPoints, action.params)
  if (res.error) {
    yield put(bonusPointsFailureAction())
  } else {
    yield put(bonusPointsSuccessAction(res.data.points))
  }
}

export default function* watchBonus() {
  yield takeLeadingPattern<BonusAction['type']>('BONUS_SETTINGS_REQUEST', bonusSettingsRequestSaga, bonusSettingsFailureAction)
  yield takeLeadingPattern<BonusAction['type']>('BONUS_POINTS_REQUEST', bonusPointsRequestSaga, bonusPointsFailureAction)
}