import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import Template from '../../components/Template'
import Recovery from '../../components/Recovery'
import { Patient } from '../../api/patient.types'
import { recoverySendPasswordRequestAction } from '../../store/reducks/recovery/recovery.send_password.actions'
import { recoverySendSmsRequestAction } from '../../store/reducks/recovery/recovery.send_sms.actions'
import { recoveryCheckCodeRequestAction } from '../../store/reducks/recovery/recovery.check_code.actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import './style.less'
import { patientsByphoneMainRequest } from '../../store/reducks/patients/patients.byphone.main.actions'

export type RecoveryViewProps = ReduxConnectedProps & RouteComponentProps & {}

export type RecoveryViewState = {
  stage: 'STEP1' | 'STEP2' | 'STEP3'
  phone?: string
  code?: string
  medic_user_id?: number
}

class RecoveryView extends React.Component<RecoveryViewProps, RecoveryViewState> {
  constructor(props: RecoveryViewProps) {
    super(props)
    this.state = {
      stage: 'STEP1',
      phone: props.user.phone,
    }
    this.props.patientsByphoneMainRequest()
  }

  componentDidUpdate(prevProps: RecoveryViewProps) {
    const { sendPassword, sendSms, checkCode } = this.props
    if (!sendPassword.fetching && prevProps.sendPassword.fetching && !sendPassword.error) {
      this.props.history.goBack()
    }
    if (!sendSms.fetching && prevProps.sendSms.fetching && !sendSms.error) {
      this.setState({ stage: 'STEP2' })
    }
    if (!checkCode.fetching && prevProps.checkCode.fetching && !checkCode.error) {
      this.setState({ stage: 'STEP3' })
    }
  }

  private renderStep1 = () => {
    const phone = this.state.phone as string
    return <Recovery.Step1
      phone={phone}
      disabled={this.fetching()}
      onSubmit={this.onPhoneSubmit}
    />
  }

  private fetching = () => {
    const { sendSms, checkCode, sendPassword } = this.props
    return sendSms.fetching || checkCode.fetching || sendPassword.fetching
  }

  private onPhoneSubmit = () => {
    const { phone } = this.state
    this.props.recoverySendSmsRequestAction({
      phone: phone as string,
    })
  }

  private renderStep2 = () => {
    const { phone } = this.state
    const { checkCode } = this.props
    return <Recovery.Step2
      phone={phone as string}
      onCodeSubmit={this.onCodeSubmit}
      codeError={checkCode.error ? 'Неверный код' : undefined}
      phoneDisabled={this.fetching()} // TODO
      onPhoneSubmit={this.onPhoneSubmit}
    />
  }

  private onCodeSubmit = (code: string) => {
    this.setState({ code }, () => {
      const { phone } = this.state
      this.props.recoveryCheckCodeRequestAction({
        phone: phone as string,
        code,
      })
    })
  }

  private renderStep3 = () => {
    const { checkCode: code } = this.props
    return <Recovery.Step3
      patients={code.patients}
      onPatientSelect={this.onPatientSelect}
    />
  }

  private onPatientSelect = (patient: Patient) => {
    const { medic_user_id } = patient
    this.setState({ medic_user_id }, () => {
      const { code, phone } = this.state
      this.props.recoverySendPasswordRequestAction({
        phone: phone as string,
        code: code as string,
        medic_user_id,
      })
    })
  }

  private renderHeader = () => {
    return <Recovery.Header
      title={this.getHeaderTitle()}
      onBack={this.onBack}
    />
  }

  private getHeaderTitle = () => {
    const { stage } = this.state
    switch (stage) {
      case 'STEP1':
      case 'STEP2':
        return 'Восстановление пароля медкарты'
      case 'STEP3':
      default:
        return 'Чей пароль вы хотите восстановить?'
    }
  }

  private onBack = () => {
    const { stage } = this.state
    switch (stage) {
      case 'STEP1':
        this.props.history.goBack()
        break;
      case 'STEP2':
        this.setState({ stage: 'STEP1' })
        break;
      case 'STEP3':
        this.setState({ stage: 'STEP2' })
        break;
      default:
        return
    }
  }

  render() {
    const { stage } = this.state
    return <Template
      className="recovery"
      title={this.getHeaderTitle()}
      onTitleBack={this.onBack}
      sidebar={<div className="sidebar-default"/>}
    >
      <div className="recovery-steps">
        {stage == 'STEP1' && this.renderStep1()}
        {stage == 'STEP2' && this.renderStep2()}
        {stage == 'STEP3' && this.renderStep3()}
      </div>
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    sendSms: state.recovery.sendSms,
    checkCode: state.recovery.checkCode,
    sendPassword: state.recovery.sendPassword,
    path: state.recovery.view.path || '/medcard',
    user: state.user,
  }), {
  recoverySendPasswordRequestAction,
  recoverySendSmsRequestAction,
  recoveryCheckCodeRequestAction,

  patientsByphoneMainRequest,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(RecoveryView))