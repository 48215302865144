import { combineReducers } from 'redux'
import ResearchesAllReducer from './researches.all.reducer'
import ResearchesByPatientReducer from './researches.bypatient.reducer'
import ResearchesFileReducer from './researches.file.reducer'
import ResearchesNewReducer from './researches.new.reducer'
import ResearchesViewReducer from './researches.view.reducer'

const ResearchesReducer = combineReducers({
  new: ResearchesNewReducer,
  all: ResearchesAllReducer,
  bypatient: ResearchesByPatientReducer,
  file: ResearchesFileReducer,
  view: ResearchesViewReducer,
})

export default ResearchesReducer