import { MyMedicTMAction, MyMedicTMState } from "./mymedic.tm.types"

export const initialMyMedicTMState: MyMedicTMState = {
  fetching: false,
  app: {},
}

const MyMedicTMReducer = (state = initialMyMedicTMState, action: MyMedicTMAction): MyMedicTMState => {
  switch (action.type) {
    case 'MYMEDIC_TM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'MYMEDIC_TM_SUCCESS':
      return {
        ...state,
        fetching: false,
        app: action.params,
      }
    case 'MYMEDIC_TM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMyMedicTMState,
      }
    default:
      return state
  }
}

export default MyMedicTMReducer