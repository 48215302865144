import React from 'react'
import { Button } from 'antd'
import { CombinedAppointment } from '../../api/payment.types'
import './style.less'
import nonBreakingSpace from '../../tools/nonBreakingSpace'

export type CombinedAppointmentsProps = {
  is_partial?: boolean;
  appointments?: CombinedAppointment[]
  onPay: () => void
}

class CombinedAppointments extends React.Component<CombinedAppointmentsProps> {
  private renderAppointment = (appointment: CombinedAppointment, index: number) => {
    const { appointment_spec: name, paysum: price } = appointment
    return <CombinedAppointments.Item key={index} name={name} price={price} />
  }

  private static Item: React.FC<{ name: string; price: number; className?: string }> = (props) => {
    const { name, price, className } = props
    return <div className={`item ${className || ''}`}>
      <span className="name">{name}</span>
      <span className="price">{CombinedAppointments.renderPrice(price)}</span>
    </div>
  }

  private static renderPrice = (price: number) => {
    return nonBreakingSpace(`${price} ₽`)
  }

  private getTotalPrice = () => {
    const { appointments } = this.props
    return appointments?.reduce((totalPrice, appointment) => {
      return totalPrice + appointment.paysum
    }, 0) || 0
  }

  render() {
    const { appointments, onPay } = this.props
    const totalPrice = this.getTotalPrice()
    return <div className="combined-appointments">
      <div className="appointment-list">
        {appointments?.map(this.renderAppointment)}
      </div>
      <div className="footer">
        <CombinedAppointments.Item
          className="total-price"
          name="Сумма к оплате"
          price={totalPrice}
        />
        <Button
          type="primary"
          className="pay-button"
          onClick={onPay}
        >
          {`Оплатить`}
        </Button>
      </div>
    </div>
  }
}

export default CombinedAppointments