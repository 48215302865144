import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { programsAllFailureAction, programsAllSuccessAction } from "./programs.all.actions";
import { programsItemFailureAction, programsItemSuccessAction } from "./programs.item.actions";
import { ProgramsAction } from "./programs.types";

export function* programsAllRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.newsfeed.getPrograms> = yield call(api.newsfeed.getPrograms)
  if (res.error) {
    yield put(programsAllFailureAction())
  } else {
    yield put(programsAllSuccessAction(res.data.programs))
  }
}

export function* programsItemRequestSaga(action: ProgramsAction) {
  const res: ThenReturnType<typeof api.newsfeed.getProgram> = yield call(api.newsfeed.getProgram, action.params)
  if (res.error) {
    yield put(programsItemFailureAction())
  } else {
    yield put(programsItemSuccessAction(res.data))
  }
}

export default function* watchPrograms() {
  yield takeLeadingPattern<ProgramsAction['type']>('PROGRAMS_ALL_REQUEST', programsAllRequestSaga, programsAllFailureAction)
  yield takeLeadingPattern<ProgramsAction['type']>('PROGRAMS_ITEM_REQUEST', programsItemRequestSaga, programsItemFailureAction)
}