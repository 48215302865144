import React from 'react'
import { Button } from 'antd'
import { CalendarIcon } from '../../../icons'
import SearchInput from '../../SearchInput'
import Modal from '../../Modal'
import './style.less'
import DateRangePicker from '../../DateRangePicker'

export type MedcardSearchBarProps = {
  searchValue?: string
  searchLabel?: string
  onSearch?: (value: string) => void
  onDateRange?: (ranges: { startDate: string; endDate: string }) => void
  onDateCancel?: () => void
}

export type MedcardSearchBarState = {
  showCalendar?: boolean
}

class MedcardSearchBar extends React.Component<MedcardSearchBarProps, MedcardSearchBarState> {
  constructor(props: MedcardSearchBarProps) {
    super(props)
    this.state = {
      showCalendar: false,
    }
  }

  private openCalendar = () => {
    this.setState({ showCalendar: true })
  }
  private closeCalendar = () => {
    this.setState({ showCalendar: false })
  }

  private onDateRange = (ranges: { startDate: string; endDate: string }) => {
    const { onDateRange } = this.props
    onDateRange && onDateRange(ranges)
    this.closeCalendar()
  }

  private onDateCancel = () => {
    const { onDateCancel } = this.props
    onDateCancel && onDateCancel()
    this.closeCalendar()
  }

  render() {
    const { searchValue, searchLabel, onSearch } = this.props
    const { showCalendar } = this.state
    return <div className="medcard-search-bar">
      <SearchInput
        className="border-none"
        value={searchValue}
        placeholder={searchLabel}
        onChangeText={onSearch}
      />
      <Button
        type="default"
        className="calendar-button"
        onClick={this.openCalendar}
      >
        <CalendarIcon />
      </Button>
      <Modal
        visible={showCalendar}
        onCancel={this.closeCalendar}
        title="Фильтр по дате"
      >
        <DateRangePicker
          onSubmit={this.onDateRange}
          onCancel={this.onDateCancel}
        />
      </Modal>
    </div>
  }
}

export default MedcardSearchBar