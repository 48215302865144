import React from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import './style.less'

export type TextInputProps = {
  onChangeText?: (text: string) => void
} & TextFieldProps

const TextInput: React.FC<TextInputProps> = (props) => {
  const { onChangeText, className, ...restProps } = props
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeText && onChangeText(e.target.value)
  }
  return <TextField
    className={`text-input ${className || ''}`}
    onChange={onChange}
    variant="outlined"
    size="small"
    {...restProps}
  />
}

export default TextInput