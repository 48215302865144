import { ChatMessage } from '../../../api/chat.types'
import { ChatMessageAction } from './chat.message.types'
export const chatMessageRequestAction = (): ChatMessageAction => ({ type: 'CHAT_MESSAGE_REQUEST' })
export const chatMessageSuccessAction = (messages: ChatMessage[]): ChatMessageAction => ({ type: 'CHAT_MESSAGE_SUCCESS', params: messages })
export const chatMessageFailureAction = (error?: any): ChatMessageAction => ({ type: 'CHAT_MESSAGE_FAILURE', params: error })
export const chatMessageAppendAction = (message: ChatMessage): ChatMessageAction => ({ type: 'CHAT_MESSAGE_APPEND', params: message })

export const chatMessageSendAction = (params: { text: string; files?: Array<{ name: string; base64: string }> }): ChatMessageAction => ({ type: 'CHAT_MESSAGE_SEND', params })

export const chatMessageUnreadIncreaseAction = (): ChatMessageAction => ({ type: 'CHAT_MESSAGE_UNREAD_INCREASE' })
export const chatMessageUnreadDropAction = (): ChatMessageAction => ({ type: 'CHAT_MESSAGE_UNREAD_DROP' })

export const chatMessageActiveSetAction = (): ChatMessageAction => ({ type: 'CHAT_MESSAGE_ACTIVE_SET' })
export const chatMessageInActiveSetAction = (): ChatMessageAction => ({ type: 'CHAT_MESSAGE_INACTIVE_SET' })