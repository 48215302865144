import React from 'react'
import Container from '../../components/Template/container'
import Doctor from '../../components/Clinic/Doctors/Doctor'

class DoctorView extends React.Component {
  render() {
    return <Container
      className="doctor"
      currentItem="clinic/doctors"
      sidebar={<div className="sidebar-default" />}
    >
      <Doctor />
    </Container>
  }
}

export default DoctorView