import React from 'react'
import { Button } from 'antd'
import { DateRangePicker as DatePicker, OnChangeProps, Range as DateRange } from 'react-date-range'
import { ru } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './style.less'
import moment from 'moment'

export type DateRangePickerProps = {
  onSubmit?: (ranges: { startDate: string; endDate: string }) => void
  onCancel?: () => void
  submitText?: string
  cancelText?: string
}
export type DateRangePickerState = {
  startDate?: Date
  endDate?: Date
}

class DateRangePicker extends React.Component<DateRangePickerProps, DateRangePickerState> {
  constructor(props: DateRangePickerProps) {
    super(props)
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    }
  }

  private onChange = (_ranges: OnChangeProps) => {
    // @ts-ignore
    const { startDate, endDate } = _ranges.selection as DateRange
    this.setState({ startDate, endDate })
  }

  private onSubmit = () => {
    const { onSubmit } = this.props
    const { startDate, endDate } = this.state
    // @ts-ignore
    onSubmit && onSubmit({
      startDate: moment(startDate).format('YYYY MM DD'),
      endDate: moment(endDate).format('YYYY MM DD'),
    })
  }

  private onCancel = () => {
    const { onCancel } = this.props
    onCancel && onCancel()
    this.setState({ startDate: new Date(), endDate: new Date() })
  }

  render() {
    const { startDate, endDate } = this.state
    const { submitText, cancelText } = this.props
    return <div className="date-range-picker-wrapper">
      <DatePicker
        className="date-range-picker"
        ranges={[{
          startDate,
          endDate,
          key: 'selection',
        }]}
        onChange={this.onChange}
        color="#a5302e"
        rangeColors={['#a5302e']}
        locale={ru}
        showSelectionPreview
        showPreview
        maxDate={new Date()}
        showDateDisplay={false}
        weekdayDisplayFormat="EEEEEE"
      />
      <Button className="submit" type="primary" onClick={this.onSubmit}>
        {submitText || 'Применить'}
      </Button>
      <Button className="cancel" type="default" onClick={this.onCancel}>
        {cancelText || 'Очистить'}
      </Button>
    </div>
  }
}

export default DateRangePicker