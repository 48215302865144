import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import Template from '../../components/Template'
import RecordingSuccess from '../../components/Recording/Success'
import { AppState } from '../../store'
import { pricelistPrepareInfoModalSetAction, pricelistPrepareInfoRequestAction } from '../../store/reducks/pricelist/pricelist.prepare_info.actions'
import { IPatient } from '../../store/types'
import './style.less'
import { appointmentsViewActiveTabSetAction, appointmentsViewPatientSetAction } from '../../store/reducks/appointments/appointments.view.actions'
import { paymentParamsClearAction, paymentParamsSetAction, paymentParamsMakePaymentAction } from '../../store/reducks/payment/payment.params.actions'
import { bonusPaymentModalSetAction } from '../../store/reducks/modals/modals.bonusPayment.actions'
import Record from '../../store/reducks/record/Record'

export type RecordingSuccessProps = RouteComponentProps & ReduxConnectedProps & {
}
class RecordingSuccessView extends React.Component<RecordingSuccessProps> {
  componentDidMount() {
    this.guardRecord()
  }

  componentDidUpdate() {
    this.guardRecord()
  }

  private guardRecord = () => {
    const { record } = this.props
    if (!record.address
      || !record.catalog
      || !record.datetime
      || !record.doctor
      || (!record.patient && !record.new_patient)
      || !record.service
    ) {
      this.props.history.replace('/')
    }
  }


  private onPay = () => {
    const { appointment_id } = this.props
    if (!appointment_id) {
      return
    }
    this.props.paymentParamsClearAction()
    const price = this.getPrice()
    this.props.paymentParamsSetAction({
      appointment_id,
      price,
    })
    this.props.bonusPaymentModalSetAction({
      visible: true,
      price,
      onPay: (params: { bonusCode: string; bonusPoints: number }) => {
        this.props.paymentParamsSetAction({
          bonusCode: params.bonusCode,
          bonusPoints: params.bonusPoints,
        })
        this.props.paymentParamsMakePaymentAction()
      },
      onSkip: () => {
        this.props.paymentParamsMakePaymentAction()
      },
    })
  }

  private getPrice = () => {
    const { record } = this.props
    return Record.getPrice(record)
  }

  private onSubmit = () => {
    const { record } = this.props
    // open future appointments
    this.props.appointmentsViewActiveTabSetAction('0')
    if (record.new_patient) {
      // TODO
    } else if (record.patient) {
      this.props.appointmentsViewPatientSetAction(record.patient)
    }
    this.props.history.replace('/journal', { selected: '/journal' } );
  }

  render() {
    const { record, patients } = this.props
    return <Template
      className="recording"
      title="Запись на прием"
      sidebar={<div className="sidebar-default" />}
      currentItem="recording"
    >
      <RecordingSuccess
        record={record}
        patients={patients.users}
        onPrepareInfo={() => {
          this.props.pricelistPrepareInfoRequestAction({ pls_id: record.service?.pls_id as number })
          this.props.pricelistPrepareInfoModalSetAction({ visible: true })
        }}
        onPay={this.onPay}
        onSubmit={this.onSubmit}
      />
    </Template>
  }
}

const connector = connect((state: AppState) => ({
  record: state.record,
  appointment_id: state.register.appointment.appointment.appointment_id,
  patients: {
    fetching: state.patients.byphone.main.fetching || state.patients.byphone.added.fetching,
    users: [
      ...state.patients.byphone.main.users,
      ...state.patients.byphone.added.users,
      ...state.patients.bymistoken.main.users,
      ...state.patients.bymistoken.added.users,
    ].filter(uniquePatient),
  },
}), {
  pricelistPrepareInfoModalSetAction,
  pricelistPrepareInfoRequestAction,

  appointmentsViewActiveTabSetAction,
  appointmentsViewPatientSetAction,

  paymentParamsSetAction,
  paymentParamsClearAction,
  paymentParamsMakePaymentAction,
  bonusPaymentModalSetAction,
})



const uniquePatient = (user: IPatient, index: number, array: IPatient[]) => {
  return array.findIndex(u => u.medic_user_id == user.medic_user_id) == index
}

export type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(RecordingSuccessView))