import { RecordAction, RecordState } from "./record.types"

export const initialState: RecordState = {
  stage: 1,
  format: null,
  patient: null,
  new_patient: null,
  catalog: null,
  service: null,
  date: null,
  datetime: null,
  doctor: null,
  address: null,
  register_info: null,
  type: 'bydate',
}

const RecordReducer = (state = initialState, action: RecordAction): RecordState => {
  const {
    stage, format, patient, new_patient, catalog, service, date, datetime, doctor, address, type, register_info,
  } = (action.params || {}) as Required<RecordState>
  switch (action.type) {
    case 'RECORD_CLEAR':
      return { ...initialState }
    case 'RECORD_STAGE_SET':
      return { ...state, stage }
    case 'RECORD_FORMAT_SET':
      return { ...state, format }
    case 'RECORD_PATIENT_SET':
      return { ...state, patient }
    case 'RECORD_NEW_PATIENT_SET':
      return { ...state, new_patient }
    case 'RECORD_CATALOG_SET':
      return { ...state, catalog }
    case 'RECORD_SERVICE_SET':
      return { ...state, service }
    case 'RECORD_DATE_SET':
      return { ...state, date }
    case 'RECORD_DATETIME_SET':
      return { ...state, datetime }
    case 'RECORD_DOCTOR_SET':
      return { ...state, doctor }
    case 'RECORD_ADDRESS_SET':
      return { ...state, address }
    case 'RECORD_TYPE_SET':
      return { ...state, type }
    case 'RECORD_REGISTER_INFO_SET':
      return { ...state, register_info }
    case 'RECORD_STATE_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default RecordReducer
