import { ResearchesNewAction, ResearchesNewState } from "./researches.new.types"

export const initialResearchesNewState: ResearchesNewState = {
  fetching: false,
  researches: [],
}

const ResearchesNewReducer = (state = initialResearchesNewState, action: ResearchesNewAction): ResearchesNewState => {
  switch (action.type) {
    case 'RESEARCHES_NEW_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'RESEARCHES_NEW_SUCCESS':
      return {
        ...state,
        fetching: false,
        researches: action.params,
      }
    case 'RESEARCHES_NEW_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialResearchesNewState,
      }
    default:
      return state
  }
}

export default ResearchesNewReducer