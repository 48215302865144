import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { medcardOnlineVisitsAllFailureAction, medcardOnlineVisitsAllSuccessAction } from "./medcard.online_visits.all.actions";
import { medcardOnlineVisitsByPatientFailureAction, medcardOnlineVisitsByPatientSuccessAction } from "./medcard.online_visits.bypatient.actions";
import { MedcardAction } from "./medcard.types";
import { medcardVisitsAllFailureAction, medcardVisitsAllSuccessAction } from "./medcard.visits.all.actions";
import { medcardVisitsByPatientFailureAction, medcardVisitsByPatientSuccessAction } from "./medcard.visits.bypatient.actions";

export function* medcardOnlineVisitsAllRequestSaga(action: MedcardAction) {
  const res: ThenReturnType<typeof api.medcard.getMedcardOnlineVisitsAll> = yield call(api.medcard.getMedcardOnlineVisitsAll)
  if (res.error) {
    yield put(medcardOnlineVisitsAllFailureAction())
  } else {
    yield put(medcardOnlineVisitsAllSuccessAction(res.data.visits))
  }
}

export function* medcardOnlineVisitsByPatientRequestSaga(action: MedcardAction) {
  const res: ThenReturnType<typeof api.medcard.getMedcardOnlineVisitsByPatient> = yield call(api.medcard.getMedcardOnlineVisitsByPatient, action.params)
  if (res.error) {
    yield put(medcardOnlineVisitsByPatientFailureAction())
  } else {
    yield put(medcardOnlineVisitsByPatientSuccessAction(res.data.visits))
  }
}

export function* medcardVisitsAllRequestSaga(action: MedcardAction) {
  const res: ThenReturnType<typeof api.medcard.getMedcardVisitsAll> = yield call(api.medcard.getMedcardVisitsAll)
  if (res.error) {
    yield put(medcardVisitsAllFailureAction())
  } else {
    yield put(medcardVisitsAllSuccessAction(res.data.visits))
  }
}

export function* MedcardVisitsByPatientRequestSaga(action: MedcardAction) {
  const res: ThenReturnType<typeof api.medcard.getMedcardVisitsByPatient> = yield call(api.medcard.getMedcardVisitsByPatient, action.params)
  if (res.error) {
    yield put(medcardVisitsByPatientFailureAction())
  } else {
    yield put(medcardVisitsByPatientSuccessAction(res.data.visits))
  }
}

export default function* watchMedcard() {
  yield takeLeadingPattern<MedcardAction['type']>('MEDCARD_ONLINE_VISITS_ALL_REQUEST', medcardOnlineVisitsAllRequestSaga, medcardOnlineVisitsAllFailureAction)
  yield takeLeadingPattern<MedcardAction['type']>('MEDCARD_ONLINE_VISITS_BY_PATIENT_REQUEST', medcardOnlineVisitsByPatientRequestSaga, medcardOnlineVisitsByPatientFailureAction)

  yield takeLeadingPattern<MedcardAction['type']>('MEDCARD_VISITS_ALL_REQUEST', medcardVisitsAllRequestSaga, medcardVisitsAllFailureAction)
  yield takeLeadingPattern<MedcardAction['type']>('MEDCARD_VISITS_BY_PATIENT_REQUEST', MedcardVisitsByPatientRequestSaga, medcardVisitsByPatientFailureAction)
}