import React, { Component } from 'react'
import { Moment } from 'moment'
import { CalendarProps, HeaderRender } from 'antd/lib/calendar/generateCalendar'
import { Calendar as AntCalendar } from 'antd'
import moment from 'moment'
import 'moment/locale/ru'
import './style.less'
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons'

moment.locale('ru')

interface State {
  selected: Moment
}

type Props = {
  validRange?: any[]
  marked?: string[]
  onChange?: (date: Moment) => void
  onSelect?: (date: Moment) => void
  dates: string[]
  disabledDate?: (date: Moment) => boolean
  value?: Moment
  initValue?: Moment
}

class Calendar extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selected: !!props.initValue ? props.initValue : this.getFirstFreeDate(),
    }
  }

  static defaultProps: Props = {
    validRange: [moment(), moment().add(1.5, 'month')],
    dates: [],
  }

  componentDidMount() {
    const { selected } = this.state
    const { onSelect } = this.props
    if (selected && onSelect) {
      onSelect(selected)
    }
  }

  componentDidUpdate() {
    const { value } = this.props
    if (!!value && moment(value).diff(this.state.selected, 'days') != 0) {
      this.setState({ selected: value })
    }
  }

  private renderMark = (date: Moment) => {
    return this.isMarked(date) ? <div className="mark" /> : null
  }

  private isMarked = (date: Moment) => {
    const { marked } = this.props
    return marked ? marked.includes(date.format('YYYY-MM-DD')) : false
  }

  private onSelect = (selected: Moment) => {
    this.setState({ selected })
    const { onSelect } = this.props
    onSelect && onSelect(selected)
  }

  private onChange = (datetime: Moment) => {
    const { onChange } = this.props
    onChange && onChange(datetime)
  }

  private renderHeader: HeaderRender<Moment> = ({ value, type, onChange, onTypeChange }) => {
    const localeData = value.localeData()
    const months = localeData.months()
    return (
      <div className="calendar__controller">
        <div
          className="prev"
          onClick={() => {
            this.onSelect(moment(this.state.selected).add(-1, 'months'))
          }}
        >
          <ArrowLeftIcon />
        </div>
        <div className="current">{localeData.months(value)}</div>
        <div
          className="next"
          onClick={() => {
            this.onSelect(moment(this.state.selected).add(1, 'months'))
          }}
        >
          <ArrowRightIcon />
        </div>
      </div>
    )
  }

  private disabledDate = (date: Moment) => {
    const { dates } = this.props
    if (!dates || !dates.length) {
      return true
    }
    return !dates.includes(date.format('YYYY-MM-DD'))
  }

  private getFirstFreeDate = () => {
    let date = moment()
    if (!this.disabledDate(date)) {
      return date
    }
    let count = 20
    while (this.disabledDate(date) && --count >= 0) {
      date = date.add(1, 'day')
    }
    return date
  }

  render() {
    const { validRange, disabledDate } = this.props
    return (
      <AntCalendar
        className="calendar"
        value={this.state.selected}
        // @ts-ignore
        validRange={validRange}
        onSelect={this.onSelect}
        onChange={this.onChange}
        dateCellRender={this.renderMark}
        headerRender={this.renderHeader}
        disabledDate={!!disabledDate ? disabledDate : this.disabledDate}
      />
    )
  }
}

export default Calendar
