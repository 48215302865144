import sleep from './sleep'

const saveLinkFile = async (url: string, filename?: string) => {
  const res = await fetch(url)
  const blob = await res.blob()
  const resource = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  const urlname = link.pathname.split('/').pop()
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = resource
  a.download = filename || urlname || ''
  a.click()
  await sleep(100)
  document.body.removeChild(a)
  URL.revokeObjectURL(resource)
}

export default saveLinkFile