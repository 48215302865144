import { MedcardOnlineVisitsByPatientAction, MedcardOnlineVisitsByPatientState } from "./medcard.online_visits.bypatient.types"

export const initialMedcardOnlineVisitsByPatientState: MedcardOnlineVisitsByPatientState = {
  fetching: false,
  visits: []
}

const MedcardOnlineVisitsByPatientReducer = (state = initialMedcardOnlineVisitsByPatientState, action: MedcardOnlineVisitsByPatientAction): MedcardOnlineVisitsByPatientState => {
  switch (action.type) {
    case 'MEDCARD_ONLINE_VISITS_BY_PATIENT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'MEDCARD_ONLINE_VISITS_BY_PATIENT_SUCCESS':
      return {
        ...state,
        fetching: false,
        visits: action.params,
      }
    case 'MEDCARD_ONLINE_VISITS_BY_PATIENT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMedcardOnlineVisitsByPatientState,
      }
    default:
      return state
  }
}

export default MedcardOnlineVisitsByPatientReducer