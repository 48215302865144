import lazyStore from '../store/lazyStore'
import { errorSetAction } from '../store/reducks/error/error.actions'
import API, { RequestOptions, RequestReturnType } from './api'
import { DoctorPreview } from './doctor.types'
import { DivisionDatetimeRequestParams, DivisionDatetimes, DivisionDoctorRequestParams, DivisionDoctors } from './schedule.api.types'
import { shift3hoursDatetime } from './utils/shift3hoursDatetime'

class ScheduleAPI {
  private static GET_DIVISIONS_DATETIMES = '/get_datestimes_by_service_id_and_patient' as const
  private static GET_DIVISIONS_DOCTORS_BY_DATE = '/get_divisions_doctors_times_by_service_id_and_patient' as const

  private static GET_DOCTORS_BY_PRICELIST = '/get_doctors_by_pls_id' as const
  private static GET_DIVISIONS_DATETIMES_BY_DOCTOR = '/get_datestimes_by_doctor_and_service_id_and_patient_split_by_division' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(ScheduleAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && res.url == ScheduleAPI.GET_DIVISIONS_DOCTORS_BY_DATE) {
      // @ts-ignore
      const divisions: DivisionDoctors[] = res.data.divisions
      divisions.forEach(division => {
        division.doctors.forEach(doctor => {
          doctor.times = doctor.times.map(shift3hoursDatetime)
        })
      })
      return {
        ...res,
        // @ts-ignore
        data: { divisions } as T
      } as const
    }
    if (!res.error && (res.url == ScheduleAPI.GET_DIVISIONS_DATETIMES || res.url == ScheduleAPI.GET_DIVISIONS_DATETIMES_BY_DOCTOR)) {
      // @ts-ignore
      const divisions: DivisionDatetimes[] = res.data.divisions
      divisions.forEach(division => {
        Object.keys(division.datetimes).forEach(date => {
          division.datetimes[date] = division.datetimes[date].map(shift3hoursDatetime)
        })
      })
      return {
        ...res,
        // @ts-ignore
        data: { divisions } as T
      }
    }
    return res
  }

  getDivisionsDatetimes = async (params: DivisionDatetimeRequestParams) => {
    const res = await this.request<{ divisions: DivisionDatetimes[] }, { message: string }>({
      method: 'get',
      url: ScheduleAPI.GET_DIVISIONS_DATETIMES,
      params,
    })
    return res
  }
  getDivisionsDoctorsByDate = async (params: DivisionDatetimeRequestParams & { date: string }) => {
    const res = await this.request<{ divisions: DivisionDoctors[] }, { message: string }>({
      method: 'get',
      url: ScheduleAPI.GET_DIVISIONS_DOCTORS_BY_DATE,
      params,
    })
    return res
  }

  getDoctorsByPricelist = async (params: { pls_id: number }) => {
    const res = await this.request<{ doctors: Required<DoctorPreview>[] }, { message: string }>({
      method: 'get',
      url: ScheduleAPI.GET_DOCTORS_BY_PRICELIST,
      params,
    })
    return res
  }
  getDivisionsDatetimesByDoctor = async (params: DivisionDatetimeRequestParams & { doctor_id: number }) => {
    const res = await this.request<{ divisions: DivisionDatetimes[] }, { message: string }>({
      method: 'get',
      url: ScheduleAPI.GET_DIVISIONS_DATETIMES_BY_DOCTOR,
      params,
    })
    return res
  }
}

export default ScheduleAPI