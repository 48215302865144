import { combineReducers, Reducer } from 'redux'
import PatientsBymistokenReducer from './patients.bymistoken.reducer'
import PatientsByphoneReducer from './patients.byphone.reducer'
import PatientsBirthdateReducer from './patients.birthdate.reducer'

const PatientsReducer = combineReducers({
  byphone: PatientsByphoneReducer,
  bymistoken: PatientsBymistokenReducer,
  birthdate: PatientsBirthdateReducer,
})

export type PatientsState = typeof PatientsReducer extends Reducer<infer State> ? State : never

export default PatientsReducer