import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { DoctorPreview } from '../../../api/doctor.types'
import { CatalogPreview } from '../../../api/pricelist.types'
import { AppState } from '../../../store'
import { doctorsByserviceRequestAction } from '../../../store/reducks/doctors/doctors.byservice.actions'
import DoctorList from '../../DoctorList'
import TemplateContent from '../../Template/content'

export type DoctorsProps = ReduxConnectedProps & {
  catalog: CatalogPreview
  onBack?: () => void
  onDoctor?: (doctor: DoctorPreview) => void
}

export type DoctorsState = {
  doctorsLoaded?: boolean
}

class Doctors extends React.Component<DoctorsProps, DoctorsState> {
  constructor(props: DoctorsProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { catalog } = this.props
    const { service_id } = catalog
    this.props.doctorsByserviceRequestAction({ service_id })
  }

  componentDidUpdate(prevProps: DoctorsProps) {
    const { doctors } = this.props
    if (!doctors.fetching && !doctors.error && prevProps.doctors.fetching) {
      this.setState({ doctorsLoaded: true })
    }
  }

  private onBack = () => {
    const { onBack } = this.props
    onBack && onBack()
  }

  render() {
    const { doctors, catalog } = this.props
    const { doctorsLoaded } = this.state
    return <TemplateContent
      title="Врачи"
      subTitle={catalog.service_name}
      onTitleBack={this.onBack}
    >
      {doctorsLoaded && <DoctorList
        doctors={doctors.doctors}
        hideSearch
        onDoctor={this.props.onDoctor}
        onInfo={() => {}}
        onRecord={() => {}}
      />}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    doctors: state.doctors.byservice,
  }), {
    doctorsByserviceRequestAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(Doctors)