import React from 'react'
import { Toast } from 'react-hot-toast'
import CancelIcon from '../../icons/CancelIcon.svg'
import './style.less'

export type ToastBodyProps = {
  message: string
  onCancel?: () => void
}

const ToastBody: React.FC<ToastBodyProps> = (props) => {
  return <div className="toast-body">
    <div className="message">
      {props.message}
    </div>
    <div className="cancel" onClick={props.onCancel}>
      <img src={CancelIcon} />
    </div>
  </div>
}

export default ToastBody