import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { researchesAllFailureAction, researchesAllSuccessAction } from "./researches.all.actions";
import { researchesByPatientFailureAction, researchesByPatientSuccessAction } from "./researches.bypatient.actions";
import { researchesFileFailureAction, researchesFileSuccessAction } from "./researches.file.actions";
import { researchesNewFailureAction, researchesNewSuccessAction } from "./researches.new.actions";
import { ResearchesAction } from "./researches.types";

export function* researchesNewRequestSaga(action: ResearchesAction) {
  const res: ThenReturnType<typeof api.research.getMedcardResearchesNew> = yield call(api.research.getMedcardResearchesNew)
  if (res.error) {
    yield put(researchesNewFailureAction())
  } else {
    yield put(researchesNewSuccessAction(res.data.researches))
  }
}

export function* researchesAllRequestSaga(action: ResearchesAction) {
  const res: ThenReturnType<typeof api.research.getMedcardResearchesAll> = yield call(api.research.getMedcardResearchesAll)
  if (res.error) {
    yield put(researchesAllFailureAction())
  } else {
    yield put(researchesAllSuccessAction(res.data.researches))
  }
}

export function* researchesByPatientRequestSaga(action: ResearchesAction) {
  const res: ThenReturnType<typeof api.research.getMedcardResearches> = yield call(api.research.getMedcardResearches, action.params)
  if (res.error) {
    yield put(researchesByPatientFailureAction())
  } else {
    yield put(researchesByPatientSuccessAction(res.data.researches))
  }
}

export function* researchesFileRequestSaga(action: ResearchesAction) {
  const res: ThenReturnType<typeof api.research.getMedcardResearchFile> = yield call(api.research.getMedcardResearchFile, action.params)
  if (res.error) {
    yield put(researchesFileFailureAction())
  } else {
    yield put(researchesFileSuccessAction(res.data))
  }
}

export default function* watchResearches() {
  yield takeLeadingPattern<ResearchesAction['type']>('RESEARCHES_ALL_REQUEST', researchesAllRequestSaga, researchesAllFailureAction)
  yield takeLeadingPattern<ResearchesAction['type']>('RESEARCHES_NEW_REQUEST', researchesNewRequestSaga, researchesNewFailureAction)
  yield takeLeadingPattern<ResearchesAction['type']>('RESEARCHES_BY_PATIENT_REQUEST', researchesByPatientRequestSaga, researchesByPatientFailureAction)

  yield takeLeadingPattern<ResearchesAction['type']>('RESEARCHES_FILE_REQUEST', researchesFileRequestSaga, researchesFileFailureAction)
}