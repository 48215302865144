import { PatientsByphoneOrderAction, PatientsByphoneOrderState } from "./patients.byphone.order.types"

export const initialPatientsByphoneOrderState: PatientsByphoneOrderState = {
  fetching: false
}

const PatientsByphoneOrderReducer = (state = initialPatientsByphoneOrderState, action: PatientsByphoneOrderAction): PatientsByphoneOrderState => {
  switch (action.type) {
    case 'PATIENTS_BYPHONE_ORDER_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENTS_BYPHONE_ORDER_SUCCESS':
    case 'PATIENTS_BYPHONE_ORDER_FAILURE':
      return {
        ...state,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPatientsByphoneOrderState,
      }
    default:
      return state
  }
}

export default PatientsByphoneOrderReducer