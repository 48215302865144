import { combineReducers } from 'redux'
import PatientsByphoneAddedReducer from "./patients.byphone.added.reducer";
import PatientsByphoneMainReducer from "./patients.byphone.main.reducer";
import PatientsByphoneOrderReducer from './patients.byphone.order.reducer';

const PatientsByphoneReducer = combineReducers({
  main: PatientsByphoneMainReducer,
  added: PatientsByphoneAddedReducer,
  order: PatientsByphoneOrderReducer,
})

export default PatientsByphoneReducer