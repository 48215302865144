import create from "./utils/create";
import config from "../config"
import requestTransform from "./utils/requestTransform";

const api = create({
  baseURL: config.api.baseURL,
  timeout: config.api.timeout || 10 * 1000,
  requestTransform,
})

export default api