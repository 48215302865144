import { PaymentParamsAction, PaymentParamsState } from "./payment.params.types"

export const initialPaymentParamsState: PaymentParamsState = {
}

const PaymentParamsReducer = (state = initialPaymentParamsState, action: PaymentParamsAction): PaymentParamsState => {
  switch (action.type) {
    case 'PAYMENT_PARAMS_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'PAYMENT_PARAMS_CLEAR':
    case 'STORAGE_CLEAR':
      return {
        ...initialPaymentParamsState,
      }
    default:
      return state
  }
}

export default PaymentParamsReducer