import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: '#a5302e',
    },
  },
  typography: {
    fontFamily: [
      '"Product Sans"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
    ].join(','),
  },
})

export default theme