import React, { ReactNode } from 'react'
import { EmptyJournalIcon } from '../../icons'
import './style.less'

export type EmptyJournalProps = {
  text?: string
  icon?: ReactNode
}

const EmptyJournal: React.FC<EmptyJournalProps> = (props) => {
  const {
    text = 'Здесь будут отображаться ваши записи на прием',
    icon,
  } = props
  return <div className="empty-journal">
    <div>
      {icon || <EmptyJournalIcon />}
      <span>{text}</span>
    </div>
  </div>
}

export default EmptyJournal