import { ReportErrorState, ReportErrorAction } from './report.error.types'
export const initialReportErrorState: ReportErrorState = {
  fetching: false,
}
const ReportErrorReducer = (state = initialReportErrorState, action: ReportErrorAction): ReportErrorState => {
  switch (action.type) {
    case 'REPORT_ERROR_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'REPORT_ERROR_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: action.params,
      }
    case 'REPORT_ERROR_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialReportErrorState
      }
    default:
      return state
  }
}
export default ReportErrorReducer