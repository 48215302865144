import { ClinicContact } from "../../../api/clinic.types";

const parseClinicInfo = (contacts: ClinicContact[]) => {
  const addresses = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('клиника') !== -1;
  });
  const phones = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('телефон') !== -1;
  });
  const emails = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('e-mail') !== -1;
  });
  const sites = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('сайт') !== -1;
  });
  const schedules = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('время') !== -1;
  });
  const vk = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('вконтакте') !== -1;
  });
  const ok = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('одноклассники') !== -1;
  });
  const facebook = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('facebook') !== -1;
  });
  const instagram = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('instagram') !== -1;
  });
  const youtube = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('youtube') !== -1;
  });
  const twitter = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('twitter') !== -1;
  });
  const telegram = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('telegram') !== -1;
  });
  const companyName = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('наименование предприятия') !== -1;
  });
  const INN = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('инн') !== -1;
  });
  const KPP = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('кпп') !== -1;
  });
  const account = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('расчетный счет') !== -1;
  });
  const bankName = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('наименование банка') !== -1;
  });
  const RCBIC = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('бик') !== -1;
  });
  const correspondentAccount = contacts.filter((contact) => {
    return contact.name.toLowerCase().indexOf('кор/счет') !== -1;
  });
  return {
    addresses,
    phones,
    emails,
    sites,
    schedules,
    vk,
    ok,
    facebook,
    instagram,
    youtube,
    twitter,
    telegram,
    companyName,
    INN,
    KPP,
    account,
    bankName,
    RCBIC,
    correspondentAccount,
  }
}

export default parseClinicInfo