
const ONE_KB = 1024;
const ONE_MB = ONE_KB * 1024
const ONE_GB = ONE_MB * 1024

export const toKB = (bytes: number) => `${(bytes / ONE_KB).toFixed(0)}KB`
export const toMB = (bytes: number) => `${(bytes / ONE_MB).toFixed(1)}MB`
export const toGB = (bytes: number) => `${(bytes / ONE_GB).toFixed(2)}GB`
export const smartBytes = (bytes: number) => {
  if (bytes < ONE_KB) {
    return `${bytes}B`
  }
  if (bytes < ONE_MB) {
    return toKB(bytes)
  }
  if (bytes < ONE_GB) {
    return toMB(bytes)
  }
  return toGB(bytes)
}