import { Nullable } from '../types'

export interface AuthState {
  token: string | null
  fetching: boolean
}

export const AUTH_SEND_CODE = 'AUTH_SEND_CODE'
export const AUTH_CHECK_CODE = 'AUTH_CHECK_CODE'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const authLogoutAction = () => ({ type: AUTH_LOGOUT })
export const authSendCodeAction = (params: { phone: string }) => ({ type: AUTH_SEND_CODE, params })
export const authCheckCodeAction = (params: { phone: string; code: string }) => ({
  type: AUTH_CHECK_CODE,
  params,
})
export const authTokenSetAction = (token: Nullable<string>) => ({
  type: AUTH_SET_TOKEN,
  token,
})

const initialState: AuthState = {
  token: null,
  fetching: false,
}

const AuthReducer = (state = initialState, action: any): AuthState => {
  const { type, token = null } = action
  switch (type) {
    case AUTH_SEND_CODE:
      return { ...initialState, fetching: true }
    case AUTH_CHECK_CODE:
      return { ...initialState, fetching: true }
    case AUTH_SET_TOKEN:
      return {
        ...state,
        fetching: false,
        token,
      }
    case AUTH_LOGOUT:
      return { ...initialState }
    default:
      return state
  }
}

export default AuthReducer
