
function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const copied = document.execCommand('copy');
    if (!copied) {
      const message = 'Fallback: copying was unsuccessful'
      throw new Error(message)
    }
  } catch (err) {
    throw err
  } finally {
    document.body.removeChild(textArea);
  }
}

async function copyTextToClipboard(text?: string) {
  if (!text) {
    return
  }
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text)
  }
  return fallbackCopyTextToClipboard(text);
}

export default copyTextToClipboard