import React from 'react'
import { Doctor as IDoctor, DoctorPreview } from '../../../api/doctor.types'
import TemplateContent from '../../Template/content'
import DoctorInfo from '../../DoctorInfo'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../../store'
import { doctorByEmployerIdRequestAction } from '../../../store/reducks/doctors/doctors.item.actions'

export type DoctorProps = ReduxConnectedProps & {
  preview: DoctorPreview
  onRecord?: (doctor: IDoctor) => void
  onBack?: () => void
}

export type DoctorState = {
  doctorLoaded?: boolean
}

class Doctor extends React.Component<DoctorProps, DoctorState> {
  constructor(props: DoctorProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { preview } = this.props
    this.props.doctorByEmployerIdRequestAction({ employer_id: preview.doctor_employer_id })
  }

  componentDidUpdate(prevProps: DoctorProps) {
    const { doctor } = this.props
    if (!doctor.fetching && !doctor.error && prevProps.doctor.fetching) {
      this.setState({ doctorLoaded: true })
    }
  }

  private onRecord = () => {
    const { onRecord, doctor } = this.props
    onRecord && doctor.doctor && onRecord(doctor.doctor)
  }

  render() {
    const { doctor } = this.props
    const { doctorLoaded } = this.state
    return <TemplateContent
      title="Врач"
      onTitleBack={this.props.onBack}
    >
      {doctorLoaded && doctor.doctor && <DoctorInfo
        doctor={doctor.doctor}
        onRecord={this.onRecord}
      />}
    </TemplateContent>
    
  }
}

const connector = connect(
  (state: AppState) => ({
    doctor: state.doctors.item,
  }), {
    doctorByEmployerIdRequestAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>


export default connector(Doctor)