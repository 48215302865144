import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { Action, ActionPreview, Article, ArticlePreview, NewsItem, NewsPreview, Program, ProgramPreview } from "./newsfeed.types";

class NewsFeedAPI {
  private static GET_ACTIONS = '/get_lk_actions' as const
  private static GET_ACTION = '/get_lk_action' as const

  private static GET_ARTICLES = '/get_lk_articles' as const
  private static GET_ARTICLE = '/get_lk_article' as const

  private static GET_NEWS_ALL = '/get_lk_news' as const
  private static GET_NEWS_ITEM = '/get_lk_one_news' as const

  private static GET_PROGRAMS = '/get_programs' as const
  private static GET_PROGRAM = '/get_program' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(NewsFeedAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && res.url == NewsFeedAPI.GET_ACTION) {
      // обертка для "неправильного" формата ответа с сервера
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { action: res.data } as T,
      } as const
    }
    if (res.error && res.status == 400 && res.url == NewsFeedAPI.GET_ACTIONS && res.data.message == 'actions not found') {
      return {
        ...res,
        error: false,
        status: 200,
        // @ts-ignore
        data: { actions: [] } as T,
      } as const
    }

    if (res.error && res.status == 400 && res.url == NewsFeedAPI.GET_ARTICLES && res.data.message == 'articles not found') {
      // обертка для "неправильного" формата ответа с сервера
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { articles: [] } as T,
        status: 200,
      } as const
    }
    if (!res.error && res.url == NewsFeedAPI.GET_ARTICLE) {
      // обертка для "неправильного" формата ответа с сервера
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { article: res.data } as T,
      } as const
    }
    return res
  }

  getActions = async (params?: { page?: number }) => {
    const res = await this.request<{ actions: ActionPreview[] }, { message: string }>({
      method: 'get',
      url: NewsFeedAPI.GET_ACTIONS,
      params,
    })
    return res
  }
  getAction = async (params: { action_id: number }) => {
    const res = await this.request<{ action: Action }, { message: string }>({
      method: 'get',
      url: NewsFeedAPI.GET_ACTION,
      params,
    })
    return res
  }

  getArticle = async (params: { article_id: number }) => {
    const res = await this.request<{ article: Article }, { message: string}>({
      method: 'get',
      url: NewsFeedAPI.GET_ARTICLE,
      params,
    })
    return res
  }
  getArticles = async (params: { service_id: number; page?: number }) => {
    const res = await this.request<{ articles: ArticlePreview[] }, { message: string}>({
      method: 'get',
      url: NewsFeedAPI.GET_ARTICLES,
      params,
    })
    return res
  }

  getNewsAll = async () => {
    const res = await this.request<{ news: NewsPreview[] }, { message: string }>({
      method: 'get',
      url: NewsFeedAPI.GET_NEWS_ALL,
    })
    return res
  }
  getNewsItem = async (params: { news_id: number }) => {
    const res = await this.request<NewsItem, { message: string }>({
      method: 'get',
      url: NewsFeedAPI.GET_NEWS_ITEM,
      params,
    })
    return res
  }

  getPrograms = async () => {
    const res = await this.request<{ programs: ProgramPreview[] }, { message: string }>({
      method: 'get',
      url: NewsFeedAPI.GET_PROGRAMS,
    })
    return res
  }
  getProgram = async (params: { program_id: number }) => {
    const res = await this.request<Program, { message: string }>({
      method: 'get',
      url: NewsFeedAPI.GET_PROGRAM,
      params,
    })
    return res
  }
}

export default NewsFeedAPI