import { BonusPaymentModalAction, BonusPaymentModalState } from "./modals.bonusPayment.types"

export const initialBonusPaymentModalState: BonusPaymentModalState = {
  visible: false,
  price: 0,
}

const noop = () => {}

const BonusPaymentModalReducer = (state = initialBonusPaymentModalState, action: BonusPaymentModalAction): BonusPaymentModalState => {
  switch (action.type) {
    case 'BONUS_PAYMENT_MODAL_CLOSE':
      return {
        ...initialBonusPaymentModalState,
      }
    case 'BONUS_PAYMENT_MODAL_OPEN':
      return {
        ...state,
        visible: true,
      }
    case 'BONUS_PAYMENT_MODAL_SET':
      return {
        ...state,
        ...action.params,
      }
    default:
      return state
  }
}

export default BonusPaymentModalReducer