import { RegisterInfoAction, RegisterInfoState } from "./register.info.types"

export const initialRegisterInfoState: RegisterInfoState = {
  fetching: false,
  registerInfo: {},
}

const RegisterInfoReducer = (state = initialRegisterInfoState, action: RegisterInfoAction): RegisterInfoState => {
  switch (action.type) {
    case 'REGISTER_INFO_FOR_PATIENT_REQUEST':
    case 'REGISTER_INFO_FOR_NEW_PATIENT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        registerInfo: {},
      }
    case 'REGISTER_INFO_FOR_PATIENT_SUCCESS':
    case 'REGISTER_INFO_FOR_NEW_PATIENT_SUCCESS':
      return {
        ...state,
        fetching: false,
        registerInfo: action.params,
      }
    case 'REGISTER_INFO_FOR_PATIENT_FAILURE':
    case 'REGISTER_INFO_FOR_NEW_PATIENT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialRegisterInfoState,
      }
    default:
      return state
  }
}

export default RegisterInfoReducer