import React from 'react'
import { Button } from 'antd'
import EmptyPrices from '../../images/emptyPrices.png'
import './style.less'

export type ButtonItem = {
  type: 'default' | 'primary',
  className?: string
  text: string
  onClick?: () => void
}

export type EmptyServiceListProps = {
  text?: string
  image?: React.ReactNode
  buttons?: ButtonItem[]
}

class EmptyServiceList extends React.Component<EmptyServiceListProps> {
  private renderImage = () => {
    const { image } = this.props
    return image || <img src={EmptyPrices} />
  }

  private renderText = () => {
    const { text } = this.props
    return <span>{text || 'К сожалению, самостоятельная запись недоступна. Свяжитесь с оператором'}</span>
  }

  private renderButtons = () => {
    const { buttons = [] } = this.props
    return <>
      {buttons.map((button, index) => (<Button
        key={index}
        type={button.type}
        className={`button ${button.className || ''}`}
        onClick={button.onClick}
      >{button.text}</Button>))}
    </>
  }

  render() {
    return <div className="empty-service-list">
      <div className="content">
        {this.renderImage()}
        {this.renderText()}
        {this.renderButtons()}
      </div>
    </div>
  }
}

export default EmptyServiceList