import { combineReducers } from 'redux'
import CallMeReducer from './call.me.reducer'
import DoctorRatingReducer from './doctor.rating.reducer'
import DoctorRatingTmReducer from './doctor.rating.tm.reducer'
import ReportErrorReducer from './report.error.reducer'
import SendFeedbackReducer from './send.feedback.reducer'

const FeedBackReducer = combineReducers({
  callme: CallMeReducer,
  doctorRating: DoctorRatingReducer,
  doctorRatingTm: DoctorRatingTmReducer,
  reportError: ReportErrorReducer,
  sendFeedback: SendFeedbackReducer,
})

export default FeedBackReducer