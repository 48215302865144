import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Button } from 'antd'
import './style.less'

export type MedcardAuthProps = {
  onAuth?: (login: string, password: string) => void
  onRecovery?: () => void
  className?: string
}

export type MedcardAuthState = {
  login?: string
  password?: string
  loginError?: string
  passwordError?: string
}

class MedcardAuth extends React.Component<MedcardAuthProps, MedcardAuthState> {
  constructor(props: MedcardAuthProps) {
    super(props)
    this.state = {}
  }

  private onLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const login = e.target.value
    this.setState({ login: login.trim(), loginError: undefined })
  }

  private onPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value
    this.setState({ password: password.trim(), passwordError: undefined })
  }

  private onAuth = () => {
    const { login, password } = this.state
    const isValid = this.validateInput()
    if (isValid) {
      const { onAuth } = this.props
      onAuth && onAuth(login as string, password as string)
    }
  }

  private validateInput = () => {
    const { login, password } = this.state
    let error: boolean = false
    if (!login || !login.trim()) {
      this.setState({ loginError: 'Необходимо ввести логин' })
      error = true
    }
    if (!password || !password.trim()) {
      this.setState({ passwordError: 'Необходимо ввести пароль' })
      error = error || true
    }
    return !error
  }

  private onRecovery = () => {
    const { onRecovery } = this.props
    onRecovery && onRecovery()
  }

  render() {
    const { login, password, loginError, passwordError } = this.state
    const { className } = this.props
    return <div className={`medcard-auth ${className || ''}`}>
      <TextField
        className="login-input"
        value={login}
        error={Boolean(loginError)}
        helperText={loginError}
        onChange={this.onLogin}
        label="Логин"
        variant="outlined"
        size="small"
      />
      <TextField
        className="password-input"
        size="small"
        value={password}
        error={Boolean(passwordError)}
        helperText={passwordError}
        onChange={this.onPassword}
        type="password"
        label="Пароль"
        variant="outlined"
      />
      <div className="buttons">
        <Button type="primary" onClick={this.onAuth} className="auth-button">Войти</Button>
        <Button type="default" onClick={this.onRecovery} className="recovery-button">Восстановить пароль</Button>
      </div>
      <div className="description">
        <div className="top">
          <span>{'Для входа в электронную медицинскую карту необходимо ввести логин и пароль пользователя.'}</span>
        </div>
        <div className="bottom">
          <span>{'Для получения логина и пароля обратитесь в клинику. Согласно Федеральному закону №152-Ф3 «О персональных данных» Вам необходимо лично, в нашей клинике, подписать согласие на обработку персональных данных и Заявку на регистрацию в электронном сервисе.'}</span>
        </div>
      </div>
    </div>
  }
}

export default MedcardAuth