import { PricelistPrepareInfoAction, PricelistPrepareInfoState } from "./pricelist.prepare_info.types"

export const initialPricelistPrepareInfoState: PricelistPrepareInfoState = {
  fetching: false,
  prepareInfo: {},
  modal: {}
}

const PricelistPrepareInfoReducer = (state = initialPricelistPrepareInfoState, action: PricelistPrepareInfoAction): PricelistPrepareInfoState => {
  switch (action.type) {
    case 'PRICELIST_PREPARE_INFO_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        prepareInfo: {},
      }
    case 'PRICELIST_PREPARE_INFO_SUCCESS':
      return {
        ...state,
        fetching: false,
        prepareInfo: action.params,
      }
    case 'PRICELIST_PREPARE_INFO_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'PRICELIST_PREPARE_INFO_MODAL_SET':
      return {
        ...state,
        modal: {
          ...state.modal,
          visible: action.params?.visible,
        }
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricelistPrepareInfoState,
      }
    default:
      return state
  }
}

export default PricelistPrepareInfoReducer