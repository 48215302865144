import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { Visit, VisitFile, VisitItem } from "./medcard.types";

class MedcardAPI {
  private static GET_MEDCARD_ONLINE_VISITS_ALL = '/get_medcard_visits_by_phone' as const
  private static GET_MEDCARD_VISITS_ALL = '/get_medcard_visits_by_token' as const

  private static GET_MEDCARD_ONLINE_VISITS_BY_PATIENT = '/get_medcard_online_visits' as const
  private static GET_MEDCARD_VISITS_BY_PATIENT = '/get_medcard_visits' as const

  private static GET_MEDCARD_ONLINE_VISIT = '/get_medcard_online_visit' as const
  private static GET_MEDCARD_VISIT = '/get_medcard_visit' as const

  private static GET_MEDCARD_VISIT_FILE = '/get_medcard_visit_as_file' as const

  private static GET_MEDCARD_NUMBER = '/get_medcard_number' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(MedcardAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (res.error && res.data.message == 'visits not found') {
      return {
        ...res,
        error: false,
        status: 200,
        // @ts-ignore
        data: { visits: [] } as T
      } as const
    }
    return res
  }

  getMedcardOnlineVisitsAll = async () => {
    const res = await this.request<{ visits: VisitItem[] }, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_ONLINE_VISITS_ALL,
      params: { is_tm: 1 },
    })
    return res
  }
  getMedcardVisitsAll = async () => {
    const res = await this.request<{ visits: VisitItem[] }, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_VISITS_ALL,
      params: { is_tm: 0 },
    })
    return res
  }

  getMedcardOnlineVisitsByPatient = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ visits: VisitItem[] }, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_ONLINE_VISITS_BY_PATIENT,
      params,
    })
    return res
  }
  getMedcardVisitsByPatient = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ visits: VisitItem[] }, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_VISITS_BY_PATIENT,
      params,
    })
    return res
  }

  getMedcardOnlineVisit = async (params: { visit_id: number }) => {
    const res = await this.request<Visit, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_ONLINE_VISIT,
      params,
    })
    return res
  }
  getMedcardVisit = async (params: { visit_id: number }) => {
    const res = await this.request<Visit, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_VISIT,
      params,
    })
    return res
  }

  getMedcardVisitFile = async (params: { visit_id: number }) => {
    const res = await this.request<VisitFile, { message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_VISIT_FILE,
      params,
    })
    return res
  }

  getMedcardNumber = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ message: string }>({
      method: 'get',
      url: MedcardAPI.GET_MEDCARD_NUMBER,
      params,
    })
    return res
  }
}

export default MedcardAPI