import { CatalogPreview, Price } from "../../../api/pricelist.types"
import { RecordAction, RecordState } from "./record.types"

export const recordClearAction = () => ({ type: 'RECORD_CLEAR' })

export const recordStageSetAction = (stage: number): RecordAction => ({ type: 'RECORD_STAGE_SET', params: { stage } })

export const recordFormatSetAction = (format: string | null): RecordAction => ({ type: 'RECORD_FORMAT_SET', params: { format } })

export const recordPatientSetAction = (patient: any) => ({ type: 'RECORD_PATIENT_SET', params: { patient } })

export const recordNewPatientSetAction = (new_patient: any) => ({ type: 'RECORD_NEW_PATIENT_SET', params: { new_patient } })

export const recordCatalogSetAction = (catalog: CatalogPreview | null) => ({ type: 'RECORD_CATALOG_SET', params: { catalog } })

export const recordServiceSetAction = (service: Price | null) => ({ type: 'RECORD_SERVICE_SET', params: { service } })

export const recordDateSetAction = (date: string | null): RecordAction => ({ type: 'RECORD_DATE_SET', params: { date } })

export const recordDateTimeSetAction = (datetime: string | null): RecordAction => ({ type: 'RECORD_DATETIME_SET', params: { datetime } })

export const recordDoctorSetAction = (doctor: any | null): RecordAction => ({ type: 'RECORD_DOCTOR_SET', params: { doctor } })

export const recordAddressSetAction = (address: string | null): RecordAction => ({ type: 'RECORD_ADDRESS_SET', params: { address } })

export const recordTypeSetAction = (type: RecordState['type']): RecordAction => ({ type: 'RECORD_TYPE_SET', params: { type } })

export const recordRegisterInfoSetAction = (register_info: RecordState['register_info']): RecordAction => ({ type: 'RECORD_REGISTER_INFO_SET', params: { register_info }})

export const recordStateSetAction = (params: Partial<RecordState>): RecordAction => ({ type: 'RECORD_STATE_SET', params })