import React, { Component } from 'react'
import { Patient } from '../../api/patient.types'
import { Man, Woman, Boy, Girl } from '../../images'
import { IPatient } from '../../store/types'
import './style.less'

interface CommonProps {
  onClick: (patient: Patient) => void
  selectedPatient: Patient
}

interface Props {
  dragHandleProps?: any
  item: Patient
  commonProps: CommonProps
}

interface State {}

class PatientItem extends Component<Props, State> {
  renderAvatar = () => {
    return <img src={Man} alt="" />
  }

  renderFullname = () => {
    const { item } = this.props
    return item.name
  }

  render() {
    const {
      item,
      dragHandleProps,
      commonProps: { onClick, selectedPatient },
    } = this.props
    return (
      <div
        className={`patient${selectedPatient && selectedPatient.medic_user_id == item.medic_user_id ? ' selected' : ''}`}
        {...dragHandleProps}
        onClick={() => {
          onClick(item)
        }}
      >
        <div className="avatar">{this.renderAvatar()}</div>
        <div className="info">
          <div className="fullname">{this.renderFullname()}</div>
        </div>
      </div>
    )
  }
}

export default PatientItem
