import React from 'react'
import moment from 'moment'
import { Row, Col, Button } from 'antd'
import { DoctorPreview } from '../../../api/doctor.types'
import { Patient } from "../../../api/patient.types"
import { AppState } from '../../../store'
import { PatientsState } from '../../../store/reducks/patients/patients.types'
import { RecordState } from '../../../store/reducks/record/record.types'
import { AvatarIcon, CalendarIcon, DoctorIcon, MapMarkerIcon, RubleIcon, ServiceIcon } from '../../../icons'
import './style.less'
import Record from '../../../store/reducks/record/Record'
import capitalize from './capitalize'

export type RecordingStage6Props = {
  record: RecordState
  patients: { added: PatientsState; main: PatientsState }
  onSubmit?: () => void
  onPrepareInfo?: () => void
}

class RecordingStage6 extends React.Component<RecordingStage6Props> {

  private renderRow = (props: { icon: any; name: string; description: any; }) => {
    const { icon, name, description } = props
    return <Row className="row">
      <Col className="left" span={8}>
        {icon}
        <span>{name}</span>
      </Col>
      <Col className="right" span={16}>
        {description}
      </Col>
    </Row>
  }

  private getPatientName = () => {
    const { record } = this.props
    const { patient, new_patient } = record
    if (new_patient) {
      const { firstname, midname, lastname } = new_patient
      return `${capitalize(lastname).slice(0, 1)}. ${capitalize(firstname)} ${capitalize(midname)}`
    }
    if (patient) {
      const { patients } = this.props
      const patientFinder = ((p: Patient) => p.medic_user_id == patient.medic_user_id)
      let p = patients.main.users.find(patientFinder)
      if (!p) {
        p = patients.added.users.find(patientFinder)
      }
      if (!p) {
        return ''
      }
      const names = p.name.split(/\s+/g)
      return [names[names.length - 1], ...names.slice(0, -1)].join(' ')
    }
    return ''
  }

  private getDateTime = () => {
    const { record } = this.props
    const datetime = moment(record.datetime).format('dddd, DD MMMM, HH:mm')
    return capitalize(datetime)
  }

  private getPrice = () => {
    const { record } = this.props
    return `${Record.getPrice(record)} ₽`
  }

  private renderServiceDescription = () => {
    const { record } = this.props
    return <div className="description">
      <span>{record.service?.name || ''}</span>
      <Button className="prepare-info" type="default" onClick={this.onPrepareInfo}>
        {'Информация о подготовке'}
      </Button>
    </div>
  }

  private onPrepareInfo = () => {
    const { onPrepareInfo } = this.props
    onPrepareInfo && onPrepareInfo()
  }

  private getDoctor = () => {
    const { record } = this.props
    const doctor: DoctorPreview = record.doctor as DoctorPreview
    return doctor.doctor_name
  }

  private renderBottom = () => {
    return <Button className="submit" type="primary" onClick={this.onSubmit}>Подтвердить запись</Button>
  }

  private onSubmit = () => {
    const { onSubmit } = this.props
    onSubmit && onSubmit()
  }

  render() {
    const { record } = this.props
    return <div className="recording-stage-sixth">
      {this.renderRow({ icon: <AvatarIcon />, name: 'Клиент', description: this.getPatientName() })}
      {this.renderRow({ icon: <CalendarIcon />, name: 'Дата и время', description: this.getDateTime() })}
      {this.renderRow({ icon: <MapMarkerIcon />, name: 'Клиника', description: record.address })}
      {this.renderRow({ icon: <RubleIcon />, name: 'Стоимость', description: this.getPrice() })}
      {this.renderRow({ icon: <ServiceIcon />, name: 'Услуга', description: this.renderServiceDescription() })}
      {this.renderRow({ icon: <DoctorIcon />, name: 'Врач', description: this.getDoctor() })}
      {this.renderBottom()}
    </div>
  }
}

export default RecordingStage6