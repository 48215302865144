import { AuthAction } from "../auth/types"
import { UserAction, UserState } from "./types"

export const initialUserState: UserState = {
  phone: '',
  token: '',
}

const UserReducer = (state = initialUserState, action: UserAction | AuthAction): UserState => {
  switch (action.type) {
    case 'AUTH_LOGOUT_SUCCESS':
    case 'STORAGE_CLEAR':
    case 'USER_CLEAR':
      return {
        ...initialUserState,
      }
    case 'AUTH_CHECK_CODE_SUCCESS':
    case 'USER_UPDATE':
      return {
        ...state,
        ...action.params,
      }
    case 'DEMO_TOKEN_SUCCESS':
      return {
        ...state,
        token: action.params?.token,
        is_demo: true,
        authByLogin: true,
      }
    case 'AUTH_BY_LOGIN_SUCCESS':
      return {
        ...state,
        authByLogin: true,
      }
    default:
      return state
  }
}

export default UserReducer