import { BonusPointsAction, BonusPointsState } from "./bonus.points.types"

export const initialBonusPointsState: BonusPointsState = {
  fetching: false,
  points: {},
}

const BonusPointsReducer = (state = initialBonusPointsState, action: BonusPointsAction): BonusPointsState => {
  switch (action.type) {
    case 'BONUS_POINTS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'BONUS_POINTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        points: action.params,
      }
    case 'BONUS_POINTS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialBonusPointsState,
      }
    default:
      return state
  }
}

export default BonusPointsReducer