import { all, call, put, takeLeading } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { patientsBymistokenAddedFailure, patientsBymistokenAddedRequest, patientsBymistokenAddedSuccess } from "./patients.bymistoken.added.actions";
import { patientsBymistokenMainFailure, patientsBymistokenMainRequest, patientsBymistokenMainSuccess } from "./patients.bymistoken.main.actions";
import { patientsBymistokenOrderFailure, patientsBymistokenOrderSuccess } from "./patients.bymistoken.order.actions";
import { patientsByphoneAddedFailure, patientsByphoneAddedRequest, patientsByphoneAddedSuccess } from "./patients.byphone.added.actions";
import { patientsByphoneMainFailure, patientsByphoneMainRequest, patientsByphoneMainSuccess } from "./patients.byphone.main.actions";
import { patientsByphoneOrderFailure, patientsByphoneOrderSuccess } from "./patients.byphone.order.actions";
import { patientBirthdateRequest, patientBirthdateSuccess, patientBirthdateFailure } from "./patients.birthdate.date.action";
import { patientBirthdateChangeSuccess, patientBirthdateChangeFailure } from "./patients.birthdate.change.action";
import { PatientsAction } from "./patients.types";
import { IPatientBirthdateChangeRequest } from "./patients.birthdate.change.types"



export function* patientsRequestSaga(action: PatientsAction) {
  yield all([
    patientsByphoneRequestSaga(action),
    patientsBymistokenRequestSaga(action),
  ])
}

export function* patientsByphoneRequestSaga(action: PatientsAction) {
  yield put(patientsByphoneAddedRequest())
  yield put(patientsByphoneMainRequest())
}

export function* patientsByphoneMainRequestSaga(action: PatientsAction) {
  const res: ThenReturnType<typeof api.patient.getPatientsByPhone> = yield call(api.patient.getPatientsByPhone)
  if (res.error) {
    yield put(patientsByphoneMainFailure())
  } else {
    yield put(patientsByphoneMainSuccess(res.data.users))
  }
}

export function* patientsByphoneAddedRequestSaga(action: PatientsAction) {
  const res: ThenReturnType<typeof api.patient.getAddedPatientsByPhone> = yield call(api.patient.getAddedPatientsByPhone)
  if (res.error) {
    yield put(patientsByphoneAddedFailure())
  } else {
    yield put(patientsByphoneAddedSuccess(res.data.users))
  }
}

export function* patientsByphoneOrderRequestSaga(action: PatientsAction) {
  const { order } = action.params as { order: number[] }
  const res: ThenReturnType<typeof api.patient.orderPatientsByPhone> = yield call(api.patient.orderPatientsByPhone, { order })
  if (res.error) {
    yield put(patientsByphoneOrderFailure())
  } else {
    yield put(patientsByphoneOrderSuccess())
    yield put(patientsByphoneMainRequest())
  }
}

export function* patientsBymistokenRequestSaga(action: PatientsAction) {
  yield put(patientsBymistokenAddedRequest())
  yield put(patientsBymistokenMainRequest())
}

export function* patientsBymistokenMainRequestSaga(action: PatientsAction) {
  const res: ThenReturnType<typeof api.patient.getPatientsByMistoken> = yield call(api.patient.getPatientsByMistoken)
  if (res.error) {
    yield put(patientsBymistokenMainFailure())
  } else {
    yield put(patientsBymistokenMainSuccess(res.data.users))
  }
}

export function* patientsBymistokenAddedRequestSaga(action: PatientsAction) {
  const res: ThenReturnType<typeof api.patient.getAddedPatientsByMistoken> = yield call(api.patient.getAddedPatientsByMistoken)
  if (res.error) {
    yield put(patientsBymistokenAddedFailure())
  } else {
    yield put(patientsBymistokenAddedSuccess(res.data.users))
  }
}

export function* patientsBymistokenOrderRequestSaga(action: PatientsAction) {
  const { order } = action.params as { order: number[] }
  const res: ThenReturnType<typeof api.patient.orderPatientsByMistoken> = yield call(api.patient.orderPatientsByMistoken, { order })
  if (res.error) {
    yield put(patientsBymistokenOrderFailure())
  } else {
    yield put(patientsBymistokenOrderSuccess())
    yield put(patientsBymistokenMainRequest())
  }
}

export function* patientBirthdateRequestSaga(action: PatientsAction) {
  const { medic_user_id } = action.params as { medic_user_id: number; }
  const res: ThenReturnType<typeof api.patient.getBirthdate> = yield call(api.patient.getBirthdate, { medic_user_id })
  if (res.error) {
    yield put(patientBirthdateFailure())
  } else {
    yield put(patientBirthdateSuccess(res.data.birthdate))
  }
}

export function* patientBirthdateChangeRequestSaga(action: PatientsAction) {
  const { medic_user_id, birthdate } = action.params as IPatientBirthdateChangeRequest
  const res: ThenReturnType<typeof api.patient.changeBirthdate> = yield call(api.patient.changeBirthdate, { medic_user_id, birthdate })
  if (res.error) {
    yield put(patientBirthdateChangeFailure())
  } else {
    yield put(patientBirthdateChangeSuccess(res.data.message))
    yield put(patientBirthdateRequest({ medic_user_id }))
  }
}

export default function* watchPatients() {
  yield takeLeading('PATIENTS_REQUEST', patientsRequestSaga)

  yield takeLeading('PATIENTS_BYPHONE_REQUEST', patientsByphoneRequestSaga)
  yield takeLeading('PATIENTS_BYMISTOKEN_REQUEST', patientsBymistokenRequestSaga)

  yield takeLeadingPattern('PATIENTS_BYPHONE_MAIN_REQUEST', patientsByphoneMainRequestSaga, patientsByphoneMainFailure)
  yield takeLeadingPattern('PATIENTS_BYPHONE_ADDED_REQUEST', patientsByphoneAddedRequestSaga, patientsByphoneAddedFailure)

  yield takeLeadingPattern('PATIENTS_BYMISTOKEN_MAIN_REQUEST', patientsBymistokenMainRequestSaga, patientsBymistokenMainFailure)
  yield takeLeadingPattern('PATIENTS_BYMISTOKEN_ADDED_REQUEST', patientsBymistokenAddedRequestSaga, patientsBymistokenAddedFailure)

  yield takeLeadingPattern('PATIENTS_BYMISTOKEN_ORDER_REQUEST', patientsBymistokenOrderRequestSaga, patientsBymistokenOrderFailure)
  yield takeLeadingPattern('PATIENTS_BYPHONE_ORDER_REQUEST', patientsByphoneOrderRequestSaga, patientsByphoneOrderFailure)

  yield takeLeadingPattern('PATIENT_BIRTHDATE_REQUEST', patientBirthdateRequestSaga, patientBirthdateFailure)
  yield takeLeadingPattern('PATIENT_BIRTHDATE_CHANGE_REQUEST', patientBirthdateChangeRequestSaga, patientBirthdateChangeFailure)
}