import React from 'react'
import { ClinicContact } from '../../../api/clinic.types'
import CopyableText from '../../CopyableText'
import parseClinicInfo from './parseClinicInfo'
import FileIcon from '../../../icons/FileIcon.png'
import JCBIcon from '../../../icons/JCBIcon.png'
import MasterCardIcon from '../../../icons/MasterCardIcon.png'
import VISAIcon from '../../../icons/VISAIcon.png'
import MIRIcon from '../../../icons/MIRIcon.png'


import './style.less'

export type ContactsProps = {
  className?: string
  addresses: ClinicContact[]
  phones: ClinicContact[]
  emails: ClinicContact[]
}

export type ItemProps = {
  name: string;
  value: string | React.ReactNode;
  className?: string;
  copyable?: boolean
  copySuccess?: string
}

export type RequisitesProps = {
  className?: string
  companyName: ClinicContact
  INN: ClinicContact
  KPP: ClinicContact
  account: ClinicContact
  bankName: ClinicContact
  RCBIC: ClinicContact
  correspondentAccount: ClinicContact
}

export type ClinicInfoProps = {
  contacts: ClinicContact[]
}

class ClinicInfo extends React.Component<ClinicInfoProps> {

  static AboutClinic: React.FC<{ className?: string }> = (props) => {
    return <ClinicInfo.Block className={props.className} title="О Клинике">
      <p><b>Сеть клиник «МЕДИК»</b> - это современные, многопрофильные клиники для взрослых и детей.</p>
      <p>Мы оказываем полный спектр медицинских услуг: профилактику, диагностику, лечение и операции.</p>
      <p>Также у нас работает стоматологическое отделение и есть дневной стационар.</p>
      <p>Наши клиники оснащены современным оборудованием, которое позволяет не только поставить верный диагноз, но и провести эффективное лечение. Этому способствует и команда сильных специалистов. Каждый из них настоящий профессионал, любящий свою работу и людей.</p>
      <p>Мы гордимся нашим детским отделением, куда дети просятся сами. И не столько из-за игровой комнаты и подарков, сколько из-за добрых и опытных врачей.</p>
      <p>Наши клиники работают без выходных, без обедов и без очередей. К нам на диагностику и лечение приезжают не только со всех уголков Чувашии из таких городов как Новочебоксарск, Канаш, Шумерля, Ядрин, Алатырь, Цивильск, Батырево, Моргауши, Порецкое, Ибреси, Мариинский Посад, но и со всей России.</p>
      <p>В наших клиниках действует постоянная система скидок. Мы любим приятно удивлять своих пациентов акциями и подарками. Обслуживаем пациентов по полисам ДМС.</p>
    </ClinicInfo.Block>
  }

  static Block: React.FC<{ className?: string, title?: string }> = (props) => {
    const { title, className } = props
    return <div className={`block ${className || ''}`}>
      <div className="title">{title || ''}</div>
      <div className="content">
        {props.children}
      </div>
    </div>
  }

  static Contacts: React.FC<ContactsProps> = (props) => {
    const { className, addresses, phones, emails } = props
    return <ClinicInfo.Block className={className} title="Контакты">
      <div className="item-container">
        {addresses.map((address, index) => <ClinicInfo.Item
          key={index}
          name={address.name}
          value={address.value}
          copyable
          copySuccess={'Адрес скопирован'}
        />)}
      </div>
      <div className="item-container">
        {phones.map((phone, index) => (<ClinicInfo.Item
          key={index}
          name="Телефон"
          value={phone.value}
          copyable
          copySuccess={'Номер скопирован'}
        />))}
        {emails.map((email, index) => (<ClinicInfo.Item
          key={index}
          name="E-mail"
          value={email.value}
          copyable
          copySuccess={'Электронная почта скопирована'}
        />))}
      </div>
    </ClinicInfo.Block>
  }

  static Item: React.FC<ItemProps> = (props) => {
    const { name, value, className, copyable, copySuccess } = props
    return <div className={`item ${className || ''}`}>
      <span className="name">{name}</span>
      <CopyableText className="value" value={value} successMessage={copySuccess} disabled={!copyable} />
    </div>
  }

  static Requisites: React.FC<RequisitesProps> = (props) => {
    const {
      className,
      companyName,
      INN,
      KPP,
      account,
      bankName,
      RCBIC,
      correspondentAccount,
    } = props
    const items = [
      INN,
      KPP,
      account,
      { ...bankName, verb: 'скопировано' },
      RCBIC,
      correspondentAccount,
    ].filter(Boolean)
    return <ClinicInfo.Block className={className} title="Реквизиты">
      <div className="item-container">
        <ClinicInfo.Item
          className="big"
          name={companyName?.name}
          value={companyName?.value}
          copyable
          copySuccess={`${companyName?.name} скопировано`}
        />
      </div>
      <div className="item-container">
        {items.map((item, index) => (<ClinicInfo.Item
          key={index}
          name={item.name}
          value={item.value}
          copyable
          // @ts-ignore
          copySuccess={`${item.name} ${item.verb || 'скопирован'}`}
        />))}
      </div>
    </ClinicInfo.Block>
  }

  static Documents: React.FC<{ className?: string }> = (props) => {
    const { className } = props
    return <ClinicInfo.Block className={className} title="Документы">
      <a href="https://mcmedic.ru/sites/default/files//lic2019new.pdf">
        <img src={FileIcon} />
        {'Лицензия'}
      </a>
      <a href="https://mcmedic.ru/sites/default/files/politika.pdf">
        <img src={FileIcon} />
        {'Политика конфиденциальности'}
      </a>
    </ClinicInfo.Block>
  }

  static Payments: React.FC<{ className?: string }> = (props) => {
    const { className } = props

    return <ClinicInfo.Block className={className} title="Способы оплаты">
      <div className="item-container">
        <ClinicInfo.Item
          className="big"
          name="Наличный расчет"
          value={<>
            {' Оплата происходит непосредственно в '}
            <b>клинике «МЕДИК»</b>
            <br />
            {' Оплата наличными денежными средствами или банковской картой через администратора в кассу. Либо самостоятельным путём через терминал оплаты установленный в клинке Медик.'}
          </>}
        />
        <ClinicInfo.Item
          className="big"
          name="Банковской картой онлайн"
          value={<>
            {'Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку '}
            <b>Оплатить</b>
            {'. Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных систем: '}
            <b>МИР, VISA International, Mastercard Worldwide, JCB</b>
          </>}
        />
      </div>
      <div className="cards">
        <img src={MIRIcon} alt="mir" />
        <img src={VISAIcon} alt="visa" />
        <img src={MasterCardIcon} alt="mastercard" />
        <img src={JCBIcon} alt="jcb" />
      </div>
    </ClinicInfo.Block>
  }

  static Refund: React.FC<{ className?: string }> = (props) => {
    const { className } = props
    return <ClinicInfo.Block className={className} title="Возврат денежных средств">
      <p>Срок возврата товара надлежащего качества составляет 30 дней с момента получения товара. Возврат переведенных средств, производится на Ваш банковский счет в течение 5—30 рабочих дней (срок зависит от банка, который выдал Вашу банковскую карту).</p>
    </ClinicInfo.Block>
  }

  static DataTransfer: React.FC<{ className?: string }> = (props) => {
    const { className } = props
    return <ClinicInfo.Block className={className} title="Процесс передачи данных">
      <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</p>
      <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем МИР, Visa Int., MasterCard Europe Sprl, JCB</p>
    </ClinicInfo.Block>
  }

  render() {
    const { contacts } = this.props
    const data = parseClinicInfo(contacts)
    return <div className="clinic-info">
      <ClinicInfo.AboutClinic />
      <ClinicInfo.Contacts addresses={data.addresses} phones={data.phones} emails={data.emails} />
      <ClinicInfo.Requisites
        companyName={data.companyName[0]}
        INN={data.INN[0]}
        KPP={data.KPP[0]}
        account={data.account[0]}
        bankName={data.bankName[0]}
        RCBIC={data.RCBIC[0]}
        correspondentAccount={data.correspondentAccount[0]}
      />
      <ClinicInfo.Documents className="documents" />
      <ClinicInfo.Payments />
      <ClinicInfo.Refund />
      <ClinicInfo.DataTransfer />
    </div>
  }
}

export default ClinicInfo