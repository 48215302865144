import React, { Component, RefObject } from 'react'
import { Button } from 'antd'
import DraggableList from 'react-draggable-list'
import { IPatient, ISortablePatient } from '../../store/types'
import './style.less'
import { PlusIcon } from '../../icons'
import PatientItem from './PatientItem'
import { Patient } from '../../api/patient.types'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { patientsByphoneOrderRequest } from '../../store/reducks/patients/patients.byphone.order.actions'

export type PatientListProps = ReduxConnectedProps & {
  onSelect: (selectedPatient: IPatient | null) => void
  onAdd?: () => void
  mainPatients: Patient[]
  addedPatients: Patient[]
  patientAllEnabled?: boolean
}

interface State {
  mainPatients: ReadonlyArray<Patient>
  selectedPatient?: Patient
}

class PatientList extends Component<PatientListProps, State> {
  private static patientAll: Patient = {
    medic_user_id: 0,
    name: 'Все пациенты',
  }

  private _container: RefObject<any> = React.createRef()

  constructor(props: PatientListProps) {
    super(props)
    this.state = {
      mainPatients: [],
    }
  }

  componentDidMount() {
    this.setState({ mainPatients: this.props.mainPatients })
  }

  private onListChange = (newList: ReadonlyArray<IPatient>) => {
    let weight = 1
    const sort: Array<ISortablePatient> = []
    newList.forEach((p) => {
      p.order = weight
      weight += 1
      sort.push({
        patient_id: `${p.medic_user_id}`,
        weight: p.order,
      })
    })
    this.setState({ mainPatients: newList })
    this.props.patientsByphoneOrderRequest(sort.map(s => +s.patient_id))
  }

  private onSelect = (patient: Patient) => {
    const { onSelect } = this.props
    onSelect && onSelect(patient)
  }

  private renderAddedPatient = (patient: Patient, index: number) => {
    const { selectedPatient } = this.state
    return <PatientItem
      key={index}
      commonProps={{
        selectedPatient: selectedPatient as Patient,
        onClick: this.onSelect,
      }}
      item={patient}
    />
  }

  render() {
    const { onAdd, addedPatients, patientAllEnabled } = this.props
    const { mainPatients, selectedPatient } = this.state
    return (
      <div className="patient-list">
        {patientAllEnabled && <PatientItem
          commonProps={{
            selectedPatient: selectedPatient as Patient,
            onClick: this.onSelect,
          }}
          item={PatientList.patientAll}
        />}
        <div ref={this._container} className="content">
          <DraggableList
            itemKey="medic_user_id"
            template={PatientItem}
            list={mainPatients}
            onMoveEnd={this.onListChange}
            container={() => this._container.current}
            constrainDrag
            padding={10}
            commonProps={{
              onClick: this.onSelect,
              selectedPatient,
            }}
          />
        </div>
        {addedPatients.length ? <div className="separator" /> : null}
        {addedPatients.map(this.renderAddedPatient)}
        {typeof onAdd === 'function' &&
          <div className="footer">
            <Button shape="circle" type="primary" size="large" icon={<PlusIcon />} onClick={onAdd} />
            <span>Добавить нового пациента</span>
          </div>
        }
      </div>
    )
  }
}

const connector = connect(
  (state: AppState) => ({
  }), {
  patientsByphoneOrderRequest,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(PatientList)
