import React from 'react'
import DoctorCardTemplate, { DoctorCardTemplateProps } from '../DoctorCardTemplate'
import RecordingDateTimes from '../RecordingDateTimes'

export type DoctorCardTimesProps = DoctorCardTemplateProps & {
  datetimes: any[]
  onDateTime?: (datetime: string) => void
  selectedDateTime?: string
}

export default class DoctorCardTimes extends React.Component<DoctorCardTimesProps> {
  render() {
    const { avatar, name, spec, datetimes, onDoctor, onInfo, onDateTime, selectedDateTime } = this.props
    return <DoctorCardTemplate
      avatar={avatar}
      name={name}
      spec={spec}
      onDoctor={onDoctor}
      onInfo={onInfo}
    >
      <RecordingDateTimes
        datetimes={datetimes}
        onDateTime={onDateTime}
        selectedDateTime={selectedDateTime}
      />
    </DoctorCardTemplate>
  }
}