import { Patient } from "../../../api/patient.types";
import capitalize from "../../../components/Recording/Stage6/capitalize";
import lazyStore from "../../lazyStore";

class PatientHelper {
  static getName(patient: Patient) {
    if (patient.medic_user_id === 0) {
      return patient.name
    }
    const names = patient.name.split(/\s+/g)
    const lastName = names[names.length - 1] as string
    return [`${lastName.slice(0, 1).toUpperCase()}.`, ...names.slice(0, -1).map(capitalize)].join(' ')
  }

  static findPatient(medic_user_id: number) {
    const { patients } = lazyStore.store.getState()
    let patient = PatientHelper.findPatientFromUsers(patients.byphone.main.users, medic_user_id)
    patient = patient || PatientHelper.findPatientFromUsers(patients.byphone.added.users, medic_user_id)
    patient = patient || PatientHelper.findPatientFromUsers(patients.bymistoken.main.users, medic_user_id)
    patient = patient || PatientHelper.findPatientFromUsers(patients.bymistoken.added.users, medic_user_id)
    return patient
  }

  private static findPatientFromUsers(users: Patient[], medic_user_id: number) {
    return users.find(PatientHelper.isPatientMatch, { medic_user_id })
  }

  private static isPatientMatch(this: { medic_user_id: number }, patient: Patient) {
    return this.medic_user_id == patient.medic_user_id
  }
}

export default PatientHelper