import api from "../../../api";
import { CatalogItem } from "../../../api/pricelist.types";
import { PricelistCatalogServicesAction } from "./pricelist.catalog_services.types";

type CatalogServicesRequestParams = Parameters<typeof api.pricelist.getCatalogServicesByPatient>[0]

export const pricelistCatalogServicesRequest = (params: CatalogServicesRequestParams): PricelistCatalogServicesAction<CatalogServicesRequestParams> => ({ type: 'PRICELIST_CATALOG_SERVICES_REQUEST', params })

export const pricelistCatalogServicesSuccess = (services: CatalogItem[]): PricelistCatalogServicesAction => ({ type: 'PRICELIST_CATALOG_SERVICES_SUCCESS', params: services })

export const pricelistCatalogServicesFailure = (error?: any) => ({ type: 'PRICELIST_CATALOG_SERVICES_FAILURE' } as const)