import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import rootSaga from './sagas/main'
import storage from './storage'

import AuthReducer from './reducks/auth/auth.reducer'
import { composeWithDevTools } from 'redux-devtools-extension'
import '../prototypes.d'
import lazyStore from './lazyStore'
import UserReducer from './reducks/user/user.reducer'
import PatientsReducer from './reducks/patients/patients.reducer'
import PricelistReducer from './reducks/pricelist/pricelist.reducer'
import RecordReducer from './reducks/record/record.reducer'
import ErrorReducer from './reducks/error/error.reducer'
import ScheduleReducer from './reducks/schedule/schedule.reducer'
import AppointmentsReducer from './reducks/appointments/appointments.reducer'
import BonusReducer from './reducks/bonus/bonus.reducer'
import RegisterReducer from './reducks/register/register.reducer'
import ModalsReducer from './reducks/modals/modals.reducer'
import AutoPaymentsReducer from './reducks/autoPayments/autoPayments.reducer'
import PaymentReducer from './reducks/payment/payment.reducer'
import MedcardReducer from './reducks/medcard/medcard.reducer'
import ResearchesReducer from './reducks/researches/researches.reducer'
import RecoveryReducer from './reducks/recovery/recovery.reducer'
import MyMedicReducer from './reducks/mymedic/mymedic.reducer'
import ClinicReducer from './reducks/clinic/clinic.reducer'
import PricesReducer from './reducks/prices/prices.reducer'
import NewsReducer from './reducks/news/news.reducer'
import ProgramsReducer from './reducks/programs/programs.reducer'
import ActionsReducer from './reducks/actions/actions.reducer'
import DoctorsReducer from './reducks/doctors/doctors.reducer'
import ArticlesReducer from './reducks/articles/articles.reducer'
import FeedBackReducer from './reducks/feedback/feedback.reducer'
import ChatReducer from './reducks/chat/chat.reducer'
import MenuReducer from './reducks/menu/menu.reducer'

const rootReducer = combineReducers({
  actions: ActionsReducer,
  appointments: AppointmentsReducer,
  articles: ArticlesReducer,
  auth: AuthReducer,
  autoPayments: AutoPaymentsReducer,
  bonus: BonusReducer,
  chat: ChatReducer,
  clinic: ClinicReducer,
  doctors: DoctorsReducer,
  error: ErrorReducer,
  feedback: FeedBackReducer,
  medcard: MedcardReducer,
  menu: MenuReducer,
  modals: ModalsReducer,
  mymedic: MyMedicReducer,
  news: NewsReducer,
  patients: PatientsReducer,
  payment: PaymentReducer,
  pricelist: PricelistReducer,
  // prices: PricesReducer,
  programs: ProgramsReducer,
  record: RecordReducer,
  recovery: RecoveryReducer,
  register: RegisterReducer,
  researches: ResearchesReducer,
  schedule: ScheduleReducer,
  user: UserReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'error',
    'patients',
    'record',
    'pricelist',
    'auth',
    'clinic',
    'schedule',
    'appointments',
    'bonus',
    'register',
    'modals',
    'autoPayments',
    'payment',
    'medcard',
    'researches',
    'recovery',
    'mymedic',
    'clinic',
    // 'prices',
    'news',
    'programs',
    'actions',
    'doctors',
    'articles',
    'feedback',
    'chat',
    'menu',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))

const persistor = persistStore(store, null, () => {
  store.getState()
})

sagaMiddleware.run(rootSaga)

lazyStore.store = store
lazyStore.persistor = persistor

export type AppState = ReturnType<typeof rootReducer>

export { persistor, store, storage }
