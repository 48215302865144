import sleep from './sleep'

const saveBase64File = async (base64: string, filename?: string) => {
  let name: string
  if (!filename) {
    const [meta] = base64.split(';base64,')
    const mime = meta.replace('data:', '')
    const ext = mime.split('/').pop()
    name = `medic_file_${Date.now()}.${ext || 'pdf'}`
  } else {
    name = filename
  }
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = base64
  a.download = name
  a.click()
  console.log(saveBase64File.name, a)
  sleep(1000)
  document.body.removeChild(a)
  return
}

export default saveBase64File