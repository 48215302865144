import React from 'react'
import { Button } from 'antd'
import { HeartIcon } from '../../icons'
import QrCodeButton from '../../images/mymedic_qr_code.svg'
import AppStoreButton from '../../images/app-store-button.svg'
import GooglePlayButton from '../../images/google-play-button.svg'
import HuaweiAppGalleryButton from '../../images/huawei-appgallery-button.svg'
import iPhone1Png from '../../images/iphone-12-1-x1.png'
import iPhone2Png from '../../images/iphone-12-2-x1.png'
import './style.less'

class TelemedicineDisabled extends React.Component {
  private renderItem = (label: string, index?: number) => {
    return <div key={index} className="item">
      <HeartIcon />
      <span>{label}</span>
    </div>
  }

  private gotoMyMedic = () => {
    window.open('https://mymedic.info/')
  }

  render() {
    return <div className="telemedicine-disabled">
      <div className="tm-top">
        <div className="tm-header">
          <span>
            {'Мы перенесли данный раздел в мобильное приложение myMedic,'}
            <br />
            {'созданное специально для проведения онлайн-консультаций'}
          </span>
        </div>
        <div className="tm-body">
          {this.renderItem('Скачайте приложение myMedic')}
          {this.renderItem('Авторизуйтесь по номеру телефона')}
          {this.renderItem('Запишитесь на онлайн-консультацию к Вашему врачу')}
          {this.renderItem('Ожидайте звонок доктора в назначенное время')}
          <Button className="mymedic-button" type="primary" onClick={this.gotoMyMedic}>{'Узнать больше'}</Button>
        </div>
      </div>
      <div className="tm-footer">
        <div className="buttons">
          <img className="qr-code" src={QrCodeButton} />
          <a href="https://apps.apple.com/ru/app/mymedic/id1546827371" target="_blank">
            <img
              className="app-button"
              src={AppStoreButton}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=ru.mcmedic.telemedicine.android" target="_blank">
            <img
              className="app-button"
              src={GooglePlayButton}
            />
          </a>
          <a href="https://appgallery.huawei.com/#/app/C103790171?appId=C103790171">
            <img
              className="app-button"
              src={HuaweiAppGalleryButton}
            />
          </a>
        </div>
        <div className="iphone">
          <img src={iPhone1Png} />
          <img src={iPhone2Png} />
        </div>
      </div>
    </div>
  }
}

export default TelemedicineDisabled