import React from 'react'
import { Card, List } from 'antd'
import DoctorCardMain from '../DoctorCardMain'
import { DoctorPreview } from '../../api/doctor.types'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { doctorByEmployerIdRequestAction, doctorByIdRequestAction } from '../../store/reducks/doctors/doctors.item.actions'
import { doctorInfoModalSetAction } from '../../store/reducks/modals/modals.doctorInfo.actions'

export type RecordingDoctorsProps = ReduxConnectedProps & {
  doctors: DoctorPreview[]
  onDoctor?: (doctor: DoctorPreview) => void
  onChoose?: (doctor: DoctorPreview) => void
  onChange?: () => void
  chosenDoctor?: DoctorPreview
}

export type RecordingDoctorsState = {
}

class RecordingDoctors extends React.Component<RecordingDoctorsProps, RecordingDoctorsState> {
  constructor(props: RecordingDoctorsProps) {
    super(props)
    this.state = {}
  }

  private onDoctorInfo = (doctor: DoctorPreview) => {
    const { onDoctor } = this.props
    onDoctor && onDoctor(doctor)
    if (doctor.doctor_employer_id) {
      this.props.doctorByEmployerIdRequestAction({ employer_id: doctor.doctor_employer_id })
    } else {
      this.props.doctorByEmployerIdRequestAction({ employer_id: doctor.doctor_id })
    }
    this.props.doctorInfoModalSetAction({ visible: true })
  }

  private onChoose = (doctor: DoctorPreview) => {
    const { onChoose } = this.props
    onChoose && onChoose(doctor)
  }

  private onChange = () => {
    const { onChange } = this.props
    onChange && onChange()
  }

  render() {
    const { doctors, chosenDoctor } = this.props
    return <Card
      className="recording-doctors__card"
    >
      {chosenDoctor ? (<DoctorCardMain
        avatar={chosenDoctor.doctor_preview_img}
        name={chosenDoctor.doctor_name}
        spec={chosenDoctor.service_name}
        onDoctor={() => this.onDoctorInfo(chosenDoctor)}
        onInfo={() => this.onDoctorInfo(chosenDoctor)}
        buttonText="Изменить"
        onClick={this.onChange}
      />) : (<List
        dataSource={doctors}
        renderItem={(doctor, index) => (
          <DoctorCardMain
            key={index}
            avatar={doctor.doctor_preview_img}
            name={doctor.doctor_name}
            spec={doctor.service_name}
            onDoctor={() => this.onDoctorInfo(doctor)}
            onInfo={() => this.onDoctorInfo(doctor)}
            buttonText="Выбрать"
            onClick={() => this.onChoose(doctor)}
          />
        )}
      />)}
    </Card>
  }
}

const connector = connect(
  (state: AppState) => ({

  }), {
  doctorByIdRequestAction,
  doctorByEmployerIdRequestAction,
  doctorInfoModalSetAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(RecordingDoctors)