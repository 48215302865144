import { DeletePaidAppointmentAction, DeletePaidAppointmentState } from "./appointments.delete.paid.types"

export const initialDeletePaidAppointmentState: DeletePaidAppointmentState = {
  fetching: false,
}

const DeletePaidAppointmentReducer = (state = initialDeletePaidAppointmentState, action: DeletePaidAppointmentAction): DeletePaidAppointmentState => {
  switch (action.type) {
    case 'DELETE_PAID_APPOINTMENT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DELETE_PAID_APPOINTMENT_SUCCESS':
      return {
        ...state,
        fetching: false,
      }
    case 'DELETE_PAID_APPOINTMENT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDeletePaidAppointmentState,
      }
    default:
      return state
  }
}

export default DeletePaidAppointmentReducer