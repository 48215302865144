import React, { Component } from 'react'
import Template from '../../components/Template'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import './style.less'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps

interface Props extends RouteComponentProps, DispatchProps, StateProps {}

interface State {}

class HomeView extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return <Template className="home" title="Главная" currentItem="home">
      <Redirect to="/clinic/actions" />
    </Template>
  }
}

const mapStateToProps = (state: AppState) => ({
  error: state.error,
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeView))
