import { RegisterNewPatientForAppointmentParams, RegisterPatientForAppointmentParams, RegisterPatientForAppointmentResponse } from "../../../api/register.types";
import { RegisterAppointmentAction } from "./register.appointment.types";

export const registerAppointmentMainRequestAction = (params: RegisterPatientForAppointmentParams): RegisterAppointmentAction => ({ type: 'REGISTER_APPOINTMENT_MAIN_REQUEST', params })

export const registerAppointmentMainSuccessAction = (appointment: RegisterPatientForAppointmentResponse): RegisterAppointmentAction => ({ type: 'REGISTER_APPOINTMENT_MAIN_SUCCESS', params: appointment })

export const registerAppointmentMainFailureAction = (error?: any) => ({ type: 'REGISTER_APPOINTMENT_MAIN_FAILURE' } as const)

export const registerAppointmentByPhoneRequestAction = (params: RegisterNewPatientForAppointmentParams): RegisterAppointmentAction => ({ type: 'REGISTER_APPOINTMENT_BY_PHONE_REQUEST', params })

export const registerAppointmentByPhoneSuccessAction = (appointment: RegisterPatientForAppointmentResponse): RegisterAppointmentAction => ({ type: 'REGISTER_APPOINTMENT_BY_PHONE_SUCCESS', params: appointment })

export const registerAppointmentByPhoneFailureAction = (error?: any) => ({ type: 'REGISTER_APPOINTMENT_BY_PHONE_FAILURE' } as const)

export const registerAppointmentByMisTokenRequestAction = (params: RegisterNewPatientForAppointmentParams): RegisterAppointmentAction => ({ type: 'REGISTER_APPOINTMENT_BY_MISTOKEN_REQUEST', params })

export const registerAppointmentByMisTokenSuccessAction = (appointment: RegisterPatientForAppointmentResponse): RegisterAppointmentAction => ({ type: 'REGISTER_APPOINTMENT_BY_MISTOKEN_SUCCESS', params: appointment })

export const registerAppointmentByMisTokenFailureAction = (error?: any) => ({ type: 'REGISTER_APPOINTMENT_BY_MISTOKEN_FAILURE' } as const)