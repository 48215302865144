import { DoctorRatingState, DoctorRatingAction } from './doctor.rating.types'
export const initialDoctorRatingState: DoctorRatingState = {
  fetching: false,
}
const DoctorRatingReducer = (state = initialDoctorRatingState, action: DoctorRatingAction): DoctorRatingState => {
  switch (action.type) {
    case 'DOCTOR_RATING_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DOCTOR_RATING_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: action.params,
      }
    case 'DOCTOR_RATING_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorRatingState
      }
    default:
      return state
  }
}
export default DoctorRatingReducer