import { DoctorsItemState, DoctorsItemAction } from './doctors.item.types'

export const initialDoctorsItemState: DoctorsItemState = {
  fetching: false,
}

const DoctorsItemReducer = (state = initialDoctorsItemState, action: DoctorsItemAction): DoctorsItemState => {
  switch (action.type) {
    case 'DOCTOR_BY_ID_REQUEST':
    case 'DOCTOR_BY_EMPLOYERID_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        doctor: undefined,
      }
    case 'DOCTOR_BY_ID_SUCCESS':
    case 'DOCTOR_BY_EMPLOYERID_SUCCESS':
      return {
        ...state,
        fetching: false,
        doctor: action.params,
      }
    case 'DOCTOR_BY_ID_FAILURE':
    case 'DOCTOR_BY_EMPLOYERID_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorsItemState
      }
    default:
      return state
  }
}
export default DoctorsItemReducer