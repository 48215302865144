import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { ResearchFile, ResearchItem, ResearchNew } from "./research.types";

class ResearchAPI {
  private static GET_MEDCARD_RESEARCHES = '/get_medcard_researches' as const
  private static GET_MEDCARD_RESEARCHES_ALL = '/get_medcard_researches_by_token' as const

  private static GET_MEDCARD_RESEARCH_FILE = '/get_medcard_research' as const
  private static GET_MEDCARD_RESEARCHES_NEW = '/get_medcard_researches_new' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(ResearchAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && res.url == ResearchAPI.GET_MEDCARD_RESEARCHES_NEW) {
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { researches: res.data.researches_new } as T
      } as const
    }
    if (res.error && res.url == ResearchAPI.GET_MEDCARD_RESEARCHES && res.data.message == 'researches not found') {
      return {
        ...res,
        error: false,
        status: 200,
        // @ts-ignore
        data: { researches: [] } as T,
      } as const
    }
    return res
  }

  getMedcardResearches = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ researches: ResearchItem[] }, { message: string }>({
      method: 'get',
      url: ResearchAPI.GET_MEDCARD_RESEARCHES,
      params,
    })
    return res
  }
  getMedcardResearchesAll = async () => {
    const res = await this.request<{ researches: ResearchItem[] }, { message: string }>({
      method: 'get',
      url: ResearchAPI.GET_MEDCARD_RESEARCHES_ALL,
    })
    return res
  }
  getMedcardResearchesNew = async () => {
    const res = await this.request<{ researches: ResearchNew[] }, { message: string }>({
      method: 'get',
      url: ResearchAPI.GET_MEDCARD_RESEARCHES_NEW,
    })
    return res
  }

  getMedcardResearchFile = async (params: { research_id: number }) => {
    const res = await this.request<ResearchFile, { message: string }>({
      method: 'get',
      url: ResearchAPI.GET_MEDCARD_RESEARCH_FILE,
      params,
    })
    return res
  }
}

export default ResearchAPI