import { Patient } from "../../../api/patient.types"
import { AppointmentsViewAction, AppointmentsViewState } from "./appointments.view.types"

export const initialAppointmentsPatientState: AppointmentsViewState = {
  patient: {},
  activeTab: '0',
}

const AppointmentsViewReducer = (state = initialAppointmentsPatientState, action: AppointmentsViewAction): AppointmentsViewState => {
  switch (action.type) {
    case 'APPOINTMENTS_VIEW_PATIENT_SET':
      return {
        ...state,
        patient: action.params,
      }
    case 'APPOINTMENTS_VIEW_PATIENT_CLEAR':
      return {
        ...state,
        patient: {},
      }
    case 'APPOINTMENTS_VIEW_ACTIVE_TAB_SET':
      return {
        ...state,
        activeTab: action.params,
      }
    case 'APPOINTMENTS_VIEW_ACTIVE_TAB_CLEAR':
      return {
        ...state,
        activeTab: initialAppointmentsPatientState.activeTab,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAppointmentsPatientState,
      }
    default:
      return state
  }
}

export default AppointmentsViewReducer