import { IPricelistCatalog, IPricelistService, IPatient } from '../types'

export const PRICELIST_CATALOGS_GET = 'PRICELIST_CATALOGS_GET'
export const PRICELIST_CATALOGS_SET = 'PRICELIST_CATALOGS_SET'
export const PRICELIST_CATALOG_SERVICES_GET = 'PRICELIST_CATALOG_SERVICES_GET'
export const PRICELIST_CATALOG_SERVICES_SET = 'PRICELIST_CATALOG_SERVICES_SET'

export const pricelistCatalogsGetAction = () => ({ type: PRICELIST_CATALOGS_GET })
export const pricelistCatalogsSetAction = (catalogs: IPricelistCatalog[]) => ({
  type: PRICELIST_CATALOGS_SET,
  catalogs,
})
export const pricelistCatalogServivesGetAction = (params: { catalog: IPricelistCatalog; patient: IPatient }) => ({
  type: PRICELIST_CATALOG_SERVICES_GET,
  params,
})
export const pricelistCatalogServivesSetAction = (catalog_services: IPricelistCatalog[]) => ({
  type: PRICELIST_CATALOG_SERVICES_SET,
  catalog_services,
})

export interface PricelistState {
  catalogs: IPricelistCatalog[]
  catalog_services: IPricelistService[]
  fetching: boolean
}

const initialState: PricelistState = {
  catalogs: [],
  catalog_services: [],
  fetching: false,
}

const PricelistReducer = (state = initialState, action: any) => {
  const { type, catalogs, catalog_services } = action
  switch (type) {
    case PRICELIST_CATALOGS_GET:
      return { ...initialState, fetching: true }
    case PRICELIST_CATALOGS_SET:
      return { ...state, catalogs, fetching: false }
    case PRICELIST_CATALOG_SERVICES_GET:
      return { ...state, fetching: true }
    case PRICELIST_CATALOG_SERVICES_SET:
      return {
        ...state,
        catalog_services,
        fetching: false,
      }
    default:
      return state
  }
}

export default PricelistReducer
