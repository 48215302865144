import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { MyMedicApp } from "./mymedic.types";

class MyMedicAPI {
  private static GET_MYMEDIC_APP = '/get_himedic_app' as const
  private static IS_TM_DISABLED = '/is_tm_disabled' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(MyMedicAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    // @ts-ignore
    if (res.url == MyMedicAPI.GET_MYMEDIC_APP && !res.error && (!res.data || res.data.success)) {
      return {
        ...res,
        error: false as const,
        status: 200,
        // @ts-ignore
        data: { ...res.data.data } as T
      }
    }
    // @ts-ignore
    if (res.url == MyMedicAPI.GET_MYMEDIC_APP && (res.error || (res.data && !res.data.success))) {
      let message: string = 'error'
      // @ts-ignore
      if (res.data && res.data.error) {
        // @ts-ignore
        if (typeof res.data.error == 'string') {
          // @ts-ignore
          ({ error: message } = res.data)
          // @ts-ignore
        } else if (res.data.error.message) {
          // @ts-ignore
          ({ message } = res.data.error)
        }
      }
      return {
        ...res,
        error: true as const,
        status: res.status == 200 ? 400 : res.status,
        data: { message } as U,
      }
    }
    return res
  }

  getApp = async () => {
    const res = await this.request<MyMedicApp, { message: string }>({
      method: 'get',
      url: MyMedicAPI.GET_MYMEDIC_APP,
    })
    return res
  }

  isTMDisabled = async () => {
    const res = await this.request<{ disabled: boolean }, { message: string }>({
      method: 'get',
      url: MyMedicAPI.IS_TM_DISABLED,
    })
    return res
  }
}

export default MyMedicAPI