import moment from 'moment'
import 'moment/locale/ru'

export const birthdateValidation = (date: string, dateFormat: string = 'DD.MM.YYYY') => {
  const dates = {
    birthdate: moment(date, dateFormat),
    now: moment(Date.now()),
    from: moment('01-01-1900', 'MM-DD-YYYY'),
  }
  const formattedDate = date.replace(/[._]/g, '')

  if (formattedDate.length != 8 || dates.birthdate.invalidAt() !== -1 || dates.birthdate > dates.now || dates.birthdate < dates.from) {
    return 'Недопустимое значение "Даты рождения"'
  }
  return ''
}

export const phoneValidation = (phone: string) => {
  if (phone[0] !== '9') return 'Недопустимый номер "Телефона"'
  if (phone.length !== 10) return 'Телефон должен состоять только из 10 цифр'
  return ''
}

export const inputValidation = (value: string, field: string) => {
  if (value.length < 2) return `Поле "${field}" должно содержать не менее 2 символов`
  if (!/^[а-яёА-ЯЁ]+$/.test(value)) return `Поле "${field}" должно содержать символы кириллицы`
  return ''
}

export const inputPasswordValidation = (value: string, field: string) => {
	if(value.length < 6){
		return  `Поле "${field}" должно содержать не меньше 6 символов`
	}
	return ''
}