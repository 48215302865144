import { Department } from "../../../api/clinic.types"
import { ClinicDeparmentsAction, ClinicDeparmentsState } from "./clinic.departments.types"

export const initialClinicDeparmentsState: ClinicDeparmentsState = {
  fetching: false,
  deparments: [],
}

const ClinicDeparmentsReducer = (state = initialClinicDeparmentsState, action: ClinicDeparmentsAction): ClinicDeparmentsState => {
  switch (action.type) {
    case 'CLINIC_DEPARMENTS_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'CLINIC_DEPARMENTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        deparments: action.params as Department[],
      }
    case 'CLINIC_DEPARMENTS_FAILURE':
      return {
        ...state,
        fetching: false,
      }
    default:
      return state
  }
}

export default ClinicDeparmentsReducer