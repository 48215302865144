import React from 'react'
import moment from 'moment'
import { ChatMessage } from '../../api/chat.types'
import './style.less'
import MessageText from './MessageText'

export type MessageItemProps = {
  message: ChatMessage
  className?: string
}

export type MessageListProps = {
  messages: ChatMessage[]
}

export default class MessageList extends React.Component<MessageListProps> {
  static Item: React.FC<MessageItemProps> = (props) => {
    const { className, message } = props
    const my = !message.operator_name
    return <div className={`message-item ${className || ''} ${my ? 'my' : 'operator'}`}>
      {message.operator_name ? (<div className="name">
        {'Оператор ' + message.operator_name}
      </div>) : null}
      <div className="text">
        <MessageText text={message.body} />
      </div>
	    {message.created != "" ? <div className="date">
		    {moment(message.created).format('HH:mm')}
	    </div> : null}
    
    </div>
  }
	
	private renderDate = (messageIndex: number) => {
		const { messages } = this.props
    const current = messages[messageIndex]
    if (!current) {
      return null
    }
    const prev = messages[messageIndex - 1]
    if (!prev) {
      return null
    }
    const diff = moment(current.created).diff(prev.created, 'day')
    if (diff == 0) {
      return null
    }
    return <div className="day-header" key={messageIndex}>
      {moment(current.created).format('DD MMMM')}
    </div>
  }

  private renderItem = (message: ChatMessage, index: number) => {
    return <>
      {this.renderDate(index)}
      <MessageList.Item
        key={index}
        message={message}
      />
    </>
  }

  render() {
    const { messages } = this.props
    return <div className="message-list">
      {messages.map(this.renderItem)}
    </div>
  }
}