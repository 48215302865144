import lazyStore from '../store/lazyStore'
import { errorSetAction } from '../store/reducks/error/error.actions'
import API, { RequestOptions, RequestReturnType } from './api'
import { formatToLkDateTime } from './utils/formatToLkDateTime'
import { RegisterInfo, RegisterInfoRequestParams, RegisterNewPatientForAppointmentParams, RegisterPatientForAppointmentParams, RegisterPatientForAppointmentResponse } from './register.types'

class RegisterAPI {
  private static REGISTER_PATIENT_FOR_APPOINTMENT = '/register_doctor' as const
  private static REGISTER_NEW_PATIENT_FOR_APPOINTMENT_BY_MISTOKEN = '/register_another_user' as const
  private static REGISTER_NEW_PATIENT_FOR_APPOINTMENT_BY_PHONE = '/register_another_user_by_phone' as const

  private static GET_REGISTER_INFO_BY_PATIENT = '/get_register_info_by_service' as const
  private static GET_REGISTER_INFO_BY_NEW_PATIENT = '/get_register_info_by_service_without_user' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(RegisterAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    return res
  }

  registerPatientForAppointment = async (params: RegisterPatientForAppointmentParams) => {
    const res = await this.request<RegisterPatientForAppointmentResponse, { message: string }>({
      method: 'get',
      url: RegisterAPI.REGISTER_PATIENT_FOR_APPOINTMENT,
      params: {
        ...params,
        doctor_date_time: formatToLkDateTime(params.doctor_date_time),
      },
    })
    return res
  }
  registerNewPatientForAppointmentByPhone = async (params: RegisterNewPatientForAppointmentParams) => {
    const res = await this.request<RegisterPatientForAppointmentResponse, { message: string }>({
      method: 'get',
      url: RegisterAPI.REGISTER_NEW_PATIENT_FOR_APPOINTMENT_BY_PHONE,
      params: {
        ...params,
        rec_date: formatToLkDateTime(params.rec_date),
      },
    })
    return res
  }
  registerNewPatientForAppointmentByMisToken = async (params: RegisterNewPatientForAppointmentParams) => {
    const res = await this.request<RegisterPatientForAppointmentResponse, { message: string }>({
      method: 'get',
      url: RegisterAPI.REGISTER_NEW_PATIENT_FOR_APPOINTMENT_BY_MISTOKEN,
      params: {
        ...params,
        rec_date: formatToLkDateTime(params.rec_date),
      },
    })
    return res
  }

  getRegisterInfoByPatient = async (params: RegisterInfoRequestParams & { medic_user_id: number }) => {
    const res = await this.request<{ register_info: RegisterInfo }, { message: string }>({
      method: 'get',
      url: RegisterAPI.GET_REGISTER_INFO_BY_PATIENT,
      params: {
        ...params,
        rec_date: formatToLkDateTime(params.rec_date),
      },
    })
    return res
  }
  getRegisterInfoByNewPatient = async (params: RegisterInfoRequestParams & { sex: 0 | 1; birthdate: string; }) => {
    const res = await this.request<{ register_info: RegisterInfo }, { message: string }>({
      method: 'get',
      url: RegisterAPI.GET_REGISTER_INFO_BY_NEW_PATIENT,
      params: {
        ...params,
        rec_date: formatToLkDateTime(params.rec_date),
      },
    })
    return res
  }
}

export default RegisterAPI