import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Modal from '../../components/Modal'
import CombinedAppointments from '../../components/CombinedAppointments'
import { AppState } from '../../store'
import { combinedAppointmentsModalCloseAction } from '../../store/reducks/modals/modals.combinedAppointments.actions'
import './style.less'

export type CombinedAppointmentsModalProps = ReduxConnectedProps & {}

class CombinedAppointmentsModal extends React.Component<CombinedAppointmentsModalProps> {
  private onClose = () => {
    const { modal } = this.props
    modal.onClose && modal.onClose()
    this.props.combinedAppointmentsModalCloseAction()
  }

  private onPay = () => {
    const { modal } = this.props
    modal.onPay && modal.onPay()
  }

  render() {
    const { modal, combined } = this.props
    return <Modal
      visible={modal.visible}
      title="Перечень услуг"
      onCancel={this.onClose}
    >
      <CombinedAppointments
        onPay={this.onPay}
        is_partial={combined.is_partial}
        appointments={combined.appointments}
      />
    </Modal>
  }
}

const connector = connect(
  (state: AppState) => ({
    modal: state.modals.combinedAppointments,
    combined: state.payment.combinedAppointments,
  }), {
    combinedAppointmentsModalCloseAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(CombinedAppointmentsModal)