import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import {
  registerAppointmentByMisTokenFailureAction,
  registerAppointmentByMisTokenSuccessAction,
  registerAppointmentByPhoneFailureAction,
  registerAppointmentByPhoneSuccessAction,
  registerAppointmentMainFailureAction,
  registerAppointmentMainSuccessAction,
} from "./register.appointment.actions";
import { registerInfoForNewPatientFailureAction, registerInfoForPatientFailureAction, registerInfoForPatientSuccessAction } from "./register.info.actions";
import { RegisterAction } from "./register.types";


export function* registerPatientForAppointmentSaga(action: RegisterAction) {
  const res: ThenReturnType<typeof api.register.registerPatientForAppointment> = yield call(api.register.registerPatientForAppointment, action.params)
  if (res.error) {
    yield put(registerAppointmentMainFailureAction())
  } else {
    yield put(registerAppointmentMainSuccessAction(res.data))
  }
}

export function* registerNewPatientFroAppointmentByPhoneSaga(action: RegisterAction) {
  const res: ThenReturnType<typeof api.register.registerNewPatientForAppointmentByPhone> = yield call(api.register.registerNewPatientForAppointmentByPhone, action.params)
  if (res.error) {
    yield put(registerAppointmentByPhoneFailureAction())
  } else {
    yield put(registerAppointmentByPhoneSuccessAction(res.data))
  }
}

export function* registerNewPatientFroAppointmentByMistokenSaga(action: RegisterAction) {
  const res: ThenReturnType<typeof api.register.registerNewPatientForAppointmentByMisToken> = yield call(api.register.registerNewPatientForAppointmentByMisToken, action.params)
  if (res.error) {
    yield put(registerAppointmentByMisTokenFailureAction())
  } else {
    yield put(registerAppointmentByMisTokenSuccessAction(res.data))
  }
}

export function* registerInfoForPatientRequestSaga(action: RegisterAction) {
  const res: ThenReturnType<typeof api.register.getRegisterInfoByPatient> = yield call(api.register.getRegisterInfoByPatient, action.params)
  if (res.error) {
    yield put(registerInfoForPatientFailureAction())
  } else {
    yield put(registerInfoForPatientSuccessAction(res.data.register_info))
  }
}

export function* registerInfoForNewPatientRequestSaga(action: RegisterAction) {
  const res: ThenReturnType<typeof api.register.getRegisterInfoByNewPatient> = yield call(api.register.getRegisterInfoByNewPatient, action.params)
  if (res.error) {
    yield put(registerInfoForNewPatientFailureAction())
  } else {
    yield put(registerInfoForPatientSuccessAction(res.data.register_info))
  }
}

export default function* watchRegister() {
  yield takeLeadingPattern<RegisterAction['type']>('REGISTER_APPOINTMENT_MAIN_REQUEST', registerPatientForAppointmentSaga, registerAppointmentMainFailureAction)
  yield takeLeadingPattern<RegisterAction['type']>('REGISTER_APPOINTMENT_BY_PHONE_REQUEST', registerNewPatientFroAppointmentByPhoneSaga, registerAppointmentByPhoneFailureAction)
  yield takeLeadingPattern<RegisterAction['type']>('REGISTER_APPOINTMENT_BY_MISTOKEN_REQUEST', registerNewPatientFroAppointmentByMistokenSaga, registerAppointmentByMisTokenFailureAction)

  yield takeLeadingPattern<RegisterAction['type']>('REGISTER_INFO_FOR_PATIENT_REQUEST', registerInfoForPatientRequestSaga, registerInfoForPatientFailureAction)
  yield takeLeadingPattern<RegisterAction['type']>('REGISTER_INFO_FOR_NEW_PATIENT_REQUEST', registerInfoForNewPatientRequestSaga, registerInfoForNewPatientFailureAction)
}