import React from 'react'
import toast from 'react-hot-toast'
import ToastBody from '../components/ToastBody'
import throttle from 'lodash/throttle'

const funcs: { [message: string]: () => void } = {}

const showToast = (message: string) => {
  if (funcs[message]) {
    return funcs[message]()
  }
  funcs[message] = throttle(() => {
    const id = `${Math.floor(Math.random() * 1e9)}`
    toast(<ToastBody
      message={message}
      onCancel={() => toast.dismiss(id)}
    />, {
      id,
      duration: 4000,
      position: 'bottom-right',
      className: 'toast-container',
    })
  }, 4000, { leading: true, trailing: false });
  return funcs[message]()
}

export default showToast