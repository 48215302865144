import React from 'react'
import ArrowIcon from '@material-ui/icons/ArrowForwardIos'
import './style.less'

export type SimpleListItem = {
  id?: string | number
  name: string
  onClick: () => void
  className?: string
}
export type SimpleListProps = {
  items: SimpleListItem[]
  className?: string
  title?: string
}

class SimpleList extends React.Component<SimpleListProps> {
  static Item: React.FC<SimpleListItem> = (props) => {
    const { name, onClick, className } = props
    return <div className={`item ${className || ''}`} onClick={onClick}>
      <span>{name}</span>
      <div className="icon"><ArrowIcon /></div>
    </div>
  }

  private renderItem = (item: SimpleListItem, index: number) => {
    return <SimpleList.Item
      key={index}
      {...item}
    />
  }

  render() {
    const { items, className, title } = this.props
    return <div className={`settings-list ${className || ''}`}>
      {!!title && <div className='settings-header'>{title}</div>}
      <div className='settings-items'>
        {items.map(this.renderItem)}
      </div>
    </div>
  }
}

export default SimpleList