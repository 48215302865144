import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { Patient } from "./patient.types";

class PatientAPI {
  private static GET_BIRTHDATE = '/get_user_birthdate' as const
  private static CHANGE_BIRTHDATE = '/post_change_user_birthdate' as const

  private static FIND_PATIENT = '/post_find_user_strict' as const
  private static FIND_PATIENT_BY_PHONE = '/post_find_user_by_phone' as const
  private static DELETE_PATIENT_BY_MISTOKEN = '/post_del_user_relation' as const
  private static DELETE_PATIENT_BY_PHONE = '/post_del_user_relation_by_phone' as const
  
  private static GET_ADDED_PATIENTS = '/get_another_users' as const
  private static GET_ADDED_PATIENTS_BY_PHONE = '/get_another_users_by_phone' as const
  private static GET_PATIENTS_BY_PHONE = '/get_users_by_phone' as const
  private static GET_PATIENTS_BY_MISTOKEN = '/get_users_by_mis_token' as const

  private static ORDER_PATIENTS_BY_PHONE = '/post_users_by_phone_order' as const
  private static ORDER_PATIENTS_BY_MISTOKEN = '/post_users_by_mis_token_order' as const

  private static REGISTER_PATIENT = '/post_questionnaire' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(PatientAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    // это обертка для "неправильного" ответа с сервера МИС
    if (res.error && res.status == 400 && res.data.message == 'users not found!') {
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { users: [] } as T,
        status: 200,
      } as const
    }
    return res
  }

  getBirthdate = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ birthdate: string }, { message: string }>({
      method: 'get',
      url: PatientAPI.GET_BIRTHDATE,
      params,
    })
    return res
  }
  changeBirthdate = async (data: { medic_user_id: number; birthdate: string }) => {
    const res = await this.request<{ message: string }>({
      method: 'post',
      url: PatientAPI.CHANGE_BIRTHDATE,
      data,
    })
    return res
  }

  findStrict = async (data: { lastname: string; firstname: string; midname: string; birthdate: string; phone: string }) => {
    const res = await this.request<{ found: boolean }, { message: string }>({
      method: 'post',
      url: PatientAPI.FIND_PATIENT,
      data,
    })
    return res
  }
  findByPhone = async (data: { phone: string }) => {
    const res = await this.request<{ found: boolean }, { message: string }>({
      method: 'post',
      url: PatientAPI.FIND_PATIENT_BY_PHONE,
      data,
    })
    return res
  }

  deleteByMistoken = async (data: { medic_user_id: number; with_appointments: 0 | 1; relation?: 'phone' | 'medic_user_id' }) => {
    const res = await this.request<{ message: 'Successfull' }, { message: string }>({
      method: 'post',
      url: PatientAPI.DELETE_PATIENT_BY_MISTOKEN,
      data,
    })
    return res
  }
  deleteByPhone = async (data: { medic_user_id: number; with_appointments: 0 | 1; relation?: 'phone' | 'medic_user_id' }) => {
    const res = await this.request<{ message: 'Successfull' }, { message: string }>({
      method: 'post',
      url: PatientAPI.DELETE_PATIENT_BY_PHONE,
      data,
    })
    return res
  }

  getAddedPatientsByMistoken = async () => {
    const res = await this.request<{ users: Patient[] }, { message: string }>({
      method: 'get',
      url: PatientAPI.GET_ADDED_PATIENTS,
    })
    return res
  }
  getAddedPatientsByPhone = async () => {
    const res = await this.request<{ users: Patient[] }, { message: string }>({
      method: 'get',
      url: PatientAPI.GET_ADDED_PATIENTS_BY_PHONE,
    })
    return res
  }

  getPatientsByPhone = async () => {
    const res = await this.request<{ users: Patient[] }, { message: string }>({
      method: 'get',
      url: PatientAPI.GET_PATIENTS_BY_PHONE,
    })
    return res
  }
  getPatientsByMistoken = async () => {
    const res = await this.request<{ users: Patient[] }, { message: string }>({
      method: 'get',
      url: PatientAPI.GET_PATIENTS_BY_MISTOKEN,
    })
    return res
  }

  orderPatientsByPhone = async (data: { order: number[] }) => {
    const res = await this.request<{ message: string }>({
      method: 'post',
      url: PatientAPI.ORDER_PATIENTS_BY_PHONE,
      data,
    })
    return res
  }
  orderPatientsByMistoken = async (data: { order: number[] }) => {
    const res = await this.request<{ message: string }>({
      method: 'post',
      url: PatientAPI.ORDER_PATIENTS_BY_MISTOKEN,
      data,
    })
    return res
  }

  registerPatient = async (data: { surname: string; name: string; midname: string; birthdate: string; sex: 'M' | 'W'; }) => {
    const res = await this.request<{ medic_user_id: number }, { message: string }>({
      method: 'post',
      url: PatientAPI.REGISTER_PATIENT,
      data,
    })
    return res
  }
}

export default PatientAPI