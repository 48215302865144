import { PastAppointmentsMainAction, PastAppointmentsMainState } from "./appointments.past.types"

export const initialPastAppointmentsMainState: PastAppointmentsMainState = {
  fetching: false,
  appointments: [],
}

const PastAppointmentsMainReducer = (state = initialPastAppointmentsMainState, action: PastAppointmentsMainAction): PastAppointmentsMainState => {
  switch (action.type) {
    case 'PAST_APPOINTMENTS_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'PAST_APPOINTMENTS_MAIN_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: action.params,
      }
    case 'PAST_APPOINTMENTS_MAIN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPastAppointmentsMainState,
      }
    default:
      return state
  }
}

export default PastAppointmentsMainReducer