import React, { Component, Key } from 'react'

interface Props {}

class TemplateSidebar extends Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const { children } = this.props
    return <div className="template__sidebar">{children}</div>
  }
}

export default TemplateSidebar
