import { NewsItemAction, NewsItemState } from "./news.item.types"

export const initialNewsItemState: NewsItemState = {
  fetching: false,
}

const NewsItemReducer = (state = initialNewsItemState, action: NewsItemAction): NewsItemState => {
  switch (action.type) {
    case 'NEWS_ITEM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        item: undefined,
      }
    case 'NEWS_ITEM_SUCCESS':
      return {
        ...state,
        fetching: false,
        item: action.params,
      }
    case 'NEWS_ITEM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialNewsItemState,
      }
    default:
      return state
  }
}

export default NewsItemReducer