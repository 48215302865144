import { RegisterInfo, RegisterInfoRequestParams } from "../../../api/register.types";
import { RegisterInfoAction } from "./register.info.types";

export const registerInfoForPatientRequestAction = (params: RegisterInfoRequestParams & { medic_user_id: number }): RegisterInfoAction => ({ type: 'REGISTER_INFO_FOR_PATIENT_REQUEST', params })

export const registerInfoForPatientSuccessAction = (registerInfo: RegisterInfo): RegisterInfoAction => ({ type: 'REGISTER_INFO_FOR_PATIENT_SUCCESS', params: registerInfo })

export const registerInfoForPatientFailureAction = (error?: any) => ({ type: 'REGISTER_INFO_FOR_PATIENT_FAILURE' } as const)

export const registerInfoForNewPatientRequestAction = (params: RegisterInfoRequestParams & { sex: 0 | 1; birthdate: string; }): RegisterInfoAction => ({ type: 'REGISTER_INFO_FOR_NEW_PATIENT_REQUEST', params })

export const registerInfoForNewPatientSuccessAction = (registerInfo: RegisterInfo): RegisterInfoAction => ({ type: 'REGISTER_INFO_FOR_NEW_PATIENT_SUCCESS', params: registerInfo })

export const registerInfoForNewPatientFailureAction = (error?: any) => ({ type: 'REGISTER_INFO_FOR_NEW_PATIENT_FAILURE' } as const)