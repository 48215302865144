import { combineReducers } from 'redux'
import PaymentAppointmentTypeReducer from './payment.appointmentType.reducer'
import PaymentCombinedAppointmentsReducer from './payment.combinedAppointments.reducer'
import PaymentParamsReducer from './payment.params.reducer'

const PaymentReducer = combineReducers({
  params: PaymentParamsReducer,
  appointmentType: PaymentAppointmentTypeReducer,
  combinedAppointments: PaymentCombinedAppointmentsReducer,
})

export default PaymentReducer