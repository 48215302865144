import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '../../icons/search_icon.svg'
import './style.less'
import TextInput, { TextInputProps } from '../TextInput'

export type SearchInputProps = TextInputProps

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const { className, ...restProps } = props
  return <TextInput
    className={`search-input ${className || ''}`}
    InputProps={{
      startAdornment: (<InputAdornment position="start">
        <img src={SearchIcon} />
      </InputAdornment>),
    }}
    {...restProps}
  />
}

export default SearchInput