import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AppState } from '../../../store'
import { programsAllRequestAction } from '../../../store/reducks/programs/programs.all.actions'
import TemplateContent from '../../Template/content'
import ProgramList from '../../ProgramList'

export type ProgramsProps = ReduxConnectedProps & RouteComponentProps & {}

class Programs extends React.Component<ProgramsProps> {
  componentDidMount() {
    this.props.programsAllRequestAction()
  }

  render() {
    const { programs } = this.props
    return <TemplateContent
      title="Программы"
    >
      <ProgramList
        programs={programs.programs}
        onPress={(program) => {
          this.props.history.push('/clinic/programs/' + program.id)
        }}
      />
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    programs: state.programs.all,
  }),
  {
    programsAllRequestAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Programs))