import React from 'react'
import BonusPaymentModal from '../../modals/BonusPaymentModal'
import GlobalModal from '../../modals/GlobalModal'
import PrepareInfoModal from '../../modals/PrepareInfoModal'
import MedcardAuthModal from '../../modals/MedcardAuthModal'
import CombinedAppointmentsModal from '../../modals/CombinedAppointmentsModal'
import DoctorInfoModal from '../../modals/DoctorInfoModal'

const ModalsContainer: React.FC = () => {
  return <>
    <PrepareInfoModal />
    <BonusPaymentModal />
    <GlobalModal />
    <MedcardAuthModal />
    <CombinedAppointmentsModal />
    <DoctorInfoModal />
  </>
}

export default ModalsContainer