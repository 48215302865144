import { combineReducers } from 'redux'
import MedcardOnlineVisitsReducer from './medcard.online_visits.reducer'
import MedcardViewReducer from './medcard.view.reducer'
import MedcardVisitsReducer from './medcard.visits.reducer'

const MedcardReducer = combineReducers({
  view: MedcardViewReducer,
  online_visits: MedcardOnlineVisitsReducer,
  visits: MedcardVisitsReducer,
})

export default MedcardReducer