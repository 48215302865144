import React from 'react'
import * as icons from '../../icons'

export default class Icons extends React.Component {
  render() {
    console.log('icons: ', icons)
    return <div style={{ flexDirection: 'column', overflow: 'scroll' }}>
      {Object.keys(icons).map(key => {
        // @ts-ignore
        const Icon = icons[key]
        console.log('icon', key, Icon)
        return <div style={{ flexDirection: 'row'}}>
          <div>{key}</div>
          <div>{Icon()}</div>
        </div>
      })}
    </div>
  }
}