import { RegisterAppointmentAction, RegisterAppointmentState } from "./register.appointment.types"

export const initialAppointmentsRegisterState: RegisterAppointmentState = {
  fetching: false,
  appointment: {},
}

const RegisterAppointmentReducer = (state = initialAppointmentsRegisterState, action: RegisterAppointmentAction): RegisterAppointmentState => {
  switch (action.type) {
    case 'REGISTER_APPOINTMENT_MAIN_REQUEST':
    case 'REGISTER_APPOINTMENT_BY_PHONE_REQUEST':
    case 'REGISTER_APPOINTMENT_BY_MISTOKEN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        appointment: {},
      }
    case 'REGISTER_APPOINTMENT_MAIN_SUCCESS':
    case 'REGISTER_APPOINTMENT_BY_PHONE_SUCCESS':
    case 'REGISTER_APPOINTMENT_BY_MISTOKEN_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointment: action.params,
      }
    case 'REGISTER_APPOINTMENT_MAIN_FAILURE':
    case 'REGISTER_APPOINTMENT_BY_PHONE_FAILURE':
    case 'REGISTER_APPOINTMENT_BY_MISTOKEN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAppointmentsRegisterState,
      }
    default:
      return state
  }
}

export default RegisterAppointmentReducer