import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AppState } from '../../../store'
import { doctorsBydepartmentRequestAction } from '../../../store/reducks/doctors/doctors.bydepartment.actions'
import { doctorsViewParamsSetAction } from '../../../store/reducks/doctors/doctors.view.actions'
import DoctorList from '../../DoctorList'
import TemplateContent from '../../Template/content'
import './style.less'

export type DoctorsProps = ReduxConnectedProps & RouteComponentProps & {
}

export type DoctorsState = {
}

class Doctors extends React.Component<DoctorsProps> {
  constructor(props: DoctorsProps) {
    super(props)
  }

  componentDidMount() {
    this.fetchDoctors()
  }

  componentDidUpdate(prevProps: DoctorsProps) {
    const { view } = this.props
    if (view.department_id != prevProps.view.department_id) {
      this.fetchDoctors()
    }
  }

  private fetchDoctors = () => {
    const { view } = this.props
    const { department_id } = view
    this.props.doctorsBydepartmentRequestAction({
      department_id,
    })
  }

  private getTabs = () => {
    const { departments } = this.props
    return departments.fetching ? [] : departments.deparments.map(d => d.department_name)
  }

  private onTabChange = (key: string) => {
    const { departments } = this.props
    const department = departments.deparments[+key]
    this.props.doctorsViewParamsSetAction({
      department_id: department.department_id,
    })
  }

  private getActiveKey = () => {
    const { departments, view } = this.props
    const index = departments.deparments.findIndex(d => d.department_id == view.department_id)
    return index > -1 ? `${index}` : '0'
  }

  render() {
    return <TemplateContent
      title="Врачи"
      tabs={this.getTabs()}
      onTabChange={this.onTabChange}
      activeKey={this.getActiveKey()}
    >
      <DoctorList
        doctors={this.props.doctors.doctors}
        onDoctor={(doctor) => {
          this.props.history.push('/clinic/doctors/' + doctor.doctor_employer_id)
        }}
        onRecord={(doctor) => {
          // TODO
        }}
      />
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    doctors: state.doctors.bydepartment,
    departments: state.clinic.deparments,
    view: state.doctors.view,
  }), {
    doctorsBydepartmentRequestAction,
    doctorsViewParamsSetAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(Doctors))