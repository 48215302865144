import React from 'react'
import Modal from '../../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { pricelistPrepareInfoModalSetAction } from '../../store/reducks/pricelist/pricelist.prepare_info.actions'
import './style.less'

export type PrepareInfoModalProps = {
  onClose?: () => void
}

const PrepareInfoModal: React.FC<PrepareInfoModalProps> = (props) => {
  const prepareInfo = useSelector((state: AppState) => state.pricelist.prepareInfo)
  const dispatch = useDispatch()
  const visible = prepareInfo.modal.visible && !prepareInfo.fetching && !prepareInfo.error
  const onClose = () => {
    dispatch(pricelistPrepareInfoModalSetAction({ visible: false }))
    props.onClose && props.onClose()
  }
  const text = prepareInfo.prepareInfo.prepare_descr?.trim() || 'Подготовка не требуется'
  return <Modal
    width={500}
    onCancel={onClose}
    visible={visible}
    className="preparation-modal"
    title="Информация о подготовке"
  >
    <p>{text}</p>
  </Modal>
}

export default PrepareInfoModal