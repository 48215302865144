import React from 'react'

export type ButtonProps = {
  title?: string
  disabled?: boolean
  onClick?: () => void
  className?: string
}

class Button extends React.Component<ButtonProps> {
  private onClick = () => {
    const { disabled, onClick } = this.props
    if (!disabled && onClick) {
      onClick()
    }
  }

  private renderTitle = () => {
    const { title } = this.props
    return title
  }

  render() {
    const { disabled, children, className } = this.props
    return <div className={`my__button ${disabled ? 'disabled' : ''} ${className || ''}`} onClick={this.onClick}>
      {children ? children : this.renderTitle()}
    </div>
  }
}

export default Button