import { AppointmentsMainAction, AppointmentsMainState } from "./appointments.main.types"

export const initialAppointmentsMainState: AppointmentsMainState = {
  fetching: false,
  appointments: [],
}

const AppointmentsMainReducer = (state = initialAppointmentsMainState, action: AppointmentsMainAction): AppointmentsMainState => {
  switch (action.type) {
    case 'APPOINTMENTS_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'APPOINTMENTS_MAIN_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: action.params,
      }
    case 'APPOINTMENTS_MAIN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAppointmentsMainState,
      }
    default:
      return state
  }
}

export default AppointmentsMainReducer