import React from 'react'
import { Appointment as IAppointment } from '../../api/appointment.types'
import JournalItem, { ButtonsProps } from '../JournalItem'
import './style.less'

export type PastAppointmentProps = {
  appointment: IAppointment
  onPay?: (appointment: IAppointment) => void
  onRepeat?: (appointment: IAppointment) => void
  onInfo?: (appointment: IAppointment) => void
}

class PastAppointment extends React.Component<PastAppointmentProps> {
  private getButtons = (): ButtonsProps['buttons'] => {
    const buttons: ButtonsProps['buttons'] = []
    if (this.isPaymentRequire()) {
      buttons.push(this.payButton())
    }
    if (this.isRecordAllowed()) {
      buttons.push(this.repeatButton())
    }
    return buttons
  }

  private isPaymentRequire = () => {
    const { appointment } = this.props
    const { is_tm, paid, dms, paysum: price = appointment.appointment_cost } = appointment
    return !paid && !dms && Boolean(price) && !is_tm
  }

  private isRecordAllowed = () => {
    const { appointment } = this.props
    const { allow_rec,} = appointment
    return allow_rec
  }

  private payButton = () => {
    return {
      type: 'primary' as const,
      text: 'Оплатить',
      onClick: this.onPay,
    }
  }

  private repeatButton = () => {
    return {
      type: 'default' as const,
      text: 'Перезаписаться',
      onClick: this.onRepeat,
    }
  }

  private onPay = () => {
    const { appointment, onPay } = this.props
    onPay && onPay(appointment)
  }

  private onRepeat = () => {
    const { appointment, onRepeat } = this.props
    onRepeat && onRepeat(appointment)
  }

  private onInfo = () => {
    const { appointment, onInfo } = this.props
    onInfo && onInfo(appointment)
  }

  render() {
    const { appointment } = this.props
    const {
      appointment_date_time: datetime,
      paysum: price = appointment.appointment_cost,
      appointment_spec: specialization,
      appointment_doctor_name: doctorName,
      division: address,
      appointment_office_number: office,
      is_tm,
    } = appointment
    return <JournalItem
      datetime={datetime}
      price={price}

      specialization={specialization}
      onInfo={this.onInfo}
      is_tm={is_tm}
      hideIcon

      doctorName={doctorName}
      address={address}
      office={office}

      buttons={this.getButtons()}
    />
  }
}

export default PastAppointment