import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { AppState } from '../../store'
import { sendFeedbackRequestAction } from '../../store/reducks/feedback/send.feedback.actions'
import TemplateContent from '../../components/Template/content'
import Container from '../../components/Template/container'
import { Button } from 'antd'
import TextInputMask from '../../components/TextInput/mask'
import TextInput from '../../components/TextInput'
import Checkbox from '../../components/Checkbox'
import { phoneValidation, inputValidation } from '../../tools/validation'
import './style.less'

export type FeedbackViewProps = ReduxConnectedProps & RouteComponentProps & {}
export type FeedbackViewState = {
  isAgree: boolean
  subject: string
  message: string
  phone: string
  name: string
  subjectError: string
  messageError: string
  phoneError: string
  nameError: string
}

class FeedbackView extends React.Component<FeedbackViewProps, FeedbackViewState> {
  constructor(props: FeedbackViewProps) {
    super(props)
    this.state = {
      isAgree: false,
      subject: '',
      message: '',
      phone: '',
      name: '',
      subjectError: '',
      messageError: '',
      phoneError: '',
      nameError: '',
    };
  }

  componentDidMount() {
    const { user } = this.props
    if (!!user.phone) this.setState({ phone: user.phone })
  }

  componentDidUpdate(prevProps: FeedbackViewProps) {
    const { sendFeedback } = this.props.feedback
    if (!sendFeedback.fetching && !sendFeedback.error && prevProps.feedback.sendFeedback.fetching) {
      this.props.history.goBack()
    }
  }

  validation(): boolean {
    const { phone, name, message, subject }: FeedbackViewState = this.state
    const subjectError = inputValidation(subject, 'Тема')
    const messageError = inputValidation(message, 'Сообщение')
    const nameError = inputValidation(name, 'Имя')
    const phoneFormatted = phone.replace(/[-_\s]/g, '')
    const phoneError = phoneValidation(phoneFormatted)

    this.setState({
      subjectError,
      messageError,
      phoneError,
      nameError,
    })

    return !subjectError && !messageError && !nameError && !phoneError
  }

  sendFeedback() {
    const { sendFeedbackRequestAction } = this.props
    const { phone, name, message, subject, isAgree } = this.state
    const phoneFormatted = phone.replace(/[-_\s]/g, '')

    const isValid = this.validation()

    if (isValid) {
      sendFeedbackRequestAction({
        subject: subject,
        message: message,
        phone: +phoneFormatted,
        name: name,
        public_value: isAgree,
      })
    }
  }

  render() {
    const { isAgree, subject, phone, message, name, subjectError, nameError, messageError, phoneError } = this.state

    return <Container
      className="callback"
      currentItem="callback"
      sidebar={<div className="sidebar-default" />}
    >
      <TemplateContent
        title="Обратная связь"
      >
        <div className='feedback-details'>
          <div className='title'>Вы можете отправить замечания, комментарии и пожелания по работе нашей клиники и мобильного приложения. Благодаря этому мы сможем стать лучше для вас.</div>

          <TextInput
            className={`feedback__input_${!!subjectError ? 'error' : 'default'}`}
            value={subject}
            label="Тема"
            error={!!subjectError}
            helperText={subjectError}
            onChangeText={(value) => this.setState({ subject: value, subjectError: '' })}
          />
          <TextInput
            className={`feedback__input_${!!nameError ? 'error' : 'default'}`}
            value={name}
            label="Имя"
            error={!!nameError}
            helperText={nameError}
            onChangeText={(value) => this.setState({ name: value, nameError: '' })}
          />
          <TextInputMask
            className={`feedback__input_${!!phoneError ? 'error' : 'default'}`}
            value={phone}
            errorString={phoneError}
            autoComplete='off'
            mask='phone'
            prefix='phone'
            label='Номер телефона'
            onChange={(e) => {
              const { value } = e.target
              const isNotEmpty = /\d+/.test(value)
              this.setState({
                phone: isNotEmpty ? e.target.value : '',
                phoneError: ''
              })
            }}
          />
          <TextInput
            className={`feedback__input_${!!messageError ? 'error' : 'default'}`}
            value={message}
            label="Сообщение"
            error={!!messageError}
            helperText={messageError}
            onChangeText={(value) => this.setState({ message: value, messageError: '' })}
          />
          <Checkbox
            label="Разрешаю публикацию в открытых источниках"
            checked={isAgree}
            onChange={() => this.setState({ isAgree: !isAgree })}
          />
          <Button
            type='primary'
            onClick={() => this.sendFeedback()}
            className='feedback__button'
          >
            Отправить
          </Button>
        </div>
      </TemplateContent>
    </Container>
  }
}

const connector = connect((state: AppState) => ({
  user: state.user,
  feedback: state.feedback,
}), {
  sendFeedbackRequestAction,
})

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(FeedbackView))
