import React, { Component } from 'react'
import { Modal as AntModal } from 'antd'
import { CloseIcon, BackIcon } from '../../icons'
import { ModalProps } from 'antd/lib/modal/Modal'
import './style.less'

interface State { }

interface OwnProps {
  withBack?: boolean
  title?: string
  onBack?: () => void
}

type Props = ModalProps & OwnProps

class Modal extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  static defaultProps: Props = {
    width: 520,
    zIndex: 1000,
    closable: true,
    centered: true,
    closeIcon: <CloseIcon />,
    footer: null,
    maskClosable: false,
    visible: false,
    cancelText: 'Отмена',
    okText: 'Ок',
    withBack: false,
  }

  private renderTitle = () => {
    const { withBack } = this.props
    if (!withBack) {
      return this.renderTitleText()
    }
    return <>
      {this.renderTitleBackButton()}
      {this.renderTitleText()}
    </>
  }

  private renderTitleText = () => {
    const { title } = this.props
    return <div className="ant-modal-title">{title || ''}</div>
  }

  private renderTitleBackButton = () => {
    const { onBack } = this.props
    return <div className="ant-modal-back" onClick={onBack}>
      <BackIcon />
    </div>
  }

  render() {
    const { title, children, ...restProps } = this.props
    return <AntModal title={this.renderTitle()} {...restProps}>{children}</AntModal>
  }
}

export default Modal
