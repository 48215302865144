import React from 'react'
import './style.less'
import ServiceList, { ServiceListProps } from '../../ServiceList'

export type Props = ServiceListProps & {}

const RecordingStage4: React.FC<Props> = (props) => {
    return <ServiceList
      className="recording-stage-fourth"
      {...props}
    />
}

export default RecordingStage4
