import { CombinedAppointmentsModalAction, CombinedAppointmentsModalState } from "./modals.combinedAppointments.types"

export const initialCombinedAppointmentsModalState: CombinedAppointmentsModalState = {
  visible: false,
}

const CombinedAppointmentsModalReducer = (state = initialCombinedAppointmentsModalState, action: CombinedAppointmentsModalAction): CombinedAppointmentsModalState => {
  switch (action.type) {
    case 'COMBINED_APPOINTMENTS_MODAL_CLOSE':
      return {
        ...initialCombinedAppointmentsModalState,
      }
    case 'COMBINED_APPOINTMENTS_MODAL_SET':
      return {
        ...state,
        ...action.params,
      }
    default:
      return state
  }
}

export default CombinedAppointmentsModalReducer