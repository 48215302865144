import React, { Component } from 'react'
import { List } from 'antd'
import { ChevronLeftIcon } from '../../icons'
import './style.less'
import { CatalogPreview } from '../../api/pricelist.types'
import { PricelistCatalogsState } from '../../store/reducks/pricelist/pricelist.catalogs.types'
import SearchInput from '../SearchInput'

export type CatalogsProps = {
  catalogs: PricelistCatalogsState['catalogsByDepartments']
  activeKey: string
  onSelect: (selectedCatalog: CatalogPreview) => void
  className?: string
}

export type CatalogsState = {
  search?: string
}

class CatalogList extends Component<CatalogsProps, CatalogsState> {
  
  constructor(props: CatalogsProps) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps: CatalogsProps) {
    const { activeKey } = this.props
    const { search } = this.state
    if (activeKey != prevProps.activeKey && search) {
      this.flushSearch()
    }
  }

  private getActiveCatalogs = () => {
    const { activeKey } = this.props
    const { catalogs } = this.props.catalogs[+activeKey]
    return catalogs
  }

  private renderSearchBar = () => {
    return <div className="search-bar">
      <SearchInput
        value={this.state.search}
        label="Поиск специальности"
        onChangeText={(search) => {
          this.setState({ search })
        }}
      />
    </div>
  }

  private flushSearch = () => {
    this.setState({ search: '' })
  }

  private filterCatalogs = () => {
    const catalogs = this.getActiveCatalogs()
    const { search } = this.state
    if (!search || !search.trim()) {
      return catalogs
    }
    return catalogs.filter(({ service_name }) => {
      return (service_name || '').trim().toLowerCase().includes(search.trim().toLowerCase())
    })
  }

  render() {
    const { onSelect, className } = this.props
    const catalogs = this.filterCatalogs()
    return (
      <div className={`catalog-list ${className || ''}`}>
        {(
          <div className="container">
            {this.renderSearchBar()}
            {catalogs.length > 0 && <List
              dataSource={catalogs}
              renderItem={(item: CatalogPreview, index: number) => (
                <List.Item
                  key={index.toString()}
                  onClick={() => {
                    onSelect(item)
                  }}
                >
                  <>
                    <div className="name">{item.service_name}</div>
                    <div className="icon">
                      <ChevronLeftIcon />
                    </div>
                  </>
                </List.Item>
              )}
            />}
          </div>
        )}
      </div>
    )
  }
}

export default CatalogList
