import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Template } from '../../components'
import Chat from '../../components/Chat'
import { AppState } from '../../store'
import { chatMessageActiveSetAction, chatMessageInActiveSetAction, chatMessageRequestAction, chatMessageSendAction } from '../../store/reducks/chat/chat.message.actions'
import './style.less'

export type ChatViewProps = ReduxConnectedProps & {}

class ChatView extends React.Component<ChatViewProps> {
  private body?: HTMLDivElement | null

  componentDidMount() {
    this.props.chatMessageRequestAction()
    this.props.chatMessageActiveSetAction()
  }

  componentDidUpdate(prevProps: ChatViewProps) {
    const { messaging } = this.props
    if (
      (!messaging.fetching && !messaging.error && prevProps.messaging.fetching)
      || (messaging.messages.length > prevProps.messaging.messages.length)
    ) {
      this.scrollToBottom()
    }
  }

  componentWillUnmount() {
    this.props.chatMessageInActiveSetAction()
  }

  private scrollToBottom = () => {
    if (this.body) {
      this.body.scrollTop = this.body.scrollHeight
    }
  }

  private sendMessage = (text: string, files?: Array<{ name: string; base64: string }>) => {
    this.props.chatMessageSendAction({ text, files })
  }

  render() {
    const { messaging } = this.props
    return <Template
      className="chat"
      title="Чат"
      sidebar={<div className="sidebar-default" />}
      bodyRef={ref => this.body = ref}
    >
      <Chat
        className="chat-view"
        messages={messaging.messages}
        onSend={this.sendMessage}
      />
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    messaging: state.chat.messaging,
  }), {
    chatMessageRequestAction,
    chatMessageSendAction,
    chatMessageActiveSetAction,
    chatMessageInActiveSetAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(ChatView)