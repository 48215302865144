import React, { Component } from 'react'
import Template from '../../components/Template'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { AppState } from '../../store'
import { logoutRequest } from '../../store/reducks/auth/auth.actions'
import SimpleList, { SimpleListItem } from '../../components/SimpleList'
import { recoveryViewSetAction } from '../../store/reducks/recovery/recovery.view.actions'
import { globalModalCloseAction, globalModalSetAction } from '../../store/reducks/modals/modals.global.actions'
import './style.less'

export type SettingsViewProps = RouteComponentProps & ReduxConnectedProps & {}
interface State { }

class SettingsView extends Component<SettingsViewProps, State> {
  items: SimpleListItem[]

  constructor(props: SettingsViewProps) {
    super(props)
    this.state = {}

    this.items = [{
      id: 'profile',
      name: 'Редактировать профиль',
      onClick: this.gotoEditProfile,
    }, {
      id: 'recovery',
      name: 'Восстановить пароль',
      onClick: this.gotoRecovery,
    }]
	  
	if (this.props.user.authByLogin){
	  this.items.push({
		  id: 'change',
		  name: 'Сменить пароль',
		  onClick: this.gotoChangePassword,
	  })
    }
	
	this.items.push({
		id: 'logout',
		name: 'Выход',
		onClick: this.logout,
	})
  }

  private gotoEditProfile = () => {
    this.props.recoveryViewSetAction({ path: '/settings' })
    this.props.history.push('/edit/profile')
  }

  private gotoRecovery = () => {
    this.props.recoveryViewSetAction({ path: '/settings' })
    this.props.history.push('/recovery')
  }
	
  private gotoChangePassword = () => {
	this.props.recoveryViewSetAction({ path: '/settings' })
	this.props.history.push('/change-password')
  }
  
  private logout = () => {
    const { user } = this.props
    if (user.is_demo) {
      this.props.logoutRequest()
      return
    }
    this.props.globalModalSetAction({
      visible: true,
      title: 'Выход',
      content: 'Вы уверены, что хотите выйти?',
      buttons: [{
        type: 'default',
        title: 'Отмена',
        onClick: () => {
          this.props.globalModalCloseAction()
        }
      }, {
        type: 'primary',
        title: 'Выйти',
        onClick: () => {
          this.props.logoutRequest()
          this.props.globalModalCloseAction()
        }
      }],
    })
  }

  render() {
    return (
      <Template
        className="settings-page"
        title="Настройки"
        currentItem="settings"
        sidebar={<div className="sidebar-default" />}
      >
        <SimpleList
          title='Вы можете сменить данные для доступа в Медкарту'
          items={this.items}
        />
      </Template>
    )
  }
}

const connector = connect(
  (state: AppState) => ({
    user: state.user,
  }), {
  logoutRequest,
  recoveryViewSetAction,
  globalModalSetAction,
  globalModalCloseAction,
})



type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(SettingsView))
