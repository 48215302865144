import React, { Component, Key, ReactNode } from 'react'
import TemplateMain from './main'
import TemplateMenu from './menu'
import TemplateSidebar from './sidebar'
import './style.less'

export type ContainerProps = {
  className: string
  onMenuItemClick?: (key: Key) => void
  sidebar?: ReactNode
  currentItem?: string
}

const Container: React.FC<ContainerProps> = (props) => {
  const {
    className,
    onMenuItemClick,
    children,
    sidebar,
    currentItem,
  } = props
  return (
    <div className={className ? `template__layout ${className}` : 'template__layout'}>
      <TemplateMenu onMenuItemClick={onMenuItemClick} currentItem={currentItem} />
      <TemplateMain>
        {children}
      </TemplateMain>
      {sidebar && <TemplateSidebar>{sidebar}</TemplateSidebar>}
    </div>
  )
}

export default Container
