import { AuthAction, AuthState } from "./types"

export const initialAuthState: AuthState = {
  fetching: false,
  stage: '',
  error: false,
  phone: '',
  token: '',
}

const AuthReducer = (state = initialAuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'AUTH_SEND_CODE_REQUEST':
      return {
        ...state,
        ...action.params,
        fetching: true,
        error: false,
        stage: 'SEND_CODE',
      }
    case 'AUTH_CHECK_CODE_REQUEST':
      return {
        ...state,
        ...action.params,
        fetching: true,
        stage: 'CHECK_CODE',
      }
    case 'AUTH_LOGOUT_REQUEST':
      return {
        ...state,
        ...action.params,
        fetching: true,
        stage: 'LOGOUT',
        error: false,
      }
    case 'AUTH_BY_LOGIN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        stage: 'AUTH_BY_LOGIN',
      }
    case 'DEMO_TOKEN_REQUEST':
      return {
        ...state,
        phone: '',
        token: '',
        stage: 'DEMO',
        error: false,
        fetching: true,
      }
    case 'AUTH_CHECK_CODE_FAILURE':
    case 'AUTH_SEND_CODE_FAILURE':
    case 'AUTH_LOGOUT_FAILURE':
    case 'DEMO_TOKEN_FAILURE':
      return {
        ...state,
        ...action.params,
        fetching: false,
        error: true,
      }
    case 'AUTH_SEND_CODE_SUCCESS':
    case 'AUTH_CHECK_CODE_SUCCESS':
    case 'DEMO_TOKEN_SUCCESS':
      return {
        ...state,
        ...action.params,
        fetching: false,
      }
    case 'AUTH_LOGOUT_SUCCESS':
      return {
        ...state,
        ...action.params,
        fetching: false,
        phone: '',
        token: '',
      }
    case 'AUTH_TOKEN_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'AUTH_BY_LOGIN_SUCCESS':
      return {
        ...state,
        fetching: false,
      }
    case 'AUTH_BY_LOGIN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    default:
      return state
  }
}

export default AuthReducer