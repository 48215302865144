import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { ChatMessage } from "./chat.types";

export const fixChatMessage = (msg: any): ChatMessage => {
  return {
    ...msg,
    operator_name: msg._operator?.name,
    _operator: undefined,
  }
}

class ChatAPI {
  private static GET_MESSAGES = '/get_messages' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(ChatAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && res.url == ChatAPI.GET_MESSAGES) {
      return {
        ...res,
        // @ts-ignore
        data: { messages: Array.isArray(res.data?.messages) ? res.data.messages.map(fixChatMessage) : [] } as T,
      }
    }
    return res
  }

  getMessages = async () => {
    const res = await this.request<{ messages: ChatMessage[] }, { message: string }>({
      method: 'get',
      url: ChatAPI.GET_MESSAGES,
    })
    return res
  }
}

export default ChatAPI