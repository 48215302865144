import { combineReducers } from 'redux'
import PatientsBymistokenAddedReducer from './patients.bymistoken.added.reducer';
import PatientsBymistokenMainReducer from './patients.bymistoken.main.reducer';
import PatientsBymistokenOrderReducer from './patients.bymistoken.order.reducer';

const PatientsBymistokenReducer = combineReducers({
  main: PatientsBymistokenAddedReducer,
  added: PatientsBymistokenMainReducer,
  order: PatientsBymistokenOrderReducer,
})

export default PatientsBymistokenReducer