import { put, call, all } from 'redux-saga/effects'
import { MedicApi } from '../../_api'
import { IPricelistCatalog, IPricelistService } from '../types'
import { pricelistCatalogsSetAction, pricelistCatalogServivesSetAction } from '../reducers/pricelist'

export function* pricelistCatalogsGetSaga() {
  const catalogs: any[] = yield call(MedicApi.pricelistCatalogsGetRequest)
  yield put(pricelistCatalogsSetAction(catalogs))
}

export function* pricelistCatalogServicesGetSaga(action: any) {
  const { catalog, patient } = action.params
  const promises: Promise<IPricelistService[]>[] = []
  const catalogs: IPricelistCatalog[] = []
  function recursion($catalog: IPricelistCatalog) {
    console.log({ catalog_id: $catalog.id })
    promises.push(MedicApi.pricelistCatalogServicesGetRequest($catalog.id, patient.id))
    catalogs.push($catalog)
    if ($catalog.catalogs) {
      $catalog.catalogs.forEach((c) => {
        recursion(c)
      })
    }
  }
  recursion(catalog)
  const results: any[] = yield all(promises)
  const catalog_services = catalogs.map((catalog: IPricelistCatalog, index: number) => {
    return { ...catalog, services: results[index] }
  })
  // .filter((catalog_service: IPricelistCatalog) => catalog_service.services.length > 0)
  yield put(pricelistCatalogServivesSetAction(catalog_services))
}
