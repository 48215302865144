import { ArticlesItemState, ArticlesItemAction } from './articles.item.types'

export const initialArticlesItemState: ArticlesItemState = {
  fetching: false,
}
const ArticlesItemReducer = (state = initialArticlesItemState, action: ArticlesItemAction): ArticlesItemState => {
  switch (action.type) {
    case 'ARTICLES_ITEM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        article: undefined,
      }
    case 'ARTICLES_ITEM_SUCCESS':
      return {
        ...state,
        fetching: false,
        article: action.params,
      }
    case 'ARTICLES_ITEM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialArticlesItemState
      }
    default:
      return state
  }
}
export default ArticlesItemReducer