import React from 'react'
import Container from '../../components/Template/container'
import News from '../../components/Clinic/News'

class NewsView extends React.Component {
  render() {
    return <Container
      className="news"
      currentItem="clinic/news"
      sidebar={<div className="sidebar-default" />}
    >
      <News />
    </Container>
  }
}

export default NewsView