import React from 'react'
// import ArrowIcon from '@material-ui/icons/'
import ArrowIcon from '@material-ui/icons/ArrowForwardIos'
import { Patient } from '../../api/patient.types'
import { AvatarIcon } from '../../icons'
import PatientListModal from '../../modals/PatientListModal'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { patientsByphoneOrderRequest } from '../../store/reducks/patients/patients.byphone.order.actions'
import { ISortablePatient } from '../../store/types'

export type PatientSelectProps = ReduxConnectedProps & {
  selectedPatient?: Partial<Patient>
  mainPatients: Patient[]
  addedPatients: Patient[]
  onChangePatient: (patient: Patient) => void
  patientAllEnabled?: boolean
}

export type PatientSelectState = {
  patientsModalVisible?: boolean
}

class PatientSelect extends React.Component<PatientSelectProps, PatientSelectState> {
  private static patientAll: Patient = { medic_user_id: 0, name: 'Все пациенты' }

  constructor(props: PatientSelectProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (!this.isSelected()) {
      this.getDefaultPatientAndSelectIt()
    }
  }

  componentDidUpdate(prevProps: PatientSelectProps) {
    const { mainPatients, addedPatients } = this.props
    if (!this.isSelected() && (mainPatients !== prevProps.mainPatients || addedPatients !== prevProps.addedPatients)) {
      this.getDefaultPatientAndSelectIt()
    }
  }

  private isSelected = () => {
    const { selectedPatient } = this.props
    return typeof selectedPatient?.medic_user_id != 'undefined'
  }

  private getDefaultPatientAndSelectIt = () => {
    const { patientAllEnabled } = this.props
    if (patientAllEnabled) {
      return this.getPatientAllAndSelectIt()
    }
    return this.getFirstPatientAndSelectIt()
  }

  private getPatientAllAndSelectIt = () => {
    const patient = this.getPatientAll()
    const { onChangePatient } = this.props
    onChangePatient && onChangePatient(patient)
    return patient
  }
  private getPatientAll = () => {
    return PatientSelect.patientAll
  }

  private getFirstPatientAndSelectIt = () => {
    const { onChangePatient } = this.props
    const patient = this.getFirstPatient()
    onChangePatient && onChangePatient(patient)
    return patient
  }
  private getFirstPatient = () => {
    const { mainPatients } = this.props
    return mainPatients[0]
  }

  private getSelectedName = () => {
    const patient = this.getSelectedPatient()
    return patient ? this.getPatientName(patient) : ''
  }
  private getSelectedPatient = () => {
    const { selectedPatient } = this.props
    if (typeof selectedPatient?.medic_user_id != 'undefined' && selectedPatient?.name) {
      return selectedPatient as Patient
    }
    if (typeof selectedPatient?.medic_user_id == 'undefined') {
      return this.getDefaultPatientAndSelectIt()
    }
    const patient = this.findPatient(selectedPatient.medic_user_id)
    return patient || this.getDefaultPatientAndSelectIt()
  }
  private findPatient = (medic_user_id: number) => {
    if (medic_user_id == 0) {
      return this.getPatientAll()
    }
    const { mainPatients, addedPatients } = this.props
    const patient = mainPatients.find(p => p.medic_user_id === medic_user_id)
    return patient || addedPatients.find(p => p.medic_user_id === medic_user_id)
  }

  private getPatientName = (patient: Patient) => {
    if (patient.medic_user_id == 0) {
      return patient.name
    }
    const names = patient.name.split(/\s+/g)
    return [names[names.length - 1], ...names.slice(0, -1)].join(' ')
  }

  private openModal = () => {
    this.setState({ patientsModalVisible: true })
  }

  private closeModal = () => {
    this.setState({ patientsModalVisible: false })
  }

  private onSelect = (patient: any) => {
    const { onChangePatient } = this.props
    onChangePatient && onChangePatient(patient)
    this.closeModal()
  }

  private onSort = (sorted: ISortablePatient[]) => {
    this.props.patientsByphoneOrderRequest(sorted.map(s => +s.patient_id))
  }

  render() {
    const { mainPatients, addedPatients, patientAllEnabled } = this.props
    return <div className="selected-patient">
      <div className="patient" onClick={this.openModal}>
        <AvatarIcon />
        <span>{this.getSelectedName()}</span>
      </div>
      <div className="select-icon" onClick={this.openModal}>
        <ArrowIcon />
      </div>
      <PatientListModal
        title="Выберите пациента"
        show={this.state.patientsModalVisible}
        onCancel={this.closeModal}
        onBack={this.closeModal}
        mainPatients={mainPatients}
        addedPatients={addedPatients}
        onSelect={this.onSelect}
        onSort={this.onSort}
        patientAllEnabled={patientAllEnabled}
      />
    </div>
  }
}

const connector = connect(
  (state: AppState) => ({
  }), {
    patientsByphoneOrderRequest,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(PatientSelect)