import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { autoPaymentsDebtsFailureAction, autoPaymentsDebtsSuccessAction } from "./autoPayments.debts.actions";
import { autoPaymentsMainFailureAction, autoPaymentsMainSuccessAction } from "./autoPayments.main.actions";
import { AutoPaymentsAction } from "./autoPayments.types";

export function* autoPaymentsMainRequestSaga(action: AutoPaymentsAction) {
  const res: ThenReturnType<typeof api.autopayment.getAutoPayments> = yield call(api.autopayment.getAutoPayments)
  if (res.error) {
    yield put(autoPaymentsMainFailureAction())
  } else {
    yield put(autoPaymentsMainSuccessAction(res.data.auto_payments))
  }
}

export function* autoPaymentsDebtsRequestSaga(action: AutoPaymentsAction) {
  const res: ThenReturnType<typeof api.autopayment.getAutoPaymentsDebts> = yield call(api.autopayment.getAutoPaymentsDebts)
  if (res.error) {
    yield put(autoPaymentsDebtsFailureAction())
  } else {
    yield put(autoPaymentsDebtsSuccessAction(res.data))
  }
}

export default function* watchAutoPayments() {
  yield takeLeadingPattern<AutoPaymentsAction['type']>('AUTO_PAYMENT_MAIN_REQUEST', autoPaymentsMainRequestSaga, autoPaymentsMainFailureAction)
  yield takeLeadingPattern<AutoPaymentsAction['type']>('AUTO_PAYMENT_DEBTS_REQUEST', autoPaymentsDebtsRequestSaga, autoPaymentsDebtsFailureAction)
}