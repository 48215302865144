import { combineReducers } from 'redux'
import AppointmentsByMisTokenReducer from './appointments.bymistoken.reducer'
import AppointmentsByPhoneReducer from './appointments.byphone.reducer'
import DeleteAppointmentReducer from './appointments.delete.reducer'
import FutureAppointmentsReducer from './appointments.future.reducer'
import AppointmentsMainReducer from './appointments.main.reducer'
import PastAppointmentsMainReducer from './appointments.past.reducer'
import AppointmentsViewReducer from './appointments.view.reducer'

const AppointmentsReducer = combineReducers({
  future: FutureAppointmentsReducer,
  past: PastAppointmentsMainReducer,
  main: AppointmentsMainReducer,
  byphone: AppointmentsByPhoneReducer,
  bymistoken: AppointmentsByMisTokenReducer,
  view: AppointmentsViewReducer,
  delete: DeleteAppointmentReducer,
})

export default AppointmentsReducer