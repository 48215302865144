import React from 'react'
import Container from '../../components/Template/container'
import Programs from '../../components/Clinic/Programs'

class ProgramsView extends React.Component {
  render() {
    return <Container
      className="programs"
      currentItem="clinic/programs"
      sidebar={<div className="sidebar-default" />}
    >
      <Programs />
    </Container>
  }
}

export default ProgramsView