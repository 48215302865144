import { PricesByServiceAction, PricesByServiceState } from "./pricelist.prices.byservice.types"

export const initialPricesByServiceState: PricesByServiceState = {
  fetching: false,
  prices: [],
}

const PricesByServiceReducer = (state = initialPricesByServiceState, action: PricesByServiceAction): PricesByServiceState => {
  switch (action.type) {
    case 'PRICES_BY_SERVICE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'PRICES_BY_SERVICE_SUCCESS':
      return {
        ...state,
        fetching: false,
        prices: action.params,
      }
    case 'PRICES_BY_SERVICE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPricesByServiceState,
      }
    default:
      return state
  }
}

export default PricesByServiceReducer