import { AppState } from "../..";
import lazyStore from "../../lazyStore";

export const getAuthStage= () => {
  return selectAuthStage(lazyStore.store.getState())
}
export const selectAuthStage = (state: AppState) => {
  return state.auth.stage
}

export const getAuthPhone = () => {
  return selectAuthPhone(lazyStore.store.getState())
}
export const selectAuthPhone = (state: AppState) => {
  return state.auth.phone
}

export const selectAuth = (state: AppState) => {
  return state.auth
}