import React from 'react'
import { Card } from 'antd'
import RecordingDateTimes, { RecordingDateTimesProps } from '../RecordingDateTimes'
import { GeoLocationIcon } from '../../icons'
import './style.less'

export type RecordingDateTimesCardProps = RecordingDateTimesProps & {
  departmentAddress: string
}

class RecordingDateTimesCard extends React.Component<RecordingDateTimesCardProps> {
  private renderTitle = () => {
    const { departmentAddress } = this.props
    return <div className="department__title">
      <GeoLocationIcon />
      <span>{departmentAddress}</span>
    </div>
  }

  render() {
    const { departmentAddress, ...restProps } = this.props
    return <Card
      title={this.renderTitle()}
      className="recording-datetimes__card"
    >
      <RecordingDateTimes
        {...restProps}
      />
    </Card>
  }
}

export default RecordingDateTimesCard