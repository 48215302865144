import { FutureAppointmentsByServiceAction, FutureAppointmentsByServiceState } from "./appointments.future.byservice.types"


export const initialFutureAppointmentsByServiceState: FutureAppointmentsByServiceState = {
  fetching: false,
  appointments: [],
  appointments_added: []
}

const FutureAppointmentsByServiceReducer = (state = initialFutureAppointmentsByServiceState, action: FutureAppointmentsByServiceAction): FutureAppointmentsByServiceState => {
  switch (action.type) {
    case 'FUTURE_APPOINTMENTS_BY_SERVICE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'FUTURE_APPOINTMENTS_BY_SERVICE_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: !action.isAdded ? action.params : state.appointments,
	    appointments_added: action.isAdded ? action.params : state.appointments_added
      }
    case 'FUTURE_APPOINTMENTS_BY_SERVICE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'FUTURE_APPOINTMENTS_BY_SERVICE_CLEAR':
      return {
        ...state,
        fetching: false,
        error: false,
        appointments: [],
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialFutureAppointmentsByServiceState,
      }
    default:
      return state
  }
}

export default FutureAppointmentsByServiceReducer