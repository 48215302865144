import React from 'react'
import { Collapse } from 'antd'
import { Program } from '../../api/newsfeed.types'
import config from '../../config'
import { Plus24Icon, MinusIcon } from '../../icons'
import './style.less'

const { Panel } = Collapse;

export type ProgramInfoProps = {
  program: Program
}

class ProgramInfo extends React.Component<ProgramInfoProps> {
  render() {
    const { program } = this.props
    return <div className='program-details'>
      <img src={`${config.staticURL}/programs/${program.image}`} />
      <div className='content'>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => isActive ? <MinusIcon /> : <Plus24Icon />}
          className="program-collapse"
        >
          {program.contents.map((item, index) =>
            <Panel key={index} header={item.name} className="program-collapse-panel">
              <div dangerouslySetInnerHTML={{
                __html: item.description.replace(/<em[^>]*>|<em\/>/g, '')
              }}></div>
            </Panel>
          )}
        </Collapse>
        <div className='description'>Описание</div>
        <div className='details' dangerouslySetInnerHTML={{ __html: program.description.replace(/<em[^>]*>|<em\/>/g, '') }}></div>
      </div>
    </div>
  }
}

export default ProgramInfo