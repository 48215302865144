import { ResearchesAllAction, ResearchesAllState } from "./researches.all.types"

export const initialResearchesAllState: ResearchesAllState = {
  fetching: false,
  researches: [],
}

const ResearchesAllReducer = (state = initialResearchesAllState, action: ResearchesAllAction): ResearchesAllState => {
  switch (action.type) {
    case 'RESEARCHES_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'RESEARCHES_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        researches: action.params,
      }
    case 'RESEARCHES_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialResearchesAllState,
      }
    default:
      return state
  }
}

export default ResearchesAllReducer