import { MedcardAuthModalAction, MedcardAuthModalState } from "./modals.medcardAuth.types"

export const initialMedcardAuthModalState: MedcardAuthModalState = {}

const MedcardAuthModalReducer = (state = initialMedcardAuthModalState, action: MedcardAuthModalAction): MedcardAuthModalState => {
  switch (action.type) {
    case 'MEDCARD_AUTH_MODAL_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'MEDCARD_AUTH_MODAL_CLOSE':
      return {
        ...state,
        visible: false,
      }
    case 'MEDCARD_AUTH_MODAL_CLEAR':
      return {
        ...initialMedcardAuthModalState,
      }
    default:
      return state
  }
}

export default MedcardAuthModalReducer