import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import LoginView from './views/login'
import HomeView from './views/home'
import NotFoundView from './views/404'
import SettingsView from './views/settings'
import RecordingView from './views/recording'
import RecordingSuccessView from './views/recordingSuccess'
import { LoadingIcon } from './icons'
import { PrivateRoute } from './components'
import { AppState } from './store'
// import { message } from 'antd'
import 'antd/dist/antd.less'
import './App.less'
import './Antd.less'
import Icons from './views/icons'
import ModalsContainer from './views/modals'
import { errorClearAction } from './store/reducks/error/error.actions'
import JournalView from './views/journal'
import Telemedicine from './views/telemedicine'
import MedcardView from './views/medcard'
import MedcardResearchesView from './views/researches'
import RecoveryView from './views/recovery'
import ClinicInfo from './views/clinic/Info'
import ClinicServices from './views/clinic/Services'
import News from './views/clinic/News'
import Actions from './views/clinic/Actions'
import Action from './views/clinic/Action'
import NewsOne from './views/clinic/NewsOne'
import Doctors from './views/clinic/Doctors'
import Doctor from './views/clinic/Doctor'
import Programs from './views/clinic/Programs'
import Program from './views/clinic/Program'
import FeedbackView from './views/feedback'
import { clinicDepartmentsRequest } from './store/reducks/clinic/clinic.departments.actions'
import ChatView from './views/chat'
import ProfileEditView from './views/edit/profile'
import { errorMessage } from './tools/errorMessage'
import showToast from './tools/showToast'
import ChangePassword from "./views/changePassword";
import { GRecaptchBadge } from './components/GRecaptchaBadge'

// message.config({
//   duration: 3,
//   maxCount: 1,
// })

interface State { }

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps
type Props = StateProps & DispatchProps

class App extends Component<Props, State> {
  componentDidMount() {
    this.fetchDepartments()
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.error && this.props.error) {
      const message = errorMessage(this.props.error)
      if (typeof message == 'string') {
        showToast(message)
      }
      this.props.errorClearAction()
    }
    if (this.props.authorized && !prevProps.authorized) {
      this.fetchDepartments()
    }
  }

  private fetchDepartments = () => {
    const { authorized } = this.props
    if (authorized) {
      this.props.clinicDepartmentsRequest()
    }
  }

  render() {
    const { fetching, authorized } = this.props
    return (
      <>
        <GRecaptchBadge />
        <Switch>
          <Route exact path="/login" component={LoginView}></Route>
          <Route path="/icons" component={Icons}></Route>
          <PrivateRoute exact path="/settings" authorized={authorized}>
            <SettingsView />
          </PrivateRoute>
          <PrivateRoute exact path="/" authorized={authorized}>
            <HomeView />
          </PrivateRoute>
          <PrivateRoute exact path="/recording" authorized={authorized}>
            <RecordingView />
          </PrivateRoute>
          <PrivateRoute exact path="/recording/success" authorized={authorized}>
            <RecordingSuccessView />
          </PrivateRoute>
          <PrivateRoute exact path="/journal" authorized={authorized}>
            <JournalView />
          </PrivateRoute>
          <PrivateRoute exact path="/telemedicine" authorized={authorized}>
            <Telemedicine />
          </PrivateRoute>
          <PrivateRoute exact path="/medcard" authorized={authorized}>
            <MedcardView />
          </PrivateRoute>
          <PrivateRoute exact path="/researches" authorized={authorized}>
            <MedcardResearchesView />
          </PrivateRoute>
          <PrivateRoute exact path="/recovery" authorized={authorized}>
            <RecoveryView />
          </PrivateRoute>
	      <PrivateRoute exact path="/change-password" authorized={authorized}>
		    <ChangePassword />
	      </PrivateRoute>
          <PrivateRoute exact path="/clinic/about" authorized={authorized}>
            <ClinicInfo />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/services" authorized={authorized}>
            <ClinicServices />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/news" authorized={authorized}>
            <News />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/news/:id" authorized={authorized}>
            <NewsOne />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/actions" authorized={authorized}>
            <Actions />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/actions/:id" authorized={authorized}>
            <Action />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/doctors/" authorized={authorized}>
            <Doctors />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/doctors/:id" authorized={authorized}>
            <Doctor />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/programs/" authorized={authorized}>
            <Programs />
          </PrivateRoute>
          <PrivateRoute exact path="/clinic/programs/:id" authorized={authorized}>
            <Program />
          </PrivateRoute>
          <PrivateRoute exact path="/callback" authorized={authorized}>
            <FeedbackView />
          </PrivateRoute>
          <PrivateRoute exact path="/chat" authorized={authorized}>
            <ChatView />
          </PrivateRoute>
          <PrivateRoute exact path="/edit/profile" authorized={authorized}>
            <ProfileEditView />
          </PrivateRoute>
          <PrivateRoute path="*" authorized={authorized}>
            <NotFoundView />
          </PrivateRoute>
        </Switch>
        <div className={`loading-wrapper${fetching ? ' active' : ''}`}>
          <div className="loading">
            <LoadingIcon />
          </div>
        </div>
        <ModalsContainer />
        <Toaster />
      </>
    )
  }
}

const searchFetching = (target: object, depth = 3): boolean => {
  if (depth <= 0) {
    return false
  }
  const fetching = target && Object.keys(target).reduce((fetching, key) => {
    if (fetching) {
      return true
    }
    // @ts-ignore
    const item = target[key]
    if (key == 'fetching') {
      return fetching || Boolean(item)
    }
    return fetching || searchFetching(item, depth - 1)
  }, false)
  return fetching
}

const mapStateToProps = (state: AppState) => ({
  error: (state.error || {}).message,
  authorized: !!state.auth.token,
  fetching: searchFetching(state, 4),
})

const mapDispatchToProps = {
  errorClearAction,
  clinicDepartmentsRequest,
}


export default connect(mapStateToProps, mapDispatchToProps)(App)
