import React from 'react'
import { Button } from 'antd'
import './style.less'

export type MedcardAuthHeaderProps = {
  onAuth?: () => void
}

class MedcardAuthHeader extends React.Component<MedcardAuthHeaderProps> {
  private onAuth = () => {
    const { onAuth } = this.props
    onAuth && onAuth()
  }

  render() {
    return <div className="medcard-auth-header">
      <div className="description">
        <span>
          {'Для просмотра '}
          <b>{'медицинской карты'}</b>
          {' по '}
          <b>{'очным посещениям'}</b>
          {' клиники, пожалуйста, '}
          <b>{'авторизуйтесь'}</b>
        </span>{''}
      </div>
      <Button type="primary" className="auth-button" onClick={this.onAuth}>Авторизация</Button>
    </div>
  }
}

export default MedcardAuthHeader