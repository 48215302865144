import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { CatalogItem, CatalogPreview, Price } from '../../../api/pricelist.types'
import { AppState } from '../../../store'
import { pricesByServiceRequestAction } from '../../../store/reducks/pricelist/pricelist.prices.byservice.actions'
import ServiceList from '../../ServiceList'
import TemplateContent from '../../Template/content'

export type PricesProps = ReduxConnectedProps & {
  onBack?: () => void
  catalog: CatalogPreview
}

export type PricesState = {
  pricesLoaded?: boolean
}

class Prices extends React.Component<PricesProps, PricesState> {
  constructor(props: PricesProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { catalog } = this.props
    const { service_id } = catalog
    this.props.pricesByServiceRequestAction({ service_id })
  }

  componentDidUpdate(prevProps: PricesProps) {
    const { prices } = this.props
    if (!prices.fetching && !prices.error && prevProps.prices.fetching) {
      this.setState({ pricesLoaded: true })
    }
  }

  private getPrices = () => {
    const { prices } = this.props
    return prices.prices.map(price => ({
      ...price,
      title: price.name,
      weight: '0',
    })) as CatalogItem[]
  }

  private onSelect = (price: Price) => {

  }

  render() {
    const { pricesLoaded } = this.state
    return <TemplateContent
      title="Услуги и цены"
      onTitleBack={this.props.onBack}
    >
      {pricesLoaded && <ServiceList
        // catalog={this.props.catalog}
        catalog_services={this.getPrices()}
        onSelect={this.onSelect}
      />}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    prices: state.pricelist.prices.byservice,
  }), {
  pricesByServiceRequestAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>


export default connector(Prices)