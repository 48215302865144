import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
const manageBadge = () => {
  const className = 'grecaptcha-badge'
  const find = () => document.querySelector(`.${className}`) as HTMLDivElement;
  const _change = (visible?: boolean) => {
    const element = find()
    if (element) {
      element.style.visibility = visible ? 'visible' : 'hidden';
      return true
    }
    return false
  }
  const change = async (visible?: boolean) => {
    let isChanged = _change(visible)
    if (!isChanged) {
      await sleep(1000)
      isChanged = _change(visible)
    }
    if (!isChanged) {
      await sleep(1000)
      isChanged = _change(visible)
    }
  }
  return {
    show: () => change(true),
    hide: () => change(false),
  }
}
const BADGE = manageBadge()

export const GRecaptchBadge = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('/login')) {
      BADGE.show()
    } else {
      BADGE.hide()
    }
  }, [location])
  return null
}