import React, { Component, Key } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { InputBaseComponentProps, TextFieldProps } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import { CalendarIcon } from '../../icons'
import './mask.style.less'

type Props = {
  autoComplete?: 'on' | 'off'
  value: string
  label?: string
  errorString?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  prefix?: 'phone'
  postfix?: 'calendar'
  onPrefix?: () => void
  onPostfix?: () => void
  mask: 'phone' | 'date'
  className?: string
  orderId?: number
} & TextFieldProps

interface State { }

class TextInputMask extends Component<Props, State> {
  maskPattern = {
    date: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
    phone: [/[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
  }

  maskPlaceholder = {
    date: '__.__.____',
    phone: '___ ___-__-__'
  }

  textMaskCustom = (props: InputBaseComponentProps) => {
    const { onPostfix, postfix, prefix, mask } = this.props
    const { inputRef, ...other } = props;
    return (
      <>
        {prefix === 'phone' &&
          <span className='input-mask__prefix'>{'+7'}</span>
        }
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          placeholder={this.maskPlaceholder[mask]}
          mask={this.maskPattern[mask]}
          showMask={!!other.value}
        />
        {postfix === 'calendar' &&
          <span className='input-mask__postfix' onClick={onPostfix}>
            <CalendarIcon />
          </span>
        }
      </>
    );
  }

  render() {
    const { autoComplete, value, errorString, onChange, label, className, orderId } = this.props
    const _key = !!orderId ? `-${orderId}` : ''

    return (
      <FormControl className={`input-mask ${className}`}>
        <InputLabel
          error={!!errorString}
          htmlFor={`formatted-text-mask-input${_key}`} className="input-mask__label">{label}</InputLabel>
        <Input
          className='input-mask__input'
          autoComplete={autoComplete}
          value={value}
          error={!!errorString}
          onChange={onChange}
          name="textmask"
          id={`formatted-text-mask-input${_key}`}
          inputComponent={this.textMaskCustom}
        />
        {!!errorString && <div className='input-mask__error'>{errorString}</div>}
      </FormControl>
    )
  }
}

export default TextInputMask