import { ScheduleByDoctorDivisionsDatetimesAction, ScheduleByDoctorDivisionsDatetimesState } from "./schedule.bydoctor.divisions_datetimes.types"

export const initialScheduleByDoctorDivisionsDatetimesState: ScheduleByDoctorDivisionsDatetimesState = {
  fetching: false,
  divisions: [],
}

const ScheduleByDoctorDivisionsDatetimesReducer = (state = initialScheduleByDoctorDivisionsDatetimesState, action: ScheduleByDoctorDivisionsDatetimesAction): ScheduleByDoctorDivisionsDatetimesState => {
  switch (action.type) {
    case 'SCHEDULE_BY_DOCTOR_DIVISIONS_DATETIMES_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'SCHEDULE_BY_DOCTOR_DIVISIONS_DATETIMES_SUCCESS':
      return {
        ...state,
        fetching: false,
        divisions: action.params,
      }
    case 'SCHEDULE_BY_DOCTOR_DIVISIONS_DATETIMES_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialScheduleByDoctorDivisionsDatetimesState,
      }
    default:
      return state
  }
}

export default ScheduleByDoctorDivisionsDatetimesReducer