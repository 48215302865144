import moment from 'moment'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Appointment } from '../../api/appointment.types'
import { Patient } from '../../api/patient.types'
import { CatalogPreview, Price } from '../../api/pricelist.types'
import { Template } from '../../components'
import EmptyJournal from '../../components/EmptyJournal'
import FutureAppointment from '../../components/FutureAppointment'
import PastAppointment from '../../components/PastAppointment'
import PatientSelect from '../../components/PatientSelect'
import { AppState } from '../../store'
import { appointmentsByMisTokenRequestAction } from '../../store/reducks/appointments/appointments.bymistoken.actions'
import { appointmentsByPhoneRequestAction } from '../../store/reducks/appointments/appointments.byphone.actions'
import { deleteAppointmentMainRequestAction } from '../../store/reducks/appointments/appointments.delete.main.actions'
import { deletePaidAppointmentRequestAction } from '../../store/reducks/appointments/appointments.delete.paid.actions'
import { appointmentsMainRequestAction } from '../../store/reducks/appointments/appointments.main.actions'
import { pastAppointmentsMainRequestAction } from '../../store/reducks/appointments/appointments.past.actions'
import { appointmentsViewActiveTabSetAction, appointmentsViewPatientSetAction } from '../../store/reducks/appointments/appointments.view.actions'
import { autoPaymentsMainRequestAction } from '../../store/reducks/autoPayments/autoPayments.main.actions'
import { bonusPaymentModalSetAction } from '../../store/reducks/modals/modals.bonusPayment.actions'
import { combinedAppointmentsModalCloseAction, combinedAppointmentsModalSetAction } from '../../store/reducks/modals/modals.combinedAppointments.actions'
import { globalModalCloseAction, globalModalSetAction } from '../../store/reducks/modals/modals.global.actions'
import { patientsByphoneRequest } from '../../store/reducks/patients/patients.byphone.actions'
import { paymentAppointmentTypeRequestAction } from '../../store/reducks/payment/payment.appointmentType.actions'
import { paymentCombinedAppointmentsRequestAction } from '../../store/reducks/payment/payment.combinedAppointments.actions'
import { paymentParamsClearAction, paymentParamsMakePaymentAction, paymentParamsSetAction } from '../../store/reducks/payment/payment.params.actions'
import { pricelistPrepareInfoModalSetAction, pricelistPrepareInfoRequestAction } from '../../store/reducks/pricelist/pricelist.prepare_info.actions'
import { recordClearAction, recordStateSetAction } from '../../store/reducks/record/record.actions'
import './style.less'

export type JournalViewProps = RouteComponentProps & ReduxConnectedProps & {}

export type JournalViewState = {
  autoPaymentsLoaded?: boolean
}

class JournalView extends React.Component<JournalViewProps, JournalViewState> {
  private static tabs = ['Предстоящие записи', 'Прошедшие записи']
  constructor(props: JournalViewProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.patientsByphoneRequest()
  }

  componentDidUpdate(prevProps: JournalViewProps, prevState: JournalViewState) {
    const { patient, patients, activeTab, appointments_delete_main, appointments_delete_paid, auto_payments, combinedPayment, paymentType } = this.props
    if (activeTab != prevProps.activeTab
      || patient?.medic_user_id != prevProps.patient?.medic_user_id
      || (!appointments_delete_main.fetching && !appointments_delete_main.error && prevProps.appointments_delete_main.fetching)
      || (!appointments_delete_paid.fetching && !appointments_delete_paid.error && prevProps.appointments_delete_paid.fetching)
      || (!patients.byphone.added.fetching && prevProps.patients.byphone.added.fetching)
      || (!patients.byphone.main.fetching && prevProps.patients.byphone.main.fetching)
    ) {
      this.getAppointments()
    }
    if (prevProps.auto_payments.fetching && !auto_payments.fetching && !auto_payments.error) {
      this.setState({ autoPaymentsLoaded: true })
    }
    if (prevProps.combinedPayment.fetching && !combinedPayment.fetching && !combinedPayment.error) {
      if (combinedPayment.appointments.length) {
        this.gotoCombinedPayment()
      } else {
        this.gotoPayment()
      }
    }
    if (prevProps.paymentType.fetching && !paymentType.fetching && !paymentType.error) {
      if (paymentType.type.is_combined) {
        this.fetchCombinedPayment()
      } else {
        this.gotoPayment()
      }
    }
  }

  private onTabChange = (index: string) => {
    const { activeTab } = this.props
    if (activeTab != index) {
      this.props.appointmentsViewActiveTabSetAction(index)
    }
  }

  private onChangePatient = (patient: Patient) => {
    this.props.appointmentsViewPatientSetAction(patient)
  }

  private getAppointments = () => {
    const { activeTab } = this.props
    if (activeTab == '0') {
      this.getFutureAppointments()
    } else {
      this.getPastAppointments()
    }
  }

  private getFutureAppointments = () => {
    const { patient } = this.props
    console.log('get future appointments [journal]', { patient })
    if (!patient || !patient.medic_user_id) {
      return
    }
    const { medic_user_id } = patient
    if (patient.relation == 'phone') {
      this.props.appointmentsByPhoneRequestAction({ medic_user_id })
    } else {
      this.props.appointmentsMainRequestAction({ medic_user_id })
    }
    this.props.autoPaymentsMainRequestAction()
    this.setState({ autoPaymentsLoaded: false })
  }

  private getPastAppointments = () => {
    const { patient } = this.props
    console.log('get past appointments [journal]', { patient })
    if (!patient || !patient.medic_user_id) {
      return
    }
    const { medic_user_id } = patient
    this.props.pastAppointmentsMainRequestAction({ medic_user_id })
  }

  private renderFutureAppointments = () => {
    const { appointments_main, appointments_byphone, patient } = this.props
    if (!patient || !patient.medic_user_id) {
      return null
    }
    if (patient.relation == 'phone') {
      return this._renderFutureAppointments(
        this.filterUpcomingAppointments(appointments_byphone.appointments),
        appointments_byphone.error,
        appointments_byphone.fetching
      )
    }
    return this._renderFutureAppointments(
      this.filterUpcomingAppointments(appointments_main.appointments),
      appointments_main.error,
      appointments_main.fetching
    )
  }

  private filterUpcomingAppointments = (appointments: Appointment[]) => {
    const isPast = false
    appointments = appointments.filter((appointment) => {
      const appointmentDateTime = moment(appointment.appointment_date_time).utc().format('YYYY-MM-DD HH:mm')
      const timeout = appointment.is_tm ? 30 * 60 * 1000 : 0
      const currentDateTime = moment(Date.now() - timeout).format('YYYY-MM-DD HH:mm')
      const isValid = isPast
        ? moment(appointmentDateTime).isBefore(currentDateTime)
        : !moment(appointmentDateTime).isBefore(currentDateTime)
      return isValid
    })
    if (!isPast) {
      appointments = appointments.slice().sort((a, b) => moment(a.appointment_date_time).diff(b.appointment_date_time))
    }
    // if (!isPast && mymedicApp?.disabled) {
    if (!isPast) {
      appointments = appointments.filter(
        (a) =>
          !a.is_tm ||
          a.paid ||
          a.dms ||
          a.appointment_cost == 0 ||
          this.props.auto_payments.auto_payments.findIndex((p) => p.direction_service_id == a.appointment_id) > -1
      )
    }
    return appointments
  }
  private filterFutureAppointment = (appointment: Appointment, index: number) => {
    const { is_tm, appointment_id,  appointment_date_time} = appointment
	 
	if(Date.parse(appointment_date_time) > Date.parse(Date()) && !is_tm){
		return true;
	}
	
    const { autoPaymentsLoaded } = this.state
    if (!autoPaymentsLoaded) {
      return false
    }
    const { auto_payments } = this.props
    return auto_payments.auto_payments.some(a => !a.is_deleted && !a.is_failed && !a.is_refunded && a.direction_service_id == appointment_id)
  }
  private _renderFutureAppointments = (appointments: Appointment[], error?: boolean, fetching?: boolean) => {
	
    if (error) {
      return null
    }
    if (appointments.length == 0) {
      return fetching ? null : <EmptyJournal />
    }
    return <div className="appointments-list">
      {appointments.map(this.renderFutureAppointmentItem)}
    </div>
  }
  private renderFutureAppointmentItem = (appointment: Appointment, index: number) => {
    return <FutureAppointment
      key={index}
      appointment={appointment}
      onDelete={this.onDelete}
      onPay={this.onPay}
      onInfo={this.onInfo}
    />
  }

  private renderPastAppointments = () => {
    const { past_appointments, patient } = this.props
    if (!patient || !patient.medic_user_id) {
      return null
    }
    if (patient.relation == 'phone') {
      // здесь всегда возвращается ошибка запроса `get_past_appointments`
      return null
    }
    if (patient.relation == 'medic_user_id') {
      // TODO
      return null
    }
    return this._renderPastAppointments(past_appointments.appointments, past_appointments.error, past_appointments.fetching)
  }
  private _renderPastAppointments = (appointments: Appointment[], error?: boolean, fetching?: boolean) => {
    if (error) {
      return null
    }
    if (appointments.length == 0) {
      return fetching ? null : <EmptyJournal />
    }
    return <div className="appointments-list">
      {appointments.map(this.renderPastAppointmentItem)}
    </div>
  }
  private renderPastAppointmentItem = (appointment: Appointment, index: number) => {
    return <PastAppointment
      key={index}
      appointment={appointment}
      onPay={this.onPay}
      onRepeat={this.onRepeat}
      onInfo={this.onInfo}
    />
  }

  private onDelete = (appointment: Appointment) => {
    const { paid, dms, appointment_cost: price = appointment.paysum, appointment_id } = appointment
    if (!paid || dms || !price) {
      const { patient } = this.props
      this.openDeleteModal({
        content: 'Вы действительно хотите удалить запись?',
        onDelete: () => {
          this.props.deleteAppointmentMainRequestAction({ appointment_id, medic_user_id: patient.medic_user_id as number })
          this.props.globalModalCloseAction()
        },
      })
    } else {
      this.openDeleteModal({
        content: 'Вы действительно хотите удалить запись? Денежные средства вернутся на карту в течение 1-3 рабочих дней.',
        onDelete: () => {
          this.props.deletePaidAppointmentRequestAction({ appointment_id })
          this.props.globalModalCloseAction()
        },
      })
    }
  }
  private openDeleteModal = (params: { content: string; onDelete: () => void }) => {
    const { content, onDelete } = params
    this.props.globalModalSetAction({
      visible: true,
      title: 'Удаление записи',
      content,
      buttons: [{
        type: 'default',
        title: 'Отмена',
        onClick: () => this.props.globalModalCloseAction(),
      }, {
        type: 'primary',
        title: 'Удалить',
        onClick: onDelete,
      }],
    })
  }

  private onPay = (appointment: Appointment) => {
    const { appointment_id, paysum: price = appointment.appointment_cost } = appointment
    this.props.paymentParamsClearAction()
    this.props.paymentParamsSetAction({ appointment_id, price })
    const { patient } = this.props
    const { medic_user_id } = patient
    this.props.paymentAppointmentTypeRequestAction({ appointment_id, medic_user_id: medic_user_id as number })
  }

  private fetchCombinedPayment = () => {
    const { patient, paymentParams } = this.props
    const { medic_user_id } = patient
    const { appointment_id } = paymentParams
    this.props.paymentCombinedAppointmentsRequestAction({
      appointment_id: appointment_id as number,
      medic_user_id: medic_user_id as number,
    })
  }

  private gotoCombinedPayment = () => {
    this.props.combinedAppointmentsModalSetAction({
      visible: true,
      onPay: () => {
        this.props.combinedAppointmentsModalCloseAction()
        this.gotoPayment()
      },
    })
  }

  private gotoPayment = () => {
    const { paymentParams } = this.props
    const { price } = paymentParams
    this.props.bonusPaymentModalSetAction({
      visible: true,
      price,
      onPay: ({ bonusCode, bonusPoints }) => {
        this.props.paymentParamsSetAction({ bonusCode, bonusPoints })
        this.props.paymentParamsMakePaymentAction()
      },
      onSkip: () => {
        this.props.paymentParamsMakePaymentAction()
      }
    })
  }

  private onRepeat = (appointment: Appointment) => {
    const { patient, history } = this.props
    const { service_id, pls_id, is_tm } = appointment
    this.props.recordClearAction()
    this.props.recordStateSetAction({
      is_repeat: true,
      stage: 5,
      format: is_tm ? 'online' : 'offline',
      patient: patient as Patient,
      catalog: {} as CatalogPreview,
      service: { service_id, pls_id } as Price,
    })
    history.replace('/recording')
  }

  private onInfo = (appointment: Appointment) => {
    this.props.pricelistPrepareInfoRequestAction({ pls_id: appointment.pls_id })
    this.props.pricelistPrepareInfoModalSetAction({ visible: true })
  }

  render() {
    const { activeTab } = this.props
    return <Template
      className="journal"
      title="Журнал записей"
      tabs={JournalView.tabs}
      onTabChange={this.onTabChange}
      sidebar={<div className="sidebar-default" />}
      currentItem="journal"
      headerFooter={<PatientSelect
        mainPatients={this.props.patients.byphone.main.users}
        addedPatients={this.props.patients.byphone.added.users}
        selectedPatient={this.props.patient}
        onChangePatient={this.onChangePatient}
      />}
    >
      {activeTab == '0' && this.renderFutureAppointments()}
      {activeTab == '1' && this.renderPastAppointments()}
    </Template>
  }
}

const connector = connect(
  (state: AppState) => ({
    user: state.user,

    appointments_main: state.appointments.main,
    appointments_byphone: state.appointments.byphone,
    appointments_bymistoken: state.appointments.bymistoken,
    past_appointments: state.appointments.past,
    patients: state.patients,

    auto_payments: state.autoPayments.main,

    patient: state.appointments.view.patient,
    activeTab: state.appointments.view.activeTab,
    
    appointments_delete_main: state.appointments.delete.main,
    appointments_delete_paid: state.appointments.delete.paid,

    prepareInfo: state.pricelist.prepareInfo,

    paymentParams: state.payment.params,
    combinedPayment: state.payment.combinedAppointments,
    paymentType: state.payment.appointmentType,
  }), {
  appointmentsMainRequestAction,
  appointmentsByPhoneRequestAction,
  appointmentsByMisTokenRequestAction,

  autoPaymentsMainRequestAction,

  pastAppointmentsMainRequestAction,
  patientsByphoneRequest,

  appointmentsViewPatientSetAction,
  appointmentsViewActiveTabSetAction,

  deleteAppointmentMainRequestAction,
  deletePaidAppointmentRequestAction,

  globalModalSetAction,
  globalModalCloseAction,

  pricelistPrepareInfoRequestAction,
  pricelistPrepareInfoModalSetAction,

  paymentParamsSetAction,
  paymentParamsClearAction,
  paymentParamsMakePaymentAction,
  bonusPaymentModalSetAction,

  paymentCombinedAppointmentsRequestAction,
  paymentAppointmentTypeRequestAction,
  combinedAppointmentsModalSetAction,
  combinedAppointmentsModalCloseAction,

  recordStateSetAction,
  recordClearAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(JournalView))