import { ApiResponse, ApisauceInstance, create, RequestTransform, ResponseTransform } from 'apisauce'
import lazyStore from '../store/lazyStore'
import { logoutRequest } from '../store/reducks/auth/auth.actions'

export type ApiConfig = {
  baseURL: string
  timeout?: number
  headers?: Record<string, string>
  requestTransform?: RequestTransform
  responseTranform?: ResponseTransform
}

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'PATCH'

export type RequestMethods = Methods | Lowercase<Methods>

export type DefaultResponse = {
  message?: string
}

export type RequestReturnType<T = any, U = T> = {
  error: false
  data: T
  status?: number
  url: string
} | {
  error: true,
  data: U
  status?: number
  url: string
}

export type RequestOptions<P = any, D = any> = {
  url: string
  method?: RequestMethods
  headers?: Record<string, string>
  data?: D
  params?: P
  timeout?: number
}

class API {
  private config: ApiConfig
  private instance: ApisauceInstance
  constructor(config: ApiConfig) {
    this.config = config
    this.instance = create({
      baseURL: config.baseURL,
      timeout: config.timeout,
      headers: config.headers,
    })
    if (config.requestTransform) {
      this.instance.addRequestTransform(config.requestTransform)
    }
    if (config.responseTranform) {
      this.instance.addResponseTransform(config.responseTranform)
    }
  }

  getConfig = () => {
    return this.config
  }

  getInstance = () => {
    return this.instance
  }

  request = async <T = any, U = T>(options: RequestOptions) => {
    const method = (options.method || 'GET').toLowerCase() as Lowercase<RequestMethods>
    let res: ApiResponse<T, U>
    switch (method) {
      case 'get':
      case 'head':
        res = await this.requestWithoutBody<T, U>(options.url, { ...options, method })
        break
      default:
        res = await this.requestWithBody<T, U>(method, options.url, options)
    }
    if (res.ok) {
      return { error: false, data: res.data as T, status: res.status, url: options.url } as const
    }
    // @ts-ignore
    if (res.status == 401 || (res.status == 400 && res.data && res.data?.message == 'token not found')) {
      this.logout()
    }
    const data = res.data === null ? { message: 'query error' } : res.data
    return { error: true, data: data as U, status: res.status, url: options.url } as const
  }

  private requestWithoutBody = async <T = any, U = T>(url: string, options: Omit<RequestOptions, 'url' | 'method'> & { method: 'get' | 'head' }) => {
    const headers = options.headers
    const timeout = options.timeout
    const res = await this.instance[options.method]<T, U>(url, options?.params, { headers, timeout })
    return res
  }
  private requestWithBody = async <T = any, U = T>(method: Exclude<Lowercase<RequestMethods>, 'get' | 'head'>, url: string, options: Omit<RequestOptions, 'url' | 'method'>) => {
    const headers = options.headers
    const timeout = options.timeout
    const params = options.params
    const res = await this.instance[method]<T, U>(url, options.data, { headers, timeout, params })
    return res
  }
  private logout() {
    lazyStore.store.dispatch(logoutRequest())
  }
}

export default API