import { AppointmentsByMisTokenAction, AppointmentsByMisTokenState } from "./appointments.bymistoken.types"

export const initialAppointmentsByMisTokenState: AppointmentsByMisTokenState = {
  fetching: false,
  appointments: [],
}

const AppointmentsByMisTokenReducer = (state = initialAppointmentsByMisTokenState, action: AppointmentsByMisTokenAction): AppointmentsByMisTokenState => {
  switch (action.type) {
    case 'APPOINTMENTS_BY_MISTOKEN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'APPOINTMENTS_BY_MISTOKEN_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: action.params,
      }
    case 'APPOINTMENTS_BY_MISTOKEN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAppointmentsByMisTokenState,
      }
    default:
      return state
  }
}

export default AppointmentsByMisTokenReducer