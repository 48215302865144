import { AutoPaymentsMainAction, AutoPaymentsMainState } from "./autoPayments.main.types"

export const initialAutoPaymentsMainState: AutoPaymentsMainState = {
  fetching: false,
  auto_payments: [],
}

const AutoPaymentsMainReducer = (state = initialAutoPaymentsMainState, action: AutoPaymentsMainAction): AutoPaymentsMainState => {
  switch (action.type) {
    case 'AUTO_PAYMENT_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'AUTO_PAYMENT_MAIN_SUCCESS':
      return {
        ...state,
        fetching: false,
        auto_payments: action.params,
      }
    case 'AUTO_PAYMENT_MAIN_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialAutoPaymentsMainState,
      }
    default:
      return state
  }
}

export default AutoPaymentsMainReducer