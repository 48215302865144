import { PatientsByphoneMainAction, PatientsByphoneMainState } from "./patients.byphone.main.types"

export const initialPatientsByphoneMainState: PatientsByphoneMainState = {
  fetching: false,
  users: [],
}

const PatientsByphoneMainReducer = (state = initialPatientsByphoneMainState, action: PatientsByphoneMainAction): PatientsByphoneMainState => {
  switch (action.type) {
    case 'PATIENTS_BYPHONE_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENTS_BYPHONE_MAIN_SUCCESS':
    case 'PATIENTS_BYPHONE_MAIN_FAILURE':
      return {
        ...state,
        ...action.params,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
    case 'PATIENTS_CLEAR':
      return {
        ...initialPatientsByphoneMainState,
      }
    default:
      return state
  }
}

export default PatientsByphoneMainReducer