import moment from 'moment'
import React from 'react'
import { Button, Col, Row } from 'antd'
import RecordingSuccessImage from '../../../images/recording_success.png'
import { RecordState } from '../../../store/reducks/record/record.types'
import { AvatarIcon, NoticeIcon, RubleIcon, ServiceIcon, DoctorIcon } from '../../../icons'
import { Patient } from "../../../api/patient.types"
import capitalize from '../Stage6/capitalize'
import './style.less'
import Record from '../../../store/reducks/record/Record'

export type RecordingSuccessProps = {
  record: RecordState
  patients: Patient[]
  onPrepareInfo?: () => void
  onPay?: () => void
  onSubmit?: () => void
}

class RecordingSuccess extends React.Component<RecordingSuccessProps> {
  private renderFooterRow = (props: { icon: any; name: string; description: string }) => {
    const { icon, name, description } = props
    return <Row className="row">
      <Col span={12} className="left">
        {icon}
        <span>{name}</span>
      </Col>
      <Col span={12} className="right">
        {description}
      </Col>
    </Row>
  }

  private getPatientName = () => {
    const { record, patients } = this.props
    if (record.patient) {
      const p = patients.find(p => p.medic_user_id == record.patient?.medic_user_id)
      if (!p) {
        return ''
      }
      const names = p.name.split(/\s+/g)
      return [names[names.length - 1], ...names.slice(0, -1)].join(' ')
    }
    if (record.new_patient) {
      const { firstname, lastname, midname } = record.new_patient
      return [`${capitalize(lastname.slice(0, 1))}.`,  capitalize(firstname), capitalize(midname)].join(' ')
    }
    return ''
  }

  private renderPrice = () => {
    return `${this.getPrice()} ₽`
  }

  private getPrice = () => {
    const { record } = this.props
    return Record.getPrice(record)
  }

  private getDoctorName = () => {
    const { record } = this.props
    if (record.doctor) {
      return record.doctor.doctor_name || ''
    }
    return ''
  }

  render() {
    const { record, onPrepareInfo, onPay, onSubmit } = this.props
    const price = this.getPrice()
    return <div className="recording-success">
      <img src={RecordingSuccessImage} />
      <span className="header">Запись прошла успешно</span>
      <div className="description">
        <span>{`Ожидаем вас в ${capitalize(moment(record.datetime).format('dddd'))}`}</span>
        <span>
          <b>{`${moment(record.datetime).format('DD MMMM')} в ${moment(record.datetime).format('HH:mm')}`}</b>
          {`в клинике по`}
        </span>
        <span>{`адресу: `}<b>{record.address}</b></span>
      </div>
      <Button type="default" className="prepare-info" onClick={onPrepareInfo}>
        <div className="prepare-info-content">
          <NoticeIcon />
          <span>Информация о подготовке</span>
        </div>
      </Button>
      {price ? <>
        <span className="">Вы можете оплатить прием заранее</span>
      <Button type="primary" className="pay" onClick={onPay}>Оплатить</Button>
      </> : null}
      <div className="footer">
        {this.renderFooterRow({ icon: <AvatarIcon />, name: 'Клиент', description: this.getPatientName() })}
        {this.renderFooterRow({ icon: <RubleIcon />, name: 'Стоимость', description: this.renderPrice() })}
        {this.renderFooterRow({ icon: <ServiceIcon />, name: 'Услуга', description: record.service?.name || '' })}
        {this.renderFooterRow({ icon: <DoctorIcon />, name: 'Врач', description: this.getDoctorName() })}
      </div>
      <Button type="primary" className="submit" onClick={onSubmit}>Готово</Button>
    </div>
  }
}

export default RecordingSuccess