import React, { Component } from 'react'
import { Modal } from '../../index'
import './style.less'
import { CheckIcon } from '../../../icons'

interface Props {
  stage: number
  stages: string[]
}

interface State {}
class RecordingSidebar extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    const { stage, stages } = this.props
    return (
      <div className="recording-sidebar">
        <h4 className="title">Процесс записи</h4>
        <div className="stages">
          {stages.map((label, index) => {
            const $stage = index + 1
            const isCurrent = $stage == stage
            const isPassed = $stage < stage
            const className = ['stage']
            if (isCurrent) className.push('current')
            if (isPassed) className.push('passed')
            return (
              <div key={index.toString()} className={className.join(' ')}>
                <span className="dot-wrapper">
                  <span className="dot">{isPassed && <CheckIcon />}</span>
                </span>
                <span className="label">
                  {$stage}
                  {'. '}
                  {label}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default RecordingSidebar
