import React from 'react'
import Modal from '../../components/Modal'
import { Button } from 'antd'
import Calendar from '../../components/Calendar'
import moment, { Moment } from 'moment'
import 'moment/locale/ru'
import { birthdateValidation } from '../../tools/validation'
import './style.less'

type Props = {
  visible: boolean
  onCancel: () => void
  onAccept: (date: string) => void
  date?: string
}

interface State {
  selectedDate: string
}

class BirthdateModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedDate: ''
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { date, visible } = this.props
    const { selectedDate } = this.state

    if (!prevProps.visible && visible && !!date && date !== selectedDate) {
      const _date = !!birthdateValidation(date) ? moment().format('DD.MM.YYYY') : date
      this.setState({ selectedDate: _date })
    }

  }

  private onDate = (date: Moment) => {
    const dateString = date.format('DD.MM.YYYY')
    this.setState({ selectedDate: dateString, })
  }

  render() {
    const { visible, onCancel, onAccept } = this.props
    const { selectedDate } = this.state

    return <Modal
      visible={visible}
      onCancel={onCancel}
      title="Выбор даты рождения"
      className="birthdate__modal"
    >
      <Calendar
        initValue={!!selectedDate ? moment(selectedDate, 'DD.MM.YYYY') : moment(Date.now())}
        value={!!selectedDate ? moment(selectedDate, 'DD.MM.YYYY') : moment(Date.now())}
        onSelect={this.onDate}
        validRange={[moment('01-01-1900', 'MM-DD-YYYY'), moment()]}
        disabledDate={() => false}
      />

      <div className="modal__button-row">
        <Button
          className='button-row__button'
          type='default'
          onClick={onCancel}
        >
          Отмена
        </Button>
        <Button
          className='button-row__button'
          type='primary'
          onClick={() => onAccept(selectedDate)}
        >
          Выбрать
        </Button>
      </div>
    </Modal>
  }
}

export default BirthdateModal
