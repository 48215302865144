import React from 'react'
import FileIcon from '../../icons/file.png'

export interface MessageTextProps {
  text: string;
}

export type TextItem = {
  text: string;
  href?: string;
  is_pdf?: boolean;
  is_file?: boolean;
}

export type MessageTextState = {
  is_html?: boolean;
  is_file?: boolean;
  is_link?: boolean;
  texts: TextItem[];
}

const URLRegExp = /https?\:\/\/[^\s]+/
const FILERegExp = /(https?\:\/\/[^\s]+)\s+(\([^()]+\))/g
const LINKRegExp = /(https?\:\/\/[^\s]+)/

export const Text: React.FC = (props) => {
  return <span {...props}>
    {props.children}
  </span>
}

export const View: React.FC<{ className?: string }> = (props) => {
  const { className, ...rest } = props
  return <div
    className={`msg-text-view ${className || ''}`}
    {...rest}
  >
    {props.children}
  </div>
}

const htmlParse = (html: string) => {
  const div = document.createElement('div')
  div.innerHTML = html
  return div.firstChild as ChildNode
}

export default class MessageText extends React.Component<MessageTextProps, MessageTextState> {
  constructor(props: MessageTextProps) {
    super(props)
    this.state = {
      texts: [{ text: props.text }]
    }
  }

  componentDidMount() {
    if (this.isHTML()) {
      this.setState({
        is_html: true,
        texts: this.parseHtml(),
      })
    } else if (this.isFile()) {
      this.setState({
        is_file: true,
        texts: this.parseFile(),
      })
    } else {
      const texts = this.parseText(this.props.text)
      this.setState({
        is_link: texts.some(t => t.href),
        texts,
      })
    }
  }

  private isHTML = () => {
    const { text } = this.props
    return text ? text.indexOf('%html%') == 0 : false
  }

  private isFile = () => {
    const is_html = this.isHTML()
    if (is_html) {
      return false
    }
    const { text } = this.props
    return FILERegExp.test(text)
  }

  private parseHtml = () => {
    const { text } = this.props
    const doc = htmlParse(`<html>${text.slice('%html%'.length)}</html>`)
    const childNodes = Array.prototype.slice.call(doc.childNodes[0].childNodes) // потому что это не массив
    const texts: TextItem[] = childNodes.map(node => {
      if (node.tagName != 'a') {
        return {
          text: node.nodeValue,
        }
      }
      return {
        href: node.getAttribute('href'),
        text: node.childNodes.length ? node.childNodes[0].nodeValue : node.getAttribute('href'),
      }
    })
    return texts
  }

  private parseFile = () => {
    const { text } = this.props
    const texts: TextItem[] = []
    let start = 0
    const res = text.replace(FILERegExp, (match, p1, p2, offset, string) => {
      const prevText = string.slice(start, offset)
      if (prevText) {
        const links = this.parseText(prevText)
        texts.push(...links)
      }
      texts.push({
        href: p1,
        text: p2,
        is_pdf: this.isPDF(p2),
        is_file: true,
      })
      start = offset + match.length
      return p2
    })
    if (start != text.length) {
      const links = this.parseText(text.slice(start))
      texts.push(...links)
    }
    return texts
  }

  private parseText = (text: string) => {
    const texts: TextItem[] = []
    let start = 0
    const res = text.replace(LINKRegExp, (match, p1, offset, string) => {
      const prevText = string.slice(start, offset)
      if (prevText) {
        texts.push({ text: prevText })
      }
      texts.push({
        href: p1,
        text: p1,
      })
      start = offset + match.length
      return p1
    })
    if (start != text.length) {
      texts.push({
        text: text.slice(start)
      })
    }
    return texts
  }

  private isPDF = (text: string) => {
    const [name] = text.split(/\s+/g)
    const parts = name.replace(/\,$/, '').split('.')
    const extension = parts[parts.length - 1]
    return extension ? extension.toLowerCase() == 'pdf' : false
  }

  private renderFile = (file: TextItem, index: number, array: TextItem[]) => {
    if (!file.href) {
      return this.renderText(file.text)
    }
    return <View
      key={index}
    >
      {file.is_file && <img src={FileIcon} style={{ width: 24, height: 24 }}/>}
      {this.renderText(<a href={file.href} target="_blank">{file.text}</a>)}
    </View>
  }

  private renderText = (text: any) => {
    return <Text>
      {text}
    </Text>
  }

  private renderLink = (link: TextItem) => {
    if (!link.href) {
      return link.text
    }
    return <a href={link.href} target="_blank">
      {link.text}
    </a>
  }

  render() {
    const { text } = this.props
    const { is_file, is_html, is_link, texts } = this.state
    if (!is_html && !is_file && !is_link) {
      return this.renderText(text)
    }
    if (is_html || is_link) {
      return <Text>
        {texts.map(this.renderLink)}
      </Text>
    }
    return <View>
      {texts.map(this.renderFile)}
    </View>
  }
}