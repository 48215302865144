import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { clinicContactsFailureAction, clinicContactsSuccessAction } from "./clinic.contacts.actions";
import { clinicDepartmentsFailure, clinicDepartmentsSuccess } from "./clinic.departments.actions";
import { clinicPricesByserviceFailure, clinicPricesByserviceSuccess } from "./clinic.prices.byservice.actions";
import { ClinicAction } from "./clinic.types";


export function* clinicDepartmentsRequestSaga(action: ClinicAction) {
  const res: ThenReturnType<typeof api.clinic.getDepartments> = yield call(api.clinic.getDepartments)
  if (res.error) {
    yield put(clinicDepartmentsFailure())
  } else {
    yield put(clinicDepartmentsSuccess(res.data.departments))
  }
}

// // TODO
// export function* clinicPricesByServiceRequestSaga(action: any) {
//   const { service_id } = action.params as { service_id: number }
//   const res: ThenReturnType<typeof api.clinic.getPricesByService> = yield call(api.clinic.getPricesByService, { service_id })
//   if (res.error) {
//     yield put(clinicPricesByserviceFailure())
//   } else {
//     yield put(clinicPricesByserviceSuccess(res.data.prices))
//   }
// }

export function* clinicContactsRequestSaga(action: any) {
  const res: ThenReturnType<typeof api.clinic.getContacts> = yield call(api.clinic.getContacts)
  if (res.error) {
    yield put(clinicContactsFailureAction())
  } else {
    yield put(clinicContactsSuccessAction(res.data.contacts))
  }
}

export default function* watchClinic() {
  yield takeLeadingPattern('CLINIC_DEPARMENTS_REQUEST', clinicDepartmentsRequestSaga, clinicDepartmentsFailure)
  // yield takeLeadingPattern('CLINIC_PRICES_BYSERVICE_REQUEST', clinicPricesByServiceRequestSaga, clinicPricesByserviceFailure)
  yield takeLeadingPattern<ClinicAction['type']>('CLINIC_CONTACTS_REQUEST', clinicContactsRequestSaga, clinicContactsFailureAction)
}
