import { PaymentAppointmentTypeAction, PaymentAppointmentTypeState } from "./payment.appointmentType.types"

export const initialPaymentAppointmentTypeState: PaymentAppointmentTypeState = {
  fetching: false,
  type: {},
}

const PaymentAppointmentTypeReducer = (state = initialPaymentAppointmentTypeState, action: PaymentAppointmentTypeAction): PaymentAppointmentTypeState => {
  switch (action.type) {
    case 'PAYMENT_APPOINTMENT_TYPE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        type: {},
      }
    case 'PAYMENT_PARAMS_CLEAR':
      return {
        ...initialPaymentAppointmentTypeState,
      }
    case 'PAYMENT_APPOINTMENT_TYPE_SUCCESS':
      return {
        ...state,
        fetching: false,
        type: action.params,
      }
    case 'PAYMENT_APPOINTMENT_TYPE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPaymentAppointmentTypeState,
      }
    default:
      return state
  }
}

export default PaymentAppointmentTypeReducer