import React, { Component } from 'react'

interface Props {
  duration?: number
  onDone: () => void
}

interface State {
  timer: number
}

class Timer extends Component<Props, State> {
  private timeout: any = null

  constructor(props: Props) {
    super(props)
    this.state = {
      timer: 0,
    }
  }

  componentDidMount() {
    this.setState({ timer: this.props.duration || 0 }, this.tick)
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  tick() {
    const { onDone = () => {} } = this.props
    const { timer } = this.state
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    if (timer > 0) {
      this.timeout = setTimeout(() => {
        this.setState({ timer: timer - 1 }, this.tick)
      }, 1000)
    } else {
      onDone()
    }
  }

  render() {
    const { timer } = this.state
    return <span>{timer}</span>
  }
}

export default Timer
