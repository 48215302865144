import { CatalogItem } from "../../../api/pricelist.types"
import { PricelistCatalogServicesAction, PricelistCatalogServicesState } from "./pricelist.catalog_services.types"

export const initialClinicCatalogServicesState: PricelistCatalogServicesState = {
  fetching: false,
  services: [],
}

const PricelistCatalogServicesReducer = (state = initialClinicCatalogServicesState, action: PricelistCatalogServicesAction): PricelistCatalogServicesState => {
  switch (action.type) {
    case 'PRICELIST_CATALOG_SERVICES_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PRICELIST_CATALOG_SERVICES_SUCCESS':
      return {
        ...state,
        fetching: false,
        services: action.params as CatalogItem[]
      }
    case 'PRICELIST_CATALOG_SERVICES_FAILURE':
      return {
        ...state,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialClinicCatalogServicesState,
      }
    default:
      return state
  }
}

export default PricelistCatalogServicesReducer