import React, { Component } from 'react'

class TemplateMain extends Component<{}, {}> {
  render() {
    const { children } = this.props
    return <div className="template__main">{children}</div>
  }
}

export default TemplateMain
