import { combineReducers } from 'redux'
import BonusPaymentModalReducer from './modals.bonusPayment.reducer'
import CombinedAppointmentsModalReducer from './modals.combinedAppointments.reducer'
import GlobalModalReducer from './modals.global.reducer'
import MedcardAuthModalReducer from './modals.medcardAuth.reducer'
import DoctorInfoModalReducer from './modals.doctorInfo.reducer'

const ModalsReducer = combineReducers({
  global: GlobalModalReducer,
  bonusPayment: BonusPaymentModalReducer,
  medcardAuth: MedcardAuthModalReducer,
  combinedAppointments: CombinedAppointmentsModalReducer,
  doctorInfo: DoctorInfoModalReducer,
})

export default ModalsReducer