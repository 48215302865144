import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Button } from 'antd'
import { AppState } from '../../store'
import Modal from '../../components/Modal'
import './style.less'
import { globalModalCloseAction } from '../../store/reducks/modals/modals.global.actions'

export type GlobalModalProps = ReduxConnectedProps & {
}

class GlobalModal extends React.Component<GlobalModalProps> {
  private renderButtons = () => {
    const { modal } = this.props
    const { buttons } = modal
    if (!buttons || !buttons.length) {
      return null
    }
    return <div className="buttons">
      {buttons.map((button, index) => (<Button
        key={index}
        type={button.type}
        onClick={button.onClick}
        className="modal-button"
      >
        {button.title || ''}
      </Button>))}
    </div>
  }

  private onClose = () => {
    const { modal, globalModalCloseAction } = this.props
    modal.onClose && modal.onClose()
    globalModalCloseAction()
  }

  render() {
    const { modal } = this.props
    return <Modal
      width={520}
      visible={modal.visible}
      className="global-modal"
      onCancel={this.onClose}
      title={modal.title}
    >
      <div className="global-modal-body">
        <span>{modal.content || ''}</span>
        {this.renderButtons()}
      </div>
    </Modal>
  }
}

const connector = connect(
  (state: AppState) => ({
    modal: state.modals.global,
  }), {
  globalModalCloseAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(GlobalModal)