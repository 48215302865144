import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import './style.less'

interface Props {
  label: string
  onChange: () => void
  className?: string
  checked?: boolean
}

export default (props: Props) => {
  const { className, label, onChange, checked } = props

  return (
    <FormControlLabel
      className={`checkbox-form ${className}`}
      control={<Checkbox
        className='checkbox-button'
        color="primary"
        checked={checked}
        onChange={onChange}
        name="public_value"
      />}
      label={label}
    />
  )
}