import { PaymentCombinedAppointmentsAction, PaymentCombinedAppointmentsState } from "./payment.combinedAppointments.types"

export const initialPaymentCombinedAppointmentsState: PaymentCombinedAppointmentsState = {
  fetching: false,
  appointments: [],
}

const PaymentCombinedAppointmentsReducer = (state = initialPaymentCombinedAppointmentsState, action: PaymentCombinedAppointmentsAction): PaymentCombinedAppointmentsState => {
  switch (action.type) {
    case 'PAYMENT_COMBINED_APPOINTMENTS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
        appointments: [],
        is_partial: false,
      }
    case 'PAYMENT_PARAMS_CLEAR':
      return {
        ...initialPaymentCombinedAppointmentsState,
      }
    case 'PAYMENT_COMBINED_APPOINTMENTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        appointments: action.params?.appointments,
        is_partial: action.params?.is_partial,
      }
    case 'PAYMENT_COMBINED_APPOINTMENTS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialPaymentCombinedAppointmentsState,
      }
    default:
      return state
  }
}

export default PaymentCombinedAppointmentsReducer