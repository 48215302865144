import config from "../config";
import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { Appointment, AppointmentFuture, AppointmentFutureMain } from "./appointment.types";
import { shift3hoursDatetime } from "./utils/shift3hoursDatetime";

export const fixAppointmentDateTimes = (appointment: Appointment): Appointment => ({
  ...appointment,
  appointment_date_time: shift3hoursDatetime(appointment.appointment_date_time),
  appointment_inserted_at: shift3hoursDatetime(appointment.appointment_inserted_at),
})

export const fixAppointmentByServiceDatetimes = (appointment: AppointmentFuture): AppointmentFuture => ({
  ...appointment,
  datetime: shift3hoursDatetime(appointment.datetime),
})

class AppointmentAPI {
  private static GET_FUTURE_APPOINTMENTS_BY_SERVICE = '/get_appointments_upcoming_by_service_id_and_patient' as const
  private static GET_FUTURE_APPOINTMENTS_MAIN = '/get_appointments_upcoming' as const

  private static GET_PAST_APPOINTMENTS_MAIN = '/get_past_appointments' as const
  
  private static GET_APPOINTMENTS_MAIN = '/get_appointments' as const
  private static GET_APPOINTMENTS_BY_PHONE = '/get_another_user_by_phone_records' as const
  private static GET_APPOINTMENTS_BY_MISTOKEN = '/get_another_user_records' as const

  private static DELETE_APPOINTMENT = '/delete_appointment' as const
  private static DELETE_PAID_APPOINTMENT = `${config.api.telemedURL}/api/v2/ecom_refund` as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(AppointmentAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && res.url == AppointmentAPI.GET_FUTURE_APPOINTMENTS_BY_SERVICE) {
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { appointments: res.data.appointments_upcoming.map(fixAppointmentByServiceDatetimes) } as T,
      } as const
    }
    if (!res.error && res.url == AppointmentAPI.GET_FUTURE_APPOINTMENTS_MAIN) {
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { appointments: res.data.appointments_upcoming.map(fixAppointmentDateTimes) } as T,
      } as const
    }
    if (!res.error && (
      res.url == AppointmentAPI.GET_PAST_APPOINTMENTS_MAIN
      || res.url == AppointmentAPI.GET_APPOINTMENTS_MAIN
      || res.url == AppointmentAPI.GET_APPOINTMENTS_BY_PHONE
      || res.url == AppointmentAPI.GET_APPOINTMENTS_BY_MISTOKEN
    )) {
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { appointments: res.data.appointments.map(fixAppointmentDateTimes) } as T
      } as const
    }
    return res
  }

  getFutureAppointmentsByService = async (params: { service_id: number; medic_user_id: number; }) => {
    const res = await this.request<{ appointments: AppointmentFuture[] }, { message: string }>({
      method: 'get',
      url: AppointmentAPI.GET_FUTURE_APPOINTMENTS_BY_SERVICE,
      params,
    })
    return res
  }

  getFutureAppointmentsMain = async () => {
    const res = await this.request<{ appointments: AppointmentFutureMain[] }, { message: string }>({
      method: 'get',
      url: AppointmentAPI.GET_FUTURE_APPOINTMENTS_MAIN,
    })
    return res
  }

  getPastAppointmentsMain = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ appointments: Appointment[] }, { message: string }>({
      method: 'get',
      url: AppointmentAPI.GET_PAST_APPOINTMENTS_MAIN,
      params,
    })
    return res
  }

  getAppointmentsMain = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ appointments: Appointment[] }, { message: string }>({
      method: 'get',
      url: AppointmentAPI.GET_APPOINTMENTS_MAIN,
      params,
    })
    return res
  }
  getAppointmentsByPhone = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ appointments: Appointment[] }, { message: string }>({
      method: 'get',
      url: AppointmentAPI.GET_APPOINTMENTS_BY_PHONE,
      params,
    })
    return res
  }
  getAppointmentsByMisToken = async (params: { medic_user_id: number }) => {
    const res = await this.request<{ appointments: Appointment[] }, { message: string }>({
      method: 'get',
      url: AppointmentAPI.GET_APPOINTMENTS_BY_MISTOKEN,
      params,
    })
    return res
  }

  deleteAppointment = async (params: { medic_user_id: number; appointment_id: number }) => {
    const res = await this.request<string, { message: string }>({
      method: 'delete',
      url: AppointmentAPI.DELETE_APPOINTMENT,
      params,
    })
    return res
  }

  deletePaidAppointment = async (params: { appointment_id: number; }) => {
    const res = await this.request<{ success: 0 | 1 }>({
      method: 'get',
      url: AppointmentAPI.DELETE_PAID_APPOINTMENT,
      params: {
        login: lazyStore.store.getState().user.phone,
        direction_service_id: params.appointment_id,
      },
    })
    return res
  }
}

export default AppointmentAPI