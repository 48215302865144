import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { ClinicContact, Department } from "./clinic.types";

class ClinicAPI {
  private static GET_CONTACTS = '/get_contacts' as const
  private static GET_DEPARTMENTS = '/get_doctor_departments' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(ClinicAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    return res
  }

  getContacts = async () => {
    const res = await this.request<{ contacts: ClinicContact[] }, { message: string }>({
      method: 'get',
      url: ClinicAPI.GET_CONTACTS,
    })
    return res
  }

  getDepartments = async () => {
    const res = await this.request<{ departments: Department[] }, { message: string }>({
      method: 'get',
      url: ClinicAPI.GET_DEPARTMENTS,
    })
    return res
  }
}

export default ClinicAPI