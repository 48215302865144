import React from 'react'
import { VisitItem } from '../../../api/medcard.types'
import { Patient } from '../../../api/patient.types'
import JournalItem, { ButtonProps } from '../../JournalItem'
import './style.less'

export type MedcardVisitProps = {
  visit: VisitItem
  patient?: Patient // TODO
  onProtocol?: (visit: VisitItem) => void
  onRepeat?: (visit: VisitItem) => void
}

class MedcardVisit extends React.Component<MedcardVisitProps> {
  private getButtons = () => {
    const { visit } = this.props
    const buttons: ButtonProps[] = []
    buttons.push({
      type: 'primary',
      text: 'Скачать протокол',
      onClick: this.onProtocol,
    })
    if (!visit.is_tm && visit.is_allowed_for_this_patient && visit.allow_rec) {
      buttons.push({
        type: 'default',
        text: 'Записаться повторно',
        onClick: this.onRepeat,
      })
    }
    return buttons
  }
  private onProtocol = () => {
    const { visit, onProtocol } = this.props
    onProtocol && onProtocol(visit)
  }
  private onRepeat = () => {
    const { visit, onRepeat } = this.props
    onRepeat && onRepeat(visit)
  }

  render() {
    const { visit } = this.props
    return <JournalItem
      className="medcard-visit"
      datetime={visit.visit_date}
      datetimeFormat={'DD MMMM YYYY'}

      specialization={visit.pls_name}
      is_tm={visit.is_tm}
      hideIcon

      doctorName={visit.visit_name}
      service={visit.visit_spec}

      longButton
      buttons={this.getButtons()}
    />
  }
}

export default MedcardVisit