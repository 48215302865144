import { call, put } from "redux-saga/effects";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import {ChangePasswordAction} from "./changePassword.types";
import {changePasswordFailureAction, changePasswordSuccessAction} from "./changePassword.actions";

export function* changePasswordRequestSaga(action: ChangePasswordAction) {
	const res: ThenReturnType<typeof api.login.changePassword> = yield call(api.login.changePassword, action.params)
	if (res.error) {
		yield put(changePasswordFailureAction())
	} else {
		yield put(changePasswordSuccessAction())
	}
}


export default function* watchChangePassword() {
	yield takeLeadingPattern<ChangePasswordAction['type']>('CHANGE_PASSWORD_REQUEST', changePasswordRequestSaga,changePasswordFailureAction)
}