import { ResearchesFileAction, ResearchesFileState } from "./researches.file.types"

export const initialResearchesFileState: ResearchesFileState = {
  fetching: false,
  file: {},
}

const ResearchesFileReducer = (state = initialResearchesFileState, action: ResearchesFileAction): ResearchesFileState => {
  switch (action.type) {
    case 'RESEARCHES_FILE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'RESEARCHES_FILE_SUCCESS':
      return {
        ...state,
        fetching: false,
        file: action.params,
      }
    case 'RESEARCHES_FILE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'RESEARCHES_FILE_CLEAR':
      return {
        ...state,
        file: {},
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialResearchesFileState,
      }
    default:
      return state
  }
}

export default ResearchesFileReducer