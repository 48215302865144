import React from 'react'
import { Button } from 'antd'
import TextInput from '../TextInput'
import { MaskedInput } from 'antd-mask-input'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { callMeRequestAction } from '../../store/reducks/feedback/call.me.actions'
import './style.less'
import parsePhone from '../../tools/parsePhone'

export type CallMeProps = ReduxConnectedProps & {
  phone: string
  onSubmit?: () => void
}

export type CallMeState = {
  name?: string
  phone: string
  nameError?: string
  phoneError?: string
}

class CallMe extends React.Component<CallMeProps, CallMeState> {
  constructor(props: CallMeProps) {
    super(props)
    this.state = {
      phone: props.phone,
    }
  }

  private onSubmit = () => {
    const { onSubmit } = this.props
    const { name, phone } = this.state
    const isValid = this.validate()
    if (isValid && name) {
      this.props.callMeRequestAction({ name, phone: +parsePhone(phone) })
      onSubmit && onSubmit()
    }
  }

  private validate = () => {
    const { name, phone } = this.state
    let isValid = true
    if (!phone || parsePhone(phone).length < 10) {
      isValid = false
      this.setState({ phoneError: 'Необходимо ввести номер телефона' })
    }
    if (!name) {
      isValid = false
      this.setState({ nameError: 'Необходимо ввести имя' })
    }
    return isValid
  }

  private isValid = () => {
    const { name, phone } = this.state
    const res = Boolean(name) && phone && parsePhone(phone).length == 10
    return res
  }

  render() {
    const { name, phone, nameError, phoneError } = this.state
    return <div className="call-me">
      <TextInput
        label="Имя"
        className="name"
        value={name}
        onChangeText={name => this.setState({ name, nameError: undefined })}
        error={Boolean(nameError)}
        helperText={nameError}
      />
      <MaskedInput
        className="phone"
        prefix="+7"
        value={phone}
        mask="(111) 111-11-11"
        placeholder="Номер телефона"
        onPressEnter={this.onSubmit}
        onChange={(e) => {
          this.setState({ phone: e.target.value, phoneError: undefined })
        }}
        size="large"
      />
      <Button
        type="primary"
        className="submit"
        disabled={!this.isValid()}
        onClick={this.onSubmit}
      >
        {'Отправить'}
      </Button>
    </div >
  }
}

const connector = connect(
  (state: AppState) => ({
  }), {
    callMeRequestAction,
  }
)


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(CallMe)