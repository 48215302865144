import { MedcardViewAction, MedcardViewState } from "./medcard.view.types"

export const initialMedcardViewState: MedcardViewState = {
  patient: {},
}

const MedcardViewReducer = (state = initialMedcardViewState, action: MedcardViewAction): MedcardViewState => {
  switch (action.type) {
    case 'MEDCARD_VIEW_PATIENT_SET':
      return {
        ...state,
        patient: action.params,
      }
    case 'MEDCARD_VIEW_PATIENT_CLEAR':
      return {
        ...state,
        patient: {},
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMedcardViewState,
      }
    default:
      return state
  }
}

export default MedcardViewReducer