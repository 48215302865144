declare const grecaptcha: {
  ready: (callback: (...args: any) => any) => any;
  execute: (key: string, params: { action: string }) => Promise<string>;
  render: (id: string, params: { sitekey: string, callback: (token: string) => any }) => any
}

export const recaptcha = {
  ready: async () => {
    return new Promise((resolve) => grecaptcha.ready(resolve))
  },
  execute: async (params: { action: string } = { action: 'send_sms_code' }) => {
    const token3 = await grecaptcha.execute('6LdqjTkhAAAAAEjmYNhN7W3w87_NbxpEpYzxicOe', params)
    return { token3 }
  },
  render: (id: string = 'captcha'): Promise<{ token2: string }> => {
    return new Promise((resolve) => {
      grecaptcha.render(id, {
        sitekey: '6LflvD4hAAAAAPGoz-Q3Ya-NJUYXfPNDwUk53Fdt',
        callback: function (token2) {
          resolve({ token2 })
        },
      })
    })
  },
}
