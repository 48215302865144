import { ArticlesAllState, ArticlesAllAction } from './articles.all.types'

export const initialArticlesAllState: ArticlesAllState = {
  fetching: false,
  articles: [],
}
const ArticlesAllReducer = (state = initialArticlesAllState, action: ArticlesAllAction): ArticlesAllState => {
  switch (action.type) {
    case 'ARTICLES_ALL_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'ARTICLES_ALL_SUCCESS':
      return {
        ...state,
        fetching: false,
        articles: action.params,
      }
    case 'ARTICLES_ALL_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialArticlesAllState
      }
    default:
      return state
  }
}
export default ArticlesAllReducer