import React, { Component } from 'react'
import Template from '../../components/Template'
import { withRouter } from 'react-router-dom'

class NotFoundView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <Template title="Страница не найдена" />
  }
}

export default withRouter(NotFoundView)
