import { combineReducers } from 'redux'
import PricelistCatalogsReducer from './pricelist.catalogs.reducer'
import PricelistCatalogInfoReducer from './pricelist.catalog_info.reducer'
import PricelistCatalogServicesReducer from './pricelist.catalog_services.reducer'
import PricelistDoctorServicesReducer from './pricelist.doctor_services.reducer'
import PricelistPrepareInfoReducer from './pricelist.prepare_info.reducer'
import PricelistPricesReducer from './pricelist.prices.reducer'
import PricelistServiceReducer from './pricelist.service.reducer'

const PricelistReducer = combineReducers({
  catalogs: PricelistCatalogsReducer,
  catalog_services: PricelistCatalogServicesReducer,
  service: PricelistServiceReducer,
  prepareInfo: PricelistPrepareInfoReducer,
  catalogInfo: PricelistCatalogInfoReducer,
  prices: PricelistPricesReducer,
  doctor_services: PricelistDoctorServicesReducer,
})

export default PricelistReducer