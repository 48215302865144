import { DoctorInfoModalState, DoctorInfoModalAction } from './modals.doctorInfo.types'

export const initialDoctorInfoModalState: DoctorInfoModalState = {
  visible: false,
}

const DoctorInfoModalReducer = (state = initialDoctorInfoModalState, action: DoctorInfoModalAction): DoctorInfoModalState => {
  switch (action.type) {
    case 'DOCTOR_INFO_MODAL_SET':
      return {
        ...state,
        ...action.params,
      }
    case 'DOCTOR_INFO_MODAL_CLEAR':
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorInfoModalState,
      }
    default:
      return state
  }
}
export default DoctorInfoModalReducer