import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { Doctor, DoctorPreview } from "./doctor.types";

class DoctorAPI {
  private static GET_DOCTOR = '/get_lk_doctor' as const
  private static GET_DOCTOR_BY_EMPLOYER = '/get_doctor_by_employer_id' as const

  private static GET_DOCTORS = '/get_all_doctors' as const
  private static GET_DOCTORS_BY_DEPARTMENT = '/get_lk_doctors_by_department' as const
  private static GET_DOCTORS_BY_SERVICE = '/get_lk_doctors' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(DoctorAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && (res.url == DoctorAPI.GET_DOCTOR || res.url == DoctorAPI.GET_DOCTOR_BY_EMPLOYER)) {
      // обертка для "неправильного" формата ответа от сервера
      return {
        ...res,
        error: false,
        // @ts-ignore
        data: { doctor: res.data } as T
      } as const
    }
    if (res.error && res.url == DoctorAPI.GET_DOCTORS_BY_DEPARTMENT) {
      return {
        ...res,
        error: false,
        status: 200,
        // @ts-ignore
        data: { doctors: [] } as T
      } as const
    }
    return res
  }

  getDoctor = async (params: { doctor_id: number }) => {
    const res = await this.request<{ doctor: Doctor }, { message: string }>({
      method: 'get',
      url: DoctorAPI.GET_DOCTOR,
      params,
    })
    return res
  }
  getDoctorByEmployer = async (params: { employer_id: number }) => {
    const res = await this.request<{ doctor: Doctor }>({
      method: 'get',
      url: DoctorAPI.GET_DOCTOR_BY_EMPLOYER,
      params,
    })
    return res
  }

  getDoctors = async () => {
    const res = await this.request<{ doctors: DoctorPreview[] }, { message: string }>({
      method: 'get',
      url: DoctorAPI.GET_DOCTORS,
    })
    return res
  }
  getDoctorsByDepartment = async (params: { department_id: number }) => {
    const res = await this.request<{ doctors: DoctorPreview[] }, { message: string }>({
      method: 'get',
      url: DoctorAPI.GET_DOCTORS_BY_DEPARTMENT,
      params,
    })
    return res
  }
  getDoctorsByService = async (params: { service_id: number }) => {
    const res = await this.request<{ doctors: DoctorPreview[] }, { message: string }>({
      method: 'get',
      url: DoctorAPI.GET_DOCTORS_BY_SERVICE,
      params,
    })
    return res
  }
}

export default DoctorAPI