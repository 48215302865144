import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { CatalogPreview, CatalogItem, Service, PrepareInfo, CatalogInfo, PriceGroup, DoctorService, Price } from "./pricelist.types";

class PricelistAPI {
  private static GET_CATALOGS = '/get_services' as const
  private static GET_CATALOG_SERVICES_BY_PATIENT = '/get_prices_by_patient' as const

  private static GET_DOCTOR_SERVICES_BY_PATIENT = '/get_services_by_doctor_and_patient' as const

  private static GET_SERVICE = '/get_service_by_pls_id' as const
  private static GET_PREPARE_INFO = '/get_prepare_info_by_pls_id' as const

  private static GET_CATALOG_DESCRIPTION = '/get_lk_service' as const

  private static GET_PRICES_BY_SERVICE = '/get_prices' as const
  private static GET_PRICES_BY_DOCTOR = '/get_prices_by_doctor_id' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(PricelistAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (!res.error && res.url == PricelistAPI.GET_CATALOGS) {
      return {
        ...res,
        // @ts-ignore
        data: { catalogs: res.data.services } as T
      } as const
    }
    if (res.error && res.url == PricelistAPI.GET_PRICES_BY_DOCTOR && res.status == 404) {
      // обертка для "неправильного" формата ответа от сервера
      return {
        ...res,
        // @ts-ignore
        data: { message: res.data.prices } as U,
      } as const
    }
    if (!res.error && res.url == PricelistAPI.GET_DOCTOR_SERVICES_BY_PATIENT) {
      return {
        ...res,
        // @ts-ignore
        data: { services: [{ prices: res.data.services.map(PricelistAPI.doctorServiceToPrice) }] } as T,
      } as const
    }
    return res
  }

  private static doctorServiceToPrice = (service: DoctorService): Price => {
    // @ts-ignore
    return {
      ...service,
      discount_price: service.price - service.discount,
      name: service.service_name,
    }
  }

  getCatalogs = async () => {
    const res = await this.request<{ catalogs: CatalogPreview[] }, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_CATALOGS,
    })
    return res
  }
  getCatalogServicesByPatient = async (params: { catalog_id: number; service_type?: string; medic_user_id?: number; sex?: 0 | 1; birthdate?: string }) => {
    const res = await this.request<{ services: CatalogItem[] }, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_CATALOG_SERVICES_BY_PATIENT,
      params,
    })
    return res
  }
  getDoctorServicesByPatient = async (params: { doctor_id: number; medic_user_id?: number; sex?: 0 | 1; birthdate?: string }) => {
    const res = await this.request<{ services: CatalogItem[] }, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_DOCTOR_SERVICES_BY_PATIENT,
      params,
    })
    return res
  }
  getService = async (params: { pls_id: number }) => {
    const res = await this.request<{ service: Service }, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_SERVICE,
      params,
    })
    return res
  }
  getCatalogDescription = async (params: { service_id: number }) => {
    const res = await this.request<CatalogInfo, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_CATALOG_DESCRIPTION,
      params,
    })
    return res
  }

  getPrepareInfo = async (params: { pls_id: number }) => {
    const res = await this.request<PrepareInfo, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_PREPARE_INFO,
      params,
    })
    return res
  }

  getPricesByService = async (params: { service_id: number }) => {
    const res = await this.request<{ prices: PriceGroup[] }, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_PRICES_BY_SERVICE,
      params,
    })
    return res
  }

  getPricesByDoctor = async (params: { doctor_id: number }) => {
    const res = await this.request<{ prices: PriceGroup[] }, { message: string }>({
      method: 'get',
      url: PricelistAPI.GET_PRICES_BY_DOCTOR,
      params,
    })
    return res
  }
}

export default PricelistAPI