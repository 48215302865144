import { call, fork, put, take } from "redux-saga/effects"
import { errorSetAction } from "./reducks/error/error.actions"

export const takeLeadingPattern = <P extends string = string, T extends ((...args: any[]) => Generator) = ((...args: any) => any)>(
  actionPattern: P,
  saga: T,
  failureActionGenerator: (...args: any[]) => ({ type: string }),
) => fork(function* () {
  while (true) {
    const action: { type: string } = yield take(actionPattern)
    // @ts-ignore
    yield runRequest(saga, failureActionGenerator, action)
  }
})

export function* runRequest<T extends ((...args: any[]) => any)>(saga: T, failureActionGenerator: (...args: any[]) => ({ type: string }), ...args: Parameters<T>) {
  try {
    yield call(saga, ...args)
  } catch (e) {
    yield put(failureActionGenerator(e))
    yield put(errorSetAction(e && e.message || 'Неизвестная ошибка приложения'))
  }
}