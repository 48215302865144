import React from 'react'
import Container from '../../components/Template/container'
import Doctors from '../../components/Clinic/Doctors'

class DoctorsView extends React.Component {
  render() {
    return <Container
      className="doctors"
      currentItem="clinic/doctors"
      sidebar={<div className="sidebar-default" />}
    >
      <Doctors />
    </Container>
  }
}

export default DoctorsView