import { DoctorRatingTmState, DoctorRatingTmAction } from './doctor.rating.tm.types'
export const initialDoctorRatingTmState: DoctorRatingTmState = {
  fetching: false,
}
const DoctorRatingTmReducer = (state = initialDoctorRatingTmState, action: DoctorRatingTmAction): DoctorRatingTmState => {
  switch (action.type) {
    case 'DOCTOR_RATING_TM_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'DOCTOR_RATING_TM_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: action.params,
      }
    case 'DOCTOR_RATING_TM_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialDoctorRatingTmState
      }
    default:
      return state
  }
}
export default DoctorRatingTmReducer