import React from 'react'
import { Button } from 'antd'
import { Doctor, DoctorPreview } from '../../api/doctor.types'
import config from '../../config'
import './style.less'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { recordClearAction, recordStateSetAction } from '../../store/reducks/record/record.actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import fixHTMLString from '../../tools/fixHTMLString'

export type DoctorInfoProps = ReduxConnectedProps & RouteComponentProps & {
  doctor: Doctor
  onRecord?: () => void
  className?: string
  column?: boolean
  hideButton?: boolean
}

class DoctorInfo extends React.Component<DoctorInfoProps> {
  private onRecord = () => {
      this.props.recordClearAction()
      this.props.recordStateSetAction({
        type: 'bydoctor',
        stage: 2,
        doctor: this.doctorPreview(),
        to_doctor: true,
        // @ts-ignore
        catalog: {},
        format: 'offline',
      })
      this.props.history.push('/recording')
      const { onRecord } = this.props
      onRecord && onRecord()
  }

  private doctorPreview = () => {
    const { doctor } = this.props
    return {
      doctor_id: doctor.doctor_employer_id,
      doctor_employer_id: doctor.doctor_employer_id,
      doctor_name: doctor.doctor_name,
      doctor_preview_img: doctor.doctor_img,
    } as DoctorPreview
  }

  private getAvatar = () => {
    const { doctor } = this.props
    if (doctor.doctor_img && doctor.doctor_img.length > 1000) {
      return `data:image/png;base64,${doctor.doctor_img}`
    }
    return `${config.staticURL}/doctors/${doctor.doctor_img}`
  }

  render() {
    const { doctor, hideButton, className, column } = this.props
    return <div className={`doctor-details ${className || ''} ${column ? 'column' : ''}`}>
      <div className="left">
        <div className="photo">
          <img src={this.getAvatar()} />
          <div className="name">{doctor.doctor_name}</div>
        </div>
        {hideButton ? null : <Button
          className="doctor-record"
          type="primary"
          onClick={this.onRecord}
        >
          {'Записаться на прием'}
        </Button>}
      </div>
      <div className="right">
        <div className="name">{doctor.doctor_name}</div>
        <div className="details" dangerouslySetInnerHTML={{ __html: fixHTMLString(doctor.doctor_descr) }}></div>
      </div>
    </div>
  }
}

const connector = connect(
  (state: AppState) => ({
  }), {
  recordStateSetAction,
  recordClearAction,
})



type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(DoctorInfo))