import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { authByLoginRequest } from '../../store/reducks/auth/auth.actions'
import { medcardAuthModalClearAction } from '../../store/reducks/modals/modals.medcardAuth.actions'
import MedcardAuth from '../../components/Medcard/Auth'
import Modal from '../../components/Modal'
import './style.less'

export type MedcardAuthModalProps = ReduxConnectedProps & {
}

class MedcardAuthModal extends React.Component<MedcardAuthModalProps> {
  componentDidMount() {
    const { modal } = this.props
    if (this.props.authByLogin && modal.visible) {
      this.close()
    }
  }

  componentDidUpdate(prevProps: MedcardAuthModalProps) {
    const { authByLogin } = this.props
    if (authByLogin && !prevProps.authByLogin) {
      this.close()
    }
  }

  private onAuth = (login: string, password: string) => {
    this.props.authByLoginRequest({ login, password })
  }

  private onRecovery = () => {
    const { modal } = this.props
    modal.onRecovery && modal.onRecovery()
    this.props.medcardAuthModalClearAction()
  }

  private close = () => {
    const { modal } = this.props
    modal.onClose && modal.onClose()
    this.props.medcardAuthModalClearAction()
  }

  render() {
    const { modal } = this.props
    return <Modal
      visible={modal.visible}
      className="medcard-auth"
      title="Вход в медкарту"
      onCancel={this.close}
    >
      <MedcardAuth
        onAuth={this.onAuth}
        onRecovery={this.onRecovery}
      />
    </Modal>
  }
}

const connector = connect(
  (state: AppState) => ({
    modal: state.modals.medcardAuth,
    authByLogin: state.user.authByLogin,
  }),
  {
    authByLoginRequest,
    medcardAuthModalClearAction,
  }
)



type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(MedcardAuthModal)