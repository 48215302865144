import React, { Component } from 'react'
import '../../prototypes.d'

interface Props {
  className?: string
  onChange: (code: string) => void
}

interface State {
  code: Array<string>
  selected: number
}

class CodeInput extends Component<Props, State> {
  private input: any

  constructor(props: Props) {
    super(props)
    this.state = {
      code: ['', '', '', ''],
      selected: 0,
    }
  }

  componentDidMount() {
    this.input.focus()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.code != this.state.code) {
      prevProps.onChange(this.state.code.join(''))
    }
  }

  render() {
    const { code, selected } = this.state
    const { className } = this.props
    return (
      <div className={className}>
        <input
          ref={(r) => (this.input = r)}
          value={code[selected]}
          style={{ padding: 0, height: 0, width: 0, position: 'absolute', border: 'none', outline: 'none' }}
          maxLength={1}
          onChange={(e) => {
            const value = e.target.value;
            if (isNaN(+value)) {
              e.preventDefault()
            } else {
              let $code = [...this.state.code]
              $code[selected] = value
              if (selected + 1 < 4) {
                this.setState({ code: $code, selected: selected + 1 })
              } else {
                this.setState({ code: $code })
              }
            }
          }}
          onBlur={(e) => {
            e.target.value = ''
          }}
          onFocus={(e) => {
            e.target.value = ''
          }}
          onKeyPress={(e) => {
            const { key } = e
            if (key == 'ArrowLeft' && selected - 1 >= 0) {
              this.setState({ selected: selected - 1 })
            }
            if (key == 'ArrowRight' && selected + 1 < 4) {
              this.setState({ selected: selected + 1 })
            }
            if (key == 'Backspace') {
              let $code = [...this.state.code]
              $code[selected] = ''
              if (selected - 1 >= 0) {
                this.setState({ selected: selected - 1, code: $code })
              } else {
                this.setState({ code: $code })
              }
            }
          }}
        />
        {[0, 1, 2, 3].map((i, index) => (
          <div
            className={selected == index ? 'selected' : ''}
            key={index.toString()}
            onClick={() => {
              this.setState({ selected: index })
              this.input.focus()
            }}
          >
            {code[index]}
          </div>
        ))}
      </div>
    )
  }
}

export default CodeInput
