import { ClinicContactsAction, ClinicContactsState } from "./clinic.contacts.types"

export const initialClinicContactsState: ClinicContactsState = {
  fetching: false,
  contacts: [],
}

const ClinicContactsReducer = (state = initialClinicContactsState, action: ClinicContactsAction): ClinicContactsState => {
  switch (action.type) {
    case 'CLINIC_CONTACTS_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'CLINIC_CONTACTS_SUCCESS':
      return {
        ...state,
        fetching: false,
        contacts: action.params,
      }
    case 'CLINIC_CONTACTS_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialClinicContactsState,
      }
    default:
      return state
  }
}

export default ClinicContactsReducer