import { MenuAction, MenuState } from "./menu.types"

export const initialMenuState: MenuState = {
  open: false,
  openedKeys: ['clinic'],
}

const MenuReducer = (state = initialMenuState, action: MenuAction): MenuState => {
  switch (action.type) {
    case 'MENU_OPEN':
      return {
        ...state,
        open: true,
      }
    case 'MENU_CLOSE':
      return {
        ...state,
        open: false,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMenuState,
      }
    case 'MENU_OPENED_KEYS_SET':
      return {
        ...state,
        openedKeys: action.params,
      }
    case 'MENU_CURRENT_ITEM_SET':
      return {
        ...state,
        currentItem: action.params,
      }
    default:
      return state
  }
}

export default MenuReducer