import React from 'react'
import PatientList, { PatientListProps } from '../../PatientList'
import NewPatientForm from '../../NewPatientForm'
import { NewPatient } from '../../../store/reducks/record/record.types'
import './style.less'

export type Stage2Props = Pick<PatientListProps, 'mainPatients' | 'addedPatients' | 'onSelect' | 'onAdd'> & {
  onCreate: (patient: NewPatient) => void
  showNewPatientForm?: boolean
  newPatientInit?: NewPatient | null
}

interface State {
  showNewPatientForm: boolean
}

export default class Stage2 extends React.Component<Stage2Props, State> {
  constructor(props: Stage2Props) {
    super(props)
    this.state = {
      showNewPatientForm: props.showNewPatientForm ?? false,
    }
  }

  onAddPatients = () => {
    const { onAdd } = this.props
    onAdd?.()
    this.setState({ showNewPatientForm: true })
  }

  render() {
    const { mainPatients, addedPatients, onSelect, onCreate, newPatientInit } = this.props
    const { showNewPatientForm } = this.state

    return <>
      {!showNewPatientForm ?
        <PatientList
          mainPatients={mainPatients}
          addedPatients={addedPatients}
          onSelect={onSelect}
          onAdd={this.onAddPatients}
        />
        :
        <NewPatientForm
          onCreate={onCreate}
          newPatientInit={newPatientInit}
        />
      }
    </>
  }
}