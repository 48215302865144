import React from 'react'
import { ResearchItem } from '../../../api/research.types'
import iconResearchUltrasound from '../../../images/researchUltrasound.png'
import iconResearchBlood from '../../../images/researchBlood.png'
import iconResearchVideo from '../../../images/researchVideo.png'
import iconResearchXray from '../../../images/researchXray.png'
import iconResearchMain from '../../../images/researchMain.png'

const getResearchIcon = (research: ResearchItem) => {
  switch (research.research_type) {
    case 'uzi':
      return {
        color: '#5A54B7',
        image: iconResearchUltrasound,
      };
    case 'old_analyze':
    case 'citilab_analyze':
    case 'gistology':
      return {
        color: '#41A285',
        image: iconResearchBlood,
      };
    case 'vresearch':
      return {
        color: '#5A54B7',
        image: iconResearchVideo,
      };
    case 'xray':
      return {
        color: '#4F4F4F',
        image: iconResearchXray,
      };
    default:
      return {
        color: '#268AAA',
        image: iconResearchMain,
      };
  }
}

export default getResearchIcon