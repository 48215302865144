import React, { Component } from 'react'
import playmarket from '../images/playmarket.png'
import appstore from '../images/appstore.png'

interface Props {}

interface State {}

class Stores extends Component<Props, State> {
  render() {
    return (
      <div className="stores">
        <a
          target="_blank"
          href={`https://apps.apple.com/us/app/${encodeURIComponent('частная-клиника-медик')}/id1266324669`}
        >
          <img src={appstore} alt="appstore" />
        </a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=ru.mcmedic.client&hl=ru">
          <img src={playmarket} alt="playmarket" />
        </a>
      </div>
    )
  }
}

export default Stores
