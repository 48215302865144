import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../../store'
import TemplateContent from '../../Template/content'
import NewsItem from '../../NewsItem'
import './style.less'
import config from '../../../config'
import { newsItemRequestAction } from '../../../store/reducks/news/news.item.actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'

export type NewsOneProps = ReduxConnectedProps & RouteComponentProps<{ id: string }> & {}

class NewsOne extends React.Component<NewsOneProps> {
  componentDidMount() {
    this.fetchData()
  }

  private fetchData = () => {
    const { match } = this.props
    const { id: news_id } = match.params
    this.props.newsItemRequestAction({ news_id: +news_id })
  }

  private renderItem = () => {
    const { item } = this.props
    if (!item.item) {
      return null
    }
    return <NewsItem
      date={item.item.news_date}
      title={item.item.news_name}
      content={item.item.news_descr}
      image={`${config.staticURL}/news/${item.item.news_img}`}
    />
  }

  private onBack = () => {
    const { history } = this.props
    if (history.action == 'POP') {
      history.push('/clinic/news')
    } else {
      history.goBack()
    }
  }

  render() {
    return <TemplateContent
      title="Новости"
      onTitleBack={this.onBack}
    >
      {this.renderItem()}
    </TemplateContent>
  }
}

const connector = connect(
  (state: AppState) => ({
    item: state.news.item,
  }),
  {
    newsItemRequestAction,
  }
)

type ReduxConnectedProps = ConnectedProps<typeof connector>

export default withRouter(connector(NewsOne))