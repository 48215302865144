import React, { useRef } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SendIcon from '../../icons/SendIcon.svg'
import AttachmentIcon from '../../icons/AttachmentIcon.svg'
import './style.less'
import TextInput, { TextInputProps } from '../TextInput'
import { ALLOWED_FILE_EXTENSIONS } from './allowed-file-extensions'
import { InputFile } from '../InputFile'

export type SendInputProps = TextInputProps & {
  onFile?: (file?: File) => any
  allowedExtensions?: string[]
  file?: File
}

const SendInput: React.FC<SendInputProps> = (props) => {
  const { className, onClick, onFile, allowedExtensions = ALLOWED_FILE_EXTENSIONS, file, ...restProps } = props
  const inputRef = useRef<HTMLInputElement>()
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFile?.(e.target.files?.[0])
  }
  const openFile = () => {
    inputRef.current?.click()
  }
  const extensions = allowedExtensions.map((ext) => `.${ext}`)
  return (
    <TextInput
      className={`send-input ${className || ''}`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className="input-buttons">
            <input
              type="file"
              hidden
              accept={`${extensions.join(', ')}|image/*`}
              onChange={handleFile}
              ref={(input) => {
                inputRef.current = input as HTMLInputElement
              }}
            />
            <InputFile
              file={file}
              onCancel={() => {
                if (inputRef.current) {
                  inputRef.current.value = ''
                }
                onFile?.(inputRef.current?.files?.[0])
              }}
            />
            <img className="attachment-button" src={AttachmentIcon} onClick={openFile} title="Выбрать файл" />
            <img className="send-button" src={SendIcon} onClick={onClick} title="Отправить" />
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  )
}

export default SendInput
