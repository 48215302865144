import { call, put, select } from "redux-saga/effects";
import { AppState } from "../..";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { AppointmentsAction } from "./appointments.actions";
import { appointmentsByMisTokenFailureAction, appointmentsByMisTokenSuccessAction } from "./appointments.bymistoken.actions";
import { appointmentsByPhoneFailureAction, appointmentsByPhoneSuccessAction } from "./appointments.byphone.actions";
import { deleteAppointmentMainFailureAction, deleteAppointmentMainSuccessAction } from "./appointments.delete.main.actions";
import { deletePaidAppointmentFailureAction, deletePaidAppointmentSuccessAction } from "./appointments.delete.paid.actions";
import { futureAppointmentsByServiceFailureAction, futureAppointmentsByServiceSuccessAction } from "./appointments.future.byservice.actions";
import { futureAppointmentsMainFailureAction, futureAppointmentsMainRequestAction, futureAppointmentsMainSuccessAction } from "./appointments.future.main.actions";
import { appointmentsMainFailureAction, appointmentsMainSuccessAction } from "./appointments.main.actions";
import { pastAppointmentsMainFailureAction, pastAppointmentsMainSuccessAction } from "./appointments.past.actions";
import {FutureAppointmentsByServiceAction} from "./appointments.future.byservice.types";

export function* futureAppointmentsByServiceRequestSaga(action: FutureAppointmentsByServiceAction) {
  const res: ThenReturnType<typeof api.appointment.getFutureAppointmentsByService> = yield call(api.appointment.getFutureAppointmentsByService, action.params)
  if (res.error) {
    yield put(futureAppointmentsByServiceFailureAction())
  } else {
    yield put(futureAppointmentsByServiceSuccessAction(res.data.appointments, action.isAdded))
  }
}

export function* futureAppointmentsMainRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.getFutureAppointmentsMain> = yield call(api.appointment.getFutureAppointmentsMain)
  if (res.error) {
    yield put(futureAppointmentsMainRequestAction())
  } else {
    yield put(futureAppointmentsMainSuccessAction(res.data.appointments))
  }
}

export function* pastAppointmentsMainRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.getPastAppointmentsMain> = yield call(api.appointment.getPastAppointmentsMain, action.params)
  if (res.error) {
    yield put(pastAppointmentsMainFailureAction())
  } else {
    yield put(pastAppointmentsMainSuccessAction(res.data.appointments))
  }
}

export function* appointmentsMainRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.getAppointmentsMain> = yield call(api.appointment.getAppointmentsMain, action.params)
  if (res.error) {
    yield put(appointmentsMainFailureAction())
  } else {
    yield put(appointmentsMainSuccessAction(res.data.appointments))
  }
}

export function* appointmentsByPhoneRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.getAppointmentsByPhone> = yield call(api.appointment.getAppointmentsByPhone, action.params)
  if (res.error) {
    yield put(appointmentsByPhoneFailureAction())
  } else {
    yield put(appointmentsByPhoneSuccessAction(res.data.appointments))
  }
}

export function* appointmentsByMisTokenRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.getAppointmentsByMisToken> = yield call(api.appointment.getAppointmentsByMisToken, action.params)
  if (res.error) {
    yield put(appointmentsByMisTokenFailureAction())
  } else {
    yield put(appointmentsByMisTokenSuccessAction(res.data.appointments))
  }
}

export function* deleteAppointmentMainRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.deleteAppointment> = yield call(api.appointment.deleteAppointment, action.params)
  if (res.error) {
    yield put(deleteAppointmentMainFailureAction())
  } else {
    yield put(deleteAppointmentMainSuccessAction())
  }
}

export function* deletePaidAppointmentRequestSaga(action: AppointmentsAction) {
  const res: ThenReturnType<typeof api.appointment.deletePaidAppointment> = yield call(api.appointment.deletePaidAppointment, action.params)
  if (res.error) {
    yield put(deletePaidAppointmentFailureAction())
  } else {
    yield put(deletePaidAppointmentSuccessAction())
  }
}

export default function* watchAppointments() {
  yield takeLeadingPattern<AppointmentsAction['type']>('FUTURE_APPOINTMENTS_BY_SERVICE_REQUEST', futureAppointmentsByServiceRequestSaga, futureAppointmentsByServiceFailureAction)
  yield takeLeadingPattern<AppointmentsAction['type']>('FUTURE_APPOINTMENTS_MAIN_REQUEST', futureAppointmentsMainRequestSaga, futureAppointmentsMainFailureAction)

  yield takeLeadingPattern<AppointmentsAction['type']>('PAST_APPOINTMENTS_MAIN_REQUEST', pastAppointmentsMainRequestSaga, pastAppointmentsMainFailureAction)

  yield takeLeadingPattern<AppointmentsAction['type']>('APPOINTMENTS_MAIN_REQUEST', appointmentsMainRequestSaga, appointmentsMainFailureAction)
  yield takeLeadingPattern<AppointmentsAction['type']>('APPOINTMENTS_BY_PHONE_REQUEST', appointmentsByPhoneRequestSaga, appointmentsByPhoneFailureAction)
  yield takeLeadingPattern<AppointmentsAction['type']>('APPOINTMENTS_BY_MISTOKEN_REQUEST', appointmentsByMisTokenRequestSaga, appointmentsByMisTokenFailureAction)

  yield takeLeadingPattern<AppointmentsAction['type']>('DELETE_APPOINTMENT_MAIN_REQUEST', deleteAppointmentMainRequestSaga, deleteAppointmentMainFailureAction)
  yield takeLeadingPattern<AppointmentsAction['type']>('DELETE_PAID_APPOINTMENT_REQUEST', deletePaidAppointmentRequestSaga, deletePaidAppointmentFailureAction)
}