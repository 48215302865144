interface String {
  replaceAt(index: number, replacement: any): string
}

String.prototype.replaceAt = function (index: number, replacement: any) {
  return this.substr(0, index) + replacement + this.substr(index + replacement.length)
}

Object.defineProperty(Object.prototype, 'getPath', {
  value: function getPath(path: string) {
    if (typeof path !== 'string') return undefined
    return path.split('.').reduce((a, k) => (a === undefined || a === null ? undefined : a[k]), this)
  },
})
