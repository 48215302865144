import { MedcardVisitsByPatientAction, MedcardVisitsByPatientState } from "./medcard.visits.bypatient.types"

export const initialMedcardVisitsByPatientState: MedcardVisitsByPatientState = {
  fetching: false,
  visits: [],
}

const MedcardVisitsByPatientReducer = (state = initialMedcardVisitsByPatientState, action: MedcardVisitsByPatientAction): MedcardVisitsByPatientState => {
  switch (action.type) {
    case 'MEDCARD_VISITS_BY_PATIENT_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'MEDCARD_VISITS_BY_PATIENT_SUCCESS':
      return {
        ...state,
        fetching: false,
        visits: action.params,
      }
    case 'MEDCARD_VISITS_BY_PATIENT_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialMedcardVisitsByPatientState,
      }
    default:
      return state
  }
}

export default MedcardVisitsByPatientReducer