import moment from 'moment'
import React from 'react'
import { Patient } from '../../../api/patient.types'
import { ResearchItem } from '../../../api/research.types'
import PatientHelper from '../../../store/reducks/patients/PatientHelper'
import getResearchIcon from './getResearchIcon'
import './style.less'

export type ResearchProps = {
  research: ResearchItem
  patient?: Patient
  className?: string
  onClick?: (research: ResearchItem) => void
}

class Research extends React.Component<ResearchProps> {
  static Icon: React.FC<{ research: ResearchItem }> = (props) => {
    const { research } = props
    const icon = getResearchIcon(research)
    return <img src={icon.image} className="research-icon" />
  }

  private getPatientName = () => {
    const { research } = this.props
    const patient = PatientHelper.findPatient(research.medic_user_id)
    return PatientHelper.getName(patient || this.props.patient as Patient)
  }

  private onClick = () => {
    const { onClick, research } = this.props
    onClick && onClick(research)
  }

  render() {
    const { research, className } = this.props
    return <div className={`research-item ${className || ''}`} onClick={this.onClick}>
      <div className="icon">
        <Research.Icon research={research} />
      </div>
      <div className="info">
        <div className="left">
          <span>{research.research_spec}</span>
          <span>{this.getPatientName()}</span>
        </div>
        <div className="right">
          <span>
            {moment(research.research_date).format('DD MMM')}
          </span>
        </div>
      </div>
    </div>
  }
}

export default Research