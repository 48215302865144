import { PatientsBirthdateState, PatientsBirthdateAction } from "./patients.birthdate.date.types"

export const initialPatientsBirthdateState: PatientsBirthdateState = {
  fetching: false,
  date: '',
}

const PatientsBirthdateDateReducer = (state = initialPatientsBirthdateState, action: PatientsBirthdateAction): PatientsBirthdateState => {
  switch (action.type) {
    case 'PATIENT_BIRTHDATE_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENT_BIRTHDATE_SUCCESS':
    case 'PATIENT_BIRTHDATE_FAILURE':
      return {
        ...state,
        date: action.params,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
    case 'PATIENTS_CLEAR':
      return {
        ...initialPatientsBirthdateState,
      }
    default:
      return state
  }
}

export default PatientsBirthdateDateReducer