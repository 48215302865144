import { PatientsBymistokenMainAction, PatientsBymistokenMainState } from "./patients.bymistoken.main.types"

export const initialPatientsBymistokenMainState: PatientsBymistokenMainState = {
  fetching: false,
  users: [],
}

const PatientsBymistokenMainReducer = (state = initialPatientsBymistokenMainState, action: PatientsBymistokenMainAction): PatientsBymistokenMainState => {
  switch (action.type) {
    case 'PATIENTS_BYMISTOKEN_MAIN_REQUEST':
      return {
        ...state,
        fetching: true,
      }
    case 'PATIENTS_BYMISTOKEN_MAIN_SUCCESS':
    case 'PATIENTS_BYMISTOKEN_MAIN_FAILURE':
      return {
        ...state,
        ...action.params,
        fetching: false,
      }
    case 'STORAGE_CLEAR':
    case 'PATIENTS_CLEAR':
      return {
        ...initialPatientsBymistokenMainState,
      }
    default:
      return state
  }
}

export default PatientsBymistokenMainReducer