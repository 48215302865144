import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { BonusHistoryItem, BonusIncreasedCashbackItem, BonusPoints, BonusSettings } from "./bonus.types";


class BonusAPI {
  private static GET_BONUS_TOKEN = '/get_bonus_token' as const
  private static GET_BONUS_CODE = '/get_bonus_code' as const
  private static GET_BONUS_POINTS = '/get_bonus_points' as const
  private static GET_BONUS_SETTINGS = '/get_bonus_settings' as const
  private static GET_BONUS_HISTORY = '/get_bonus_history' as const
  private static GET_BONUS_INCREASED_CASHBACK = '/get_bonus_increased_cashback' as const

  private api: API
  
  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(BonusAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (res.error) {
      return {
        ...res,
        // @ts-ignore
        data: { message: res.data.error?.message } as U
      } as const
    }
    if (!res.error) {
      return {
        ...res,
        // @ts-ignore
        data: res.data?.data as T
      } as const
    }
    return res
  }

  getToken = async (params?: any) => {
    const res = await this.request<{ token: string }, { message: string }>({
      method: 'get',
      url: BonusAPI.GET_BONUS_TOKEN,
      params,
    })
    return res
  }
  getCode = async () => {
    const res = await this.request<{ code: string }, { message: string }>({
      method: 'get',
      url: BonusAPI.GET_BONUS_CODE,
    })
    return res
  }
  getPoints = async (params?: { bonus_code: string }) => {
    const res = await this.request<{ points: BonusPoints }, { message: string }>({
      method: 'get',
      url: BonusAPI.GET_BONUS_POINTS,
      params,
    })
    return res
  }
  getSettings = async () => {
    const res = await this.request<{ settings: BonusSettings }, { message: string }>({
      method: 'get',
      url: BonusAPI.GET_BONUS_SETTINGS,
    })
    return res
  }
  getHistory = async () => {
    const res = await this.request<{ history: BonusHistoryItem[] }, { message: string }>({
      method: 'get',
      url: BonusAPI.GET_BONUS_HISTORY,
    })
    return res
  }
  getIncreasedCashback = async () => {
    const res = await this.request<BonusIncreasedCashbackItem[], { message: string }>({
      method: 'get',
      url: BonusAPI.GET_BONUS_INCREASED_CASHBACK,
    })
    return res
  }
}

export default BonusAPI