import config from "../config";
import lazyStore from "../store/lazyStore";
import { errorSetAction } from "../store/reducks/error/error.actions";
import API, { RequestOptions, RequestReturnType } from "./api";
import { DoctorRatingParams, FeedBackParams, ReportErrorParams } from "./feedback.types";

class FeedBackAPI {
  private static CALL_ME = '/post_call_back_form' as const
  private static SEND_FEEDBACK = '/post_contact_form' as const
  private static SEND_DOCTOR_RATING = '/post_doctor_rating' as const
  private static SEND_DOCTOR_RATING_TM = `${config.api.telemedURL}/api/doctor/rating` as const
  private static REPORT_ERROR = '/post_send_error' as const

  private api: API

  constructor(api: API) {
    this.api = api
  }

  private request = async <T = any, U extends { message: string } = any>(options: RequestOptions) => {
    const res = await this.api.request<T, U>(options).then(FeedBackAPI.responseTransform)
    if (res.error) {
      lazyStore.store.dispatch(errorSetAction(res.data.message))
    }
    return res
  }

  private static responseTransform = <T = any, U extends { message: string } = any>(res: RequestReturnType<T, U>) => {
    if (res.url == FeedBackAPI.SEND_DOCTOR_RATING_TM) {
      // @ts-ignore
      if (res.data && res.data.success && res.error) {
        return {
          ...res,
          error: false,
          status: 200,
        } as const
      }
      // @ts-ignore
      if (res.data && (!res.data.success || res.error)) {
        return {
          ...res,
          error: true,
          // @ts-ignore
          data: { message: res.data.error } as U
        } as const
      }
    }
    return res
  }

  callMe = async (params: { phone: string; name: string; }) => {
    const res = await this.request<{ phone: number; name: string; }, { message: string; }>({
      method: 'post',
      url: FeedBackAPI.CALL_ME,
      data: params,
    })
    return res
  }
  
  sendFeedBack = async (params: FeedBackParams) => {
    const res = await this.request<any, { message: string }>({
      method: 'post',
      url: FeedBackAPI.SEND_FEEDBACK,
      data: params,
    })
    return res
  }

  sendDoctorRating = async (params: DoctorRatingParams) => {
    const res = await this.request<{ message: string }>({
      method: 'post',
      url: FeedBackAPI.SEND_DOCTOR_RATING,
      data: params,
    })
    return res
  }

  sendDoctorRatingTM = async (params: DoctorRatingParams) => {
    const res = await this.request<any, { message: string }>({
      method: 'post',
      url: FeedBackAPI.SEND_DOCTOR_RATING_TM,
      data: params,
    })
    return res
  }

  reportError = async (params: ReportErrorParams) => {
    const res = await this.request({
      method: 'post',
      url: FeedBackAPI.REPORT_ERROR,
      data: params,
    })
    return res
  }
}

export default FeedBackAPI