import React from 'react'
import { Card, List } from 'antd'
import { GeoLocationIcon } from '../../icons'
import DoctorCardTimes from '../DoctorCardTimes'
import { DivisionDoctor } from '../../api/schedule.api.types'
import './style.less'
import { DoctorPreview } from '../../api/doctor.types'
import { connect, ConnectedProps } from 'react-redux'
import { AppState } from '../../store'
import { doctorByEmployerIdRequestAction, doctorByIdRequestAction } from '../../store/reducks/doctors/doctors.item.actions'
import { doctorInfoModalSetAction } from '../../store/reducks/modals/modals.doctorInfo.actions'

export type RecordingDepartmentDoctorsProps = ReduxConnectedProps & {
  doctorsWithTimes: any[]
  departmentAddress: string
  onDateTime?: (datetime: string) => void
  onDoctor?: (doctor: DoctorPreview) => void
  onAddress?: (address: string) => void
  selectedDateTime?: string
  selectedDoctor?: DoctorPreview
}

class RecordingDepartmentDoctors extends React.Component<RecordingDepartmentDoctorsProps> {
  renderTitle = () => {
    const { departmentAddress } = this.props
    return <div className="department__title">
      <GeoLocationIcon />
      <span>{departmentAddress}</span>
    </div>
  }

  private showDoctor = (doctor: DoctorPreview) => {
    // this.props.doctorByIdRequestAction({ doctor_id: doctor.doctor_id })
    this.props.doctorByEmployerIdRequestAction({ employer_id: doctor.doctor_id })
    this.props.doctorInfoModalSetAction({ visible: true })
  }

  render() {
    const { doctorsWithTimes, onDateTime, onDoctor, onAddress, selectedDateTime, departmentAddress, selectedDoctor } = this.props
    return <Card
      title={this.renderTitle()}
      className="recording-department__card"
    >
      <List
        dataSource={doctorsWithTimes}
        renderItem={(doctor: DivisionDoctor) => (<List.Item>
          <DoctorCardTimes
            name={doctor.doctor_name}
            avatar={doctor.doctor_preview_img}
            spec={doctor.service_name}
            datetimes={doctor.times}
            onDateTime={(datetime) => {
              onDateTime && onDateTime(datetime)
              onDoctor && onDoctor(doctor)
              onAddress && onAddress(departmentAddress)
            }}
            onDoctor={() => this.showDoctor(doctor)}
            onInfo={() => this.showDoctor(doctor)}
            selectedDateTime={doctor.doctor_id == selectedDoctor?.doctor_id ? selectedDateTime : undefined}
          />
        </List.Item>)}
      />
    </Card>
  }
}

const connector = connect(
  (state: AppState) => ({}), {
  doctorByIdRequestAction,
  doctorByEmployerIdRequestAction,
  doctorInfoModalSetAction,
})


type ReduxConnectedProps = ConnectedProps<typeof connector>

export default connector(RecordingDepartmentDoctors)