import { call, put, select } from "redux-saga/effects";
import { AppState } from "../..";
import api from "../../../api";
import { ThenReturnType } from "../../types";
import { takeLeadingPattern } from "../../utils";
import { recoveryCheckCodeFailureAction, recoveryCheckCodeSuccessAction } from "./recovery.check_code.actions";
import { recoverySendPasswordFailureAction, recoverySendPasswordSuccessAction } from "./recovery.send_password.actions";
import { recoverySendSmsFailureAction, recoverySendSmsSuccessAction } from "./recovery.send_sms.actions";
import { RecoveryAction } from "./recovery.types";

export function* loginRecoverySendSmsRequestSaga(action: RecoveryAction) {
  const res: ThenReturnType<typeof api.login.sendPasswordRecoveryCode> = yield call(api.login.sendPasswordRecoveryCode, action.params)
  if (res.error) {
    yield put(recoverySendSmsFailureAction())
  } else {
    yield put(recoverySendSmsSuccessAction())
  }
}

export function* loginRecoveryCheckCodeRequestSaga(action: RecoveryAction) {
  const res: ThenReturnType<typeof api.login.checkPasswordRecoveryCode> = yield call(api.login.checkPasswordRecoveryCode, action.params)
  if (res.error) {
    yield put(recoveryCheckCodeFailureAction())
  } else {
    yield put(recoveryCheckCodeSuccessAction(res.data.users))
  }
}

export function* loginRecoverySendPasswordRequestSaga(action: RecoveryAction) {
  const res: ThenReturnType<typeof api.login.recoverPassword> = yield call(api.login.recoverPassword, action.params)
  if (res.error) {
    yield put(recoverySendPasswordFailureAction())
  } else {
    yield put(recoverySendPasswordSuccessAction())
  }
}

export default function* watchLogin() {
  yield takeLeadingPattern<RecoveryAction['type']>('RECOVERY_SEND_SMS_REQUEST', loginRecoverySendSmsRequestSaga, recoverySendSmsFailureAction)
  yield takeLeadingPattern<RecoveryAction['type']>('RECOVERY_CHECK_CODE_REQUEST', loginRecoveryCheckCodeRequestSaga, recoveryCheckCodeFailureAction)
  yield takeLeadingPattern<RecoveryAction['type']>('RECOVERY_SEND_PASSWORD_REQUEST', loginRecoverySendPasswordRequestSaga, recoverySendPasswordFailureAction)
}