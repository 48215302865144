import { ChatMessageState, ChatMessageAction } from './chat.message.types'
export const initialChatMessageState: ChatMessageState = {
  fetching: false,
  messages: [],
  unread: 0,
}
const ChatMessageReducer = (state = initialChatMessageState, action: ChatMessageAction): ChatMessageState => {
  switch (action.type) {
    case 'CHAT_MESSAGE_REQUEST':
      return {
        ...state,
        fetching: true,
        error: false,
      }
    case 'CHAT_MESSAGE_SUCCESS':
      return {
        ...state,
        fetching: false,
        messages: action.params,
      }
    case 'CHAT_MESSAGE_APPEND':
      return {
        ...state,
        messages: [
          ...state.messages,
          action.params,
        ]
      }
    case 'CHAT_MESSAGE_FAILURE':
      return {
        ...state,
        fetching: false,
        error: true,
      }
    case 'CHAT_MESSAGE_UNREAD_INCREASE':
      return {
        ...state,
        unread: state.unread + 1,
      }
    case 'CHAT_MESSAGE_UNREAD_DROP':
      return {
        ...state,
        unread: 0,
      }
    case 'STORAGE_CLEAR':
      return {
        ...initialChatMessageState
      }
    default:
      return state
  }
}
export default ChatMessageReducer