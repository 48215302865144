import React from 'react'
import Action from '../../components/Clinic/Actions/Action'
import Container from '../../components/Template/container'

const ActionView: React.FC = () => {
  return <Container
    className="action"
    currentItem="clinic/actions"
    sidebar={<div className="sidebar-default" />}
  >
    <Action />
  </Container>
}

export default ActionView