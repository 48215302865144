import io from 'socket.io-client'
import config from '../../../config'
import lazyStore from '../../lazyStore'
import { chatSocketConnectFailureAction, chatSocketConnectSuccessAction, chatSocketDisconnectSuccessAction } from './chat.socket.actions'

export type ConnectionProps = {
  onConnectionStart?: () => any;
  onConnect?: (socket: SocketIOClient.Socket) => any;
  onDisconnect?: (socket: SocketIOClient.Socket, reason?: any) => any;
  onError?: (socket: SocketIOClient.Socket, error?: any) => any;
}

export default class ChatSocket {
  static sockets: SocketIOClient.Socket[] = []

  static connect(props: ConnectionProps = {}) {
    const socket = ChatSocket.createSocket(props)
    const { onConnect, onDisconnect, onError } = props
    socket.on('connect', () => {
      ChatSocket.sockets.push(socket)
      lazyStore.store.dispatch(chatSocketConnectSuccessAction(socket.id))
      onConnect && onConnect(socket)
    })
    socket.on('disconnect', (reason: any) => {
      onDisconnect && onDisconnect(socket, reason)
      lazyStore.store.dispatch(chatSocketDisconnectSuccessAction(socket.id))
      ChatSocket.removeSocket(socket.id)
    })
    socket.on('error', (reason: any) => {
      lazyStore.store.dispatch(chatSocketConnectFailureAction(reason))
      onError && onError(socket, reason)
    })
    return socket
  }

  private static createSocket(props?: ConnectionProps) {
    const { user } = lazyStore.store.getState()
    const socket = io(`${config.chat.baseURL}/lk`, {
      query: `accesstoken=${user.token}&room=default_${user.phone}`,
      secure: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
    })
    try {
      props?.onConnectionStart && props.onConnectionStart()
    } catch (e) { }
    return socket
  }

  private static removeSocket(socketId: string) {
    const index = ChatSocket.sockets.findIndex(s => s.id == socketId)
    if (index >= 0) {
      ChatSocket.sockets.splice(index, 1)
    }
    return index
  }

  static disconnect(socketId: string) {
    const socket = ChatSocket.sockets.find(s => s.id == socketId)
    if (socket) {
      socket.disconnect()
      return true
    }
    return false
  }

  static getConnectedSocket() {
    const state = lazyStore.store.getState()
    const socket = state.chat.socket.socket
    if (socket && socket.connected && socket.id) {
      return ChatSocket.sockets.find(soc => soc.id == socket.id)
    }
  }

  static disconnectAll() {
    for (const socket of ChatSocket.sockets) {
      socket.disconnect()
    }
  }
}
