import { store, AppState } from './store'
import config from './config'
import { create, ApisauceInstance, ApiResponse } from 'apisauce'
import { authLogoutAction } from './store/reducers/auth'
import { errorClearAction, errorSetAction } from './store/reducks/error/error.actions'
import { IPatient, ISortablePatient, IPricelistCatalog, IPricelistService } from './store/types'

interface MedicApIResponse<T> {
  success: boolean
  result?: T
  error: {
    code: string
    message: string
  }
}

type MedicApiRequest = (url: string, payload?: Object, headers?: Object) => Promise<ApiResponse<MedicApIResponse<any>>>

export interface AuthSendCodeResponse {
  token?: string
}

export interface AuthCheckCodeResponse {
  token: string
}

export interface PatientsByPhoneGetResponse {
  patients: IPatient[]
}

export type PatientsByPhoneSortResponse = PatientsByPhoneGetResponse

export interface PricelistCatalogsGetResponse {
  catalogs: IPricelistCatalog[]
}
export interface PricelistCatalogServicesGetResponse {
  services: IPricelistService[]
}

class Api {
  private apisauce: ApisauceInstance

  constructor(baseURL: string, clientCode: string) {
    this.apisauce = create({
      baseURL,
      headers: {
        'Cache-Control': 'no-cache',
        'X-CLIENT-CODE': config.api.code,
      },
      timeout: 60 * 1000,
    })
  }

  _headers = () => {
    const state: AppState = store.getState()
    const token = state.auth?.token || ''
    return {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  }

  _get = async (url: string, payload?: any, headers?: any) => {
    return await this._handleResponse(this.apisauce.get, { url, payload, headers })
  }

  _put = async (url: string, payload?: any, headers?: any) => {
    return await this._handleResponse(this.apisauce.put, { url, payload, headers })
  }

  _post = async (url: string, payload?: any, headers?: any) => {
    return await this._handleResponse(this.apisauce.post, { url, payload, headers })
  }

  _delete = async (url: string, payload?: any, headers?: any) => {
    return await this._handleResponse(this.apisauce.delete, { url, payload, headers })
  }

  _handleResponse = async (apiRequest: MedicApiRequest, params: any): Promise<ApiResponse<MedicApIResponse<any>>> => {
    const res = await apiRequest(params.url, params.payload, params.headers)
    return this._handleError(res)
  }

  _handleError = (res: ApiResponse<MedicApIResponse<any>>) => {
    store.dispatch(errorClearAction())
    if (!res.ok) {
      if (res.status === 401 || res.status === 403) {
        // @ts-ignore
        store.dispatch(authLogoutAction)
      } else if (res.data?.error) {
        let message = null
        switch (res.data?.error.code) {
          case 'SMS_ERROR_1':
            message = 'Срок действия кода истек'
            break
          case 'SMS_ERROR_0':
          case 'SMS_ERROR_2':
            message = 'Неверный код'
            break
          default:
            message = 'Неизвестная ошибка сервера'
        }
        console.log({ message })
        store.dispatch(errorSetAction(message))
      } else {
        let message = null
        switch (res.problem) {
          case 'CLIENT_ERROR':
            message = 'Неизвестная ошибка приложения'
            break
          case 'SERVER_ERROR':
            message = 'Неизвестная ошибка сервера'
            break
          case 'TIMEOUT_ERROR':
            message = 'Время ожидания ответа от сервера истекло'
            break
          case 'CONNECTION_ERROR':
            message = 'Сервер недоступен'
            break
          case 'NETWORK_ERROR':
            message = 'Нет доступа к интернету'
            break
          default:
            message = 'Неизвестная ошибка'
        }
        console.error({ originalError: res.originalError })
        store.dispatch(errorSetAction(message))
      }
    } else {
      store.dispatch(errorClearAction())
    }
    return res
  }

  authSendCodeRequest = async (payload: { phone: string }): Promise<string> => {
    const res: ApiResponse<MedicApIResponse<AuthSendCodeResponse>> = await this._post(
      'auth/phone',
      { data: payload },
      this._headers()
    )
    return res.data?.result?.token || ''
  }

  authCheckCodeRequest = async (payload: { phone: string; code: string }): Promise<string> => {
    const res: ApiResponse<MedicApIResponse<AuthCheckCodeResponse>> = await this._post(
      'auth/phone',
      { data: payload },
      this._headers()
    )
    return res.data?.result?.token || ''
  }

  patientsByPhoneGetRequest = async (): Promise<IPatient[]> => {
    const res: ApiResponse<MedicApIResponse<PatientsByPhoneGetResponse>> = await this._get(
      'patients/phone',
      {},
      this._headers()
    )
    return res.data?.result?.patients || []
  }

  patientsByPhoneSortRequest = async (payload: { sort: ISortablePatient[] }): Promise<IPatient[]> => {
    const res: ApiResponse<MedicApIResponse<PatientsByPhoneSortResponse>> = await this._post(
      'patients/phone/sort',
      { data: payload },
      this._headers()
    )
    return res.data?.result?.patients || []
  }

  pricelistCatalogsGetRequest = async (): Promise<IPricelistCatalog[]> => {
    const res: ApiResponse<MedicApIResponse<PricelistCatalogsGetResponse>> = await this._get(
      'pricelist/catalogs',
      {},
      this._headers()
    )
    return res.data?.result?.catalogs || []
  }

  pricelistCatalogServicesGetRequest = async (
    catalog_id: string,
    patient_id?: string
  ): Promise<IPricelistService[]> => {
    const res: ApiResponse<MedicApIResponse<PricelistCatalogServicesGetResponse>> = await this._get(
      `pricelist/services/${catalog_id}`,
      { patient_id },
      this._headers()
    )
    return res.data?.result?.services || []
  }
}

export const MedicApi = new Api(config.api.url, config.api.code)
