import React, { useState } from 'react'
import { List } from 'antd'
import { ChevronLeftIcon, NoticeIcon } from '../../icons'
import './style.less'
import { CatalogItem, CatalogPreview, Price } from '../../api/pricelist.types'
import EmptyServiceList from '../EmptyServiceList'
import Modal from '../Modal'
import CallMe from '../CallMe'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { AppState } from '../../store'

export type ServiceListProps = {
  catalog_services: CatalogItem[]
  onSelect: (selectedService: Price) => void
  onPrepareInfo?: (service: Price) => void
  activeKey?: string
  className?: string
}

const ServiceList: React.FC<ServiceListProps> = (props) => {
  const { className, catalog_services, onSelect, activeKey } = props
  const [visible, setVisible] = useState<boolean>()
  const user = useSelector((state: AppState) => state.user)
  const getEmptyListText = () => {
    if (activeKey == '0') {
      return 'К сожалению, самостоятельная запись недоступна. Свяжитесь с оператором'
    }
    return 'Список пуст'
  }
  return (
    <div className={`service-list ${className || ''}`}>
      {catalog_services.length == 0 && <EmptyServiceList
        text={getEmptyListText()}
        buttons={[{
          type: 'default',
          text: 'Перезвоните мне',
          onClick: () => setVisible(true),
        }]}
      />}
      {catalog_services.length > 0 && (
        <div className="container">
          {catalog_services.map((catalog_service, index: number) => (
            <List
              key={index.toString()}
              className={`parent ${catalog_service.prices.length == 0 ? 'empty' : ''}`}
              header={catalog_service.title}
              dataSource={catalog_service.prices}
              renderItem={(item, index: number) => {
                const price = item.price
                const discount = item.discount_price
                const is_allowed = (item.is_allowed_for_this_patient ?? 1) && (item.allowrec ?? 1)
                return (
                  <List.Item
                    onClick={() => {
                      if (is_allowed) {
                        onSelect(item)
                      }
                    }}
                    key={index.toString()}
                    className={is_allowed ? '' : 'disabled'}
                  >
                    <>
                      <div className="left">
                        <div className="name">
                          <div
                            className={`notice`}
                            onClick={(e) => {
                              e.stopPropagation()
                              const { onPrepareInfo } = props
                              onPrepareInfo && onPrepareInfo(item)
                            }}
                          >
                            <NoticeIcon />
                          </div>
                          {item.name}
                        </div>
                      </div>
                      <div className="right">
                        <div className="price-wrapper">
                          {discount !== null ? (
                            <>
                              <div className="price-new">{discount} ₽</div>
                              <div className="price-old">{price} ₽</div>
                            </>
                          ) : (
                            <div className="price">{price} ₽</div>
                          )}
                        </div>
                        <div className="icon">
                          <ChevronLeftIcon />
                        </div>
                      </div>
                    </>
                  </List.Item>
                )
              }}
            />
          ))}
        </div>
      )}
      <Modal
        title="Перезвоните мне"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <CallMe
          phone={user.phone}
          onSubmit={() => setVisible(false)}
        />
      </Modal>
    </div>
  )
}

// const connector = connect(
//   (state: AppState) => ({
//     user: state.user,
//   }), {
//   }
// )

// type ReduxConnectedProps = ConnectedProps<typeof connector>

export default ServiceList
