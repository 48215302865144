import React from 'react'
import Actions from "../../components/Clinic/Actions"
import Container from "../../components/Template/container"

const ActionsView: React.FC = () => {
  return <Container
    className="actions"
    currentItem="clinic/actions"
    sidebar={<div className="sidebar-default" />}
  >
    <Actions />
  </Container>
}

export default ActionsView