import React, { useState } from 'react'
import { ArticlePreview } from '../../api/newsfeed.types'
import config from '../../config'
import NewsItem from '../NewsItem'
import './style.less'

export type ArticleListProps = {
  articles: ArticlePreview[]
  onSelect?: (article: ArticlePreview) => void
}

class ArticleList extends React.Component<ArticleListProps> {
  static Item: React.FC<{ article: ArticlePreview; preview?: boolean }> = (props) => {
    const { article } = props
    const [preview, setPreview] = useState(props.preview)
    const { article_preview_descr: full_text, article_preview_text: preview_text } = article
    const text = (preview ? preview_text : full_text) || ''
    return <NewsItem
      image={`${config.staticURL}/articles/${article.article_preview_img}`}
      onImage={() => setPreview(!preview)}
      title={article.article_name}
      content={text}
    />
  }

  private renderItem = (article: ArticlePreview, index: number) => {
    return <ArticleList.Item
      key={index}
      article={article}
      preview
    />
  }

  render() {
    const { articles } = this.props
    return <div className="article-list">
      {articles.map(this.renderItem)}
    </div>
  }
}

export default ArticleList