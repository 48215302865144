export const fileToBase64 = async (file: File) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise<string>((resolve, reject) => {
    reader.onload = function () {
      resolve(reader.result as string)
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
}
